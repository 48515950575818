/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Kendo skin */
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.k-theme-test-class,
.ktb-theme-id-material {
  opacity: 0;
}
.ktb-var-accent {
  color: #009ddd;
}
.ktb-var-base {
  color: #ffffff;
}
.ktb-var-background {
  color: #ffffff;
}
.ktb-var-border-radius {
  border-radius: 0px;
}
.ktb-var-normal-background {
  color: #ffffff;
}
.ktb-var-normal-gradient {
  background-image: none;
}
.ktb-var-normal-text-color {
  color: #072640;
}
.ktb-var-hover-background {
  color: #ebebeb;
}
.ktb-var-hover-gradient {
  background-image: none;
}
.ktb-var-hover-text-color {
  color: #072640;
}
.ktb-var-selected-background {
  color: #009ddd;
}
.ktb-var-selected-gradient {
  background-image: none;
}
.ktb-var-selected-text-color {
  color: #072640;
}
.ktb-var-error {
  color: #d9534f;
}
.ktb-var-warning {
  color: #f0ad4e;
}
.ktb-var-success {
  color: #5cb85c;
}
.ktb-var-info {
  color: #5bc0de;
}
.ktb-var-series-a {
  color: #007acc;
}
.ktb-var-series-b {
  color: #007acc;
}
.ktb-var-series-c {
  color: #007acc;
}
.ktb-var-series-d {
  color: #007acc;
}
.ktb-var-series-e {
  color: #007acc;
}
.ktb-var-series-f {
  color: #007acc;
}
.k-grid-norecords-template {
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
}
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.k-button {
  border-radius: 0px;
  border-color: #fafafa;
  color: #072640;
  background-color: #fafafa;
  background-position: 50% 50%;
  background-image: none;
  background-image: none, linear-gradient(to bottom, false);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2), 0 2px 3px rgba(0, 0, 0, 0.05);
}
.k-button:hover,
.k-button.k-state-hover {
  color: #072640;
  border-color: #ebebeb;
  background-color: #ebebeb;
  background-image: none;
  background-image: none, linear-gradient(to bottom, false);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2), 0 2px 3px rgba(0, 0, 0, 0.05);
}
.k-button:focus,
.k-button.k-state-focused {
  border-color: #dbdbdb;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2), 0 2px 3px rgba(0, 0, 0, 0.05);
}
.k-button:active,
.k-button.k-state-active {
  color: #000000;
  background-color: #dbdbdb;
  border-color: #dbdbdb;
  background-image: none;
  background-image: none, linear-gradient(to bottom, false);
  box-shadow: 0 6px 17px 0 rgba(0, 0, 0, 0.3);
}
.k-button.k-state-active:hover {
  color: #ffffff;
  border-color: #5c6bc0;
  background-color: #5c6bc0;
}
.k-button:focus:active:not(.k-state-disabled):not([disabled]) {
  box-shadow: 0 6px 17px 0 rgba(0, 0, 0, 0.3);
}
.k-button[disabled],
.k-button[disabled]:hover,
.k-button[disabled]:focus,
.k-button[disabled]:active,
.k-button[disabled]:hover:active,
.k-state-disabled .k-button,
.k-state-disabled .k-button:hover,
.k-state-disabled .k-button:focus,
.k-state-disabled .k-button:active,
.k-state-disabled .k-button:active:active,
.k-button.k-state-disabled,
.k-button.k-state-disabled:hover,
.k-button.k-state-disabled:focus,
.k-button.k-state-disabled:active,
.k-button.k-state-disabled:hover:active {
  color: #999999;
  border-color: #fafafa;
  background-color: #fafafa;
  background-image: none;
  box-shadow: none;
}
.k-primary {
  color: #ffffff;
  border-color: #009ddd;
  background-color: #009ddd;
  background-image: none;
  background-image: none, linear-gradient(to bottom, false);
}
.k-primary:hover,
.k-primary.k-state-hover {
  color: #ffffff;
  border-color: #5c6bc0;
  background-color: #5c6bc0;
  background-image: none;
  background-image: none, linear-gradient(to bottom, false);
}
.k-primary:focus,
.k-primary.k-state-focused {
  border-color: #eff8ff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2), 0 2px 3px rgba(0, 0, 0, 0.05);
}
.k-primary:active,
.k-primary.k-state-active {
  color: #ffffff;
  border-color: #283593;
  background-color: #283593;
  background-image: none;
  background-image: none, linear-gradient(to bottom, false);
}
.k-primary:focus:active:not(.k-state-disabled):not([disabled]) {
  box-shadow: 0 6px 17px 0 rgba(0, 0, 0, 0.3);
}
.k-primary[disabled],
.k-primary[disabled]:hover,
.k-primary[disabled]:focus,
.k-primary[disabled]:active,
.k-primary[disabled]:hover:active,
.k-state-disabled .k-primary,
.k-state-disabled .k-primary:hover,
.k-state-disabled .k-primary:focus,
.k-state-disabled .k-primary:active,
.k-state-disabled .k-primary:hover:active,
.k-primary.k-state-disabled,
.k-primary.k-state-disabled:hover,
.k-primary.k-state-disabled:focus,
.k-primary.k-state-disabled:active,
.k-primary.k-state-disabled:hover:active {
  color: #999999;
  border-color: #fafafa;
  background-color: #fafafa;
  background-image: none;
  background-image: none, linear-gradient(to bottom, false);
  box-shadow: none;
}
.k-button-group .k-button {
  box-shadow: none;
}
.k-button-group .k-button:focus,
.k-button-group .k-button.k-state-focused,
.k-button-group > input[type="radio"]:focus + .k-button,
.k-button-group > input[type="checkbox"]:focus + .k-button {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2), 0 2px 3px rgba(0, 0, 0, 0.05);
}
.k-button-group .k-button:active,
.k-button-group .k-button.k-state-active,
.k-button-group > input[type="radio"]:checked + .k-button,
.k-button-group > input[type="checkbox"]:checked + .k-button {
  color: #000000;
  background-color: #dbdbdb;
  border-color: #dbdbdb;
  background-image: none;
}
.k-button-group .k-button:active:hover,
.k-button-group .k-button.k-state-active:hover,
.k-button-group > input[type="radio"]:checked + .k-button:hover,
.k-button-group > input[type="checkbox"]:checked + .k-button:hover {
  color: #ffffff;
  border-color: #5c6bc0;
  background-color: #5c6bc0;
}
.k-button-group .k-button {
  border-radius: 0;
}
.k-button-group .k-group-start,
.k-button-group .k-button:first-child {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.k-button-group .k-group-end,
.k-button-group .k-button:last-child {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.k-button-group .k-group-start.k-group-end,
.k-button-group .k-button:first-child:last-child {
  border-radius: 0px;
}
.k-in,
.k-item,
.k-window-action {
  border-color: transparent;
}
/* main colors */
.k-header .k-window-actions .k-link {
  color: inherit;
}
a.k-icon {
  color: #072640;
}
a.k-icon:hover {
  color: #072640;
}
.k-button-icon .k-icon {
  opacity: .8;
}
.k-button-icon:hover .k-icon {
  opacity: 1;
}
.k-splitbar .k-resize-handle {
  background-color: #072640;
}
.k-block,
.k-widget {
  background-color: #ffffff;
}
.k-block,
.k-widget,
.k-input,
.k-textbox,
.k-group,
.k-content,
.k-header,
.k-filter-row > th,
.k-editable-area,
.k-separator,
.k-textbox > input,
.k-autocomplete,
.k-dropdown-wrap,
.k-group-footer td,
.k-grid-footer,
.k-footer-template td,
.k-state-default,
.k-state-default .k-select,
.k-state-disabled,
.k-grid-header,
.k-grid-header-wrap,
.k-grid-header-locked,
.k-grid-footer-locked,
.k-grid-content-locked,
.k-grid td,
.k-grid td.k-state-selected,
.k-grid-footer-wrap,
.k-pager-wrap,
.k-pager-wrap .k-link,
.k-pager-refresh,
.k-grouping-header,
.k-grouping-header .k-group-indicator,
.k-panelbar > .k-item > .k-link,
.k-panel > .k-item > .k-link,
.k-panelbar .k-panel,
.k-panelbar .k-content,
.k-treemap-tile,
.k-calendar th,
.k-slider-track,
.k-splitbar,
.k-dropzone-active,
.k-tiles,
.k-tooltip,
.k-upload-files,
.k-popup.k-align .k-list .k-item:last-child {
  border-color: #e6e6e6;
}
.k-group,
.k-grouping-header,
.k-pager-wrap,
.k-group-footer td,
.k-grid-footer,
.k-footer-template td,
.k-widget .k-status,
.k-calendar th,
.k-dropzone-hovered,
.k-popup {
  background-color: #009ddd;
}
.k-grouping-row td,
td.k-group-cell,
.k-resize-handle-inner {
  background-color: #009ddd;
}
.k-list-container {
  border-color: rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
}
.k-content,
.k-panelbar > li.k-item,
.k-panel > li.k-item,
.k-tiles {
  background-color: #ffffff;
}
.k-alt,
.k-separator,
.k-resource.k-alt,
.k-pivot-layout > tbody > tr:first-child > td:first-child {
  background-color: #f2f2f2;
}
.k-pivot-rowheaders .k-alt .k-alt,
.k-header.k-alt {
  background-color: #dedede;
}
.k-textbox,
.k-autocomplete.k-header,
.k-dropdown-wrap.k-state-active,
.k-picker-wrap.k-state-active,
.k-numeric-wrap.k-state-active {
  border-color: #e6e6e6;
  background-color: #ffffff;
}
.k-textbox > input,
.k-autocomplete .k-input,
.k-dropdown-wrap .k-input,
.k-autocomplete.k-state-focused .k-input,
.k-dropdown-wrap.k-state-focused .k-input,
.k-picker-wrap.k-state-focused .k-input,
.k-numeric-wrap.k-state-focused .k-input {
  border-color: #e6e6e6;
}
input.k-textbox,
textarea.k-textbox,
input.k-textbox:hover,
textarea.k-textbox:hover,
.k-textbox > input {
  background: none;
}
.k-input,
input.k-textbox,
textarea.k-textbox,
input.k-textbox:hover,
textarea.k-textbox:hover,
.k-textbox > input,
.k-multiselect-wrap {
  background-color: #ffffff;
  color: #072640;
}
.k-input[readonly] {
  background-color: #ffffff;
  color: #072640;
}
.k-block,
.k-widget,
.k-popup,
.k-content,
.k-dropdown .k-input {
  color: #072640;
}
.k-inverse {
  color: #ffffff;
}
.k-block {
  color: #ffffff;
}
.k-link:link,
.k-link:visited,
.k-nav-current.k-state-hover .k-link {
  color: #428bca;
}
.k-tabstrip-items .k-link,
.k-panelbar > li > .k-link {
  color: #072640;
}
.k-header,
.k-treemap-title,
.k-grid-header .k-header > .k-link {
  color: #ffffff;
}
.k-header,
.k-grid-header,
.k-dropdown-wrap,
.k-picker-wrap,
.k-numeric-wrap,
.k-grouping-header,
.k-pager-wrap,
.k-textbox,
.k-progressbar,
.k-draghandle,
.k-autocomplete,
.k-state-highlight,
.k-tabstrip-items .k-item,
.k-panelbar .k-tabstrip-items .k-item,
.km-pane-wrapper > .km-pane > .km-view > .km-content {
  background-image: none;
  background-image: none, linear-gradient(to bottom, false);
  background-position: 50% 50%;
  background-color: #009ddd;
}
.k-widget.k-tooltip {
  background-image: none;
}
.k-block,
.k-header,
.k-grid-header,
.k-grouping-header,
.k-pager-wrap,
.k-draghandle,
.k-treemap-tile,
html .km-pane-wrapper .k-header {
  background-color: #009ddd;
}
.k-hr {
  border-color: #e6e6e6;
}
/* icons */
.k-icon:hover,
.k-state-hover .k-icon,
.k-state-selected .k-icon,
.k-state-focused .k-icon,
.k-column-menu .k-state-hover .k-sprite,
.k-column-menu .k-state-active .k-sprite,
.k-pager-numbers .k-current-page .k-link:hover:after,
.k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view.k-state-hover > .k-link:after {
  opacity: 1;
}
.k-state-disabled .k-icon,
.k-column-menu .k-sprite,
.k-pager-numbers .k-current-page .k-link:after,
.k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link:after {
  opacity: 0.7;
}
.k-mobile-list .k-check:checked,
.k-mobile-list .k-edit-field [type=checkbox]:checked,
.k-mobile-list .k-edit-field [type=radio]:checked {
  opacity: 0.7;
}
/* IE will ignore the above selectors if these are added too */
.k-mobile-list .k-check:checked,
.k-mobile-list .k-edit-field [type=checkbox]:checked,
.k-mobile-list .k-edit-field [type=radio]:checked {
  background-image: url('https://kendo.cdn.telerik.com/2016.2.714/styles/Material/sprite.png');
  border-color: transparent;
}
.k-i-loading {
  background-image: url('https://kendo.cdn.telerik.com/2016.2.714/styles/Material/loading.gif');
}
.k-loading-image {
  background-image: url('https://kendo.cdn.telerik.com/2016.2.714/styles/Material/loading-image.gif');
}
.k-loading-color {
  background-color: #ebebeb;
}
.k-draghandle {
  border-color: #009ddd;
  background-color: #009ddd;
  box-shadow: none;
}
.k-draghandle:hover {
  border-color: #009ddd;
  background-color: #009ddd;
  box-shadow: 0 0 0 8px rgba(63, 81, 181, 0.3);
}
/* Scheduler */
.k-scheduler {
  color: #ffffff;
  background-color: #ffffff;
}
.k-scheduler-layout {
  color: #072640;
}
.k-scheduler-datecolumn,
.k-scheduler-groupcolumn {
  background-color: #ffffff;
  color: #072640;
}
.k-scheduler-times tr,
.k-scheduler-times th,
.k-scheduler-table td,
.k-scheduler-header th,
.k-scheduler-header-wrap,
.k-scheduler-times {
  border-color: #e6e6e6;
}
.k-nonwork-hour {
  background-color: #fafafa;
}
.k-gantt .k-nonwork-hour {
  background-color: rgba(0, 0, 0, 0.02);
}
.k-gantt .k-header.k-nonwork-hour {
  background-color: rgba(0, 0, 0, 0.2);
}
.k-scheduler-table .k-today,
.k-today > .k-scheduler-datecolumn,
.k-today > .k-scheduler-groupcolumn {
  background-color: #e9e9e9;
}
.k-scheduler-now-arrow {
  border-left-color: #eed3d7;
}
.k-scheduler-now-line {
  background-color: #eed3d7;
}
.k-event,
.k-task-complete {
  border-color: #11baff;
  background: #11baff 0 -257px none repeat-x;
  color: #ffffff;
}
.k-event-inverse {
  color: #072640;
}
.k-event.k-state-selected {
  background-position: 0 0;
  box-shadow: 0 0 0 2px #072640;
}
.k-event .k-resize-handle:after,
.k-task-single .k-resize-handle:after {
  background-color: #ffffff;
}
.k-scheduler-marquee:before,
.k-scheduler-marquee:after {
  border-color: #ffffff;
}
.k-panelbar .k-content,
.k-panelbar .k-panel,
.k-panelbar .k-item {
  background-color: #ffffff;
  color: #072640;
  border-color: #cccccc;
}
.k-panelbar > li > .k-link {
  color: #072640;
}
.k-panelbar > .k-item > .k-link {
  border-color: #cccccc;
}
.k-panel > li.k-item {
  background-color: #ffffff;
}
/* states */
.k-state-active,
.k-state-active:hover,
.k-active-filter,
.k-tabstrip .k-state-active {
  background-color: #ffffff;
  border-color: #cccccc;
  color: #072640;
}
.k-fieldselector .k-list-container {
  background-color: #ffffff;
}
.k-menu .k-state-hover > .k-state-active {
  background-color: transparent;
}
.k-state-highlight {
  background: #ffffff;
  color: #072640;
}
.k-state-focused,
.k-grouping-row .k-state-focused {
  border-color: #67afe9;
}
.k-button.k-bare {
  position: relative;
}
.k-button.k-bare:before {
  content: "";
  background-color: currentcolor;
  opacity: 0.12;
  border-radius: inherit;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  display: none;
}
.k-button.k-bare.k-state-focused:before,
.k-button.k-bare:focus:before {
  display: block;
}
.k-mediaplayer-toolbar .k-button.k-bare:active,
.k-mediaplayer-toolbar .k-button.k-bare.k-state-active,
.k-mediaplayer-toolbar .k-button.k-bare.k-state-active:hover {
  color: #009ddd;
}
.k-mediaplayer-toolbar .k-button.k-bare:active:before,
.k-mediaplayer-toolbar .k-button.k-bare.k-state-active:before,
.k-mediaplayer-toolbar .k-button.k-bare.k-state-active:hover:before {
  opacity: 0.24;
}
.k-slider.k-mediaplayer-seekbar {
  top: -14px;
}
.k-quality-list {
  margin-left: -25px !important;
}
.k-calendar .k-link {
  color: #072640;
}
.k-calendar .k-footer {
  padding: 0;
}
.k-calendar .k-footer .k-nav-today {
  color: #072640;
  text-decoration: none;
  background-color: #ffffff;
}
.k-calendar .k-footer .k-nav-today:hover,
.k-calendar .k-footer .k-nav-today.k-state-hover {
  background-color: #ffffff;
  text-decoration: underline;
}
.k-calendar .k-footer .k-nav-today:active {
  background-color: #ffffff;
}
.k-calendar .k-link.k-nav-fast {
  color: #072640;
}
.k-calendar .k-nav-fast.k-state-hover {
  text-decoration: none;
  background-color: #ebebeb;
  color: #072640;
}
.k-calendar .k-link.k-state-hover {
  border-radius: 50%;
}
.k-calendar .k-footer .k-link {
  border-radius: 0;
}
.k-calendar th {
  background-color: #009ddd;
}
.k-window-titlebar .k-link {
  border-radius: 50%;
}
.k-calendar-container.k-group {
  border-color: rgba(0, 0, 0, 0.2);
}
.k-state-selected,
.k-state-selected:link,
.k-state-selected:visited,
.k-list > .k-state-selected,
.k-list > .k-state-highlight,
.k-panel > .k-state-selected,
.k-ghost-splitbar-vertical,
.k-ghost-splitbar-horizontal,
.k-draghandle.k-state-selected:hover,
.k-scheduler .k-scheduler-toolbar .k-state-selected,
.k-scheduler .k-today.k-state-selected,
.k-marquee-color,
.k-drag-clue.k-state-selected {
  color: #072640;
  background-color: #ffffff;
  border-color: #ffffff;
}
.k-virtual-item.k-first,
.k-group-header + .k-list > .k-item.k-first,
.k-static-header + .k-list > .k-item.k-first {
  border-top-color: #ebebeb;
}
.k-group-header + div > .k-list > .k-item.k-first:before {
  border-top-color: #ebebeb;
}
.k-popup > .k-group-header,
.k-popup > .k-virtual-wrap > .k-group-header {
  background: #ebebeb;
  color: #072640;
}
.k-popup .k-list .k-item > .k-group {
  background: #ebebeb;
  color: #072640;
  border-bottom-left-radius: -1px;
}
.k-marquee-text {
  color: #072640;
}
.k-state-focused,
.k-list > .k-state-focused,
.k-listview > .k-state-focused,
.k-listview > .k-state-focused.k-state-selected,
td.k-state-focused {
  box-shadow: inset 0 0 0 1px #808080;
}
.k-menu .k-item.k-state-default.k-state-focused {
  box-shadow: inset 0 0 0 1px #b3b3b3;
}
.k-state-focused.k-state-selected,
.k-list > .k-state-focused.k-state-selected,
td.k-state-focused.k-state-selected {
  box-shadow: none;
}
.k-list-optionlabel.k-state-selected.k-state-focused {
  box-shadow: none;
}
.k-state-selected > .k-link,
.k-panelbar > li > .k-state-selected,
.k-panelbar > li.k-state-default > .k-link.k-state-selected {
  color: #072640;
}
.k-state-hover,
.k-state-hover:hover,
.k-splitbar-horizontal-hover:hover,
.k-splitbar-vertical-hover:hover,
.k-list > .k-state-hover,
.k-scheduler .k-scheduler-toolbar ul li.k-state-hover,
.k-pager-wrap .k-link:hover,
.k-dropdown .k-state-focused,
.k-filebrowser-dropzone,
.k-mobile-list .k-item > .k-link:active,
.k-mobile-list .k-item > .k-label:active,
.k-mobile-list .k-edit-label.k-check:active,
.k-mobile-list .k-recur-view .k-check:active {
  color: #072640;
  background-color: #ebebeb;
  border-color: #ebebeb;
}
/* this selector should be used separately, otherwise old IEs ignore the whole rule */
.k-mobile-list .k-scheduler-timezones .k-edit-field:nth-child(2):active {
  color: #072640;
  background-color: #ebebeb;
  border-color: #ebebeb;
}
.k-state-hover > .k-select,
.k-state-focused > .k-select {
  border-color: #ebebeb;
}
.k-textbox:hover,
.k-state-hover,
.k-state-hover:hover,
.k-pager-wrap .k-link:hover,
.k-other-month.k-state-hover .k-link,
div.k-filebrowser-dropzone em,
.k-draghandle:hover,
.k-listbox .k-item:hover:not(.k-state-disabled) {
  background-image: none;
}
.k-pager-wrap {
  background-color: #009ddd;
  color: #ffffff;
}
.k-autocomplete.k-state-active,
.k-picker-wrap.k-state-active,
.k-numeric-wrap.k-state-active,
.k-dropdown-wrap.k-state-active,
.k-state-active,
.k-state-active:hover,
.k-state-active > .k-link,
.k-panelbar > .k-item > .k-state-focused {
  background-image: none;
}
.k-state-selected,
.k-draghandle.k-state-selected:hover {
  background-image: none;
}
.k-draghandle.k-state-selected:hover {
  background-position: 50% 50%;
}
.k-state-hover > .k-link,
.k-other-month.k-state-hover .k-link,
div.k-filebrowser-dropzone em {
  color: #072640;
}
.k-autocomplete.k-state-hover,
.k-autocomplete.k-state-focused,
.k-picker-wrap.k-state-hover,
.k-picker-wrap.k-state-focused,
.k-numeric-wrap.k-state-hover,
.k-numeric-wrap.k-state-focused,
.k-dropdown-wrap.k-state-hover,
.k-dropdown-wrap.k-state-focused {
  background-color: #ffffff;
  background-image: none;
  background-position: 50% 50%;
  border-color: #ebebeb;
}
.km-pane-wrapper .k-mobile-list input:not([type="checkbox"]):not([type="radio"]),
.km-pane-wrapper .km-pane .k-mobile-list select:not([multiple]),
.km-pane-wrapper .k-mobile-list textarea,
.k-dropdown .k-state-focused .k-input {
  color: #072640;
}
.km-pane-wrapper .km-pane .k-mobile-list.k-filter-menu .k-space-right {
  background: #ffffff;
  border-color: #e6e6e6;
}
.km-pane-wrapper .km-pane .k-mobile-list.k-filter-menu .k-space-right > input {
  background-color: #ffffff;
  border-color: #f0f0f0;
}
.km-pane-wrapper .km-pane .k-mobile-list.k-filter-menu .k-space-right > input + .k-i-zoom:before {
  color: #072640;
}
.km-pane-wrapper .km-pane .k-mobile-list.k-filter-menu .k-space-right > input:focus {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2), 0 2px 3px rgba(0, 0, 0, 0.05);
}
.k-dropdown .k-state-hover .k-input {
  color: #072640;
}
.k-state-error {
  border-color: #eed3d7;
  background-color: #f2dede;
  color: #b94a48;
}
.k-state-disabled {
  opacity: .7;
}
.k-tile-empty.k-state-selected,
.k-loading-mask.k-state-selected {
  border-width: 0;
  background-image: none;
  background-color: transparent;
}
.k-state-disabled,
.k-state-disabled .k-link,
.k-other-month,
.k-other-month .k-link,
.k-dropzone em,
.k-dropzone .k-upload-status,
.k-tile-empty strong,
.k-slider .k-draghandle {
  color: #999999;
}
.k-file .k-upload-status {
  color: #072640;
}
/* Progressbar */
.k-progressbar-indeterminate {
  background: url('https://kendo.cdn.telerik.com/2016.2.714/styles/Material/indeterminate.gif');
}
.k-progressbar-indeterminate .k-progress-status-wrap,
.k-progressbar-indeterminate .k-state-selected {
  display: none;
}
/* Slider */
.k-slider-track {
  background-color: #e6e6e6;
}
.k-slider-selection {
  background-color: #ffffff;
}
.k-slider-horizontal .k-tick {
  background-image: url('https://kendo.cdn.telerik.com/2016.2.714/styles/Material/slider-h.gif');
}
.k-slider-vertical .k-tick {
  background-image: url('https://kendo.cdn.telerik.com/2016.2.714/styles/Material/slider-v.gif');
}
/* Tooltip */
.k-widget.k-tooltip,
.k-chart-crosshair-tooltip,
.k-chart-shared-tooltip {
  border-color: rgba(100, 100, 100, 0.9);
  background-color: rgba(100, 100, 100, 0.9);
  color: #ffffff;
}
.k-widget.k-tooltip-validation {
  border-color: #f0ad4e;
  background-color: #f0ad4e;
  color: #ffffff;
}
/* Bootstrap theme fix */
.input-prepend .k-tooltip-validation,
.input-append .k-tooltip-validation {
  font-size: 12px;
  position: relative;
  top: 3px;
}
.k-callout-n {
  border-bottom-color: rgba(100, 100, 100, 0.9);
}
.k-callout-w {
  border-right-color: rgba(100, 100, 100, 0.9);
}
.k-callout-s {
  border-top-color: rgba(100, 100, 100, 0.9);
}
.k-callout-e {
  border-left-color: rgba(100, 100, 100, 0.9);
}
.k-tooltip-validation .k-callout-n {
  border-bottom-color: #f0ad4e;
}
.k-tooltip-validation .k-callout-w {
  border-right-color: #f0ad4e;
}
.k-tooltip-validation .k-callout-s {
  border-top-color: #f0ad4e;
}
.k-tooltip-validation .k-callout-e {
  border-left-color: #f0ad4e;
}
/* Splitter */
.k-splitbar {
  background-color: #fafafa;
}
.k-restricted-size-vertical,
.k-restricted-size-horizontal {
  background-color: #b94a48;
}
/* Upload */
.k-file {
  background-color: #ffffff;
  border-color: #e6e6e6;
}
.k-file-progress {
  color: #5bc0de;
}
.k-file-progress .k-progress {
  background-color: #5bc0de;
}
.k-file-success .k-file-name,
.k-file-success .k-upload-pct {
  color: #5cb85c;
}
.k-file-success .k-progress {
  background-color: #5cb85c;
}
.k-file-error {
  color: #d9534f;
}
.k-file-error .k-file-extension-wrapper,
.k-file-error .k-multiple-files-extension-wrapper {
  color: #d9534f;
  border-color: #d9534f;
}
.k-file-error .k-file-extension-wrapper:before,
.k-file-error .k-multiple-files-extension-wrapper:before {
  background-color: #ffffff;
  border-color: transparent transparent #d9534f #d9534f;
}
.k-file-error .k-progress {
  background-color: #d9534f;
}
.k-file-extension-wrapper,
.k-multiple-files-extension-wrapper {
  color: #999999;
  border-color: #999999;
}
.k-file-invalid .k-file-name-invalid {
  color: #d9534f;
}
.k-file-invalid-extension-wrapper,
.k-multiple-files-invalid-extension-wrapper {
  color: #d9534f;
  border-color: #d9534f;
}
.k-file-extension-wrapper:before,
.k-multiple-files-extension-wrapper:before {
  background-color: #ffffff;
  border-color: transparent transparent #999999 #999999;
}
.k-file-invalid-extension-wrapper:before,
.k-multiple-files-invalid-extension-wrapper:before {
  background-color: #ffffff;
  border-color: transparent transparent #d9534f #d9534f;
}
.k-multiple-files-extension-wrapper:after {
  border-top-color: #999999;
  border-left-color: #999999;
}
.k-multiple-files-invalid-extension-wrapper:after {
  border-top-color: #d9534f;
  border-left-color: #d9534f;
}
.k-file-size,
.k-file-information,
.k-file-validation-message {
  color: #999999;
}
.k-upload .k-upload-selected {
  color: #072640;
  border-color: #e6e6e6;
}
.k-upload .k-upload-selected:hover {
  color: #ffffff;
  background-color: #072640;
}
/* ImageBrowser */
.k-tile {
  border-color: #ffffff;
}
.k-textbox:hover,
.k-tiles li.k-state-hover {
  border-color: #ebebeb;
}
.k-tiles li.k-state-selected {
  border-color: #ffffff;
}
/* TreeMap */
.k-leaf,
.k-leaf.k-state-hover:hover {
  color: #fff;
}
.k-leaf.k-inverse,
.k-leaf.k-inverse.k-state-hover:hover {
  color: #000;
}
/* Shadows */
.k-slider,
.k-treeview,
.k-upload {
  box-shadow: none;
}
.k-state-hover {
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
}
.k-textbox:focus,
.k-autocomplete.k-state-focused,
.k-dropdown-wrap.k-state-focused,
.k-picker-wrap.k-state-focused,
.k-numeric-wrap.k-state-focused {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2), 0 2px 3px rgba(0, 0, 0, 0.05);
}
.k-state-selected {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
}
.k-state-active {
  box-shadow: none;
}
.k-grid td.k-state-selected.k-state-focused {
  background-color: #ffffff;
}
.k-popup,
.k-menu .k-menu-group,
.k-grid .k-filter-options,
.k-time-popup,
.k-datepicker-calendar,
.k-autocomplete.k-state-border-down,
.k-autocomplete.k-state-border-up,
.k-dropdown-wrap.k-state-active,
.k-picker-wrap.k-state-active,
.k-multiselect.k-state-focused,
.k-filebrowser .k-image,
.k-tooltip {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.k-calendar-container.k-popup {
  box-shadow: 0 0px 6px 1px rgba(0, 0, 0, 0.2);
}
.k-treemap-tile.k-state-hover {
  box-shadow: inset 0 0 0 3px #e6e6e6;
}
/* Window */
.k-window {
  border-color: rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 7px 1px rgba(128, 128, 128, 0.2);
  background-color: #ffffff;
}
.k-window.k-state-focused {
  border-color: rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 7px 1px rgba(0, 0, 0, 0.2);
}
.k-window.k-window-maximized,
.k-window-maximized .k-window-titlebar,
.k-window-maximized .k-window-content {
  border-radius: 0;
}
.k-shadow {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}
.k-inset {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.2);
}
/* Selection */
.k-editor-inline ::selection {
  background-color: #009ddd;
  text-shadow: none;
  color: #fff;
}
.k-editor-inline ::-moz-selection {
  background-color: #009ddd;
  text-shadow: none;
  color: #fff;
}
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.k-notification-info {
  background-color: #5bc0de;
  color: #ffffff;
  border-color: #5bc0de;
}
.k-notification-success {
  background-color: #5cb85c;
  color: #ffffff;
  border-color: #5cb85c;
}
.k-notification-warning {
  background-color: #f0ad4e;
  color: #ffffff;
  border-color: #f0ad4e;
}
.k-notification-error {
  background-color: #d9534f;
  color: #ffffff;
  border-color: #d9534f;
}
/* Gantt */
.k-gantt .k-treelist {
  background: #f2f2f2;
}
.k-gantt .k-treelist .k-alt {
  background-color: #d9d9d9;
}
.k-gantt .k-treelist tr:hover {
  background-color: #ebebeb;
}
.k-gantt .k-treelist .k-state-selected,
.k-gantt .k-treelist .k-state-selected td,
.k-gantt .k-treelist .k-alt.k-state-selected,
.k-gantt .k-treelist .k-alt.k-state-selected > td {
  background-color: #ffffff;
}
.k-gantt .k-treelist .k-alt.k-state-selected:hover,
.k-gantt .k-treelist .k-alt.k-state-selected:hover td {
  background-color: #008fc9;
}
.k-task-dot:after {
  background-color: #072640;
  border-color: #072640;
}
.k-task-dot:hover:after {
  background-color: #ffffff;
}
.k-task-summary {
  border-color: #5ed0ff;
  background: #5ed0ff;
}
.k-task-milestone,
.k-task-summary-complete {
  border-color: #072640;
  background: #072640;
}
.k-state-selected.k-task-summary {
  border-color: #5ed0ff;
  background: #5ed0ff;
}
.k-state-selected.k-task-milestone,
.k-state-selected .k-task-summary-complete {
  border-color: #ffffff;
  background: #ffffff;
}
.k-task-single {
  background-color: #35c4ff;
  border-color: #11baff;
  color: #ffffff;
}
.k-state-selected.k-task-single {
  border-color: #ffffff;
}
.k-line {
  background-color: #072640;
  color: #072640;
}
.k-state-selected.k-line {
  background-color: #ffffff;
  color: #ffffff;
}
.k-resource {
  background-color: #ffffff;
}
/* Border radius */
.k-block,
.k-textbox,
.k-drag-clue,
.k-touch-scrollbar,
.k-window,
.k-window-titleless .k-window-content,
.k-window-action,
.k-inline-block,
.k-grid .k-filter-options,
.k-grouping-header .k-group-indicator,
.k-autocomplete,
.k-multiselect,
.k-combobox,
.k-dropdown,
.k-dropdown-wrap,
.k-datepicker,
.k-timepicker,
.k-colorpicker,
.k-datetimepicker,
.k-notification,
.k-numerictextbox,
.k-picker-wrap,
.k-numeric-wrap,
.k-list-container,
.k-calendar-container,
.k-calendar td,
.k-calendar .k-link,
.k-treeview .k-in,
.k-editor-inline,
.k-tooltip,
.k-tile,
.k-slider-track,
.k-slider-selection,
.k-upload {
  border-radius: 0px;
}
.k-toolbar .k-split-button .k-button {
  border-radius: 0px 0 0 0px;
}
.k-rtl .k-tool.k-group-start,
.k-rtl .k-toolbar .k-split-button .k-button,
.k-rtl .k-toolbar .k-button-group .k-group-start {
  border-radius: 0 0px 0px 0;
}
.k-toolbar .k-split-button .k-split-button-arrow {
  border-radius: 0 0px 0px 0;
}
.k-rtl .k-tool.k-group-end,
.k-rtl .k-toolbar .k-button-group .k-group-end,
.k-rtl .k-toolbar .k-split-button .k-split-button-arrow {
  border-radius: 0px 0 0 0px;
}
.k-calendar-container.k-state-border-up,
.k-list-container.k-state-border-up,
.k-autocomplete.k-state-border-up,
.k-multiselect.k-state-border-up,
.k-dropdown-wrap.k-state-border-up,
.k-picker-wrap.k-state-border-up,
.k-numeric-wrap.k-state-border-up,
.k-window-content,
.k-filter-menu {
  border-radius: 0 0 0px 0px;
}
.k-autocomplete.k-state-border-up .k-input,
.k-dropdown-wrap.k-state-border-up .k-input,
.k-picker-wrap.k-state-border-up .k-input,
.k-picker-wrap.k-state-border-up .k-selected-color,
.k-numeric-wrap.k-state-border-up .k-input {
  border-radius: 0 0 0 0px;
}
.k-multiselect.k-state-border-up .k-multiselect-wrap {
  border-radius: 0 0 0px 0px;
}
.k-window-titlebar,
.k-block > .k-header,
.k-tabstrip-items .k-item,
.k-panelbar .k-tabstrip-items .k-item,
.k-tabstrip-items .k-link,
.k-calendar-container.k-state-border-down,
.k-list-container.k-state-border-down,
.k-autocomplete.k-state-border-down,
.k-multiselect.k-state-border-down,
.k-dropdown-wrap.k-state-border-down,
.k-picker-wrap.k-state-border-down,
.k-numeric-wrap.k-state-border-down {
  border-radius: 0px 0px 0 0;
}
.k-split-button.k-state-border-down > .k-button {
  border-radius: 0px 0 0 0;
}
.k-split-button.k-state-border-up > .k-button {
  border-radius: 0 0 0 0px;
}
.k-split-button.k-state-border-down > .k-split-button-arrow {
  border-radius: 0 0px 0 0;
}
.k-split-button.k-state-border-up > .k-split-button-arrow {
  border-radius: 0 0 0px 0;
}
.k-dropdown-wrap .k-input,
.k-picker-wrap .k-input,
.k-numeric-wrap .k-input {
  border-radius: -1px 0 0 -1px;
}
.k-rtl .k-dropdown-wrap .k-input,
.k-rtl .k-picker-wrap .k-input,
.k-rtl .k-numeric-wrap .k-input {
  border-radius: 0 -1px -1px 0;
}
.k-numeric-wrap .k-link {
  border-radius: 0 -1px 0 0;
}
.k-numeric-wrap .k-link + .k-link {
  border-radius: 0 0 -1px 0;
}
.k-colorpicker .k-selected-color {
  border-radius: -1px 0 0 -1px;
}
.k-rtl .k-colorpicker .k-selected-color {
  border-radius: 0 -1px -1px 0;
}
.k-autocomplete.k-state-border-down .k-input {
  border-radius: 0px 0px 0 0;
}
.k-dropdown-wrap.k-state-border-down .k-input,
.k-picker-wrap.k-state-border-down .k-input,
.k-picker-wrap.k-state-border-down .k-selected-color,
.k-numeric-wrap.k-state-border-down .k-input {
  border-radius: 0px 0 0 0;
}
.k-numeric-wrap .k-link.k-state-selected {
  background-color: #ebebeb;
}
.k-multiselect.k-state-border-down .k-multiselect-wrap {
  border-radius: -1px -1px 0 0;
}
.k-dropdown-wrap .k-select,
.k-picker-wrap .k-select,
.k-numeric-wrap .k-select,
.k-datetimepicker .k-select + .k-select,
.k-list-container.k-state-border-right {
  border-radius: 0 0px 0px 0;
}
.k-rtl .k-dropdown-wrap .k-select,
.k-rtl .k-picker-wrap .k-select,
.k-rtl .k-numeric-wrap .k-select,
.k-rtl .k-datetimepicker .k-select + .k-select,
.k-rtl .k-list-container.k-state-border-right {
  border-radius: 0px 0 0 0px;
}
.k-numeric-wrap.k-expand-padding .k-input {
  border-radius: 0px;
}
.k-textbox > input,
.k-autocomplete .k-input,
.k-multiselect-wrap {
  border-radius: 0;
}
.k-list .k-state-hover,
.k-list .k-state-focused,
.k-list .k-state-highlight,
.k-list .k-state-selected,
.k-fieldselector .k-list .k-item,
.k-list-optionlabel,
.k-dropzone,
.k-listbox .k-item {
  border-radius: 0;
}
.k-slider .k-button,
.k-grid .k-slider .k-button {
  border-radius: 13px;
}
.k-draghandle {
  border-radius: 13px;
}
.k-scheduler-toolbar > ul li:first-child,
.k-scheduler-toolbar > ul li:first-child .k-link,
.k-scheduler-toolbar > ul.k-scheduler-views li:first-child + li,
.k-scheduler-toolbar > ul.k-scheduler-views li:first-child + li .k-link {
  border-radius: 0px 0 0 0px;
}
.k-rtl .k-scheduler-toolbar > ul li:first-child,
.k-rtl .k-scheduler-toolbar > ul li:first-child .k-link,
.k-rtl .k-scheduler-toolbar > ul.k-scheduler-views li:first-child + li,
.k-rtl .k-scheduler-toolbar > ul.k-scheduler-views li:first-child + li .k-link,
.km-view.k-popup-edit-form .k-scheduler-toolbar > ul li:last-child,
.km-view.k-popup-edit-form .k-scheduler-toolbar > ul li:last-child .k-link {
  border-radius: 0 0px 0px 0;
}
.k-scheduler-phone .k-scheduler-toolbar > ul li.k-nav-today,
.k-scheduler-phone .k-scheduler-toolbar > ul li.k-nav-today .k-link,
.k-edit-field > .k-scheduler-navigation {
  border-radius: 0px;
}
.k-scheduler-toolbar .k-nav-next,
.k-scheduler-toolbar ul + ul li:last-child,
.k-scheduler-toolbar .k-nav-next .k-link,
.k-scheduler-toolbar ul + ul li:last-child .k-link {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.k-rtl .k-scheduler-toolbar .k-nav-next,
.k-rtl .k-scheduler-toolbar ul + ul li:last-child,
.k-rtl .k-scheduler-toolbar .k-nav-next .k-link,
.k-rtl .k-scheduler-toolbar ul + ul li:last-child .k-link {
  border-radius: 0px 0 0 0px;
}
.k-scheduler div.k-scheduler-footer ul li,
.k-scheduler div.k-scheduler-footer .k-link {
  border-radius: 0px;
}
.k-more-events,
.k-event,
.k-task-single,
.k-task-complete,
.k-event .k-link {
  border-radius: -1px;
}
.k-scheduler-mobile .k-event {
  border-radius: -2px;
}
/* Adaptive Grid */
.k-grid-mobile .k-column-active + th.k-header {
  border-left-color: #072640;
}
html .km-pane-wrapper .km-widget,
.k-ie .km-pane-wrapper .k-widget,
.k-ie .km-pane-wrapper .k-group,
.k-ie .km-pane-wrapper .k-content,
.k-ie .km-pane-wrapper .k-header,
.k-ie .km-pane-wrapper .k-popup-edit-form .k-edit-field .k-button,
.km-pane-wrapper .k-mobile-list .k-item,
.km-pane-wrapper .k-mobile-list .k-edit-label,
.km-pane-wrapper .k-mobile-list .k-edit-field {
  color: #072640;
}
@media screen and (-ms-high-contrast: active) and (-ms-high-contrast: none) {
  div.km-pane-wrapper a {
    color: #072640;
  }
}
.km-pane-wrapper .k-mobile-list .k-item,
.km-pane-wrapper .k-mobile-list .k-edit-field,
.km-pane-wrapper .k-mobile-list .k-recur-view > .k-edit-field .k-check {
  background-color: #ffffff;
  border-top: 1px solid #e7e7e7;
}
.km-pane-wrapper .k-mobile-list .k-edit-field textarea {
  outline-width: 0;
}
.km-pane-wrapper .k-mobile-list .k-item.k-state-selected {
  background-color: #ffffff;
  border-top-color: #ffffff;
}
.km-pane-wrapper .k-mobile-list .k-recur-view > .k-edit-field .k-check:first-child {
  border-top-color: transparent;
}
.km-pane-wrapper .k-mobile-list .k-item:last-child {
  box-shadow: inset 0 -1px 0 #e7e7e7;
}
.km-pane-wrapper .k-mobile-list > ul > li > .k-link,
.km-pane-wrapper .k-mobile-list .k-recur-view > .k-edit-label:nth-child(3),
.km-pane-wrapper #recurrence .km-scroll-container > .k-edit-label:first-child {
  color: #9b9b9b;
}
.km-pane-wrapper .k-mobile-list > ul > li > .k-link {
  border-bottom: 1px solid #e7e7e7;
}
.km-pane-wrapper .k-mobile-list .k-edit-field {
  box-shadow: 0 1px 1px #e7e7e7;
}
.km-actionsheet .k-grid-delete,
.km-actionsheet .k-scheduler-delete,
.km-pane-wrapper .k-scheduler-delete,
.km-pane-wrapper .k-filter-menu .k-button[type=reset] {
  color: #fff;
  border-color: #eed3d7;
  background-color: red;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.15));
}
.km-actionsheet .k-grid-delete:active,
.km-actionsheet .k-scheduler-delete:active,
.km-pane-wrapper .k-scheduler-delete:active,
.km-pane-wrapper .k-filter-menu .k-button[type=reset]:active {
  background-color: #990000;
}
/* /Column Menu */
.k-autocomplete.k-state-default,
.k-picker-wrap.k-state-default,
.k-numeric-wrap.k-state-default,
.k-dropdown-wrap.k-state-default {
  background-image: none;
  background-image: none, linear-gradient(to bottom, false);
  background-position: 50% 50%;
  color: #072640;
  background-color: #fafafa;
  border-color: #f0f0f0;
}
.k-autocomplete.k-state-hover,
.k-picker-wrap.k-state-hover,
.k-numeric-wrap.k-state-hover,
.k-dropdown-wrap.k-state-hover {
  background-color: #ffffff;
  background-image: none;
  background-position: 50% 50%;
  border-color: #f5f5f5;
}
.k-multiselect.k-header {
  border-color: #f0f0f0;
  background: #fafafa;
}
.k-multiselect.k-header.k-state-hover {
  border-color: #f5f5f5;
}
.k-autocomplete.k-state-focused,
.k-picker-wrap.k-state-focused,
.k-numeric-wrap.k-state-focused,
.k-dropdown-wrap.k-state-focused,
.k-multiselect.k-header.k-state-focused {
  background-color: #ffffff;
  background-image: none;
  background-position: 50% 50%;
  border-color: #f5f5f5;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2), 0 2px 3px rgba(0, 0, 0, 0.05);
}
.k-list-container {
  color: #072640;
}
.k-nodata {
  color: #999999;
}
.k-dropdown .k-input,
.k-dropdown .k-state-focused .k-input,
.k-menu .k-popup {
  color: #072640;
}
.k-state-default > .k-select {
  border-color: #f0f0f0;
}
.k-state-focused > .k-select {
  border-color: #f5f5f5;
}
.k-state-hover > .k-select {
  border-color: #f5f5f5;
}
.k-tabstrip:focus {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2), 0 2px 3px rgba(0, 0, 0, 0.05);
}
.k-tabstrip-items .k-item .k-link,
.k-panelbar > li.k-state-default > .k-link {
  color: #ffffff;
}
.k-tabstrip-items .k-state-hover .k-link,
.k-panelbar > li.k-state-hover > .k-link,
.k-panelbar > li.k-state-default > .k-link.k-state-hover {
  color: #072640;
}
.k-panelbar > li > .k-state-focused.k-state-hover {
  background: #ebebeb;
  box-shadow: none;
}
.k-tabstrip-items .k-state-default,
.k-panelbar .k-tabstrip-items .k-state-default {
  border-color: transparent;
}
.k-tabstrip-items .k-state-hover {
  border-color: #ebebeb;
}
.k-tabstrip .k-content.k-state-active {
  background-color: #ffffff;
  color: #072640;
}
.k-menu.k-header,
.k-menu .k-item {
  border-color: #e6e6e6;
}
.k-column-menu,
.k-column-menu .k-item,
.k-overflow-container .k-overflow-group {
  border-color: #cccccc;
}
.k-overflow-container .k-overflow-group {
  box-shadow: inset 0 1px 0 #ffffff, 0 1px 0 #ffffff;
}
.k-toolbar-first-visible.k-overflow-group,
.k-overflow-container .k-overflow-group + .k-overflow-group {
  box-shadow: 0 1px 0 #ffffff;
}
.k-toolbar-last-visible.k-overflow-group {
  box-shadow: inset 0 1px 0 #ffffff;
}
.k-column-menu .k-separator {
  border-color: #cccccc;
  background-color: transparent;
}
.k-menu .k-group {
  border-color: rgba(0, 0, 0, 0.2);
}
.k-grid-filter.k-state-active {
  background-color: #ffffff;
}
.k-grouping-row td,
.k-group-footer td,
.k-grid-footer td {
  color: #ffffff;
  border-color: #cccccc;
  font-weight: bold;
}
.k-grouping-header {
  color: #ffffff;
}
.k-header,
.k-grid-header-wrap,
.k-grid .k-grouping-header,
.k-grid-header,
.k-pager-wrap,
.k-pager-wrap .k-textbox,
.k-pager-wrap .k-link,
.k-grouping-header .k-group-indicator,
.k-gantt-toolbar .k-state-default {
  border-color: #cccccc;
}
.k-button:focus,
.k-button.k-state-focused {
  border-color: #dbdbdb;
  background-color: #dbdbdb;
}
.k-button:active:hover,
.k-button.k-state-active:hover {
  color: #000000;
  background-color: #dbdbdb;
  border-color: #dbdbdb;
}
.k-pager-numbers .k-link,
.k-treeview .k-in {
  border-color: transparent;
}
.k-treeview .k-icon,
.k-scheduler-table .k-icon,
.k-grid .k-hierarchy-cell .k-icon {
  background-color: transparent;
  border-radius: 50%;
}
.k-scheduler-table .k-state-hover .k-icon {
  background-color: transparent;
}
.k-split-button:focus {
  outline: none;
}
.k-split-button:focus {
  background-color: #dbdbdb;
}
.k-split-button:focus > .k-button {
  background: transparent;
  border-color: #dbdbdb;
}
.k-split-button:focus > .k-button.k-split-button-arrow {
  border-left-color: #f2f2f2;
}
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.k-checkbox-label:before {
  border-color: #1365aa;
  background: #ffffff;
  border-radius: 1px;
}
.k-checkbox:hover + .k-checkbox-label:before,
.k-checkbox:checked:hover + .k-checkbox-label:before,
.k-checkbox-label:hover:before,
.k-checkbox:checked + .k-checkbox-label:hover:before {
  border-color: #1365aa;
  box-shadow: none;
}
.k-checkbox:checked + .k-checkbox-label:before {
  background-color: #009ddd;
  border-color: #009ddd;
  color: #ffffff;
}
.k-checkbox:active + .k-checkbox-label:before,
.k-checkbox-label:active:before {
  box-shadow: none;
  border-color: #53cdff;
}
.k-checkbox:checked:active + .k-checkbox-label:before,
.k-checkbox:checked + .k-checkbox-label:active:before {
  box-shadow: none;
  border-color: #53cdff;
}
.k-checkbox:disabled + .k-checkbox-label {
  color: #999999;
}
.k-checkbox:disabled + .k-checkbox-label:hover:before {
  box-shadow: none;
}
.k-checkbox:disabled + .k-checkbox-label:before,
.k-checkbox:checked:disabled + .k-checkbox-label:before,
.k-checkbox:checked:disabled + .k-checkbox-label:active:before,
.k-checkbox:checked:disabled + .k-checkbox-label:hover:before {
  color: #999999;
  background: #f5f5f5;
  border-color: #bfbfbf;
  border-radius: 1px;
}
.k-checkbox:focus + .k-checkbox-label:before,
.k-checkbox:focus + .k-checkbox-label:hover:before {
  border-color: #53cdff;
  box-shadow: none;
}
.k-checkbox:indeterminate + .k-checkbox-label:after {
  background-color: #009ddd;
  background-image: none;
  border-color: #009ddd;
  border-radius: 0px;
}
.k-checkbox:indeterminate:hover + .k-checkbox-label:after {
  border-color: #009ddd;
  background-color: #009ddd;
}
.k-checkbox-label:before {
  background: transparent;
}
.k-checkbox + .k-checkbox-label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  box-shadow: 0 0 0 0px transparent;
  transition: box-shadow 0.3s;
}
.k-checkbox:focus + .k-checkbox-label:before {
  border-color: #1365aa;
}
.k-checkbox:checked + .k-checkbox-label:before,
.k-checkbox:checked:hover + .k-checkbox-label:before,
.k-checkbox:checked + .k-checkbox-label:hover:before {
  border-color: #009ddd;
}
.k-checkbox:focus + .k-checkbox-label:after {
  box-shadow: 0 0 0 12px rgba(19, 101, 170, 0.2);
  background: rgba(19, 101, 170, 0.2);
}
.k-checkbox:active + .k-checkbox-label:after,
.k-checkbox + .k-checkbox-label:active:after {
  box-shadow: 0 0 0 12px rgba(83, 205, 255, 0.4);
  background: rgba(83, 205, 255, 0.4);
}
.k-checkbox:checked:focus + .k-checkbox-label:after,
.k-checkbox:checked + .k-checkbox-label:focus:after,
.k-checkbox:checked:active + .k-checkbox-label:after,
.k-checkbox:checked + .k-checkbox-label:active:after {
  box-shadow: 0 0 0 12px rgba(83, 205, 255, 0.4);
  background: rgba(83, 205, 255, 0.4);
}
.k-checkbox:disabled + .k-checkbox-label:active:after {
  box-shadow: none;
}
.k-checkbox:indeterminate + .k-checkbox-label:before {
  border-color: #009ddd;
}
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.k-radio-label:before {
  border-color: #1365aa;
  border-radius: 50%;
  background-color: #ffffff;
  border-width: 2px;
}
.k-radio-label:hover:before,
.k-radio:checked + .k-radio-label:hover:before {
  border-color: #1365aa;
  box-shadow: none;
}
.k-radio:checked + .k-radio-label:after {
  background-color: #009ddd;
  border-radius: 50%;
}
.k-radio-label:active:before {
  border-color: #20bfff;
  box-shadow: 0 0 2px 0 #20bfff;
}
.k-radio:checked + .k-radio-label:active:before {
  box-shadow: 0 0 2px 0 #20bfff;
  border-color: #20bfff;
}
.k-radio:disabled + .k-radio-label {
  color: #bfbfbf;
}
.k-radio:disabled + .k-radio-label:before,
.k-radio:disabled + .k-radio-label:active:before,
.k-radio:disabled + .k-radio-label:hover:after,
.k-radio:disabled + .k-radio-label:hover:before {
  background: #ffffff;
  border-color: #bfbfbf;
  box-shadow: none;
}
.k-radio:disabled:checked + .k-radio-label:after {
  background-color: #009ddd;
  opacity: .5;
}
.k-radio:focus + .k-radio-label:before {
  border-color: #20bfff;
  box-shadow: 0 0 2px 0 #20bfff;
}
.k-radio:checked + .k-radio-label:before,
.k-radio:checked + .k-radio-label:hover:before {
  border-color: #009ddd;
}
.k-radio + .k-radio-label:active:before {
  border-color: #1365aa;
  box-shadow: 0 0 0 12px rgba(235, 235, 235, 0.3);
}
.k-radio:checked + .k-radio-label:active:before {
  box-shadow: 0 0 0 12px rgba(0, 157, 221, 0.3);
}
.k-radio:focus + .k-radio-label:before {
  border-color: #1365aa;
  box-shadow: 0 0 0 12px rgba(235, 235, 235, 0.3);
}
.k-radio:disabled:checked + .k-radio-label:before,
.k-radio:disabled:checked + .k-radio-label:hover:before {
  border-color: #bfbfbf;
}
.k-radio:disabled:checked + .k-radio-label:active:before {
  box-shadow: none;
}
.k-button:hover .k-icon,
.k-tool-icon:hover,
.k-state-hover .k-tool-icon,
.k-state-selected .k-tool-icon,
.k-state-focused .k-tool-icon,
.k-button:hover .k-tool-icon,
.k-splitbar.k-splitbar-horizontal-hover .k-icon,
.k-splitbar.k-splitbar-vertical-hover .k-icon,
div.k-splitbar.k-state-focused .k-icon,
.k-textbox:hover > .k-icon,
.k-grouping-header .k-i-delete,
.k-grouping-header .k-button-icon:hover > .k-icon.k-i-delete,
.k-grouping-header .k-i-delete,
.k-grouping-header .k-button-icon:hover > .k-icon.k-i-delete,
.k-grouping-header .k-i-arrow-60-up,
.k-grouping-header .k-link:hover > .k-icon.k-i-arrow-60-up,
.k-grouping-header .k-i-arrow-60-up,
.k-grouping-header .k-link:hover > .k-icon.k-i-arrow-60-up,
.k-grouping-header .k-i-arrow-60-down,
.k-grouping-header .k-link:hover > .k-icon.k-i-arrow-60-down,
.k-grouping-header .k-i-arrow-60-down,
.k-grouping-header .k-link:hover > .k-icon.k-i-arrow-60-down,
.k-grid-toolbar .k-i-file-pdf,
.k-grid-toolbar .k-button:hover > .k-i-file-pdf,
.k-grid-toolbar .k-i-file-excel,
.k-grid-toolbar .k-button:hover > .k-i-file-excel,
.k-grid-toolbar .k-icon,
.k-scheduler-toolbar .k-icon,
.k-scheduler-footer .k-icon,
.k-scheduler-content .k-icon,
.k-gantt-toolbar .k-icon,
.k-field-actions .k-icon,
.k-notification .k-icon,
.k-pivot-configurator-settings .k-icon:hover,
.k-window-titlebar .k-icon {
  opacity: 1;
}
.k-splitbar .k-icon,
.k-pivot-configurator-settings .k-icon {
  opacity: 0.7;
}
.k-pager-wrap .k-link.k-state-disabled .k-icon {
  opacity: 0.25;
}
.k-button,
.k-header {
  font-weight: 500;
}
.k-primary,
.k-overflow-container .k-primary {
  color: #ffffff;
  border-color: #009ddd;
  background-image: none;
  background-image: none, linear-gradient(to bottom, false);
  background-position: 50% 50%;
  background-color: #009ddd;
}
.k-primary:hover,
.k-primary.k-state-hover,
.k-primary:active:hover,
.k-primary.k-state-active:hover {
  color: #ffffff;
  border-color: #5c6bc0;
  background-image: none;
  background-image: none, linear-gradient(to bottom, false);
  background-color: #5c6bc0;
}
.k-primary:focus,
.k-primary.k-state-focused,
.k-primary:active,
.k-primary.k-state-active {
  color: #ffffff;
  border-color: #283593;
  background-image: none;
  background-image: none, linear-gradient(to bottom, false);
  background-color: #283593;
}
.k-primary.k-state-disabled,
.k-state-disabled .k-primary,
.k-primary.k-state-disabled:hover,
.k-state-disabled .k-primary:hover,
.k-primary.k-state-disabled:hover,
.k-state-disabled .k-primary:active,
.k-primary.k-state-disabled:active {
  color: #999999;
  border-color: #fafafa;
  background-color: #fafafa;
  background-image: none;
  background-image: none, linear-gradient(to bottom, false);
  box-shadow: none;
}
.k-widget .k-button:active,
.k-widget .k-button.k-state-active {
  color: #072640;
  background-color: #dbdbdb;
  border-color: #ebebeb;
}
.k-widget .k-button:active:hover,
.k-widget .k-button.k-state-active:hover {
  color: #072640;
  border-color: #ebebeb;
  background-color: #ebebeb;
}
.k-dropdown .k-state-default {
  border-color: #fafafa;
  background-image: none;
  background-image: none, linear-gradient(to bottom, false);
  background-position: 50% 50%;
  background-color: #fafafa;
}
.k-dropdown,
span.k-colorpicker {
  background-color: #fafafa;
}
.k-textbox {
  background-color: #fafafa;
  border-color: #f0f0f0;
}
.k-combobox,
.k-datepicker,
.k-timepicker,
.k-datetimepicker {
  background-color: #fafafa;
}
.k-picker-wrap.k-state-default > .k-select {
  border-color: #fafafa;
}
.k-datepicker .k-input,
.k-timepicker .k-input {
  background-color: #fafafa;
}
.k-autocomplete.k-state-active .k-input,
.k-picker-wrap.k-state-active .k-input,
.k-numeric-wrap.k-state-active .k-input {
  background-color: #ffffff;
}
.k-picker-wrap.k-state-hover > .k-select,
.k-picker-wrap.k-state-focused > .k-select {
  border-color: #ffffff;
}
.k-picker-wrap.k-state-hover .k-input,
.k-picker-wrap.k-state-focused .k-input {
  background-color: #ffffff;
}
.k-textbox:hover,
.k-overflow-anchor:hover,
.k-autocomplete.k-state-hover,
.k-picker-wrap.k-state-hover,
.k-numeric-wrap.k-state-hover,
.k-dropdown-wrap.k-state-hover {
  background-color: #ffffff;
  border-color: #f5f5f5;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2), 0 2px 3px rgba(0, 0, 0, 0.05);
}
.k-maskedtextbox.k-state-disabled > .k-textbox:hover,
.k-dateinput.k-state-disabled > .k-textbox:hover {
  border-color: #f0f0f0;
  box-shadow: none;
}
.k-textbox:focus,
.k-autocomplete.k-state-focused,
.k-picker-wrap.k-state-focused,
.k-numeric-wrap.k-state-focused,
.k-dropdown-wrap.k-state-focused,
.k-multiselect.k-header.k-state-focused {
  background-color: #ebebeb;
  background-image: none;
  background-position: 50% 50%;
  border-color: #ebebeb;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2), 0 2px 3px rgba(0, 0, 0, 0.05);
}
.k-numeric-wrap.k-state-focused > .k-select {
  background-color: #ebebeb;
}
.k-textbox:focus,
.k-autocomplete.k-state-active,
.k-picker-wrap.k-state-active,
.k-numeric-wrap.k-state-active,
.k-dropdown-wrap.k-state-active,
.k-multiselect.k-header.k-state-active {
  background-color: #ffffff;
  background-image: none;
  background-position: 50% 50%;
  border-color: #f5f5f5;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2), 0 2px 3px rgba(0, 0, 0, 0.05);
}
.k-autocomplete.k-state-disabled,
.k-picker-wrap.k-state-disabled,
.k-numeric-wrap.k-state-disabled,
.k-numeric-wrap.k-state-disabled .k-input,
.k-numeric-wrap.k-state-disabled .k-select,
.k-dropdown-wrap.k-state-disabled,
.k-multiselect.k-header.k-state-disabled {
  background-color: #fafafa;
}
.k-numeric-wrap.k-state-disabled .k-select {
  border-color: #fafafa;
}
.k-numerictextbox .k-select {
  background-color: #ffffff;
  border-color: #ffffff;
}
.k-list > .k-state-selected.k-state-focused {
  box-shadow: none;
  color: #072640;
}
.k-list > .k-state-selected {
  box-shadow: none;
}
.k-list > .k-state-focused {
  border-color: transparent;
  box-shadow: none;
}
.k-list > .k-state-hover,
.k-list > .k-state-selected.k-state-hover {
  background-color: #ebebeb;
  border-color: #ebebeb;
}
.k-list-container {
  border-color: #ebebeb;
}
.k-grid td.k-state-focused.k-state-selected {
  box-shadow: inset 0 0 0 1px #808080;
}
.k-calendar td.k-state-focused,
.k-calendar td.k-state-selected.k-state-focused {
  box-shadow: inset 0 0 0 1px #808080;
}
.k-calendar td.k-state-selected {
  background-color: #009ddd;
  box-shadow: none;
}
.k-calendar td.k-state-selected.k-state-hover {
  background-color: #008fc9;
}
.k-calendar .k-state-selected > .k-link {
  color: #ffffff;
}
/* Calendar */
.k-calendar .k-header .k-link {
  color: #ffffff;
}
.k-calendar .k-footer {
  border-color: #e6e6e6;
}
.k-calendar td {
  border-radius: 50%;
}
.k-calendar .k-content th {
  background-color: #ffffff;
}
.k-calendar .k-alt {
  border-radius: 0;
  background-color: #f2f2f2;
}
.k-calendar .k-header .k-state-hover {
  background-color: #0079aa;
}
.k-calendar .k-footer .k-nav-today {
  color: #009ddd;
}
.k-calendar .k-nav-fast.k-state-hover {
  border-radius: 0;
}
.k-calendar .k-today {
  background-color: #009ddd;
}
.k-calendar .k-today .k-link {
  color: #ffffff;
}
.k-calendar .k-today.k-state-hover {
  background-color: #0079aa;
}
.k-calendar .k-today:active {
  box-shadow: inset 0 0 0 1px #006791;
}
.k-calendar .k-link.k-state-hover,
.k-window-titlebar .k-link {
  box-shadow: none;
}
.k-window-titlebar .k-state-hover {
  background-color: #00b0e4;
  border-color: #00b0e4;
}
/* TabStrip */
.k-tabstrip > .k-tabstrip-items > .k-item {
  border-radius: 0;
}
.k-tabstrip-items .k-state-active,
.k-panelbar .k-tabstrip-items .k-state-active {
  background-color: #009ddd;
  background-image: none;
  border-bottom-color: #009ddd;
}
.k-tabstrip .k-content.k-state-active {
  border-color: transparent;
}
.k-tabstrip-items .k-item.k-state-hover {
  background: #00b0e4;
  border-color: #00b0e4;
}
.k-tabstrip-items .k-state-hover .k-link {
  color: #ffffff;
}
/* Menu */
.k-group,
.k-flatcolorpicker.k-group,
.k-menu,
.k-menu .k-group,
.k-popup.k-widget.k-context-menu {
  color: #072640;
  background-color: #ffffff;
}
.k-menu .k-group,
.k-popup.k-context-menu.k-group {
  border-color: #e6e6e6;
}
.k-menu.k-header,
.k-menu .k-item,
.k-widget.k-menu-horizontal > .k-item {
  box-shadow: none;
}
.k-menu .k-state-active,
.k-popup.k-context-menu.k-group .k-state-hover {
  background-color: #ebebeb;
  border-color: #ebebeb;
}
.k-grid .k-header .k-button,
.k-scheduler .k-header .k-button,
.k-scheduler .k-header li,
.k-scheduler .k-header .k-link,
.k-gantt > .k-header li,
.k-gantt > .k-header .k-link,
.k-gantt-toolbar .k-button,
.km-pane-wrapper .k-header .k-button {
  color: #ffffff;
  background-color: #009ddd;
  border-color: #009ddd;
  box-shadow: none;
}
.k-grid .k-header .k-button:hover,
.k-scheduler .k-header .k-button:hover,
.k-scheduler .k-scheduler-toolbar .k-scheduler-views li.k-state-hover,
.k-scheduler .k-scheduler-toolbar .k-scheduler-views li.k-state-hover .k-link,
.k-gantt .k-gantt-toolbar .k-gantt-views li.k-state-hover,
.k-gantt .k-gantt-toolbar .k-gantt-views li.k-state-hover .k-link,
.k-gantt .k-gantt-toolbar .k-button:hover,
.km-pane-wrapper .k-header .k-button:hover {
  background-color: #00b0e4;
  border-color: #00b0e4;
}
.km-pane-wrapper .k-header .k-button:active:hover {
  color: #ffffff;
}
.k-scheduler .k-scheduler-toolbar ul li.k-state-hover,
.k-scheduler .k-scheduler-toolbar .k-state-selected,
.k-gantt-toolbar .k-button {
  background-color: #009ddd;
  border-color: #009ddd;
}
.k-gantt .k-gantt-toolbar .k-button:active {
  background: #ffffff;
  box-shadow: none;
}
.k-gantt-toolbar > .k-gantt-views > li.k-state-selected,
.k-gantt .k-gantt-toolbar .k-gantt-views li.k-state-selected.k-state-hover,
.k-scheduler .k-scheduler-toolbar .k-scheduler-views li.k-state-selected.k-state-hover,
.k-scheduler-toolbar > .k-scheduler-views > li.k-state-selected {
  border-bottom-color: #009ddd;
}
.k-scheduler-mark {
  border-radius: 50%;
}
/* Grid */
.k-grid .k-alt {
  background-color: #ffffff;
}
.k-grouping-row td,
td.k-group-cell,
.k-resize-handle-inner {
  color: #072640;
  background-color: #f2f2f2;
}
.k-grouping-header .k-group-indicator,
.k-pivot-toolbar .k-button {
  color: #ffffff;
  background-color: #0079aa;
  border-color: #0079aa;
  box-shadow: none;
}
.k-grid-header,
.k-grid-header .k-header,
.k-pager-wrap,
.k-pager-numbers .k-state-selected,
.k-grid-footer,
.k-grid-footer td,
.k-scheduler-header,
.km-pane-wrapper .k-grid-header .k-header {
  color: #072640;
  background-color: #fafafa;
}
.k-header.k-scheduler-footer .k-header,
.k-header.k-scheduler-footer ul.k-header li .k-link {
  color: #009ddd;
  background-color: #fafafa;
}
.k-header.k-scheduler-footer ul.k-header li {
  background-color: #fafafa;
  border-color: #fafafa;
}
.k-header,
.k-grid-header-wrap,
.k-grid .k-grouping-header,
.k-grid-header,
.k-pager-wrap,
.k-pager-wrap .k-textbox,
.k-pager-wrap .k-link,
.k-gantt-toolbar .k-state-default,
.k-grouping-row td,
.k-group-footer td,
.k-grid-footer td {
  border-color: #e6e6e6;
}
.k-group-footer td,
.k-footer-template td,
.k-fieldselector .k-item.k-header {
  color: #072640;
  background-color: #f2f2f2;
}
.k-grid .k-grouping-header {
  color: rgba(255, 255, 255, 0.5);
}
.k-pager-wrap {
  color: #072640;
}
.k-grouping-header .k-link,
.k-grouping-header .k-link:link {
  color: #ffffff;
}
.k-scheduler-layout .k-state-selected,
.k-scheduler .k-today.k-state-selected,
.k-grid tr.k-state-selected,
.k-grid td.k-state-selected,
.k-grid td.k-state-selected.k-state-focused,
.k-marquee-color,
.k-gantt .k-treelist .k-state-selected,
.k-gantt .k-treelist .k-state-selected td,
.k-gantt .k-treelist .k-alt.k-state-selected,
.k-gantt .k-treelist .k-alt.k-state-selected > td,
.k-listview > .k-state-selected,
.k-state-selected.k-line {
  background-color: #009ddd;
}
.k-state-selected.k-line {
  color: #009ddd;
}
.k-grid tr.k-state-selected,
.k-grid td.k-state-selected,
.k-listview > .k-state-selected,
.k-state-selected .k-progress-status {
  color: #ffffff;
}
.k-grid tr:hover {
  background-color: #ebebeb;
}
.k-grid .k-filter-row:hover,
.k-pivot-rowheaders .k-grid tr:hover {
  background: none;
}
.k-grid td.k-state-selected,
.k-grid tr.k-state-selected > td {
  border-color: #0079aa;
}
.k-grid td.k-state-selected:hover,
.k-grid tr.k-state-selected:hover td {
  background-color: #008fc9;
}
.k-grid-header .k-header .k-link,
.k-grid-header .k-header,
.k-grid-header .k-link,
.k-grid-header .k-link:link,
.k-pager-info,
.k-scheduler-header,
.k-scheduler-agendaview .k-scheduler-datecolumn {
  color: #999999;
}
.k-gantt .k-task-draghandle {
  border-color: #009ddd;
}
.k-grid-pager .k-link,
.k-grid-pager .k-link:link {
  color: #072640;
}
.k-pager-numbers .k-link,
.k-pager-wrap > .k-link {
  border-radius: 0;
}
.k-pager-numbers .k-state-selected {
  border-color: #072640 transparent transparent;
  border-radius: 0;
  box-shadow: none;
  color: #072640;
}
.k-pager-wrap .k-link {
  border-color: #fafafa;
  cursor: pointer;
}
.k-pager-wrap .k-link:hover {
  background-color: transparent;
  border-color: transparent;
}
.k-scheduler-toolbar > ul li:first-child,
.k-scheduler-toolbar > ul li:first-child .k-link,
.k-scheduler-toolbar .k-nav-next,
.k-scheduler-toolbar ul + ul li:last-child,
.k-scheduler-toolbar .k-nav-next .k-link,
.k-scheduler-toolbar ul + ul li:last-child .k-link,
.k-gantt-toolbar li:first-child,
.k-gantt-toolbar li:first-child > .k-link,
.k-gantt-toolbar li:last-child,
.k-gantt-toolbar li:last-child > .k-link {
  border-radius: 0;
}
.k-grid,
.k-panelbar,
.k-notification,
.k-popup .k-textbox:focus,
.k-popup .k-autocomplete.k-state-focused,
.k-popup .k-picker-wrap.k-state-focused,
.k-popup .k-numeric-wrap.k-state-focused,
.k-popup .k-dropdown-wrap.k-state-focused,
.k-popup .k-multiselect.k-header.k-state-focused,
.k-popup .k-textbox:hover,
.k-popup .k-autocomplete.k-state-hover,
.k-popup .k-picker-wrap.k-state-hover,
.k-popup .k-numeric-wrap.k-state-hover,
.k-popup .k-dropdown-wrap.k-state-hover {
  box-shadow: none;
}
/* PanelBar */
.k-panelbar,
.k-panelbar .k-header,
.k-panelbar .k-content,
.k-panel > li.k-item,
.k-panelbar .k-state-selected {
  background-color: #fafafa;
}
.k-panelbar .k-grid-toolbar {
  background-color: #009ddd;
}
.k-panelbar > li.k-state-default > .k-link {
  color: #072640;
}
.k-panelbar > li > .k-state-hover {
  background-color: #ebebeb;
}
.k-panelbar > .k-item > .k-link,
.k-panelbar.k-header,
.k-panelbar .k-content,
.k-panelbar .k-panel,
.k-panelbar .k-item {
  border-color: #e6e6e6;
}
/* Splitter */
.k-splitbar {
  border-color: #fafafa;
}
.k-splitbar.k-state-focused {
  background-color: #009ddd;
  border-color: #009ddd;
  box-shadow: none;
}
/* Upload */
.k-upload {
  color: #072640;
  background-color: #ffffff;
}
.k-upload-files .k-button {
  box-shadow: none;
}
/* Gantt */
.k-task-milestone,
.k-task-summary-complete,
.k-state-selected.k-task-milestone,
.k-state-selected .k-task-summary-complete {
  background-color: #009ddd;
  border-color: #009ddd;
}
.k-task-single {
  background-color: #44c9ff;
}
.k-task-complete {
  background: #009ddd 0 -257px none repeat-x;
}
.k-treelist .k-state-selected,
.k-treelist .k-state-selected td,
.k-treelist .k-alt.k-state-selected,
.k-treelist .k-alt.k-state-selected > td {
  background-color: #009ddd;
  border-color: #009ddd;
}
.k-multiselect .k-button:focus:active:not(.k-state-disabled):not([disabled]),
.k-group-indicator .k-button,
.k-group-indicator .k-button:focus:active:not(.k-state-disabled):not([disabled]),
.k-group-indicator .k-button:focus:not(.k-state-disabled):not([disabled]),
.k-gantt-toolbar .k-button:focus:not(.k-state-disabled):not([disabled]),
.k-gantt-toolbar .k-button:focus:active:not(.k-state-disabled):not([disabled]) {
  box-shadow: none;
}
.k-multiselect .k-button:active:hover {
  color: #072640;
  background-color: #dbdbdb;
  border-color: #dbdbdb;
}
.k-multiselect-wrap > ul > .k-button {
  box-shadow: none;
}
.k-filebrowser .k-header {
  color: #072640;
}
.k-treeview .k-state-selected,
.k-treeview .k-state-focused,
.k-panelbar > li > .k-state-focused {
  box-shadow: none;
}
.k-treeview .k-state-focused,
.k-panelbar > li > .k-state-focused {
  background-color: #ebebeb;
}
/* Progressbar */
.k-progressbar {
  background-color: #fafafa;
  border-color: #fafafa;
}
.k-progressbar .k-item,
.k-progressbar .k-item.k-state-selected {
  border-color: #ffffff;
}
.k-progressbar .k-state-selected {
  background-color: #009ddd;
  border-color: #009ddd;
}
.k-widget.k-tooltip-validation {
  box-shadow: none;
}
/* Pivot Grid */
.k-grid.k-alt {
  background-color: #fafafa;
}
.k-gantt .k-treelist .k-alt,
.k-gantt .k-header.k-nonwork-hour {
  background-color: #f2f2f2;
}
.k-list > .k-state-hover,
.k-list > .k-state-focused {
  color: #072640;
  background-color: #ebebeb;
  border-color: #ebebeb;
}
/* Slider */
.k-slider-track {
  background-color: #cccccc;
  box-shadow: none;
}
.k-slider-selection {
  background-color: #009ddd;
  border-color: #009ddd;
}
.k-slider .k-button,
.k-slider .k-button.k-state-hover,
.k-slider .k-button:active:hover,
.k-slider .k-button:focus,
.k-slider .k-button:active {
  background: none;
  border: none;
  box-shadow: none;
}
.k-draghandle,
.k-flatcolorpicker .k-slider-horizontal .k-slider-track {
  box-shadow: none;
}
.k-flatcolorpicker .k-hue-slider .k-draghandle,
.k-flatcolorpicker .k-transparency-slider .k-draghandle {
  border-color: #009ddd;
  background-color: #009ddd;
  box-shadow: none;
}
.k-flatcolorpicker .k-hue-slider .k-draghandle:hover,
.k-flatcolorpicker .k-transparency-slider .k-draghandle:hover {
  border-color: #009ddd;
  background-color: #009ddd;
  box-shadow: 0 0 0 8px rgba(63, 81, 181, 0.3);
}
.k-draghandle.k-state-selected,
.k-draghandle.k-state-selected:link,
.k-draghandle.k-state-selected:hover,
.k-flatcolorpicker .k-hue-slider .k-draghandle.k-state-selected,
.k-flatcolorpicker .k-transparency-slider .k-draghandle.k-state-selected {
  background-color: #cccccc;
  border-color: #cccccc;
}
.k-draghandle.k-state-focused,
.k-draghandle.k-state-focused:link,
.k-flatcolorpicker .k-hue-slider .k-draghandle.k-state-focused,
.k-flatcolorpicker .k-transparency-slider .k-draghandle.k-state-focused {
  box-shadow: none;
  border-color: #009ddd;
  background-color: #009ddd;
}
.k-edit-form-container .k-edit-buttons {
  background-color: #fafafa;
}
.k-popup .k-button,
.k-popup .k-button:active:hover {
  box-shadow: none;
}
.k-edit-form-container .k-button,
.k-popup .k-button,
.k-popup .k-primary:active,
.k-popup .k-primary:active:hover,
.k-edit-form-container .k-primary:active {
  color: #072640;
  background-color: #fafafa;
  border-color: #fafafa;
  box-shadow: none;
}
.k-popup .k-primary,
.k-edit-form-container .k-primary {
  color: #009ddd;
  background-color: #fafafa;
  border-color: #fafafa;
}
.k-split-wrapper .k-button,
.k-overflow-container .k-button,
.k-filter-menu .k-button {
  background: transparent;
  border-color: transparent;
}
.k-split-wrapper .k-button,
.k-overflow-container .k-button {
  text-transform: none;
}
.k-split-wrapper .k-button:hover,
.k-overflow-container .k-button:hover {
  background-color: #ebebeb;
  border-color: #ebebeb;
}
.k-split-wrapper .k-button:focus,
.k-overflow-container .k-button:focus,
.k-split-wrapper .k-button:focus:not(.k-state-disabled):not([disabled]),
.k-overflow-container .k-button:focus:not(.k-state-disabled):not([disabled]) {
  color: #009ddd;
  box-shadow: none;
}
.k-filter-menu .k-button {
  background: transparent;
  border-color: transparent;
}
.k-filter-menu .k-primary {
  border-left-color: #f0f0f0;
}
.k-filter-menu > div > div:last-child {
  border-color: #f0f0f0;
}
.k-popup .k-button:focus:active:not(.k-state-disabled):not([disabled]),
.k-edit-form-container .k-button:focus:active:not(.k-state-disabled):not([disabled]) {
  box-shadow: none;
}
.k-edit-form-container .k-scheduler-delete {
  color: #009ddd;
}
div.k-scheduler-marquee:before,
div.k-scheduler-marquee:after {
  border-color: #009ddd;
}
.km-pane-wrapper > .km-pane > .km-view > .km-content {
  color: #009ddd;
  background-color: #ffffff;
}
.km-pane-wrapper > .km-pane .km-content .k-mobile-list > ul > li > .k-link {
  color: #009ddd;
}
.k-popup.k-context-menu {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.k-drag-clue {
  color: #072640;
  background-color: #ebebeb;
  border-color: #ebebeb;
  box-shadow: inset 0 0 0 1px #808080;
}
.k-popup > .k-group-header,
.k-popup > .k-virtual-wrap > .k-group-header {
  color: #072640;
}
.k-popup .k-item > .k-group {
  color: #072640;
}
/* Responsive styles */
@media only screen and (max-width: 370px) {
  .k-webkit .k-pager-refresh,
  .k-ff .k-pager-refresh,
  .k-ie11 .k-pager-refresh,
  .k-edge .k-pager-refresh,
  .k-safari .k-pager-refresh {
    display: none;
  }
}
@media only screen and (max-width: 590px) {
  .k-webkit .k-pager-refresh,
  .k-ff .k-pager-refresh,
  .k-ie11 .k-pager-refresh,
  .k-edge .k-pager-refresh,
  .k-safari .k-pager-refresh {
    margin-right: 0;
  }
}
@media only screen and (max-width: 530px) {
  .k-webkit .k-pager-sizes,
  .k-ff .k-pager-sizes,
  .k-ie11 .k-pager-sizes,
  .k-edge .k-pager-sizes,
  .k-safari .k-pager-sizes {
    display: none;
  }
}
@media only screen and (max-width: 687px) {
  .k-webkit .k-pager-info,
  .k-ff .k-pager-info,
  .k-ie11 .k-pager-info,
  .k-edge .k-pager-info,
  .k-safari .k-pager-info {
    display: none;
  }
}
@media only screen and (max-width: 1024px) {
  .k-scheduler-toolbar > ul.k-scheduler-views {
    right: 13px;
    top: 0;
  }
  .k-webkit,
  .k-ff,
  .k-ie11,
  .k-edge,
  .k-safari {
    /* Responsive Scheduler */
    /* Responsive Pager */
  }
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views,
  .k-edge .k-scheduler-toolbar > ul.k-scheduler-views,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views {
    right: 13px;
    top: 0;
  }
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view,
  .k-edge .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view,
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover,
  .k-edge .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover {
    background-image: none;
    background-image: none, linear-gradient(to bottom, false);
    background-position: 50% 50%;
    background-color: transparent;
    border-color: transparent;
    border-radius: 0px;
    text-align: right;
  }
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li,
  .k-edge .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li {
    border-radius: 0;
  }
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li.k-current-view,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li.k-current-view,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li.k-current-view,
  .k-edge .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li.k-current-view,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li.k-current-view {
    border-radius: -1px -1px 0 0;
  }
  .k-webkit .k-scheduler-toolbar > ul li:first-child,
  .k-ff .k-scheduler-toolbar > ul li:first-child,
  .k-ie11 .k-scheduler-toolbar > ul li:first-child,
  .k-edge .k-scheduler-toolbar > ul li:first-child,
  .k-safari .k-scheduler-toolbar > ul li:first-child,
  .k-webkit .k-scheduler-toolbar > ul li:first-child .k-link,
  .k-ff .k-scheduler-toolbar > ul li:first-child .k-link,
  .k-ie11 .k-scheduler-toolbar > ul li:first-child .k-link,
  .k-edge .k-scheduler-toolbar > ul li:first-child .k-link,
  .k-safari .k-scheduler-toolbar > ul li:first-child .k-link,
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views li,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views li,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views li,
  .k-edge .k-scheduler-toolbar > ul.k-scheduler-views li,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views li,
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views li .k-link,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views li .k-link,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views li .k-link,
  .k-edge .k-scheduler-toolbar > ul.k-scheduler-views li .k-link,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views li .k-link {
    border-radius: 0;
  }
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views li:last-child,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views li:last-child,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views li:last-child,
  .k-edge .k-scheduler-toolbar > ul.k-scheduler-views li:last-child,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views li:last-child,
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views li:last-child .k-link,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views li:last-child .k-link,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views li:last-child .k-link,
  .k-edge .k-scheduler-toolbar > ul.k-scheduler-views li:last-child .k-link,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views li:last-child .k-link {
    border-radius: 0 0 -1px -1px;
  }
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover,
  .k-edge .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover {
    border-color: transparent;
    background-image: none;
    background-color: transparent;
  }
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link,
  .k-edge .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link,
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover > .k-link,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover > .k-link,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover > .k-link,
  .k-edge .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover > .k-link,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover > .k-link {
    color: #ffffff;
    min-width: 20px;
  }
  .k-webkit .k-scheduler-views > li.k-state-selected > .k-link:after,
  .k-ff .k-scheduler-views > li.k-state-selected > .k-link:after,
  .k-ie11 .k-scheduler-views > li.k-state-selected > .k-link:after,
  .k-edge .k-scheduler-views > li.k-state-selected > .k-link:after,
  .k-safari .k-scheduler-views > li.k-state-selected > .k-link:after {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -0.5em;
    right: 0.333em;
    width: 1.333em;
    height: 1.333em;
  }
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded,
  .k-edge .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded {
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    /*@secondary-border-color*/
    background-image: none;
    background-color: #009ddd;
    border-radius: 0px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  }
  .k-webkit .k-pager-wrap,
  .k-ff .k-pager-wrap,
  .k-ie11 .k-pager-wrap,
  .k-edge .k-pager-wrap,
  .k-safari .k-pager-wrap {
    min-height: 2.56em;
  }
  .k-webkit .k-pager-wrap .k-pager-nav,
  .k-ff .k-pager-wrap .k-pager-nav,
  .k-ie11 .k-pager-wrap .k-pager-nav,
  .k-edge .k-pager-wrap .k-pager-nav,
  .k-safari .k-pager-wrap .k-pager-nav,
  .k-webkit .k-pager-input,
  .k-ff .k-pager-input,
  .k-ie11 .k-pager-input,
  .k-edge .k-pager-input,
  .k-safari .k-pager-input {
    display: inline-block;
    vertical-align: top;
  }
  .k-webkit .k-pager-numbers,
  .k-ff .k-pager-numbers,
  .k-ie11 .k-pager-numbers,
  .k-edge .k-pager-numbers,
  .k-safari .k-pager-numbers,
  .k-webkit .k-grid .k-pager-numbers,
  .k-ff .k-grid .k-pager-numbers,
  .k-ie11 .k-grid .k-pager-numbers,
  .k-edge .k-grid .k-pager-numbers,
  .k-safari .k-grid .k-pager-numbers {
    position: absolute;
    left: 5.6em;
    display: inline-flex;
    flex-direction: column-reverse;
    overflow: visible;
    height: auto;
  }
  .k-webkit .k-pager-numbers.k-state-expanded,
  .k-ff .k-pager-numbers.k-state-expanded,
  .k-ie11 .k-pager-numbers.k-state-expanded,
  .k-edge .k-pager-numbers.k-state-expanded,
  .k-safari .k-pager-numbers.k-state-expanded,
  .k-webkit .k-grid .k-pager-numbers.k-state-expanded,
  .k-ff .k-grid .k-pager-numbers.k-state-expanded,
  .k-ie11 .k-grid .k-pager-numbers.k-state-expanded,
  .k-edge .k-grid .k-pager-numbers.k-state-expanded,
  .k-safari .k-grid .k-pager-numbers.k-state-expanded {
    transform: translatey(-100%);
  }
  .k-webkit .km-pane-wrapper .k-pager-numbers,
  .k-ff .km-pane-wrapper .k-pager-numbers,
  .k-ie11 .km-pane-wrapper .k-pager-numbers,
  .k-edge .km-pane-wrapper .k-pager-numbers,
  .k-safari .km-pane-wrapper .k-pager-numbers,
  .k-webkit .km-pane-wrapper .k-grid .k-pager-numbers,
  .k-ff .km-pane-wrapper .k-grid .k-pager-numbers,
  .k-ie11 .km-pane-wrapper .k-grid .k-pager-numbers,
  .k-edge .km-pane-wrapper .k-grid .k-pager-numbers,
  .k-safari .km-pane-wrapper .k-grid .k-pager-numbers {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    -webkit-transform: translate(-50%, 0%);
  }
  .k-webkit .km-pane-wrapper .k-pager-numbers.k-state-expanded,
  .k-ff .km-pane-wrapper .k-pager-numbers.k-state-expanded,
  .k-ie11 .km-pane-wrapper .k-pager-numbers.k-state-expanded,
  .k-edge .km-pane-wrapper .k-pager-numbers.k-state-expanded,
  .k-safari .km-pane-wrapper .k-pager-numbers.k-state-expanded,
  .k-webkit .km-pane-wrapper .k-grid .k-pager-numbers.k-state-expanded,
  .k-ff .km-pane-wrapper .k-grid .k-pager-numbers.k-state-expanded,
  .k-ie11 .km-pane-wrapper .k-grid .k-pager-numbers.k-state-expanded,
  .k-edge .km-pane-wrapper .k-grid .k-pager-numbers.k-state-expanded,
  .k-safari .km-pane-wrapper .k-grid .k-pager-numbers.k-state-expanded {
    -webkit-transform: translate(-50%, -100%);
    transform: translate(-50%, -100%);
  }
  .k-webkit .km-pane-wrapper .k-pager-numbers .k-link,
  .k-ff .km-pane-wrapper .k-pager-numbers .k-link,
  .k-ie11 .km-pane-wrapper .k-pager-numbers .k-link,
  .k-edge .km-pane-wrapper .k-pager-numbers .k-link,
  .k-safari .km-pane-wrapper .k-pager-numbers .k-link,
  .k-webkit .km-pane-wrapper .k-pager-numbers .k-state-selected,
  .k-ff .km-pane-wrapper .k-pager-numbers .k-state-selected,
  .k-ie11 .km-pane-wrapper .k-pager-numbers .k-state-selected,
  .k-edge .km-pane-wrapper .k-pager-numbers .k-state-selected,
  .k-safari .km-pane-wrapper .k-pager-numbers .k-state-selected,
  .k-webkit .km-pane-wrapper .k-pager-wrap > .k-link,
  .k-ff .km-pane-wrapper .k-pager-wrap > .k-link,
  .k-ie11 .km-pane-wrapper .k-pager-wrap > .k-link,
  .k-edge .km-pane-wrapper .k-pager-wrap > .k-link,
  .k-safari .km-pane-wrapper .k-pager-wrap > .k-link,
  .k-webkit .km-pane-wrapper .k-pager-wrap > .k-pager-info,
  .k-ff .km-pane-wrapper .k-pager-wrap > .k-pager-info,
  .k-ie11 .km-pane-wrapper .k-pager-wrap > .k-pager-info,
  .k-edge .km-pane-wrapper .k-pager-wrap > .k-pager-info,
  .k-safari .km-pane-wrapper .k-pager-wrap > .k-pager-info {
    padding-top: 0;
    padding-bottom: 0;
  }
  .k-webkit .k-rtl .k-pager-numbers,
  .k-ff .k-rtl .k-pager-numbers,
  .k-ie11 .k-rtl .k-pager-numbers,
  .k-edge .k-rtl .k-pager-numbers,
  .k-safari .k-rtl .k-pager-numbers,
  .k-webkit .k-rtl .k-grid .k-pager-numbers,
  .k-ff .k-rtl .k-grid .k-pager-numbers,
  .k-ie11 .k-rtl .k-grid .k-pager-numbers,
  .k-edge .k-rtl .k-grid .k-pager-numbers,
  .k-safari .k-rtl .k-grid .k-pager-numbers {
    right: 5.6em;
    width: 5.15em;
  }
  .k-webkit .k-pager-numbers .k-current-page,
  .k-ff .k-pager-numbers .k-current-page,
  .k-ie11 .k-pager-numbers .k-current-page,
  .k-edge .k-pager-numbers .k-current-page,
  .k-safari .k-pager-numbers .k-current-page,
  .k-webkit .k-grid .k-pager-numbers .k-current-page,
  .k-ff .k-grid .k-pager-numbers .k-current-page,
  .k-ie11 .k-grid .k-pager-numbers .k-current-page,
  .k-edge .k-grid .k-pager-numbers .k-current-page,
  .k-safari .k-grid .k-pager-numbers .k-current-page {
    display: block;
    border-left: 0;
  }
  .k-webkit .k-pager-numbers li:not(.k-current-page),
  .k-ff .k-pager-numbers li:not(.k-current-page),
  .k-ie11 .k-pager-numbers li:not(.k-current-page),
  .k-edge .k-pager-numbers li:not(.k-current-page),
  .k-safari .k-pager-numbers li:not(.k-current-page) {
    display: none;
  }
  .k-webkit .k-pager-numbers .k-current-page .k-link,
  .k-ff .k-pager-numbers .k-current-page .k-link,
  .k-ie11 .k-pager-numbers .k-current-page .k-link,
  .k-edge .k-pager-numbers .k-current-page .k-link,
  .k-safari .k-pager-numbers .k-current-page .k-link {
    width: 3.8em;
    line-height: 2.564em;
    padding: 0 .429em 0 0.8em;
    border-radius: 0px;
    background-image: none;
    background-image: none, linear-gradient(to bottom, false);
    background-position: 50% 50%;
    background-color: #fafafa;
    border: 1px solid transparent;
    border-top: 0;
    box-shadow: 0 2px 2px 0 #fafafa;
  }
  .k-webkit .k-pager-numbers .k-current-page:hover .k-link,
  .k-ff .k-pager-numbers .k-current-page:hover .k-link,
  .k-ie11 .k-pager-numbers .k-current-page:hover .k-link,
  .k-edge .k-pager-numbers .k-current-page:hover .k-link,
  .k-safari .k-pager-numbers .k-current-page:hover .k-link {
    border-radius: 0px;
    background-color: #ffffff;
    border: 1px solid #ebebeb;
    border-top: 0;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2), 0 2px 3px rgba(0, 0, 0, 0.05);
  }
  .k-webkit .k-pager-numbers .k-current-page .k-link:after,
  .k-ff .k-pager-numbers .k-current-page .k-link:after,
  .k-ie11 .k-pager-numbers .k-current-page .k-link:after,
  .k-edge .k-pager-numbers .k-current-page .k-link:after,
  .k-safari .k-pager-numbers .k-current-page .k-link:after {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -0.6em;
    right: 0.6em;
    width: 1.333em;
    height: 1.333em;
    background-position: 0 -30px;
  }
  .k-webkit .k-pager-numbers + .k-link,
  .k-ff .k-pager-numbers + .k-link,
  .k-ie11 .k-pager-numbers + .k-link,
  .k-edge .k-pager-numbers + .k-link,
  .k-safari .k-pager-numbers + .k-link {
    margin-left: 5.4em;
  }
  .k-webkit .k-rtl .k-pager-numbers + .k-link,
  .k-ff .k-rtl .k-pager-numbers + .k-link,
  .k-ie11 .k-rtl .k-pager-numbers + .k-link,
  .k-edge .k-rtl .k-pager-numbers + .k-link,
  .k-safari .k-rtl .k-pager-numbers + .k-link {
    margin-right: 5.4em;
    margin-left: 0;
  }
  .k-webkit .k-pager-wrap .k-pager-numbers .k-state-selected,
  .k-ff .k-pager-wrap .k-pager-numbers .k-state-selected,
  .k-ie11 .k-pager-wrap .k-pager-numbers .k-state-selected,
  .k-edge .k-pager-wrap .k-pager-numbers .k-state-selected,
  .k-safari .k-pager-wrap .k-pager-numbers .k-state-selected,
  .k-webkit .k-pager-wrap .k-pager-numbers .k-link,
  .k-ff .k-pager-wrap .k-pager-numbers .k-link,
  .k-ie11 .k-pager-wrap .k-pager-numbers .k-link,
  .k-edge .k-pager-wrap .k-pager-numbers .k-link,
  .k-safari .k-pager-wrap .k-pager-numbers .k-link {
    display: block;
    margin-top: 0;
    margin-right: 0;
    padding: 1px 5px 1px .8em;
    text-align: left;
    border-top: 0;
    border-radius: 0;
  }
  .k-webkit .k-pager-wrap .k-pager-numbers li:not(.k-current-page) .k-link:hover,
  .k-ff .k-pager-wrap .k-pager-numbers li:not(.k-current-page) .k-link:hover,
  .k-ie11 .k-pager-wrap .k-pager-numbers li:not(.k-current-page) .k-link:hover,
  .k-edge .k-pager-wrap .k-pager-numbers li:not(.k-current-page) .k-link:hover,
  .k-safari .k-pager-wrap .k-pager-numbers li:not(.k-current-page) .k-link:hover {
    background-color: #ebebeb;
  }
  .k-webkit .k-pager-numbers.k-state-expanded,
  .k-ff .k-pager-numbers.k-state-expanded,
  .k-ie11 .k-pager-numbers.k-state-expanded,
  .k-edge .k-pager-numbers.k-state-expanded,
  .k-safari .k-pager-numbers.k-state-expanded {
    box-sizing: border-box;
    padding: 2px 0 0;
    border-width: 1px 1px 0 1px;
    border-style: solid;
    border-color: #ebebeb;
    /*@secondary-border-color*/
    background-color: #ffffff;
    border-radius: 0px 0px 0 0;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  }
  .k-webkit .k-pager-numbers.k-state-expanded .k-current-page,
  .k-ff .k-pager-numbers.k-state-expanded .k-current-page,
  .k-ie11 .k-pager-numbers.k-state-expanded .k-current-page,
  .k-edge .k-pager-numbers.k-state-expanded .k-current-page,
  .k-safari .k-pager-numbers.k-state-expanded .k-current-page {
    margin: -2.2em -1px 0;
    padding: 0;
  }
  .k-webkit .k-pager-numbers.k-state-expanded .k-current-page .k-link,
  .k-ff .k-pager-numbers.k-state-expanded .k-current-page .k-link,
  .k-ie11 .k-pager-numbers.k-state-expanded .k-current-page .k-link,
  .k-edge .k-pager-numbers.k-state-expanded .k-current-page .k-link,
  .k-safari .k-pager-numbers.k-state-expanded .k-current-page .k-link {
    border-radius: 0 0 0px 0px;
    background-color: #ffffff;
    border: 1px solid #ebebeb;
    border-top: 0;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  }
  .k-webkit .k-pager-numbers.k-state-expanded li,
  .k-ff .k-pager-numbers.k-state-expanded li,
  .k-ie11 .k-pager-numbers.k-state-expanded li,
  .k-edge .k-pager-numbers.k-state-expanded li,
  .k-safari .k-pager-numbers.k-state-expanded li {
    display: inline-block;
  }
  .k-webkit .k-gantt-toolbar > ul.k-gantt-views,
  .k-ff .k-gantt-toolbar > ul.k-gantt-views,
  .k-ie11 .k-gantt-toolbar > ul.k-gantt-views,
  .k-edge .k-gantt-toolbar > ul.k-gantt-views,
  .k-safari .k-gantt-toolbar > ul.k-gantt-views {
    top: 0;
  }
}
@media only screen and (max-width: 755px) {
  .k-webkit .k-pager-info,
  .k-ff .k-pager-info,
  .k-ie11 .k-pager-info,
  .k-edge .k-pager-info,
  .k-safari .k-pager-info {
    display: none;
  }
}
@media only screen and (max-width: 572px) {
  .k-webkit .k-pager-sizes,
  .k-ff .k-pager-sizes,
  .k-ie11 .k-pager-sizes,
  .k-edge .k-pager-sizes,
  .k-safari .k-pager-sizes {
    display: none;
  }
}
/* Default Theme */
.k-chart .k-mask {
  background-color: #ffffff;
  filter: alpha(opacity=68);
  opacity: 0.68;
}
.k-chart .k-selection {
  border-color: #e5e5e5;
}
.k-chart .k-handle {
  width: 15px;
  height: 15px;
  background-color: #072640;
  border-radius: 10px;
}
.k-chart .k-left-handle {
  left: -8px;
}
.k-chart .k-right-handle {
  right: -8px;
}
.k-chart .k-handle:hover {
  background-color: #009ddd;
  border-color: #009ddd;
}
.k-chart .k-navigator-hint .k-tooltip {
  border: 3px solid #ffffff;
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2);
  background: #ffffff;
  color: #242424;
}
.k-chart .k-navigator-hint .k-scroll {
  background: #009ddd;
  height: 4px;
}
.k-chart-tooltip {
  background-image: none;
}
/* Map */
.k-map .k-marker {
  font-size: 28px;
  color: #009ddd;
}
.k-map .k-attribution {
  color: #666666;
}
.k-map .k-shadow {
  background-color: #f9f9f9;
  border-color: #f9f9f9;
}
.k-map .k-zoom-control {
  border-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}
.k-map .k-map-controls .k-button {
  box-shadow: none;
}
.k-map .k-map-controls .k-button:focus,
.k-map .k-map-controls .k-button:active,
.k-map .k-map-controls .k-button:focus:active {
  background-color: #d6d6d6;
  border-color: #d6d6d6;
  box-shadow: none;
}
.k-buttons-horizontal .k-zoom-out {
  border-radius: 0 2px 2px 0;
}
.k-buttons-horizontal :first-child {
  border-radius: 2px 0 0 2px;
}
.k-rtl .k-buttons-horizontal .k-zoom-out {
  border-radius: 2px 0 0 2px;
}
.k-rtl .k-buttons-horizontal :first-child {
  border-radius: 0 2px 2px 0;
}
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.k-spreadsheet-row-header,
.k-spreadsheet-column-header {
  background-color: #ffffff;
}
.k-spreadsheet-top-corner,
.k-spreadsheet-row-header,
.k-spreadsheet-column-header {
  background-color: #ffffff;
  background-image: none;
  color: #000000;
  border-color: #cccccc;
}
.k-spreadsheet-top-corner {
  border-color: #cccccc;
}
.k-spreadsheet-top-corner:after {
  border-color: transparent #cccccc #cccccc transparent;
}
.k-spreadsheet-pane {
  border-color: #cccccc;
}
.k-spreadsheet-pane .k-spreadsheet-vaxis,
.k-spreadsheet-pane .k-spreadsheet-haxis {
  border-color: #e6e6e6;
}
.k-spreadsheet-pane .k-spreadsheet-column-header,
.k-spreadsheet-pane .k-spreadsheet-row-header {
  border-color: #cccccc;
}
.k-spreadsheet-pane .k-spreadsheet-merged-cell {
  background-color: #ffffff;
}
.k-spreadsheet-pane .k-selection-partial,
.k-spreadsheet-pane .k-selection-full {
  border-color: rgba(0, 157, 221, 0.2);
  background-color: rgba(0, 157, 221, 0.2);
}
.k-spreadsheet-pane .k-filter-range {
  border-color: #009ddd;
}
.k-spreadsheet-pane .k-spreadsheet-column-header .k-selection-partial,
.k-spreadsheet-pane .k-spreadsheet-column-header .k-selection-full {
  border-bottom-color: #009ddd;
}
.k-spreadsheet-pane .k-spreadsheet-row-header .k-selection-partial,
.k-spreadsheet-pane .k-spreadsheet-row-header .k-selection-full {
  border-right-color: #009ddd;
}
.k-auto-fill,
.k-spreadsheet-selection {
  border-color: #009ddd;
  box-shadow: inset 0 0 0 1px #ffffff, 0 0 0 1px #009ddd;
}
.k-spreadsheet-selection {
  background-color: rgba(0, 157, 221, 0.2);
}
.k-spreadsheet-active-cell {
  border-color: #009ddd !important;
  background-color: #ffffff;
}
.k-spreadsheet-active-cell.k-single {
  color: #072640;
  background-color: #ffffff;
}
.k-spreadsheet .k-spreadsheet-action-bar {
  background-color: #ffffff;
  border-color: #e6e6e6;
}
.k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-name-editor {
  border-color: #cccccc;
}
.k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-formula-bar::before {
  border-color: #cccccc;
}
.k-spreadsheet .k-spreadsheet-formula-input {
  background-color: #ffffff;
  color: #072640;
}
.k-spreadsheet .k-resize-handle,
.k-spreadsheet .k-resize-hint-handle,
.k-spreadsheet .k-resize-hint-marker {
  background-color: #009ddd;
}
.k-spreadsheet .k-resize-hint-vertical .k-resize-hint-handle,
.k-spreadsheet .k-resize-hint-vertical .k-resize-hint-marker {
  background-color: #009ddd;
}
.k-spreadsheet .k-single-selection::after {
  background-color: #009ddd;
  border-color: #ffffff;
}
.k-spreadsheet .k-auto-fill-punch {
  background-color: rgba(255, 255, 255, 0.5);
}
.k-spreadsheet .k-single-selection.k-dim-auto-fill-handle::after {
  background-color: rgba(0, 157, 221, 0.5);
}
.k-spreadsheet-format-cells .k-spreadsheet-preview {
  border-color: #e6e6e6;
}
.k-spreadsheet-filter {
  border-radius: 0px;
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #e6e6e6;
}
.k-spreadsheet-filter.k-state-active {
  color: #072640;
  background-color: #009ddd;
}
.k-spreadsheet-filter:hover {
  color: #072640;
  background: #ebebeb;
  border-color: #d7d7d7;
}
.k-action-window .k-action-buttons {
  border-color: #e6e6e6;
}
.k-spreadsheet-sample {
  color: #1678ca;
}
.k-state-selected .k-spreadsheet-sample {
  color: inherit;
}
.k-spreadsheet-window .k-list-wrapper,
.k-spreadsheet-window .k-list {
  border-color: #e6e6e6;
  border-radius: 0px;
}
.k-spreadsheet-toolbar.k-toolbar .k-button-group .k-button:not(.k-toggle-button) {
  border-radius: 0px;
}
.k-spreadsheet-toolbar > .k-widget,
.k-spreadsheet-toolbar > .k-button,
.k-spreadsheet-toolbar > .k-button-group {
  border-radius: 0px;
}
.k-spreadsheet-toolbar > .k-separator {
  border-color: #e6e6e6;
}
.k-spreadsheet-toolbar .k-overflow-anchor {
  border-radius: 0;
}
.k-spreadsheet-popup {
  border-radius: 0px;
}
.k-spreadsheet-popup .k-separator {
  background-color: #e6e6e6;
}
.k-spreadsheet-popup .k-button {
  background-color: transparent;
}
.k-spreadsheet-popup .k-button:hover {
  background-color: #ebebeb;
}
.k-spreadsheet-popup .k-state-active {
  background-color: #009ddd;
  color: #ffffff;
}
.k-spreadsheet-popup .k-state-active:hover {
  background-color: #0079aa;
}
.k-spreadsheet-filter-menu .k-details {
  border-color: #e6e6e6;
}
.k-spreadsheet-filter-menu .k-details-content .k-space-right {
  background-color: #ffffff;
}
.k-spreadsheet-filter-menu .k-spreadsheet-value-treeview-wrapper {
  background-color: #ffffff;
  border-color: #e6e6e6;
  border-radius: 0px 0 0 0px;
}
.k-syntax-ref {
  color: #ff8822;
}
.k-syntax-num {
  color: #0099ff;
}
.k-syntax-func {
  font-weight: bold;
}
.k-syntax-str {
  color: #38b714;
}
.k-syntax-error {
  color: red;
}
.k-syntax-bool {
  color: #a9169c;
}
.k-syntax-startexp {
  font-weight: bold;
}
.k-syntax-paren-match {
  background-color: #caf200;
}
.k-series-a {
  border-color: #007acc;
  background-color: rgba(0, 122, 204, 0.15);
}
.k-series-b {
  border-color: #007acc;
  background-color: rgba(0, 122, 204, 0.15);
}
.k-series-c {
  border-color: #007acc;
  background-color: rgba(0, 122, 204, 0.15);
}
.k-series-d {
  border-color: #007acc;
  background-color: rgba(0, 122, 204, 0.15);
}
.k-series-e {
  border-color: #007acc;
  background-color: rgba(0, 122, 204, 0.15);
}
.k-series-f {
  border-color: #007acc;
  background-color: rgba(0, 122, 204, 0.15);
}
.k-spreadsheet-sheets-remove:hover .k-icon {
  color: #cc2222;
}
.k-spreadsheet-formula-list .k-state-focused {
  background-color: #009ddd;
  color: #072640;
}
.k-spreadsheet .k-spreadsheet-quick-access-toolbar .k-button,
.k-spreadsheet .k-spreadsheet-sheets-bar .k-button {
  box-shadow: none;
  color: #ffffff;
  border-radius: 0;
  line-height: 2.6em;
  height: calc(5.1em);
  width: 3em;
}
.k-spreadsheet .k-spreadsheet-quick-access-toolbar .k-button:hover,
.k-spreadsheet .k-spreadsheet-sheets-bar .k-button:hover {
  background-color: #007eb1;
  border-color: #007eb1;
}
.k-spreadsheet .k-spreadsheet-sheets-bar .k-button {
  left: 0;
  bottom: 0;
  padding-top: .5em;
  padding-bottom: .5em;
  line-height: 2.2em;
}
.k-spreadsheet .k-spreadsheet-sheets-remove {
  margin: 0 0 0 -1em;
}
.k-spreadsheet-sheets-items .k-state-default .k-link,
.k-spreadsheet-tabstrip .k-state-default .k-link {
  color: #80ceee;
}
.k-spreadsheet-sheets-items .k-item.k-state-hover,
.k-spreadsheet-tabstrip .k-item.k-state-hover,
.k-spreadsheet-sheets-items .k-item.k-state-active,
.k-spreadsheet-tabstrip .k-item.k-state-active,
.k-spreadsheet-sheets-items .k-item.k-state-focused,
.k-spreadsheet-tabstrip .k-item.k-state-focused {
  background-color: transparent;
}
.k-spreadsheet-sheets-items .k-item.k-state-hover .k-link,
.k-spreadsheet-tabstrip .k-item.k-state-hover .k-link,
.k-spreadsheet-sheets-items .k-item.k-state-active .k-link,
.k-spreadsheet-tabstrip .k-item.k-state-active .k-link,
.k-spreadsheet-sheets-items .k-item.k-state-focused .k-link,
.k-spreadsheet-tabstrip .k-item.k-state-focused .k-link {
  color: #ffffff;
}
.k-spreadsheet-sheets-items .k-state-active .k-link,
.k-spreadsheet-tabstrip .k-state-active .k-link {
  color: #ffffff;
}
.k-rtl .k-grid .k-checkbox:focus + .k-checkbox-label:after,
.k-rtl .k-grid .k-checkbox:active + .k-checkbox-label:after,
.k-rtl .k-grid .k-checkbox + .k-checkbox-label:active:after,
.k-rtl .k-grid .k-checkbox:checked:active + .k-checkbox-label:after,
.k-rtl .k-grid .k-checkbox:checked + .k-checkbox-label:active:after {
  left: auto;
  right: 1px;
}
.k-window .k-popup-edit-form .k-edit-field label.k-checkbox-label {
  margin-top: 0.8em;
}
/* Dialog */
.k-dialog .k-content {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.k-dialog.k-dialog-titleless .k-content {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.k-dialog .k-window-titlebar {
  border-width: 0;
  color: #072640;
}
.k-dialog > .k-header {
  background: none;
}
.k-dialog.k-alert .k-window-titlebar,
.k-dialog.k-confirm .k-window-titlebar,
.k-dialog.k-prompt .k-window-titlebar {
  border-bottom: none;
}
.k-dialog.k-alert .k-window-titlebar .k-dialog-title,
.k-dialog.k-confirm .k-window-titlebar .k-dialog-title,
.k-dialog.k-prompt .k-window-titlebar .k-dialog-title {
  color: #072640;
}
.k-dialog a.k-dialog-action.k-dialog-close.k-button.k-bare:before {
  content: normal;
}
.k-dialog .k-dialog-buttongroup .k-button:active,
.k-dialog .k-dialog-buttongroup .k-button:focus,
.k-dialog .k-dialog-buttongroup .k-button:focus:active {
  box-shadow: none;
}
.k-dialog .k-dialog-buttongroup.k-dialog-button-layout-stretched {
  width: 100%;
}
.k-dialog .k-dialog-buttongroup.k-dialog-button-layout-stretched .k-button {
  display: inline-block;
  padding: 1.5em 0;
  border-radius: 0;
  border-bottom: 0;
  box-shadow: none;
}
.k-dialog .k-dialog-buttongroup.k-dialog-button-layout-stretched .k-button:first-child {
  border-bottom-left-radius: 0px;
  border-left: 0;
}
.k-dialog .k-dialog-buttongroup.k-dialog-button-layout-stretched .k-button:last-child {
  border-bottom-right-radius: 0px;
  border-right: 0;
}
.k-dialog .k-dialog-buttongroup.k-dialog-button-layout-normal {
  width: auto;
  margin: 0;
  padding: 1em;
  text-align: right;
}
.k-dialog .k-dialog-buttongroup.k-dialog-button-layout-normal .k-button {
  margin-left: 0.5em;
  background: none;
  border: none;
  border-radius: 0;
  box-shadow: none;
}
.k-dialog .k-dialog-buttongroup.k-dialog-button-layout-normal .k-button:first-child {
  margin-left: 0;
}
.k-dialog .k-dialog-buttongroup.k-dialog-button-layout-normal .k-button.k-primary {
  color: #009ddd;
}
.k-rtl .k-grid-header .k-header:first-child,
.k-rtl .k-filter-row th:first-child,
.k-rtl .k-grid tbody td:first-child,
.k-rtl .k-grid tfoot td:first-child {
  border-left-width: 0;
}
.k-rtl .k-dialog a.k-dialog-action.k-dialog-close {
  left: 0;
}
.k-rtl .k-dialog .k-dialog-buttongroup.k-dialog-button-layout-stretched .k-button:first-child {
  border-bottom-right-radius: 0px;
}
.k-rtl .k-dialog .k-dialog-buttongroup.k-dialog-button-layout-stretched .k-button:last-child {
  border-bottom-left-radius: 0px;
}
.k-rtl .k-dialog .k-dialog-buttongroup.k-dialog-button-layout-normal {
  text-align: left;
}
.k-rtl .k-dialog .k-dialog-buttongroup.k-dialog-button-layout-normal .k-button {
  margin-left: 0;
  margin-right: 0.5em;
}
.k-rtl .k-dialog .k-dialog-buttongroup.k-dialog-button-layout-normal .k-button:first-child {
  margin-right: 0;
}
.k-numeric-wrap .k-i-warning {
  color: #d9534f;
  position: absolute;
  top: 0;
  right: 2.2em;
  width: 2.2em;
}
.k-numeric-wrap.k-state-invalid {
  border-color: #d9534f;
}
.k-numeric-wrap.k-state-invalid input {
  color: #d9534f;
}
.k-maskedtextbox.k-state-invalid .k-textbox {
  border-color: #d9534f;
  color: #d9534f;
}
.k-maskedtextbox.k-state-invalid .k-i-warning {
  color: #d9534f;
}
.k-dateinput.k-state-invalid .k-textbox {
  color: #d9534f;
  border-color: #d9534f;
}
.k-dateinput.k-state-invalid .k-i-warning {
  margin-left: 0;
  margin-right: 0.7em;
  color: #d9534f;
}
.k-rtl .k-dateinput .k-i-warning {
  margin-right: 0;
  margin-left: 0.7em;
}
.k-datepicker .k-picker-wrap.k-state-invalid,
.k-timepicker .k-picker-wrap.k-state-invalid {
  border-color: #d9534f;
}
.k-datepicker .k-picker-wrap.k-state-invalid .k-input,
.k-timepicker .k-picker-wrap.k-state-invalid .k-input {
  color: #d9534f;
}
.k-datepicker .k-picker-wrap .k-i-warning,
.k-timepicker .k-picker-wrap .k-i-warning {
  color: #d9534f;
  margin-left: 0;
  margin-right: 2.8em;
}
.k-rtl .k-datepicker .k-picker-wrap .k-i-warning,
.k-rtl .k-timepicker .k-picker-wrap .k-i-warning {
  margin-right: 0;
  margin-left: 2.8em;
}
.k-datetimepicker .k-picker-wrap.k-state-invalid {
  border-color: #d9534f;
}
.k-datetimepicker .k-picker-wrap.k-state-invalid .k-input {
  color: #d9534f;
}
.k-datetimepicker .k-picker-wrap .k-i-warning {
  color: #d9534f;
  margin-left: 0;
  margin-right: 5.6em;
}
.k-rtl .k-datetimepicker .k-picker-wrap .k-icon.k-i-warning {
  margin-right: 0;
  margin-left: 5.6em;
}
.k-listbox .k-list-scroller {
  border-color: #e6e6e6;
  background-color: #ffffff;
}
.k-listbox .k-item:hover:not(.k-state-disabled),
.k-listbox .k-item.k-state-selected {
  background-color: #ebebeb;
  border-color: transparent;
}
.k-listbox .k-button.k-state-disabled:hover .k-icon {
  opacity: .7;
}
.k-listbox .k-button.k-state-disabled:hover .k-icon {
  opacity: .7;
}
.k-listbox .k-drop-hint {
  height: 0;
  border-top: 1px solid #009ddd;
}
.k-item.k-state-selected.k-drag-clue {
  border-color: transparent;
  background-color: #ebebeb;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2), 0 2px 3px rgba(0, 0, 0, 0.05);
}
.k-grid-header .k-i-sort-asc-sm,
.k-grid-header .k-i-sort-desc-sm,
.k-grid-header .k-sort-order {
  color: #072640;
  opacity: 1;
  margin-bottom: -1px;
}
.k-menu-scroll-button {
  border-width: 0;
  box-shadow: none;
  border-color: #e6e6e6;
  color: #072640;
  background-color: #ffffff;
}
.k-menu-scroll-wrapper.horizontal .k-scroll-left {
  border-right-width: 1px;
}
.k-menu-scroll-wrapper.horizontal .k-scroll-right {
  border-left-width: 1px;
}
.k-menu-scroll-wrapper.vertical .k-scroll-up {
  border-bottom-width: 1px;
}
.k-menu-scroll-wrapper.vertical .k-scroll-down {
  border-top-width: 1px;
}
.k-toolbar {
  border-color: #e6e6e6;
  color: #072640;
  background-color: #009ddd;
}
.k-toolbar .k-button {
  box-shadow: none;
}
.k-toolbar .k-button:focus:active:not(.k-state-disabled):not([disabled]),
.k-toolbar .k-button:focus:not(.k-state-disabled):not([disabled]),
.k-toolbar .k-button:focus:active:not(.k-state-disabled):not([disabled]),
.k-toolbar .k-button:active:hover,
.k-toolbar .k-button.k-state-active:hover {
  box-shadow: none;
}
.k-toolbar .k-toggle-button:focus {
  border-color: #b3b3b3;
}
.k-toolbar .k-overflow-anchor.k-state-active,
.k-toolbar .k-overflow-anchor.k-state-border-down {
  background-color: #ffffff;
}
.k-ie .k-toolbar .k-input {
  height: 2.2em;
}
/* Toolbar */
.k-toolbar {
  background-color: #fafafa;
  border-color: #e6e6e6;
}
.k-mediaplayer-toolbar {
  background: rgba(250, 250, 250, 0.85);
}
.k-editor .k-tool {
  color: inherit;
}
.k-tool {
  border-color: transparent;
}
.k-tool {
  text-align: center;
  vertical-align: middle;
}
.k-editor .k-tool:focus {
  outline: 0;
  border-color: #dbdbdb;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2), 0 2px 3px rgba(0, 0, 0, 0.05);
}
@media screen and (-ms-high-contrast: active) {
  .k-editor-toolbar-wrap .k-dropdown-wrap.k-state-focused,
  .k-editor-toolbar-wrap .k-button-group .k-tool:focus {
    border-color: #fff;
  }
}
.k-editor-toolbar {
  color: #072640;
}
/* Editor */
table.k-editor {
  border-color: #e6e6e6;
}
.k-editor.k-header,
.editorToolbarWindow.k-header,
.k-filebrowser .k-header {
  color: #072640;
  background-color: #f2f2f2;
  box-shadow: none;
}
.k-editor-toolbar .k-tool,
.k-group-start.k-group-end.k-tool {
  border-color: #f2f2f2;
}
.k-editor-toolbar .k-dropdown {
  box-shadow: none;
}
.k-editor-toolbar .k-dropdown-wrap.k-state-default,
.k-toolbar .k-dropdown-wrap.k-state-default {
  border-color: #f2f2f2;
}
.k-editor-toolbar .k-tool.k-state-hover,
.k-toolbar .k-tool.k-state-hover,
.k-editor-toolbar .k-dropdown-wrap.k-state-hover,
.k-toolbar .k-dropdown-wrap.k-state-hover,
.k-editor-toolbar .k-tool.k-state-selected,
.k-toolbar .k-tool.k-state-selected,
.k-editor-toolbar .k-button-group .k-button.k-state-active,
.k-toolbar .k-button-group .k-button.k-state-active {
  box-shadow: none;
}
.k-editor .k-tool:hover,
.k-editor .k-tool.k-state-hover {
  color: #072640;
  border-color: #ebebeb;
  background-color: #ebebeb;
}
.k-editor .k-tool:active,
.k-editor .k-tool.k-state-selected {
  color: #000000;
  background-color: #dbdbdb;
  border-color: #dbdbdb;
}
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Kendo base CSS */
.fake {
  color: red;
}
.k-common-test-class {
  opacity: 0;
}
.k-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  text-decoration: none;
  font-size: 100%;
  list-style: none;
}
.k-floatwrap:after,
.k-slider-items:after,
.k-grid-toolbar:after {
  content: "";
  display: block;
  clear: both;
  visibility: hidden;
  height: 0;
  overflow: hidden;
}
.k-floatwrap,
.k-slider-items,
.k-grid-toolbar {
  display: inline-block;
}
.k-floatwrap,
.k-slider-items,
.k-grid-toolbar {
  display: block;
}
/* main gradient */
.k-block,
.k-header,
.k-grid-header,
.k-toolbar,
.k-grouping-header,
.k-tooltip,
.k-pager-wrap,
.k-tabstrip-items .k-item,
.k-link.k-state-hover,
.k-textbox,
.k-textbox:hover,
.k-autocomplete,
.k-dropdown-wrap,
.k-picker-wrap,
.k-numeric-wrap,
.k-autocomplete.k-state-hover,
.k-dropdown-wrap.k-state-hover,
.k-picker-wrap.k-state-hover,
.k-numeric-wrap.k-state-hover,
.k-draghandle {
  background-repeat: repeat;
  background-position: 0 center;
}
.k-link:hover {
  text-decoration: none;
}
.k-state-highlight > .k-link {
  color: inherit;
}
/* widget */
.k-textbox > input,
.k-input[type="text"],
.k-input[type="number"],
.k-textbox,
.k-picker-wrap .k-input {
  font-size: 100%;
  font-family: inherit;
  border-style: solid;
  border-width: 1px;
  -webkit-appearance: none;
}
.k-widget,
.k-block,
.k-inline-block,
.k-draghandle {
  border-style: solid;
  border-width: 1px;
  -webkit-appearance: none;
}
.k-block,
.k-widget {
  line-height: normal;
  outline: 0;
}
.k-widget .k-input::-ms-clear,
.k-list-filter ::-ms-clear {
  width: 0;
  height: 0;
}
/* Block */
.k-block {
  padding: 2px;
}
/* button */
a.k-button-expand {
  display: block;
}
button.k-button-expand,
input[type="submit"].k-button-expand,
input[type="button"].k-button-expand,
input[type="reset"].k-button-expand {
  width: 100%;
}
.k-toolbar .k-button-icontext {
  padding-right: .8em;
}
/* link */
.k-link {
  cursor: pointer;
  outline: 0;
  text-decoration: none;
}
.k-grid-header span.k-link {
  cursor: default;
}
/* states */
.k-state-disabled,
.k-state-disabled .k-link,
.k-state-disabled .k-icon,
.k-state-disabled .k-draghandle,
.k-state-disabled .k-upload-button input {
  cursor: default !important;
  outline: 0;
}
@media print {
  .k-state-disabled,
  .k-state-disabled .k-input {
    opacity: 1 !important;
  }
}
.k-state-error {
  border-style: ridge;
}
.k-state-empty {
  font-style: italic;
}
/* icons */
.k-icon.k-i-none {
  background-image: none !important;
  /* should never be a background on these */
}
.k-image {
  border: 0;
}
/* Colors */
html .k-success-colored {
  color: #507f50;
  border-color: #d0dfd0;
  background-color: #f0fff0;
}
html .k-info-colored {
  color: #50607f;
  border-color: #d0d9df;
  background-color: #f0f9ff;
}
html .k-error-colored {
  color: #7f5050;
  border-color: #dfd0d0;
  background-color: #fff0f0;
}
.k-inline-block {
  padding: 0 2px;
}
/* loading */
.k-loading,
.k-loading-image {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
}
.k-icon.k-i-loading {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
}
.k-icon.k-i-loading::before {
  content: "";
  display: none;
}
.k-loading-mask,
.k-loading-image,
.k-loading-text {
  position: absolute;
}
.k-loading-mask {
  z-index: 100;
}
.k-loading-mask.k-opaque .k-loading-color {
  opacity: 1;
}
.k-loading-mask .k-loading-progress {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.k-loading-text {
  text-indent: -4000px;
  text-align: center;
  /*rtl*/
}
.k-loading-image,
.k-loading-color {
  width: 100%;
  height: 100%;
}
.k-loading-image {
  top: 0;
  left: 0;
  z-index: 2;
}
.k-loading-color {
  filter: alpha(opacity=30);
  opacity: .3;
}
.k-content-frame {
  border: 0;
  width: 100%;
  height: 100%;
}
.k-pane > .k-splitter-overlay {
  filter: alpha(opacity=0);
  opacity: 0;
  position: absolute;
}
/* drag n drop */
.k-drag-clue {
  position: absolute;
  z-index: 10003;
  border-style: solid;
  border-width: 1px;
  font-size: .9em;
  padding: .2em .4em;
  white-space: nowrap;
  cursor: default;
}
/* ListBox Drag Clue */
.k-item.k-drag-clue {
  font-size: inherit;
}
.k-drag-status {
  margin-top: -3px;
  margin-right: 4px;
  vertical-align: middle;
}
.k-reorder-cue {
  position: absolute;
  width: 1px;
  overflow: visible;
}
.k-reorder-cue .k-icon {
  position: absolute;
  left: -4px;
  width: 8px;
  height: 4px;
}
.k-reorder-cue .k-i-arrow-60-down {
  top: -4px;
}
.k-reorder-cue .k-i-arrow-60-up {
  bottom: -4px;
}
/* virtual scrollbar */
.k-scrollbar {
  position: absolute;
  overflow: scroll;
}
.k-scrollbar-vertical {
  top: 0;
  right: 0;
  width: 17px;
  /* scrollbar width */
  height: 100%;
  overflow-x: hidden;
}
.k-touch-scrollbar {
  display: none;
  position: absolute;
  z-index: 200000;
  height: 8px;
  width: 8px;
  border: 1px solid #8a8a8a;
  background-color: #858585;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) {
  body .k-touch-scrollbar {
    height: 12px;
    width: 12px;
    border-radius: 7px;
  }
}
.k-virtual-scrollable-wrap {
  overflow-x: auto;
  /*needed by IE8*/
}
/* current time indicator */
.k-current-time {
  background: #f00;
  position: absolute;
}
.k-current-time-arrow-down {
  width: 0;
  height: 0;
  background: transparent;
  border-bottom: 4px solid  transparent;
  border-top: 4px solid #f00;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}
.k-current-time-arrow-left {
  width: 0;
  height: 0;
  background: transparent;
  border-bottom: 4px solid  transparent;
  border-top: 4px solid transparent;
  border-left: 4px solid transparent;
  border-right: 4px solid #f00;
}
.k-current-time-arrow-right {
  width: 0;
  height: 0;
  background: transparent;
  border-bottom: 4px solid  transparent;
  border-top: 4px solid transparent;
  border-left: 4px solid #f00;
  border-right: 4px solid transparent;
}
/* override box sizing for grid layout framework integration (Bootstrap 3, Foundation 4) */
.k-animation-container,
.k-widget,
.k-widget *,
.k-animation-container *,
.k-widget *:before,
.k-animation-container *:after,
.k-block .k-header,
.k-list-container,
.k-dialog .k-window-content {
  box-sizing: content-box;
}
.k-textbox,
.k-autocomplete,
.k-tabstrip > .k-content > .km-scroll-container,
.k-block,
.k-edit-cell .k-widget,
.k-grid-edit-row .k-widget,
.k-grid-edit-row .text-box,
.km-actionsheet > li,
.km-shim,
div.k-window-content {
  box-sizing: border-box;
}
/* Fix for Bootstrap 3 */
.input-group .form-control {
  box-sizing: border-box;
}
.form-control.k-widget {
  padding: 0;
}
/* override iOS styles in mobile Kendo */
.km-widget,
.km-widget * {
  -moz-background-clip: border-box;
  -webkit-background-clip: border-box;
  background-clip: border-box;
}
input.k-checkbox,
.k-radio {
  display: inline;
  opacity: 0;
  width: 0;
  margin: 0;
  -webkit-appearance: none;
  overflow: hidden;
}
.k-ff input.k-checkbox,
.k-ff .k-radio {
  position: absolute;
}
.k-checkbox-label,
.k-radio-label {
  display: inline-block;
  position: relative;
  padding-left: 28.8px;
  vertical-align: text-top;
  line-height: 16px;
  cursor: pointer;
  border-style: solid;
  border-width: 0;
}
.k-checkbox-label:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-width: 1px;
  border-style: solid;
  width: 16px;
  height: 16px;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
}
.k-checkbox:indeterminate + .k-checkbox-label:after {
  content: "";
  position: absolute;
  left: 8px;
  top: 8px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-width: 1px;
  border-style: solid;
  width: 8px;
  height: 8px;
  font-size: 16px;
  text-align: center;
  content: " ";
}
.k-checkbox:checked + .k-checkbox-label:before {
  content: "\2713";
}
.k-checkbox:disabled + .k-checkbox-label {
  cursor: auto;
}
.k-radio-label:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 14px;
  height: 14px;
  border-style: solid;
}
.k-radio:checked + .k-radio-label:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  top: 3px;
  left: 3px;
}
.k-radio:disabled + .k-radio-label {
  cursor: auto;
}
/* RTL for checkboxes and radio buttons */
.k-rtl .k-checkbox-label,
.k-rtl .k-radio-label {
  padding-right: 1.5em;
}
.k-rtl .k-checkbox-label:before,
.k-rtl .k-radio-label:before {
  right: 0;
}
.k-rtl .k-radio:checked + .k-radio-label:after {
  right: 3px;
}
input.k-checkbox + label {
  -webkit-user-select: none;
}
/* Off-screen container used during export */
.k-pdf-export-shadow {
  position: absolute;
  overflow: hidden;
  left: -15000px;
  width: 14400px;
}
.km-native-scroller {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -ms-touch-action: pan-x pan-y;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -ms-scroll-snap-type: proximity;
}
.k-hr {
  margin: .5em auto;
  padding: 0;
  height: 0;
  border-width: 1px 0 0;
  border-style: solid;
  display: block;
  float: none;
  clear: both;
}
/* Font Icons */
.k-icon,
.k-tool-icon {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 1em;
  height: 1em;
  text-align: center;
  vertical-align: middle;
  background-image: none;
  font: 16px/1 'WebComponentsIcons';
  speak: none;
  font-variant: normal;
  text-transform: none;
  text-indent: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: inherit;
}
.k-i-drag-and-drop,
.k-column-menu .k-sprite,
.k-grid-mobile .k-resize-handle-inner:before,
.k-grid-mobile .k-resize-handle-inner:after,
.k-pager-numbers .k-current-page .k-link:after,
.k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link:after,
.k-gantt-views > .k-current-view > .k-link:after {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 1em;
  height: 1em;
  text-align: center;
  vertical-align: middle;
  background-image: none;
  font: 16px/1 'WebComponentsIcons';
  speak: none;
  font-variant: normal;
  text-transform: none;
  text-indent: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.k-icon:before {
  margin: auto;
  width: 1em;
  height: 1em;
  line-height: 1;
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.k-button.k-button-icon .k-icon,
.k-grid-filter .k-icon,
.k-header .k-icon {
  text-indent: -99999px;
  overflow: hidden;
}
.k-button.k-button-icon .k-icon:before,
.k-grid-filter .k-icon:before,
.k-header .k-icon:before {
  text-indent: 0;
}
/* Sprite icons */
.k-sprite {
  width: 16px;
  height: 16px;
  font-size: 0;
  line-height: 0;
  text-align: center;
  background-repeat: no-repeat;
  background-color: transparent;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  -ms-high-contrast-adjust: none;
}
.k-list-container .k-footer:before,
.k-list-container .k-footer:after {
  content: "";
  display: block;
  overflow: hidden;
}
.k-reorder-cue {
  position: absolute;
}
.k-reorder-cue::before,
.k-reorder-cue::after {
  content: "";
  width: 0;
  height: 0;
  border: 3px solid transparent;
  position: absolute;
  transform: translateX(-50%);
}
.k-reorder-cue::before {
  border-bottom-width: 0;
  border-top-color: currentColor;
  top: -4px;
}
.k-reorder-cue::after {
  border-top-width: 0;
  border-bottom-color: currentColor;
  bottom: -4px;
}
.k-flip-h {
  transform: scaleX(-1);
}
.k-flip-v {
  transform: scaleY(-1);
}
.k-flip-h.k-flip-v {
  transform: scale(-1, -1);
}
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.k-button {
  margin: 0;
  padding: 10px 14px;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  background-repeat: repeat-x;
  background-position: 0 center;
  font: inherit;
  line-height: 1.72em;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  overflow: hidden;
  user-select: none;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
}
.k-no-flexbox .k-button {
  display: inline-block;
}
.k-button::-moz-focus-inner {
  padding: 0;
  border: 0;
  outline: 0;
}
.k-button:hover,
.k-button:focus {
  text-decoration: none;
  outline: 0;
}
.k-button .k-icon,
.k-button .k-image,
.k-button .k-sprite {
  color: inherit;
  align-self: center;
}
.k-button-icontext {
  overflow: visible;
}
.k-button-icontext .k-icon,
.k-button-icontext .k-image,
.k-button-icontext .k-sprite {
  margin-right: 3px;
  margin-right: .3rem;
  margin-left: -3px;
  margin-left: -0.3rem;
}
.k-button[disabled] .k-state-disabled .k-button .k-button.k-state-disabled {
  cursor: default;
  outline: 0;
}
.k-ie9 .k-button {
  display: inline-block;
}
a.k-button-expand {
  display: block;
}
button.k-button-expand,
input[type="submit"].k-button-expand,
input[type="button"].k-button-expand,
input[type="reset"].k-button-expand {
  width: 100%;
}
.k-button-icon,
.k-split-button-arrow {
  height: calc(23.72em);
  width: calc(23.72em);
  padding: 10px calc(7px);
}
.k-overflow-group .k-button-icon {
  width: auto;
}
.k-toolbar .k-button-icontext {
  padding-right: .8em;
}
.k-button.k-bare {
  border-color: transparent !important;
  color: inherit !important;
  background: none !important;
  box-shadow: none !important;
  opacity: .7;
}
.k-button.k-bare:hover,
.k-button.k-bare.k-state-hover,
.k-button.k-bare:active,
.k-button.k-bare.k-state-active,
.k-button.k-bare.k-state-active:hover {
  color: inherit;
}
.k-button.k-bare:focus,
.k-button.k-bare .k-state-focused {
  opacity: .8;
}
.k-button.k-bare:hover,
.k-button.k-bare.k-state-hover,
.k-button.k-bare:active,
.k-button.k-bare.k-state-active {
  opacity: 1;
}
.k-button.k-bare .k-icon {
  overflow: visible;
}
.k-button.k-bare:focus .k-icon,
.k-button.k-bare .k-state-focused .k-icon {
  text-shadow: 0 0 3px currentColor;
}
.k-button-group {
  margin: 0;
  padding: 0;
  list-style: none;
  white-space: nowrap;
  display: inline-block;
  display: inline-flex;
  flex-direction: row;
  vertical-align: middle;
  position: relative;
}
.k-button-group .k-button {
  position: relative;
}
.k-button-group .k-button:active,
.k-button-group .k-button.k-state-active {
  z-index: 2;
}
.k-button-group .k-button:hover,
.k-button-group .k-button.k-state-hover {
  z-index: 3;
}
.k-button-group .k-button:focus,
.k-button-group .k-button.k-state-focused {
  z-index: 4;
}
.k-button-group > input[type="radio"],
.k-button-group > input[type="checkbox"],
.k-button-group label input[type="radio"],
.k-button-group label input[type="checkbox"] {
  margin: 0;
  padding: 0;
  clip: rect(0, 0, 0, 0);
  position: absolute;
  pointer-events: none;
}
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* responsive panel */
.k-rpanel-left {
  -webkit-transform: translateX(-100%) translateZ(0);
  -ms-transform: translateX(-100%) translateZ(0);
  transform: translateX(-100%) translateZ(0);
  left: 0;
}
.k-rpanel-right {
  -webkit-transform: translateX(100%) translateZ(0);
  -ms-transform: translateX(100%) translateZ(0);
  transform: translateX(100%) translateZ(0);
  right: 0;
}
.k-rpanel-left,
.k-rpanel-right {
  position: fixed;
  display: block;
  overflow: auto;
  min-width: 320px;
  height: 100%;
  top: 0;
}
.k-rpanel-left.k-rpanel-expanded,
.k-rpanel-right.k-rpanel-expanded {
  -webkit-transform: translateX(0) translateZ(0);
  -ms-transform: translateX(0) translateZ(0);
  transform: translateX(0) translateZ(0);
}
.k-rpanel-left + *,
.k-rpanel-right + * {
  overflow: auto;
}
.k-ie9 .k-rpanel-left {
  left: -100%;
}
.k-ie9 .k-rpanel-left.k-rpanel-expanded {
  left: 0;
}
.k-rpanel-top {
  position: static;
  max-height: 0;
}
.k-rpanel-top.k-rpanel-expanded {
  max-height: 568px;
  overflow: visible !important;
}
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.k-edit-form {
  margin: 0;
  padding: 0;
}
.k-window > div.k-popup-edit-form {
  padding: 1em 0;
}
.k-grid-edit-row .k-edit-form td {
  border-bottom-width: 0;
}
.k-edit-form-container {
  position: relative;
  width: 400px;
}
.k-edit-label,
.k-edit-form-container .editor-label {
  float: left;
  clear: both;
  width: 30%;
  padding: .4em 0 1em;
  margin-left: 2%;
  text-align: right;
}
.k-edit-field,
.k-edit-form-container .editor-field {
  float: right;
  clear: right;
  width: 60%;
  margin-right: 2%;
  padding: 0 0 .6em;
}
.k-edit-field > input[type="checkbox"],
.k-edit-field > input[type="radio"] {
  margin-top: .4em;
}
.k-edit-form-container .k-button {
  margin: 0 .16em;
}
.k-edit-field > input[type="checkbox"]:first-child,
.k-edit-field > input[type="radio"]:first-child,
.k-edit-field > label:first-child > input[type="checkbox"],
.k-edit-field > .k-button:first-child {
  margin-left: 0;
}
.k-edit-form-container .k-edit-buttons {
  clear: both;
  text-align: right;
  border-width: 1px 0 0;
  border-style: solid;
  position: relative;
  bottom: -1em;
  padding: .6em;
}
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Window */
div.k-window {
  display: inline-block;
  position: absolute;
  z-index: 10001;
  border-style: solid;
  border-width: 1px;
  padding-top: 2em;
}
.k-block > .k-header,
.k-window-titlebar {
  position: absolute;
  width: 100%;
  height: 1.1em;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin-top: -2em;
  padding: .4em 0;
  font-size: 1.2em;
  white-space: nowrap;
  min-height: 16px;
  /* icon size */
}
.k-block > .k-header {
  position: relative;
  margin: -2px 0 10px -2px;
  padding: .3em 2px;
}
.k-window-title {
  position: absolute;
  left: .44em;
  right: .44em;
  overflow: hidden;
  cursor: default;
  text-overflow: ellipsis;
}
.k-window-title .k-image {
  margin: 0 5px 0 0;
  vertical-align: middle;
}
div.k-window-titleless {
  padding-top: 0;
}
div.k-window-content {
  position: relative;
  height: 100%;
  padding: .58em;
  overflow: auto;
  outline: 0;
}
div.k-window-iframecontent {
  padding: 0;
  overflow: visible;
}
.k-window-content > .km-scroll-container {
  height: 100%;
}
.k-window-titlebar .k-window-actions {
  position: absolute;
  top: 0;
  right: .3em;
  padding-top: .3em;
  white-space: nowrap;
}
.k-window-titlebar .k-window-action {
  width: 22px;
  height: 22px;
  padding: 2px;
}
.k-window-action .k-icon {
  margin: 0;
  vertical-align: top;
}
.k-window > .k-resize-handle {
  position: absolute;
  z-index: 1;
  background-color: #fff;
  font-size: 0;
  line-height: 6px;
  filter: alpha(opacity=0);
  opacity: 0;
  zoom: 1;
}
.k-resize-n {
  top: -3px;
  left: 0;
  width: 100%;
  height: 6px;
  cursor: n-resize;
}
.k-resize-e {
  top: 0;
  right: -3px;
  width: 6px;
  height: 100%;
  cursor: e-resize;
}
.k-resize-s {
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 6px;
  cursor: s-resize;
}
.k-resize-w {
  top: 0;
  left: -3px;
  width: 6px;
  height: 100%;
  cursor: w-resize;
}
.k-resize-se {
  bottom: -3px;
  right: -3px;
  width: 6px;
  height: 6px;
  cursor: se-resize;
}
.k-resize-sw {
  bottom: -3px;
  left: -3px;
  width: 6px;
  height: 6px;
  cursor: sw-resize;
}
.k-resize-ne {
  top: -3px;
  right: -3px;
  width: 6px;
  height: 6px;
  cursor: ne-resize;
}
.k-resize-nw {
  top: -3px;
  left: -3px;
  width: 6px;
  height: 6px;
  cursor: nw-resize;
}
.k-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10001;
  width: 100%;
  height: 100%;
  background-color: #000;
  filter: alpha(opacity=50);
  opacity: .5;
  -webkit-backface-visibility: hidden;
}
.k-window .k-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  filter: alpha(opacity=0);
  opacity: 0;
}
.k-action-buttons {
  clear: both;
  text-align: right;
  border-width: 1px 0 0;
  border-style: solid;
  position: relative;
  bottom: -1em;
  padding: .6em;
  margin: 0 -1em;
}
.k-action-buttons .k-button {
  display: inline-block;
  margin: 0 6px 0 0;
  min-width: 75px;
}
.k-action-buttons .k-button:last-child {
  margin-right: 1em;
}
.k-action-buttons .k-button.k-left {
  float: left;
  margin: 0 0 0 1em;
}
.k-action-buttons .k-button.k-left + .k-button {
  margin-left: 6px;
}
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* TabStrip */
.k-tabstrip {
  margin: 0;
  padding: 0;
  zoom: 1;
  position: relative;
}
.k-tabstrip-items {
  padding: 0.3em 0.3em 0;
}
.k-tabstrip-scrollable .k-tabstrip-items {
  white-space: nowrap;
  overflow: hidden;
}
.k-tabstrip > .k-button.k-bare {
  position: absolute;
  top: .27em;
  z-index: 2;
  user-select: none;
}
.k-tabstrip-bottom > .k-button {
  top: auto;
  bottom: .4em;
}
.k-tabstrip-prev {
  left: .4em;
}
.k-tabstrip-next {
  right: .4em;
}
.k-tabstrip-items .k-item,
.k-panelbar .k-tabstrip-items .k-item {
  list-style-type: none;
  display: inline-block;
  position: relative;
  border-style: solid;
  border-width: 1px 1px 0;
  margin: 0 -1px 0 0;
  padding: 0;
  vertical-align: top;
}
.k-tabstrip-items .k-item {
  overflow: hidden;
}
.k-tabstrip-items .k-tab-on-top,
.k-tabstrip-items .k-state-active,
.k-panelbar .k-tabstrip-items .k-state-active {
  margin-bottom: -1px;
  padding-bottom: 1px;
}
.k-tabstrip-top .k-tabstrip-items .k-state-active,
.k-panelbar .k-tabstrip-top .k-tabstrip-items .k-state-active {
  border-bottom-width: 1px;
  margin-bottom: -2px;
  padding-bottom: 0px;
}
.k-tabstrip-items .k-tab-on-top {
  z-index: 1;
}
.k-tabstrip-items .k-link,
.k-panelbar .k-tabstrip-items .k-link {
  display: inline-block;
  border-bottom-width: 0;
  padding: .5em .92em;
}
.k-tabstrip-items .k-icon,
.k-panelbar .k-tabstrip-items .k-icon {
  margin: -1px 4px 0 -3px;
  vertical-align: top;
}
.k-tabstrip-items .k-item .k-image,
.k-tabstrip-items .k-item .k-sprite,
.k-panelbar .k-tabstrip-items .k-item .k-image,
.k-panelbar .k-tabstrip-items .k-item .k-sprite {
  margin: -3px 3px 0 -6px;
  vertical-align: middle;
}
/* TabStrip Loading Progress */
.k-tabstrip-items .k-loading {
  top: 0;
  left: 0;
  height: 0;
  width: 20%;
  position: absolute;
  background: transparent;
  border-top: 1px solid transparent;
  border-color: inherit;
  transition: width 200ms linear;
  transition: "width 200ms linear";
  animation: k-tab-loader 1s ease-in-out infinite;
}
.k-tabstrip-items .k-progress {
  animation: none;
}
.k-tabstrip-items .k-loading.k-complete {
  width: 100%;
  animation: none;
}
.k-tabstrip > .k-content,
.k-panelbar .k-tabstrip > .k-content {
  position: static;
  border-style: solid;
  border-width: 1px;
  margin: 0 .286em .3em;
  padding: .92em;
  zoom: 1;
}
.k-tabstrip > .k-content {
  display: none;
  overflow: auto;
}
.k-tabstrip > .k-content.km-scroll-wrapper {
  padding: 0;
}
.k-tabstrip > .k-content > .km-scroll-container {
  padding: .3em .92em;
}
@keyframes k-tab-loader {
  0% {
    left: 0;
  }
  50% {
    left: 80%;
  }
  100% {
    left: 0;
  }
}
/* left and right tabs */
.k-tabstrip-left > div.k-content,
.k-tabstrip-right > div.k-content {
  margin: .286em .3em;
}
.k-tabstrip-left > .k-tabstrip-items .k-item,
.k-tabstrip-right > .k-tabstrip-items .k-item {
  display: block;
  margin-bottom: -1px;
}
.k-tabstrip-left > .k-tabstrip-items .k-link,
.k-tabstrip-right > .k-tabstrip-items .k-link {
  display: block;
}
.k-tabstrip-left > .k-tabstrip-items .k-tab-on-top,
.k-tabstrip-right > .k-tabstrip-items .k-tab-on-top,
.k-tabstrip-left > .k-tabstrip-items .k-state-active,
.k-tabstrip-right > .k-tabstrip-items .k-state-active,
.k-panelbar .k-tabstrip-left > .k-tabstrip-items .k-state-active,
.k-panelbar .k-tabstrip-right > .k-tabstrip-items .k-state-active {
  margin-bottom: -1px;
  padding-bottom: 0;
}
/* left tabs */
.k-tabstrip-left > .k-tabstrip-items {
  float: left;
  padding: .25em 0 .3em .3em;
}
.k-tabstrip-left > .k-tabstrip-items .k-item {
  border-width: 1px 0 1px 1px;
  border-radius: 3px 0 0 3px;
}
.k-tabstrip-left > .k-tabstrip-items .k-state-active {
  border-width: 1px 0 1px 1px;
}
.k-tabstrip-left > .k-tabstrip-items .k-tab-on-top,
.k-tabstrip-left > .k-tabstrip-items .k-state-active,
.k-panelbar .k-tabstrip-left > .k-tabstrip-items .k-state-active {
  margin-right: -2px;
  padding-right: 1px;
}
/* right tabs */
.k-tabstrip-right > .k-tabstrip-items {
  float: right;
  padding: .25em .3em .3em 0;
}
.k-tabstrip-right > .k-tabstrip-items .k-item {
  border-width: 1px 1px 1px 0;
  border-radius: 0 3px 3px 0;
}
.k-tabstrip-right > .k-tabstrip-items .k-state-active {
  border-width: 1px 1px 1px 0;
}
.k-tabstrip-right > .k-tabstrip-items .k-tab-on-top,
.k-tabstrip-right > .k-tabstrip-items .k-state-active,
.k-panelbar .k-tabstrip-right > .k-tabstrip-items .k-state-active {
  margin-left: -1px;
  padding-left: 1px;
}
/* bottom tabs */
.k-tabstrip-bottom > .k-tabstrip-items {
  margin-top: -1px;
  padding: 0 .3em .3em;
}
.k-tabstrip-bottom > .k-content,
.k-panelbar .k-tabstrip-bottom > .k-content {
  margin: .3em .286em 0;
  z-index: 1;
  position: relative;
}
.k-tabstrip-bottom > .k-tabstrip-items .k-item {
  border-width: 0 1px 1px;
  border-radius: 0 0 4px 4px;
}
.k-tabstrip-bottom > .k-tabstrip-items .k-state-active {
  margin-bottom: 0;
  padding-bottom: 0;
}
.k-tabstrip-bottom > .k-content {
  min-height: 100px;
}
.k-tabstrip-bottom > .k-tabstrip-items .k-loading {
  top: auto;
  bottom: 0;
}
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* PanelBar */
.k-panelbar {
  zoom: 1;
}
.k-panelbar > .k-item,
.k-panel > .k-item {
  list-style-type: none;
  display: block;
  border-width: 0;
  margin: 0;
  zoom: 1;
  border-radius: 0;
}
.k-panelbar .k-link > .k-image,
.k-panelbar .k-link > .k-sprite {
  margin-top: -2px;
  margin-right: 5px;
  vertical-align: middle;
}
.k-panelbar > .k-item > .k-link,
.k-panel > .k-item > .k-link {
  display: block;
  position: relative;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding: 0 1em;
  line-height: 2.34em;
  text-decoration: none;
  zoom: 1;
}
.k-panelbar-expand.k-icon,
.k-panelbar-collapse.k-icon {
  position: absolute;
  top: 50%;
  right: 4px;
  margin-top: -8px;
}
.k-panelbar .k-panel,
.k-panelbar .k-content {
  position: relative;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin: 0;
  padding: 0;
  zoom: 1;
}
.k-panel > .k-item > .k-link {
  border-bottom: 0;
  font-size: .95em;
  line-height: 2.2;
}
.k-panel .k-panel > .k-item > .k-link {
  padding-left: 2em;
}
.k-panelbar .k-i-arrow-end-right .k-link {
  border-bottom: 0;
}
.k-panel .k-panel {
  border-bottom: 0;
}
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.k-pager-wrap {
  clear: both;
  overflow: hidden;
  position: relative;
  border-style: solid;
  border-width: 1px;
  line-height: 2.0em;
  padding: 0.333em 0 0.333em 0.250em;
}
.k-pager-numbers .k-link,
.k-pager-numbers .k-state-selected {
  display: inline-block;
  vertical-align: top;
  margin-right: 1px;
}
.k-pager-numbers {
  margin: 0 2px;
}
.k-pager-numbers .k-state-selected {
  vertical-align: top;
}
.k-pager-numbers .k-current-page {
  display: none;
}
.k-pager-numbers li,
.k-pager-input {
  float: left;
}
.k-pager-info {
  float: right;
  padding: 0 1.333em;
}
.k-pager-numbers .k-link {
  text-decoration: none;
}
.k-pager-wrap > .k-link,
.k-pager-numbers .k-link,
.k-pager-numbers .k-state-selected {
  min-width: 2em;
}
.k-pager-wrap > .k-link {
  float: left;
  margin: 0 0.08333em;
  height: 2em;
  /*IE7*/
  line-height: 2em;
  /*IE7*/
  border-radius: 1.0833em;
  cursor: pointer;
  text-align: center;
}
.k-pager-wrap > .k-link > .k-icon {
  margin-top: -2px;
}
.k-pager-wrap > a.k-state-disabled:hover {
  background: none;
  cursor: default;
}
.k-pager-numbers .k-link {
  text-align: center;
  line-height: 2em;
  border-style: solid;
  border-width: 1px;
  border-radius: 1.0833em;
}
.k-pager-wrap > .k-link {
  border-style: solid;
  border-width: 1px;
}
.k-pager-wrap .k-pager-refresh {
  float: right;
  margin-right: 0.5em;
  border-width: 0;
  border-radius: 0;
}
.k-pager-numbers .k-state-selected {
  border-style: solid;
  border-width: 1px;
  text-align: center;
  border-radius: 1.0833em;
}
.k-pager-wrap .k-textbox {
  width: 3.333em;
}
.k-pager-wrap .k-dropdown {
  width: 4.500em;
}
.k-pager-refresh {
  float: right;
}
.k-pager-input,
.k-pager-sizes {
  padding: 0 1.4166em;
}
.k-pager-sizes {
  display: inline-block;
  padding-top: 1px;
}
.k-pager-sizes .k-widget.k-dropdown {
  margin-top: -2px;
}
.k-pager-wrap .k-textbox,
.k-pager-wrap .k-widget {
  margin: 0 .4em 0;
}
@media only screen and (max-width: 1024px) {
  .k-webkit .k-pager-wrap,
  .k-ff .k-pager-wrap,
  .k-ie11 .k-pager-wrap,
  .k-edge .k-pager-wrap,
  .k-safari .k-pager-wrap {
    overflow: visible;
    min-height: 2.1em;
  }
  .k-webkit .k-pager-wrap .k-pager-nav,
  .k-ff .k-pager-wrap .k-pager-nav,
  .k-ie11 .k-pager-wrap .k-pager-nav,
  .k-edge .k-pager-wrap .k-pager-nav,
  .k-safari .k-pager-wrap .k-pager-nav,
  .k-webkit .k-pager-input,
  .k-ff .k-pager-input,
  .k-ie11 .k-pager-input,
  .k-edge .k-pager-input,
  .k-safari .k-pager-input {
    display: inline-block;
    vertical-align: top;
  }
  .k-webkit .k-pager-numbers,
  .k-ff .k-pager-numbers,
  .k-ie11 .k-pager-numbers,
  .k-edge .k-pager-numbers,
  .k-safari .k-pager-numbers {
    position: absolute;
    left: 4.8em;
    display: inline-flex;
    flex-direction: column-reverse;
    overflow: visible;
    height: auto;
  }
  .k-webkit .k-pager-numbers:first-child,
  .k-ff .k-pager-numbers:first-child,
  .k-ie11 .k-pager-numbers:first-child,
  .k-edge .k-pager-numbers:first-child,
  .k-safari .k-pager-numbers:first-child {
    left: .2em;
  }
  .k-webkit .k-pager-numbers.k-state-expanded,
  .k-ff .k-pager-numbers.k-state-expanded,
  .k-ie11 .k-pager-numbers.k-state-expanded,
  .k-edge .k-pager-numbers.k-state-expanded,
  .k-safari .k-pager-numbers.k-state-expanded {
    transform: translatey(-100%);
    -webkit-transform: translatey(-100%);
  }
  .k-webkit .km-pane-wrapper .k-pager-numbers,
  .k-ff .km-pane-wrapper .k-pager-numbers,
  .k-ie11 .km-pane-wrapper .k-pager-numbers,
  .k-edge .km-pane-wrapper .k-pager-numbers,
  .k-safari .km-pane-wrapper .k-pager-numbers {
    position: relative;
    left: 50%;
    transform: translate(-50%, -100%);
    -webkit-transform: translate(-50%, -100%);
  }
  .k-webkit .km-pane-wrapper .k-pager-numbers .k-link,
  .k-ff .km-pane-wrapper .k-pager-numbers .k-link,
  .k-ie11 .km-pane-wrapper .k-pager-numbers .k-link,
  .k-edge .km-pane-wrapper .k-pager-numbers .k-link,
  .k-safari .km-pane-wrapper .k-pager-numbers .k-link,
  .k-webkit .km-pane-wrapper .k-pager-numbers .k-state-selected,
  .k-ff .km-pane-wrapper .k-pager-numbers .k-state-selected,
  .k-ie11 .km-pane-wrapper .k-pager-numbers .k-state-selected,
  .k-edge .km-pane-wrapper .k-pager-numbers .k-state-selected,
  .k-safari .km-pane-wrapper .k-pager-numbers .k-state-selected,
  .k-webkit .km-pane-wrapper .k-pager-wrap > .k-link,
  .k-ff .km-pane-wrapper .k-pager-wrap > .k-link,
  .k-ie11 .km-pane-wrapper .k-pager-wrap > .k-link,
  .k-edge .km-pane-wrapper .k-pager-wrap > .k-link,
  .k-safari .km-pane-wrapper .k-pager-wrap > .k-link,
  .k-webkit .km-pane-wrapper .k-pager-wrap > .k-pager-info,
  .k-ff .km-pane-wrapper .k-pager-wrap > .k-pager-info,
  .k-ie11 .km-pane-wrapper .k-pager-wrap > .k-pager-info,
  .k-edge .km-pane-wrapper .k-pager-wrap > .k-pager-info,
  .k-safari .km-pane-wrapper .k-pager-wrap > .k-pager-info {
    padding-top: 0;
    padding-bottom: 0;
  }
  .k-webkit .k-rtl .k-pager-numbers,
  .k-ff .k-rtl .k-pager-numbers,
  .k-ie11 .k-rtl .k-pager-numbers,
  .k-edge .k-rtl .k-pager-numbers,
  .k-safari .k-rtl .k-pager-numbers {
    left: auto;
    right: 4.8em;
    width: 4.5em;
  }
  .k-webkit .k-rtl .k-pager-numbers:first-child,
  .k-ff .k-rtl .k-pager-numbers:first-child,
  .k-ie11 .k-rtl .k-pager-numbers:first-child,
  .k-edge .k-rtl .k-pager-numbers:first-child,
  .k-safari .k-rtl .k-pager-numbers:first-child {
    left: auto;
    right: .2em;
  }
  .k-webkit .k-rtl .km-pane-wrapper .k-pager-numbers,
  .k-ff .k-rtl .km-pane-wrapper .k-pager-numbers,
  .k-ie11 .k-rtl .km-pane-wrapper .k-pager-numbers,
  .k-edge .k-rtl .km-pane-wrapper .k-pager-numbers,
  .k-safari .k-rtl .km-pane-wrapper .k-pager-numbers {
    right: 5.8em;
  }
  .k-webkit .k-pager-numbers .k-current-page,
  .k-ff .k-pager-numbers .k-current-page,
  .k-ie11 .k-pager-numbers .k-current-page,
  .k-edge .k-pager-numbers .k-current-page,
  .k-safari .k-pager-numbers .k-current-page {
    display: block;
    border-left: 0;
  }
  .k-webkit .k-pager-numbers.k-state-expanded .k-current-page,
  .k-ff .k-pager-numbers.k-state-expanded .k-current-page,
  .k-ie11 .k-pager-numbers.k-state-expanded .k-current-page,
  .k-edge .k-pager-numbers.k-state-expanded .k-current-page,
  .k-safari .k-pager-numbers.k-state-expanded .k-current-page {
    transform: translatey(100%);
    -webkit-transform: translatey(100%);
  }
  .k-webkit .k-pager-numbers li:not(.k-current-page),
  .k-ff .k-pager-numbers li:not(.k-current-page),
  .k-ie11 .k-pager-numbers li:not(.k-current-page),
  .k-edge .k-pager-numbers li:not(.k-current-page),
  .k-safari .k-pager-numbers li:not(.k-current-page) {
    display: none;
  }
  .k-webkit .k-pager-numbers .k-current-page .k-link,
  .k-ff .k-pager-numbers .k-current-page .k-link,
  .k-ie11 .k-pager-numbers .k-current-page .k-link,
  .k-edge .k-pager-numbers .k-current-page .k-link,
  .k-safari .k-pager-numbers .k-current-page .k-link {
    width: 3.2em;
    padding: 0 .429em 0 .714em;
    border-radius: 1.0833em;
  }
  .k-webkit .k-pager-numbers + .k-link,
  .k-ff .k-pager-numbers + .k-link,
  .k-ie11 .k-pager-numbers + .k-link,
  .k-edge .k-pager-numbers + .k-link,
  .k-safari .k-pager-numbers + .k-link {
    margin-left: 4.8em;
  }
  .k-webkit .k-rtl .k-pager-numbers + .k-link,
  .k-ff .k-rtl .k-pager-numbers + .k-link,
  .k-ie11 .k-rtl .k-pager-numbers + .k-link,
  .k-edge .k-rtl .k-pager-numbers + .k-link,
  .k-safari .k-rtl .k-pager-numbers + .k-link {
    margin-right: 5.1em;
    margin-left: 0;
  }
  .k-webkit .k-pager-numbers .k-state-selected,
  .k-ff .k-pager-numbers .k-state-selected,
  .k-ie11 .k-pager-numbers .k-state-selected,
  .k-edge .k-pager-numbers .k-state-selected,
  .k-safari .k-pager-numbers .k-state-selected,
  .k-webkit .k-pager-numbers .k-link,
  .k-ff .k-pager-numbers .k-link,
  .k-ie11 .k-pager-numbers .k-link,
  .k-edge .k-pager-numbers .k-link,
  .k-safari .k-pager-numbers .k-link {
    display: block;
    margin-right: 0;
    padding: 1px 5px 1px 5px;
    text-align: left;
  }
  .k-webkit .k-pager-numbers.k-state-expanded,
  .k-ff .k-pager-numbers.k-state-expanded,
  .k-ie11 .k-pager-numbers.k-state-expanded,
  .k-edge .k-pager-numbers.k-state-expanded,
  .k-safari .k-pager-numbers.k-state-expanded {
    box-sizing: border-box;
    padding: 2px 2px 0;
  }
  .k-webkit .k-pager-numbers.k-state-expanded .k-current-page,
  .k-ff .k-pager-numbers.k-state-expanded .k-current-page,
  .k-ie11 .k-pager-numbers.k-state-expanded .k-current-page,
  .k-edge .k-pager-numbers.k-state-expanded .k-current-page,
  .k-safari .k-pager-numbers.k-state-expanded .k-current-page {
    margin: -2em -3px 0;
    padding: 0;
  }
  .k-webkit .k-pager-numbers.k-state-expanded .k-current-page .k-link,
  .k-ff .k-pager-numbers.k-state-expanded .k-current-page .k-link,
  .k-ie11 .k-pager-numbers.k-state-expanded .k-current-page .k-link,
  .k-edge .k-pager-numbers.k-state-expanded .k-current-page .k-link,
  .k-safari .k-pager-numbers.k-state-expanded .k-current-page .k-link {
    border-radius: 0 0 1.0833em 1.0833em;
  }
  .k-webkit .k-pager-numbers.k-state-expanded li,
  .k-ff .k-pager-numbers.k-state-expanded li,
  .k-ie11 .k-pager-numbers.k-state-expanded li,
  .k-edge .k-pager-numbers.k-state-expanded li,
  .k-safari .k-pager-numbers.k-state-expanded li {
    display: inline-block;
  }
}
@media only screen and (max-width: 640px) {
  .k-webkit .k-pager-info,
  .k-ff .k-pager-info,
  .k-ie11 .k-pager-info,
  .k-edge .k-pager-info,
  .k-safari .k-pager-info {
    display: none;
  }
}
@media only screen and (max-width: 480px) {
  .k-webkit .k-pager-sizes,
  .k-ff .k-pager-sizes,
  .k-ie11 .k-pager-sizes,
  .k-edge .k-pager-sizes,
  .k-safari .k-pager-sizes {
    display: none;
  }
}
.k-rtl .k-pager-wrap .k-i-seek-e,
.k-rtl .k-pager-wrap .k-i-seek-w,
.k-rtl .k-pager-wrap .k-i-arrow-e,
.k-rtl .k-pager-wrap .k-i-arrow-w {
  transform: scaleX(-1);
}
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Menu */
.k-menu {
  cursor: default;
}
.k-menu .k-link {
  white-space: nowrap;
}
.k-menu,
.k-menu-scroll-wrapper .k-menu-group,
.k-popups-wrapper .k-menu-group,
.k-menu .k-menu-group {
  list-style: none;
  margin: 0;
  padding: 0;
  zoom: 1;
}
.k-menu:after {
  content: '';
  display: block;
  width: 99%;
  height: 0;
  float: inherit;
  clear: both;
}
.k-menu-scroll-wrapper .k-item,
.k-popups-wrapper .k-item,
.k-menu .k-item {
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  user-select: none;
}
.k-menu-scroll-wrapper .k-item div,
.k-popups-wrapper .k-item div,
.k-menu .k-item div {
  user-select: default;
}
.k-popups-wrapper .k-item .k-item,
.k-popups-wrapper.vertical > .k-item,
.k-menu-scroll-wrapper .k-item .k-item,
.k-menu-scroll-wrapper.vertical > .k-item,
.k-menu .k-item .k-item,
ul.k-menu-vertical > .k-item {
  display: block;
  float: none;
  border-width: 0;
}
.k-menu-scroll-wrapper .k-item > .k-link > .k-icon,
.k-menu-scroll-wrapper .k-image,
.k-menu-scroll-wrapper .k-sprite,
.k-popups-wrapper .k-item > .k-link > .k-icon,
.k-popups-wrapper .k-image,
.k-popups-wrapper .k-sprite,
.k-menu .k-item > .k-link > .k-icon,
.k-menu .k-image,
.k-menu .k-sprite {
  margin: -2px 4px 0 -4px;
  vertical-align: middle;
}
.k-menu-scroll-wrapper .k-item > .k-link > .k-menu-expand-arrow,
.k-popups-wrapper .k-item > .k-link > .k-menu-expand-arrow,
.k-menu .k-item > .k-link > .k-menu-expand-arrow {
  margin: -2px 0 0;
}
.k-menu-scroll-wrapper .k-item > .k-link,
.k-popups-wrapper .k-item > .k-link,
.k-menu .k-item > .k-link {
  display: block;
  padding: 1.071em;
  line-height: 1.34em;
  user-select: none;
}
.k-menu-scroll-wrapper .k-menu-group,
.k-popups-wrapper .k-menu-group,
.k-menu .k-menu-group {
  display: none;
  border-style: solid;
  border-width: 1px;
  overflow: visible;
  white-space: nowrap;
}
.k-menu-scroll-wrapper .k-menu-group > .k-item,
.k-popups-wrapper .k-menu-group > .k-item,
.k-menu .k-menu-group > .k-item {
  display: block;
  border-width: 0;
}
.k-menu-scroll-wrapper .k-item,
.k-menu-scroll-wrapper.horizontal > .k-item,
.k-popups-wrapper .k-item,
.k-popups-wrapper.horizontal > .k-item,
.k-menu .k-item,
.k-widget.k-menu-horizontal > .k-item {
  position: relative;
  float: left;
  border-style: solid;
  border-width: 0 1px 0 0;
  vertical-align: top;
  zoom: 1;
  box-sizing: content-box;
}
.k-menu-scroll-wrapper .k-menu-group .k-item > .k-link,
.k-popups-wrapper.vertical > .k-item > .k-link,
.k-context-menu.k-menu-vertical > .k-item > .k-link,
.k-menu .k-menu-group .k-item > .k-link {
  padding: .28em 1.8em .38em .9em;
}
.k-popups-wrapper.horizontal > .k-separator,
.k-context-menu.k-menu-horizontal > .k-separator {
  display: none;
}
.k-popups-wrapper.horizontal > .k-item,
.k-context-menu.k-menu-horizontal > .k-item {
  box-sizing: border-box;
}
.k-popups-wrapper.horizontal > .k-last,
.k-context-menu.k-menu-horizontal > .k-last {
  border: 0;
}
.k-menu-scroll-wrapper .k-item > .k-link > .k-i-arrow-60-down,
.k-popups-wrapper .k-item > .k-link > .k-i-arrow-60-down,
.k-menu .k-item > .k-link > .k-i-arrow-60-down {
  margin-right: -8px;
}
.k-menu-scroll-wrapper .k-item > .k-link > .k-i-arrow-60-right,
.k-popups-wrapper .k-item > .k-link > .k-i-arrow-60-right,
.k-menu .k-item > .k-link > .k-i-arrow-60-right {
  position: absolute;
  top: 50%;
  margin-top: -8px;
  right: 2px;
  right: .2rem;
}
.k-menu-scroll-wrapper .k-animation-container,
.k-popups-wrapper .k-animation-container,
.k-menu .k-animation-container {
  border: 0;
}
.k-menu-scroll-wrapper .k-animation-container,
.k-menu-scroll-wrapper .k-menu-group,
.k-popups-wrapper .k-animation-container,
.k-popups-wrapper .k-menu-group,
.k-menu .k-animation-container,
.k-menu .k-menu-group {
  position: absolute;
  left: 0;
}
.k-menu-scroll-wrapper .k-animation-container .k-animation-container,
.k-menu-scroll-wrapper .k-menu-group .k-menu-group,
.k-menu-scroll-wrapper.vertical .k-animation-container,
.k-menu-scroll-wrapper.vertical .k-menu-group,
.k-popups-wrapper .k-animation-container .k-animation-container,
.k-popups-wrapper .k-menu-group .k-menu-group,
.k-popups-wrapper.vertical .k-animation-container,
.k-popups-wrapper.vertical .k-menu-group,
.k-menu .k-animation-container .k-animation-container,
.k-menu .k-menu-group .k-menu-group,
.k-menu-vertical .k-animation-container,
.k-menu-vertical .k-menu-group {
  top: 0;
  left: 0;
}
.k-menu-scroll-wrapper .k-animation-container .k-menu-group,
.k-popups-wrapper .k-animation-container .k-menu-group,
.k-menu .k-animation-container .k-menu-group {
  top: auto;
  left: auto;
  margin-left: -1px;
}
.k-menu .k-animation-container,
.k-menu-scroll-wrapper .k-animation-container,
.k-popups-wrapper .k-animation-container,
.k-popup .k-animation-container {
  margin-top: -1px;
  padding-left: 1px;
}
.k-ie .k-menu .k-animation-container,
.k-ie .k-menu-scroll-wrapper .k-animation-container,
.k-ie .k-popups-wrapper .k-animation-container,
.k-ie .k-popup .k-animation-container {
  margin-top: -2px;
}
.k-popup .k-animation-container .k-popup {
  margin-left: -1px;
}
.k-menu-scroll-wrapper .k-separator,
.k-popups-wrapper .k-separator,
ul.k-menu .k-separator {
  padding: 0.25em 0;
  height: 100%;
  width: 1px;
  font-size: 0;
  line-height: 0;
  border-width: 0 1px 0 0;
}
.k-menu-scroll-wrapper.vertical .k-separator,
.k-menu-scroll-wrapper.vertical .k-menu-group .k-separator,
.k-popups-wrapper.vertical .k-separator,
.k-popups-wrapper.vertical .k-menu-group .k-separator,
ul.k-menu-vertical .k-separator,
.k-menu .k-menu-group .k-separator {
  padding: 0;
  height: 1px;
  width: 100%;
  border-width: 1px 0 0;
}
.k-popups-wrapper .k-widget.k-menu,
.k-menu-scroll-wrapper .k-widget.k-menu {
  white-space: nowrap;
  overflow: hidden;
}
.k-popups-wrapper.vertical .k-menu.k-menu-vertical,
.k-menu-scroll-wrapper.vertical .k-menu.k-menu-vertical {
  height: 100%;
  box-sizing: border-box;
}
.k-popups-wrapper.horizontal .k-menu.k-menu-horizontal > .k-item,
.k-menu-scroll-wrapper.horizontal .k-menu.k-menu-horizontal > .k-item {
  display: inline-block;
  overflow: hidden;
  float: none;
}
.k-popups-wrapper.vertical .k-menu.k-menu-vertical > .k-item,
.k-menu-scroll-wrapper.vertical .k-menu.k-menu-vertical > .k-item {
  overflow: hidden;
}
.k-popups-wrapper,
.k-menu-scroll-wrapper {
  position: relative;
  border: 0;
  margin: 0;
  padding: 0;
}
.k-popups-wrapper.vertical,
.k-menu-scroll-wrapper.vertical {
  height: 100%;
}
.k-popups-wrapper > .k-animation-container .k-item,
.k-menu-scroll-wrapper > .k-animation-container .k-item {
  float: none;
}
.k-popups-wrapper .k-menu-scroll-button,
.k-menu-scroll-wrapper .k-menu-scroll-button {
  border-radius: 0;
  position: absolute;
  display: none;
}
.k-popups-wrapper .k-scroll-up,
.k-menu-scroll-wrapper .k-scroll-up {
  top: 0;
  left: 0;
}
.k-popups-wrapper .k-scroll-down,
.k-menu-scroll-wrapper .k-scroll-down {
  bottom: 0;
  left: 0;
}
.k-popups-wrapper .k-scroll-up,
.k-menu-scroll-wrapper .k-scroll-up,
.k-popups-wrapper .k-scroll-down,
.k-menu-scroll-wrapper .k-scroll-down {
  width: 100%;
  height: 16px;
  padding: 0em 0.7em;
  line-height: 0.2em;
}
.k-popups-wrapper .k-scroll-left,
.k-menu-scroll-wrapper .k-scroll-left {
  top: 0;
  left: 0;
}
.k-popups-wrapper .k-scroll-right,
.k-menu-scroll-wrapper .k-scroll-right {
  top: 0;
  right: 0;
}
.k-popups-wrapper .k-scroll-left,
.k-menu-scroll-wrapper .k-scroll-left,
.k-popups-wrapper .k-scroll-right,
.k-menu-scroll-wrapper .k-scroll-right {
  width: 16px;
  height: 100%;
  padding-left: 0;
  padding-right: 0;
}
.k-popups-wrapper .k-scroll-left .k-icon,
.k-menu-scroll-wrapper .k-scroll-left .k-icon,
.k-popups-wrapper .k-scroll-right .k-icon,
.k-menu-scroll-wrapper .k-scroll-right .k-icon {
  margin-top: -0.5em;
  position: absolute;
  top: 50%;
  left: 0;
}
/* Context Menu */
.k-context-menu {
  border: 0;
  user-select: none;
}
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Grid */
.k-grid,
.k-listview {
  position: relative;
  zoom: 1;
}
.k-grid table {
  width: 100%;
  margin: 0;
  /* override CSS libraries */
  max-width: none;
  border-collapse: separate;
  border-spacing: 0;
  empty-cells: show;
  border-width: 0;
  outline: none;
}
.k-header.k-drag-clue {
  overflow: hidden;
}
.k-grid-header th.k-header,
.k-filter-row th {
  overflow: hidden;
  border-style: solid;
  border-width: 0 0 1px 1px;
  padding: .5em .6em .4em .6em;
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}
.k-grid-header th.k-header {
  vertical-align: bottom;
}
.k-filtercell,
.k-filtercell > span,
.k-filtercell .k-widget {
  display: block;
  width: auto;
}
.k-filtercell > span {
  padding-right: 4.8em;
  position: relative;
  min-height: 2em;
  line-height: 2em;
}
.k-filtercell > .k-operator-hidden {
  padding-right: 2.3em;
}
.k-filtercell > span > .k-button,
.k-filter-row .k-dropdown-operator {
  position: absolute;
  top: 0;
  right: 0;
}
.k-filter-row .k-dropdown-operator {
  width: 2.1em;
  right: 2.5em;
}
.k-filtercell > span > label {
  vertical-align: middle;
}
.k-filter-row label > input[type="radio"] {
  vertical-align: middle;
  position: relative;
  bottom: 2px;
}
.k-ie10 .k-grid-header a:active {
  background-color: transparent;
  /*remove gray background*/
}
.k-grid-header th.k-header > .k-link {
  display: block;
  min-height: 18px;
  line-height: 18px;
  /* due to sorting icons*/
  margin: -0.5em -0.6em -0.4em 0;
  padding: .5em .6em .4em 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.k-grid-header th.k-with-icon .k-link {
  margin-right: 18px;
}
.k-grid-header th > .k-link > .k-icon {
  vertical-align: text-top;
}
.k-grid .k-state-hover {
  cursor: pointer;
}
.k-grid-column-resizing,
.k-grid-column-resizing .k-grid-filter,
.k-grid-column-resizing .k-link,
.k-grid-column-resizing .k-button,
.k-grid-column-resizing .k-grid-toolbar {
  cursor: col-resize;
}
.k-grid td {
  border-style: solid;
  border-width: 0 0 0 1px;
  padding: .4em .6em;
  overflow: hidden;
  line-height: 1.6em;
  vertical-align: middle;
  text-overflow: ellipsis;
}
.k-grid .k-grouping-row td,
.k-grid .k-hierarchy-cell {
  overflow: visible;
}
.k-grid-edit-row td {
  text-overflow: clip;
}
.k-grid-edit-row .k-textbox,
.k-grid-edit-row .text-box {
  /*reset default webkit styles*/
  margin-top: 0;
  margin-bottom: 0;
}
.k-grid-header-wrap,
.k-grid-footer-wrap {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-style: solid;
  border-width: 0 1px 0 0;
  zoom: 1;
}
div.k-grid-header,
div.k-grid-footer {
  padding-right: 17px;
  /* scrollbar width; may vary; can be calculated */
  border-bottom-style: solid;
  border-bottom-width: 1px;
  zoom: 1;
}
.k-grid-header-wrap > table,
.k-grid-header-locked > table {
  margin-bottom: -1px;
}
.k-grid-content {
  position: relative;
  width: 100%;
  overflow: auto;
  overflow-x: auto;
  overflow-y: scroll;
  zoom: 1;
  min-height: 0%;
  /* IE9 bug workaround - expanding Grid on hover */
}
.k-mobile .k-grid tbody {
  -webkit-backface-visibility: hidden;
}
.k-mobile .k-grid-backface tbody {
  -webkit-backface-visibility: visible;
}
.k-grid-content-expander {
  position: absolute;
  visibility: hidden;
  height: 1px;
}
.k-grid-norecords {
  width: 100%;
  height: 100%;
  text-align: center;
}
.k-grid-norecords-template {
  width: 20em;
  height: 4em;
  line-height: 4em;
  vertical-align: middle;
  margin: 0 auto;
}
.k-grid-content > .k-grid-norecords > .k-grid-norecords-template {
  top: 50%;
  left: 50%;
  margin-left: -10em;
  margin-top: -2em;
  position: absolute;
}
@media print {
  .k-grid {
    height: auto !important;
  }
  .k-grid-header {
    padding: 0 !important;
  }
  .k-grid-header-wrap,
  .k-grid-content {
    overflow: visible;
    height: auto !important;
  }
}
.k-grid .k-scrollbar {
  -ms-overflow-style: scrollbar;
}
.k-virtual-scrollable-wrap {
  height: 100%;
  overflow-y: hidden;
  position: relative;
}
.k-grid-header table,
.k-grid-content table,
.k-grid-footer table,
.k-grid-content-locked > table {
  table-layout: fixed;
}
/* Grid :: locked columns */
.k-grid-lockedcolumns {
  white-space: nowrap;
}
.k-grid-content-locked,
.k-grid-content,
.k-pager-wrap {
  white-space: normal;
}
.k-grid-header-locked,
.k-grid-content-locked,
.k-grid-footer-locked {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  /* generally uneeded */
  position: relative;
  border-style: solid;
  border-width: 0 1px 0 0;
}
.k-grid-header-locked + .k-grid-header-wrap,
.k-grid-content-locked + .k-grid-content,
.k-grid-footer-locked + .k-grid-footer-wrap {
  display: inline-block;
  vertical-align: top;
}
.k-grid-toolbar {
  border-style: solid;
  border-width: 1px 0 0;
}
.k-grid-header th.k-header:first-child,
.k-grid tbody td:first-child,
.k-grid tfoot td:first-child,
.k-filter-row > th:first-child {
  border-left-width: 0;
}
.k-grid-header th.k-header.k-first {
  border-left-width: 1px;
}
.k-grid-toolbar:first-child,
.k-grouping-header + .k-grid-toolbar {
  border-width: 0 0 1px;
}
/* Grid :: footer */
.k-footer-template td {
  border-style: solid;
  border-width: 1px 0 0 1px;
}
.k-group-footer td {
  border-style: solid;
  border-width: 1px 0;
}
.k-group-footer .k-group-cell + td {
  border-left-width: 1px;
}
.k-grid-footer {
  border-style: solid;
  border-width: 1px 0 0;
}
.k-grid-footer td {
  border-top-width: 0;
}
.k-grid-footer > td {
  border-top-width: 1px;
}
/* Grid :: paging */
.k-grid-pager {
  border-width: 1px 0 0;
}
.k-grid .k-pager-numbers {
  float: left;
  cursor: default;
  display: inline-block;
  vertical-align: top;
  margin-right: 1px;
}
.k-widget.k-grid .k-pager-numbers {
  position: relative;
}
/* Grid :: filtering */
.k-header > .k-grid-filter,
.k-header > .k-header-column-menu {
  float: right;
  margin: -0.5em -0.6em -0.4em 0;
  padding: .5em .2em .4em;
  position: relative;
  z-index: 1;
  /*mvc site.css*/
  color: inherit;
}
.k-grid .k-animation-container {
  position: absolute;
}
.k-filter-menu {
  padding: .5em;
}
.k-list-filter {
  display: block;
}
form.k-filter-menu .k-widget,
form.k-filter-menu .k-textbox {
  display: block;
}
form.k-filter-menu .k-textbox {
  width: 100%;
  margin-bottom: 3px;
}
.k-filter-help-text,
.k-filter-menu .k-widget,
.k-filter-menu .k-textbox {
  margin: .19em 0 0;
}
.k-filter-menu span.k-filter-and {
  width: 6em;
  margin: .5em 0 .5em;
}
.k-filter-menu .k-button {
  width: 48%;
  margin: .5em 4% 0 0;
}
.k-filter-menu .k-button + .k-button {
  margin-right: 0;
}
.k-filter-menu .k-filter-selected-items {
  font-weight: bold;
  margin: .5em;
}
.k-multicheck-wrap {
  overflow: auto;
  overflow-x: hidden;
  white-space: nowrap;
  max-height: 300px;
}
.k-multicheck-wrap .k-item {
  line-height: 2.2em;
}
/* Grid :: grouping */
.k-grouping-row .k-icon {
  margin: -3px 4px 0 2px;
}
.k-grouping-row p {
  display: inline-block;
  vertical-align: middle;
  margin-left: -0.6em;
  padding: 0 .6em;
}
.k-grouping-row + tr td {
  border-top-width: 1px;
}
.k-grouping-row .k-group-cell,
.k-grouping-row + tr .k-group-cell {
  border-top-width: 0;
  text-overflow: none;
}
.k-grid .k-hierarchy-cell + td {
  border-left-width: 0;
}
.k-grid .k-group-col,
.k-grid .k-hierarchy-col {
  width: 27px;
}
.k-grouping-header {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.k-grouping-header {
  line-height: 2;
}
.k-grouping-dropclue {
  position: absolute;
  width: 6px;
  height: 25px;
  background-repeat: no-repeat;
  background-position: -165px -148px;
}
.k-grouping-dropclue:before,
.k-grouping-dropclue:after {
  content: "";
  width: 0;
  height: 0;
  border: 3px solid;
  border-left-color: transparent;
  border-right-color: transparent;
  position: absolute;
  left: 0;
}
.k-grouping-dropclue:before {
  top: 0;
  border-bottom: 0;
}
.k-grouping-dropclue:after {
  bottom: 0;
  border-top: 0;
}
.k-group-indicator {
  padding: .15em .15em .15em .4em;
  border-width: 1px;
  border-style: solid;
  line-height: 1.5em;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  vertical-align: top;
}
.k-grouping-header .k-group-indicator {
  margin: 0 3px;
}
.k-group-indicator .k-link,
.k-group-indicator .k-button.k-bare {
  padding: 0;
  border-width: 0;
  display: inline-flex;
  align-items: center;
}
.k-group-indicator .k-link .k-icon {
  margin-right: 4px;
}
.k-group-indicator .k-button.k-bare {
  margin-left: 8px;
  padding: 0;
  width: auto;
  height: auto;
  opacity: .5;
}
.k-group-indicator .k-button.k-bare::before,
.k-group-indicator .k-button.k-bare::after {
  display: none;
}
.k-group-indicator .k-button.k-bare:hover {
  opacity: 1;
}
.k-no-flexbox .k-group-indicator {
  display: inline-block;
}
.k-no-flexbox .k-group-indicator .k-link,
.k-no-flexbox .k-group-indicator .k-button {
  display: inline-block;
  vertical-align: middle;
}
/* Grid :: editing */
.k-dirty-cell:before {
  content: "\a0";
  display: inline-block;
  width: 0;
  float: left;
}
.k-dirty {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 3px;
  border-color: #f00 transparent transparent #f00;
  margin: -0.45em 0 0 -0.6em;
  padding: 0;
  overflow: hidden;
  vertical-align: top;
}
.k-grouping-header,
.k-grid-toolbar {
  margin: 0;
  padding: 0.429em 0.2em 0.429em 1em;
  cursor: default;
}
.k-grid .k-edit-container {
  padding: 0;
}
.k-grid .field-validation-error {
  display: block;
}
.k-grid .input-validation-error {
  border-style: ridge;
  border-color: #f00;
  background-color: #ffc0cb;
}
.k-grid-toolbar .k-button {
  vertical-align: middle;
}
.k-grid-actions {
  display: inline-block;
}
.k-grid .k-button {
  margin: 0 .16em;
}
.k-grid tbody .k-button {
  min-width: 64px;
}
.k-grid tbody button.k-button {
  min-width: 78px;
  /* for all except IE8 */
}
html body .k-grid tbody .k-button-icon {
  width: auto;
  min-width: 0;
}
.k-detail-row {
  position: relative;
}
.k-grid .k-detail-cell {
  overflow: visible;
}
.k-grid .k-edit-cell {
  padding: 0 .3em;
  white-space: nowrap;
}
.k-grid .k-edit-cell .k-tooltip {
  white-space: normal;
}
.k-edit-cell > .k-textbox,
.k-edit-cell > .k-widget,
.k-grid-edit-row > td > .k-textbox,
.k-grid-edit-row > td > .k-widget,
.k-grid-edit-row > td > .text-box {
  width: 100%;
}
html .k-edit-cell .k-tooltip,
html .k-grid-edit-row .k-tooltip {
  width: auto;
  max-width: 300px;
}
.k-edit-cell input[type="checkbox"] {
  margin-left: .6em;
}
.k-grid tbody td > .k-grid-delete {
  margin-top: -0.2em;
  margin-bottom: -0.2em;
}
/* Grid :: resizing */
.k-grid-resize-indicator {
  position: absolute;
  width: 2px;
  background-color: #aaa;
}
.k-grid-header .k-resize-handle,
.k-grid > .k-resize-handle {
  position: absolute;
  height: 25px;
  cursor: col-resize;
  z-index: 2;
}
.k-marquee {
  position: absolute;
  z-index: 100000;
}
.k-marquee-color,
.k-marquee-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.k-marquee-color {
  filter: alpha(opacity=60);
  opacity: .6;
}
.k-ie9 .k-column-menu {
  width: 160px;
  /*fix flicker on item hover*/
}
.k-column-menu {
  min-width: 160px;
}
.k-column-menu .k-sprite {
  margin-right: 10px;
}
.k-column-menu > .k-menu {
  border-width: 0;
}
.k-column-menu .k-calendar .k-link {
  white-space: normal;
}
.k-columns-item .k-group {
  max-height: 200px;
  overflow: auto;
}
/* Remove Grid scrollbar during built-in export */
.k-pdf-export-shadow .k-grid {
  float: left;
  width: auto !important;
}
/* Remove all sizes and scrolling */
.k-pdf-export-shadow .k-grid,
.k-pdf-export-shadow .k-grid-content,
.k-pdf-export-shadow .k-grid-content-locked {
  height: auto !important;
  overflow: visible;
}
.k-pdf-export-shadow .k-grid-header-locked + .k-grid-header-wrap,
.k-pdf-export-shadow .k-grid-content-locked + .k-grid-content,
.k-pdf-export-shadow .k-grid-footer-locked + .k-grid-footer-wrap {
  width: auto !important;
}
/* Remove empty space reserved above the scrollbar */
.k-pdf-export-shadow .k-grid-header,
.k-pdf-export-shadow .k-grid-footer {
  padding: 0 !important;
}
.k-loading-pdf-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
}
.k-loading-pdf-mask .k-loading-color {
  filter: alpha(opacity=50);
  opacity: 0.5;
}
.k-loading-pdf-mask .k-loading-pdf-progress {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.k-pdf-export .k-loading-pdf-mask {
  display: none;
}
/* responsive styles  */
.k-autofitting {
  width: auto !important;
  table-layout: auto !important;
}
.k-autofitting th.k-header,
.k-autofitting td {
  white-space: nowrap !important;
}
.k-autofitting .k-detail-row {
  display: none !important;
}
.k-grid input.k-checkbox + label.k-checkbox-label {
  cursor: default;
  outline: 0;
}
.k-grid .k-checkbox-label.k-no-text {
  padding: 0;
}
.k-grid .k-edit-cell input[type='checkbox'],
.k-grid .k-edit-cell input.k-checkbox + label.k-checkbox-label {
  margin-left: .3em;
}
.k-window .k-popup-edit-form .k-edit-field input.k-checkbox {
  position: absolute;
  margin-left: 1px;
  margin-top: 0.8em;
  width: 14px;
  height: 14px;
  z-index: 1;
}
.k-window .k-popup-edit-form .k-edit-field input.k-checkbox + label.k-checkbox-label {
  cursor: default;
}
.k-window .k-popup-edit-form .k-edit-field label.k-checkbox-label {
  margin-top: 0.6em;
}
.k-grid-header .k-i-sort-asc-sm,
.k-grid-header .k-i-sort-desc-sm {
  margin-left: 7px;
}
.k-grid-header .k-sort-order {
  display: inline-block;
  height: 16px;
  line-height: 16px;
  margin-top: 1px;
  margin-left: -1px;
  vertical-align: text-top;
  font-size: 12px;
}
.k-grid-header .k-link .k-icon.k-i-sort-asc-sm,
.k-grid-header .k-link .k-icon.k-i-sort-desc-sm {
  margin-top: 1px;
  vertical-align: text-top;
}
@media only screen and (max-width: 1024px) {
  .k-webkit .k-grid .k-pager-numbers,
  .k-ff .k-grid .k-pager-numbers,
  .k-ie11 .k-grid .k-pager-numbers,
  .k-edge .k-grid .k-pager-numbers,
  .k-safari .k-grid .k-pager-numbers {
    position: absolute;
    left: 4.8em;
    display: inline-flex;
    flex-direction: column-reverse;
    overflow: visible;
    height: auto;
  }
  .k-webkit .k-grid .k-pager-numbers:first-child,
  .k-ff .k-grid .k-pager-numbers:first-child,
  .k-ie11 .k-grid .k-pager-numbers:first-child,
  .k-edge .k-grid .k-pager-numbers:first-child,
  .k-safari .k-grid .k-pager-numbers:first-child {
    left: .2em;
  }
  .k-webkit .k-grid .k-pager-numbers.k-state-expanded,
  .k-ff .k-grid .k-pager-numbers.k-state-expanded,
  .k-ie11 .k-grid .k-pager-numbers.k-state-expanded,
  .k-edge .k-grid .k-pager-numbers.k-state-expanded,
  .k-safari .k-grid .k-pager-numbers.k-state-expanded {
    transform: translatey(-100%);
    -webkit-transform: translatey(-100%);
  }
  .k-webkit .km-pane-wrapper .k-grid .k-pager-numbers,
  .k-ff .km-pane-wrapper .k-grid .k-pager-numbers,
  .k-ie11 .km-pane-wrapper .k-grid .k-pager-numbers,
  .k-edge .km-pane-wrapper .k-grid .k-pager-numbers,
  .k-safari .km-pane-wrapper .k-grid .k-pager-numbers {
    position: relative;
    left: 50%;
    transform: translate(-50%, -100%);
    -webkit-transform: translate(-50%, -100%);
  }
  .k-webkit .k-rtl .k-grid .k-pager-numbers,
  .k-ff .k-rtl .k-grid .k-pager-numbers,
  .k-ie11 .k-rtl .k-grid .k-pager-numbers,
  .k-edge .k-rtl .k-grid .k-pager-numbers,
  .k-safari .k-rtl .k-grid .k-pager-numbers {
    left: auto;
    right: 4.8em;
    width: 4.5em;
  }
  .k-webkit .k-rtl .k-grid .k-pager-numbers:first-child,
  .k-ff .k-rtl .k-grid .k-pager-numbers:first-child,
  .k-ie11 .k-rtl .k-grid .k-pager-numbers:first-child,
  .k-edge .k-rtl .k-grid .k-pager-numbers:first-child,
  .k-safari .k-rtl .k-grid .k-pager-numbers:first-child {
    left: auto;
    right: .2em;
  }
  .k-webkit .k-rtl .km-pane-wrapper .k-grid .k-pager-numbers,
  .k-ff .k-rtl .km-pane-wrapper .k-grid .k-pager-numbers,
  .k-ie11 .k-rtl .km-pane-wrapper .k-grid .k-pager-numbers,
  .k-edge .k-rtl .km-pane-wrapper .k-grid .k-pager-numbers,
  .k-safari .k-rtl .km-pane-wrapper .k-grid .k-pager-numbers {
    right: 5.8em;
  }
  .k-webkit .k-grid .k-pager-numbers .k-current-page,
  .k-ff .k-grid .k-pager-numbers .k-current-page,
  .k-ie11 .k-grid .k-pager-numbers .k-current-page,
  .k-edge .k-grid .k-pager-numbers .k-current-page,
  .k-safari .k-grid .k-pager-numbers .k-current-page {
    display: block;
    border-left: 0;
  }
  .k-webkit .k-grid .k-pager-number.k-state-expanded .k-current-page,
  .k-ff .k-grid .k-pager-number.k-state-expanded .k-current-page,
  .k-ie11 .k-grid .k-pager-number.k-state-expanded .k-current-page,
  .k-edge .k-grid .k-pager-number.k-state-expanded .k-current-page,
  .k-safari .k-grid .k-pager-number.k-state-expanded .k-current-page {
    transform: translatey(100%);
    -webkit-transform: translatey(100%);
  }
}
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.k-treelist .k-status {
  padding: .4em .6em;
  line-height: 1.6em;
}
.k-treelist .k-status .k-loading {
  vertical-align: baseline;
  margin-right: 5px;
}
.k-treelist tr.k-hidden {
  display: none;
}
.k-treelist.k-treelist-dragging,
.k-treelist.k-treelist-dragging .k-state-hover {
  cursor: default;
}
.k-treelist .k-i-drag-and-drop {
  position: absolute;
  z-index: 10000;
  visibility: hidden;
  width: 80px;
  height: 5px;
  margin-top: -3px;
  background-color: transparent;
  background-repeat: no-repeat;
}
.k-treelist .k-i-arrow-60-right,
.k-treelist .k-i-arrow-45-down-right {
  cursor: pointer;
}
.k-treelist .k-i-arrow-45-down-right {
  margin-top: -0.5em;
}
.k-drag-separator {
  display: inline-block;
  border-right: 1px solid;
  height: 1em;
  vertical-align: top;
  margin: 0 .5em;
}
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Gantt Chart start */
/* Gantt Main Layout */
.k-gantt {
  white-space: nowrap;
  position: relative;
}
.k-gantt-layout {
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.k-gantt .k-splitbar {
  position: relative;
  cursor: e-resize;
  width: 5px;
  border-width: 0 1px;
  background-repeat: repeat-y;
}
.k-gantt .k-gantt-layout th {
  vertical-align: bottom;
}
.k-gantt td {
  overflow: hidden;
  white-space: nowrap;
  vertical-align: top;
}
.k-gantt .k-grid .k-edit-cell {
  vertical-align: middle;
}
.k-gantt-treelist > .k-treelist,
.k-gantt-timeline > .k-timeline {
  border-width: 0;
  height: 100%;
}
/* Gantt Toolbar, footer */
.k-gantt-toolbar {
  border-style: solid;
  border-width: 0 0 1px;
  line-height: 2.4em;
  padding: .5em;
}
.k-gantt-layout + .k-gantt-toolbar {
  border-width: 1px 0 0;
}
.k-gantt-actions,
.k-gantt-toolbar > ul {
  float: left;
  margin-right: .6em;
}
.k-gantt-actions > .k-button {
  margin-right: .5em;
  vertical-align: top;
}
.k-gantt-toolbar > .k-gantt-views {
  float: right;
  margin-right: 0;
}
.k-gantt-toolbar > .k-gantt-views > li.k-current-view {
  display: none;
}
.k-gantt-toolbar > ul > li {
  display: inline-block;
  border-style: solid;
  border-width: 1px 1px 1px 0;
}
.k-gantt-toolbar > ul > li:first-child + li {
  border-left-width: 1px;
}
.k-gantt-toolbar .k-link {
  display: inline-block;
  padding: 0 1.1em;
}
.k-gantt-toolbar li:first-child + li,
.k-gantt-toolbar li:first-child + li > .k-link {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.k-gantt-toolbar li:last-child,
.k-gantt-toolbar li:last-child > .k-link {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.k-gantt-toolbar li.k-button {
  line-height: inherit;
  padding-top: 0;
  padding-bottom: 0;
}
/* Gantt TreeList */
.k-gantt-treelist .k-grid-header tr {
  height: 5em;
}
.k-gantt .k-treelist .k-grid-header {
  padding: 0 !important;
}
.k-gantt .k-treelist .k-grid-content {
  overflow-y: hidden;
  overflow-x: scroll;
}
.k-treelist-group > tr > span {
  font-weight: bold;
}
.k-treelist-group .k-widget {
  font-weight: normal;
}
/* Gantt TimeLine */
.k-gantt-timeline .k-grid-header tr {
  height: 2.5em;
}
.k-gantt-tasks {
  /*needed for RTL*/
  position: relative;
}
.k-gantt-rows tr,
.k-gantt-tasks tr,
.k-gantt .k-grid-content tr {
  height: 2.3em;
}
.k-gantt .k-gantt-tasks td:after {
  content: "\a0";
}
.k-gantt-timeline {
  background: transparent;
}
.k-gantt-rows,
.k-gantt-columns,
.k-gantt-dependencies {
  position: absolute;
  top: 0;
  left: 0;
}
.k-gantt-tables {
  position: relative;
}
.k-gantt .k-timeline .k-grid-content {
  overflow-x: scroll;
}
.k-gantt .k-gantt-timeline th {
  text-align: center;
}
.k-gantt .k-gantt-timeline tr:first-child th {
  border-bottom-width: 1px;
}
/* Gantt TimeLine objects */
/* Summary */
.k-task-summary {
  height: 10px;
  display: inline-block;
  vertical-align: top;
  margin-top: 3px;
}
.k-task-summary-complete {
  height: 10px;
  position: relative;
  z-index: 2;
}
.k-task-summary-progress {
  height: 15px;
  overflow: hidden;
}
.k-task-summary:before,
.k-task-summary-complete:before,
.k-task-summary:after,
.k-task-summary-complete:after {
  content: "";
  position: absolute;
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px;
  border-color: transparent;
}
.k-task-summary:before,
.k-task-summary-complete:before {
  left: 0;
  border-left-color: inherit;
}
.k-task-summary:after,
.k-task-summary-complete:after {
  right: 0;
  border-right-color: inherit;
}
/* Lines */
.k-line-h,
.k-line-v {
  position: absolute;
}
.k-line-h {
  height: 2px;
}
.k-line-v {
  width: 2px;
}
.k-arrow-e,
.k-arrow-w {
  position: absolute;
  top: -4px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px;
}
.k-arrow-e {
  right: -6px;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.k-arrow-w {
  left: -6px;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}
/* Milestone */
.k-task-milestone {
  width: 13px;
  height: 13px;
  margin-top: 3px;
  border-style: solid;
  border-width: 1px;
  transform: rotate(45deg);
}
/* Button */
.k-gantt .k-gantt-treelist .k-button,
.k-gantt .k-gantt-tasks .k-button-icon {
  padding-top: 0;
  padding-bottom: 0;
}
.k-gantt .k-gantt-tasks .k-button-icon {
  margin-top: 4px;
}
.k-gantt .k-gantt-treelist .k-button {
  margin-top: -4px;
  margin-bottom: -2px;
}
.k-gantt .k-gantt-tasks .k-button-icon {
  padding-left: 2px;
  padding-right: 2px;
}
.k-gantt .k-gantt-treelist .k-button .k-icon,
.k-gantt .k-gantt-tasks .k-button .k-icon {
  vertical-align: text-top;
}
.k-rel .k-button-icon {
  position: absolute;
  left: 200px;
}
/* Tasks */
.k-rel {
  position: relative;
  height: 0;
  top: -0.3em;
}
.k-task-wrap {
  position: absolute;
  padding: 0 23px 5px;
  margin: -1px -23px 0;
  z-index: 2;
}
.k-task-wrap:hover,
.k-line.k-state-selected {
  z-index: 3;
}
.k-milestone-wrap {
  margin: 0 -13px 0 -27px;
}
.k-task-content {
  position: relative;
  z-index: 2;
}
.k-task-complete {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 20%;
  z-index: 1;
}
.k-task-dot {
  position: absolute;
  top: 0;
  width: 16px;
  height: 16px;
  line-height: 16px;
  display: none;
  cursor: pointer;
}
.k-task-dot.k-state-hover {
  background-color: transparent;
}
.k-task-single + .k-task-dot,
.k-task-single + .k-task-dot + .k-task-dot {
  top: .2em;
}
.k-task-wrap:hover .k-task-dot,
.k-task-wrap-active .k-task-dot {
  display: block;
}
.k-task-dot:before {
  content: "\a0";
  display: inline-block;
  width: 0;
  height: 16px;
}
.k-task-dot:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-left: 4px;
}
.k-task-dot:hover:after,
.k-task-dot.k-state-hover:after,
.k-task-wrap-active .k-task-dot:after {
  border-style: solid;
  border-width: 1px;
  margin-left: 3px;
}
.k-task-start {
  left: 0;
}
.k-task-end {
  right: 0;
}
.k-task-single {
  border-style: solid;
  border-width: 1px;
  text-align: left;
  overflow: hidden;
  cursor: default;
  min-height: 1.3em;
  white-space: nowrap;
}
.k-task-template {
  padding: .2em 1.4em .2em .6em;
  line-height: normal;
}
.k-task-actions,
.k-task-content > .k-link {
  position: absolute;
  top: 0;
  right: 4px;
  white-space: nowrap;
}
.k-task-actions {
  z-index: 1;
}
.k-task-actions:first-child {
  position: static;
  float: left;
  margin: 4px 2px 0 4px;
}
.k-webkit .k-task-actions:first-child {
  margin-top: 3px;
}
.k-task-actions:first-child > .k-link {
  display: inline-block;
}
.k-task-delete {
  display: none;
}
.k-task-wrap:hover .k-task-delete,
.k-task-wrap-active .k-task-delete {
  display: inline-block;
}
.k-task-single .k-resize-handle {
  position: absolute;
  visibility: hidden;
  z-index: 2;
  height: auto;
}
.k-task-single:hover .k-resize-handle,
.k-task-wrap-active .k-resize-handle {
  visibility: visible;
}
.k-task-single .k-resize-handle:after {
  content: "";
  position: absolute;
  filter: alpha(opacity=50);
  opacity: .5;
}
.k-task-content > .k-resize-e {
  right: 0;
  top: 0;
  bottom: 0;
  width: .4em;
}
.k-task-content > .k-resize-w {
  left: 0;
  top: 0;
  bottom: 0;
  width: .4em;
}
.k-task-content > .k-resize-e:after,
.k-task-content > .k-resize-w:after {
  left: 1px;
  top: 50%;
  margin-top: -0.7em;
  height: 1.4em;
  width: 1px;
}
.k-task-content > .k-resize-e:after {
  left: auto;
  right: 1px;
}
.k-task-draghandle {
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  margin: 0 18px;
  border-width: 5px;
  border-style: solid;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  display: none;
  cursor: e-resize;
}
.k-task-wrap:hover .k-task-draghandle,
.k-task-wrap-active .k-task-draghandle {
  display: block;
}
.k-dependency-hint {
  z-index: 4;
}
/* Higher row height styles */
.k-gantt-rowheight .k-task-summary,
.k-gantt-rowheight .k-task-milestone,
.k-gantt-rowheight .k-task-dot,
.k-gantt-rowheight .k-task-single + .k-task-dot,
.k-gantt-rowheight .k-task-single + .k-task-dot + .k-task-dot {
  top: 50%;
}
.k-gantt-rowheight .k-task-summary,
.k-gantt-rowheight .k-task-milestone {
  margin-top: -6px;
}
.k-gantt-rowheight .k-task-dot,
.k-gantt-rowheight .k-task-single + .k-task-dot,
.k-gantt-rowheight .k-task-single + .k-task-dot + .k-task-dot {
  margin-top: -11px;
}
.k-gantt-rowheight .k-task-single {
  height: calc(98%);
}
.k-ie .k-gantt-rowheight .k-task-single {
  height: 99%;
}
.k-gantt-rowheight .k-task-content {
  height: 100%;
}
.k-gantt-rowheight .k-task-content > .k-resize-e:after,
.k-gantt-rowheight .k-task-content > .k-resize-w:after {
  top: 0;
  margin-top: 0;
  height: 100%;
}
/*Task Hover Tooltip*/
.k-task-details {
  padding: .4em;
  text-align: left;
  white-space: nowrap;
}
.k-task-details > strong {
  font-size: 120%;
  display: block;
}
.k-task-pct {
  margin: .5em 0 .1em;
  font-size: 170%;
}
.k-task-details > ul {
  line-height: 1.2;
}
/*Resources*/
.k-resources-wrap {
  position: absolute;
  z-index: 2;
  zoom: 1;
  margin-left: 20px;
  margin-top: -2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.k-resources-wrap .k-resource {
  margin: 0px 5px;
}
/* Gantt Edit form */
.k-gantt-edit-form > .k-edit-form-container {
  width: 430px;
}
.k-gantt-edit-form > .k-resources-form-container {
  width: 506px;
}
.k-resources-form-container > .k-grid {
  margin: 0 .9em;
}
.k-gantt-edit-form > .k-edit-form-container .k-textbox,
.k-gantt-edit-form > .k-edit-form-container .k-numerictextbox {
  width: 15em;
}
.k-gantt-edit-form .k-edit-buttons .k-gantt-delete {
  float: left;
}
/* Gantt Chart PDF export */
.k-pdf-export-shadow .k-gantt {
  float: left;
}
/* Remove all sizes and scrolling */
.k-pdf-export-shadow .k-gantt,
.k-pdf-export-shadow .k-gantt-timeline,
.k-pdf-export-shadow .k-gantt .k-grid-content {
  width: auto !important;
  height: auto !important;
  overflow: visible !important;
}
.k-pdf-export-shadow .k-gantt-treelist {
  height: auto !important;
  overflow: visible !important;
}
/* Remove empty space reserved above the scrollbar */
.k-pdf-export-shadow .k-gantt-timeline .k-grid-header {
  padding: 0 !important;
}
/* Hide the splitter */
.k-pdf-export-shadow .k-gantt .k-splitbar,
.k-pdf-export-shadow .k-pdf-export {
  display: none;
}
/* Responsive styles  */
button.k-gantt-toggle {
  display: none;
  float: left;
  margin-right: .5em;
}
@media only screen and (max-width: 1024px) {
  .k-gantt-toolbar > ul.k-gantt-views {
    position: absolute;
    right: 6px;
    top: 6px;
    z-index: 10000;
  }
  .k-rtl .k-gantt-toolbar > ul.k-gantt-views {
    right: auto;
    left: 6px;
  }
  .k-gantt-toolbar > ul.k-gantt-views > li:not(.k-current-view) {
    display: none;
  }
  .k-gantt-toolbar > ul.k-gantt-views > li.k-current-view {
    display: block;
    border-width: 1px;
  }
  .k-rtl .k-gantt-toolbar > ul.k-gantt-views > li.k-current-view {
    text-align: left;
    padding-left: 1em;
  }
  .k-gantt-toolbar > ul.k-gantt-views > li.k-current-view > .k-link {
    display: block;
    position: relative;
    padding-right: 2.5em;
    padding-left: 1em;
  }
  .k-rtl .k-gantt-toolbar > ul.k-gantt-views > li.k-current-view > .k-link {
    padding-left: 0;
  }
  .k-gantt-toolbar > ul.k-gantt-views > li.k-current-view > .k-link:after {
    content: "\E006";
    position: absolute;
    top: 50%;
    right: 0.6em;
    margin-top: -0.5em;
    line-height: 1em;
  }
  .k-gantt-toolbar > ul.k-gantt-views.k-state-expanded > li,
  .k-gantt-toolbar > ul.k-gantt-views.k-state-expanded > li:first-child + li {
    display: block;
    border: 0;
    border-radius: 0;
  }
  .k-gantt-toolbar > ul.k-gantt-views.k-state-expanded {
    border: 1px solid #c5c5c5;
    background-color: #fff;
    background-image: none;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
  }
  .k-rtl .k-gantt-toolbar > ul.k-gantt-views.k-state-expanded {
    text-align: left;
  }
}
@media only screen and (max-width: 480px) {
  button.k-gantt-toggle {
    display: inline-block;
  }
  .k-gantt-create span + span,
  .k-gantt-pdf span + span {
    display: none;
  }
  .k-gantt-create .k-icon,
  .k-gantt-pdf .k-icon {
    margin: 0;
  }
  .k-gantt .k-splitbar {
    display: none;
    width: 0;
    border-width: 0;
  }
  .k-gantt .k-gantt-treelist {
    display: none;
    max-width: 0;
  }
  .k-gantt .k-treelist .k-grid-content {
    overflow-y: scroll;
  }
  .k-gantt .k-gantt-timeline {
    width: 100%;
  }
}
/* Gantt Chart end */
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Pivot start */
.k-pivot {
  position: relative;
}
.k-pivot-toolbar {
  padding: .2em;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.k-pivot .k-pivot-toolbar {
  padding: .6em;
}
.k-pivot-toolbar .k-button {
  margin-right: .4em;
  line-height: 1.2em;
  font-size: .9em;
  text-align: left;
  position: relative;
  padding: .3em 5em .3em .3em;
  cursor: move;
}
.k-field-actions {
  position: absolute;
  right: 2px;
  top: 3px;
  cursor: pointer;
}
/*IE7 requires the following style to be applied to cells directly*/
.k-pivot .k-grid td {
  white-space: nowrap;
}
.k-pivot-layout {
  border-spacing: 0;
  table-layout: auto;
}
.k-pivot-layout > tbody > tr > td {
  vertical-align: top;
  padding: 0;
}
.k-pivot td {
  vertical-align: top;
}
.k-pivot-rowheaders > .k-grid,
.k-pivot-table > .k-grid {
  border-width: 0;
}
.k-pivot-rowheaders > .k-grid td:first-child,
.k-pivot-table .k-grid-header .k-header.k-first {
  border-left-width: 1px;
}
.k-pivot-rowheaders > .k-grid td.k-first {
  border-left-width: 0;
}
.k-pivot-rowheaders > .k-grid {
  overflow: hidden;
}
.k-pivot-table {
  border-left-width: 1px;
  border-left-style: solid;
}
.k-pivot-table .k-grid-header-wrap > table {
  height: 100%;
}
.k-pivot .k-grid-header .k-header {
  vertical-align: top;
}
.k-header.k-alt,
td.k-alt {
  font-weight: bold;
}
.k-header.k-alt {
  background-image: none;
}
.k-pivot-layout .k-grid td {
  border-bottom-width: 1px;
}
.k-pivot-layout .k-grid-footer > td {
  border-top-width: 0;
}
.k-pivot-filter-window .k-treeview {
  max-height: 600px;
}
/* selector */
.k-fieldselector .k-edit-buttons {
  bottom: auto;
}
.k-fieldselector .k-edit-label {
  width: 16%;
}
.k-fieldselector .k-edit-field {
  width: 77%;
}
.k-fieldselector .k-edit-field > .k-widget,
.k-fieldselector .k-edit-field > .k-textbox {
  width: 99%;
}
.k-fieldselector .k-edit-buttons > input,
.k-fieldselector .k-edit-buttons > label {
  float: left;
  margin-top: .4em;
}
.k-fieldselector p {
  margin: 0 0 .2em .5em;
  text-transform: uppercase;
}
.k-fieldselector p .k-icon {
  margin: 0 5px 0 0;
}
.k-fieldselector .k-columns {
  border-style: solid;
  border-width: 0;
}
.k-fieldselector .k-columns > div {
  overflow: auto;
  padding: .6em;
  border-style: solid;
  border-width: 0 0 0 1px;
  float: left;
  width: 45%;
}
.k-fieldselector .k-columns > div:first-child {
  border-width: 0;
  margin-right: -1px;
}
.k-fieldselector .k-columns > div + div {
  float: right;
  border-width: 0;
}
.k-fieldselector div.k-treeview {
  border-width: 0;
  margin-right: -1px;
  padding-left: 4px;
  overflow: visible;
}
.k-fieldselector .k-list-container {
  margin-left: .5em;
  margin-bottom: 1em;
  padding: .2em 0 0;
  border-style: solid;
  border-width: 1px;
}
.k-fieldselector .k-list {
  padding-bottom: 2em;
}
.k-fieldselector .k-list li.k-item {
  padding: .3em 3.3em .3em .3em;
  margin: 0 .2em .2em;
  position: relative;
  font-size: .9em;
  line-height: 1.2em;
  min-height: 1em;
}
.k-i-kpi-trend-increase,
.k-i-kpi-trend-decrease,
.k-i-kpi-trend-equal {
  color: #858585;
}
.k-i-kpi-status-hold {
  color: #f9ce1d;
}
.k-i-kpi-status-deny {
  color: #f44336;
}
.k-i-kpi-status-open {
  color: #4caf50;
}
/* Pivot PDF Export styles */
.k-pdf-export-shadow .k-pivot .k-grid-content {
  overflow: visible !important;
}
.k-pdf-export-shadow .k-pivot .k-pivot-rowheaders .k-grid,
.k-pdf-export-shadow .k-pivot .k-grid-content {
  height: 100% !important;
}
.k-pdf-export-shadow .k-pivot .k-grid-header {
  padding-right: 0 !important;
}
.k-pdf-export-shadow .k-pivot {
  width: auto !important;
  height: auto !important;
}
.k-pdf-export-shadow .k-pivot .k-grid-header-wrap > table,
.k-pdf-export-shadow .k-pivot .k-grid-content > table {
  width: 100% !important;
  height: auto !important;
}
/* Pivot end */
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Calendar */
.k-calendar {
  position: relative;
  display: inline-block;
  width: 19em;
  overflow: hidden;
}
.k-week-number {
  width: 21.71428571em;
}
.k-calendar td,
.k-calendar .k-link {
  text-decoration: none;
}
.k-calendar .k-action-link {
  text-decoration: underline;
}
.k-calendar .k-header,
.k-calendar .k-footer {
  position: relative;
  text-align: center;
  zoom: 1;
}
.k-widget.k-calendar .k-nav-prev,
.k-widget.k-calendar .k-nav-next {
  position: absolute;
  top: 0.16666em;
  line-height: 1.8333em;
  height: 1.8333em;
}
.k-widget.k-calendar .k-nav-prev {
  left: 1%;
}
.k-widget.k-calendar .k-nav-next {
  right: 1%;
}
.k-calendar .k-content {
  float: left;
  border-spacing: 0;
  width: 100%;
  height: 14.167em;
  border-width: 0;
  margin: 0;
  table-layout: fixed;
  outline: 0;
}
.k-calendar .k-content,
.k-calendar .k-content th {
  text-align: right;
}
.k-calendar .k-content .k-alt {
  border-radius: 0;
  text-align: center;
  font-weight: normal;
  cursor: default;
  line-height: 1.8333em;
}
.k-calendar .k-animation-container .k-content {
  height: 100%;
}
.k-widget.k-calendar .k-nav-fast {
  display: inline-block;
  width: 75%;
  height: 1.8333em;
  line-height: 1.8333em;
  margin: 0.16666em -0.08333em 0.3333em 0;
}
.k-calendar .k-header .k-icon {
  vertical-align: middle;
}
.k-calendar .k-header .k-link.k-nav-prev,
.k-calendar .k-header .k-link.k-nav-next {
  height: 1.8333em;
  width: 1.8333em;
}
.k-calendar th {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding: .4em .45em .4em .1em;
  font-weight: normal;
  cursor: default;
}
.k-calendar td {
  padding: 0.08333em;
  cursor: pointer;
}
.k-calendar .k-state-focus {
  border-style: dotted;
  border-width: 0.08333em;
  padding: 0;
}
.k-calendar .k-content .k-link {
  display: block;
  overflow: hidden;
  min-height: 1.8333em;
  line-height: 1.8333em;
  padding: 0 .45em 0 .1em;
}
.k-calendar .k-meta-view .k-link {
  padding: .25em 0 .3em;
  text-align: center;
}
.k-calendar .k-century .k-link {
  text-align: left;
  padding: 0 .45em;
}
.k-calendar .k-footer {
  clear: both;
}
.k-calendar .k-footer .k-nav-today,
.k-calendar .k-footer > .k-state-disabled {
  display: block;
  height: 100%;
  padding: .5em 0;
}
.k-calendar .k-nav-today:hover {
  text-decoration: underline;
}
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* TreeView */
div.k-treeview {
  /* due to k-widget */
  border-width: 0;
  background: none;
  overflow: auto;
  white-space: nowrap;
}
.k-treeview .k-item {
  display: block;
  border-width: 0;
  margin: 0;
  padding: 0 0 0 16px;
}
.k-treeview > .k-group,
.k-treeview .k-item > .k-group,
.k-treeview .k-content {
  margin: 0;
  padding: 0;
  background: none;
  list-style-type: none;
  position: relative;
}
.k-treeview .k-icon,
.k-treeview .k-image,
.k-treeview .k-sprite,
.k-treeview .k-checkbox,
.k-treeview .k-in {
  display: inline-block;
  vertical-align: top;
}
.k-treeview .k-checkbox {
  margin-top: .2em;
}
.k-treeview .k-checkbox-label {
  padding-left: calc(16.7px);
}
.k-treeview .k-icon,
.k-treeview .k-in {
  vertical-align: middle;
}
.k-treeview .k-request-retry {
  vertical-align: baseline;
}
.k-treeview .k-i-plus,
.k-treeview .k-i-minus,
.k-treeview .k-i-expand,
.k-treeview .k-i-collapse {
  margin-top: .05em;
  margin-left: -16px;
  cursor: pointer;
}
.k-treeview .k-i-collapse {
  margin-top: -0.4em;
}
.k-treeview .k-sprite,
.k-treeview .k-image {
  margin-right: 3px;
}
.k-treeview .k-in {
  margin: 1px 0 1px 0.3333em;
  padding: 0.429em 0.6667em 0.429em 0.5833em;
  line-height: 1.3333em;
  text-decoration: none;
  border-style: solid;
  border-width: 1px;
}
.k-treeview span.k-in {
  cursor: default;
}
.k-treeview .k-i-drag-and-drop {
  position: absolute;
  z-index: 10000;
  visibility: hidden;
  width: 80px;
  height: 5px;
  margin-top: -3px;
  background-color: transparent;
  background-repeat: no-repeat;
}
.k-treeview .k-state-hover,
.k-treeview .k-state-hover:hover {
  cursor: pointer;
}
.k-rtl .k-treeview .k-i-expand,
.k-rtl .k-treeview .k-i-collapse {
  transform: scaleX(-1);
  margin-left: 0;
  margin-right: -16px;
}
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ComboBox & DropDownList */
span.k-datepicker,
span.k-timepicker,
span.k-datetimepicker,
span.k-colorpicker,
span.k-numerictextbox,
span.k-combobox,
span.k-dropdown,
.k-toolbar .k-split-button {
  background-image: none;
}
.k-autocomplete,
.k-combobox,
.k-datepicker,
.k-timepicker,
.k-datetimepicker,
.k-colorpicker,
.k-numerictextbox,
.k-dropdown,
.k-selectbox,
.k-textbox,
.k-toolbar .k-split-button,
.k-listbox {
  position: relative;
  display: inline-block;
  width: 12.4em;
  overflow: visible;
  border-width: 0;
  vertical-align: middle;
}
.k-autocomplete > .k-i-close,
.k-combobox > .k-dropdown-wrap > .k-i-close,
.k-multiselect > .k-multiselect-wrap > .k-i-close {
  display: none;
  position: absolute;
  right: 0;
}
.k-autocomplete > .k-i-close,
.k-combobox > .k-dropdown-wrap > .k-i-close {
  bottom: 0;
  top: 50%;
  transform: translateY(-50%);
}
.k-combobox > .k-dropdown-wrap > .k-i-close {
  font-size: 100%;
  right: 2.4em;
}
.k-multiselect > .k-multiselect-wrap > .k-i-close {
  top: 6px;
}
.k-autocomplete > .k-i-close,
.k-multiselect > .k-multiselect-wrap > .k-i-close {
  margin-right: 6px;
}
.k-autocomplete.k-state-hover > .k-i-close,
.k-combobox > .k-dropdown-wrap.k-state-hover > .k-i-close,
.k-autocomplete.k-state-focused > .k-i-close,
.k-combobox > .k-dropdown-wrap.k-state-focused > .k-i-close {
  display: inline-block;
  outline: none;
}
.k-autocomplete > .k-hidden,
.k-combobox > .k-dropdown-wrap > .k-hidden,
.k-multiselect .k-hidden {
  display: none !important;
}
.k-multiselect.k-state-hover > .k-multiselect-wrap > .k-i-close,
.k-multiselect.k-state-focused > .k-multiselect-wrap > .k-i-close {
  display: inline-block;
  outline: none;
}
.k-autocomplete.k-state-border-down > .k-i-close,
.k-combobox > .k-dropdown-wrap.k-state-border-down > .k-i-close {
  margin-top: -1px;
}
.k-filter-menu .k-combobox,
.k-filter-menu .k-datepicker,
.k-filter-menu .k-timepicker,
.k-filter-menu .k-datetimepicker,
.k-filter-menu .k-numerictextbox,
.k-filter-menu .k-dropdown,
.k-filter-menu .k-autocomplete,
.k-filter-menu .k-textbox {
  width: 13.2em;
}
.k-autocomplete,
.k-combobox,
.k-datepicker,
.k-timepicker,
.k-datetimepicker,
.k-colorpicker,
.k-numerictextbox,
.k-dropdown,
.k-selectbox,
.k-toolbar .k-split-button {
  white-space: nowrap;
}
.k-colorpicker,
.k-toolbar .k-split-button {
  width: auto;
}
.k-datetimepicker {
  width: 15em;
}
.k-autocomplete,
.k-picker-wrap,
.k-numeric-wrap {
  position: relative;
  cursor: default;
}
.k-dropdown-wrap {
  position: relative;
}
.k-dropdown-wrap,
.k-picker-wrap,
.k-numeric-wrap {
  display: block;
}
.k-block,
.k-widget,
.k-grid,
.k-slider,
.k-splitter,
.k-treeview,
.k-panelbar,
.k-content,
.k-header-column-menu {
  outline: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-block,
.k-slider,
.k-splitbar,
.k-calendar,
.k-treeview,
.k-pager-wrap,
.k-grid-header .k-i-link-horizontal,
.k-header-column-menu {
  -webkit-touch-callout: none;
}
.k-header-column-menu {
  color: inhert;
}
.k-list-scroller {
  position: relative;
  overflow: auto;
}
.k-popup.k-list-container,
.k-popup.k-calendar-container {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  padding: 2px;
  border-width: 1px;
  border-style: solid;
}
.k-list-container.k-state-border-down,
.k-autocomplete.k-state-border-down,
.k-dropdown-wrap.k-state-border-down,
.k-picker-wrap.k-state-border-down,
.k-numeric-wrap.k-state-border-down {
  border-bottom-width: 0;
  padding-bottom: 1px;
}
.k-list-container .km-scroll-container {
  padding-bottom: 6px;
}
.k-textbox,
.k-autocomplete,
.k-dropdown-wrap,
.k-picker-wrap,
.k-numeric-wrap {
  border-width: 1px;
  border-style: solid;
  padding: 0 1.9em 0 0;
}
.k-numeric-wrap.k-expand-padding {
  padding-right: 0;
}
.k-textbox,
.k-autocomplete {
  padding: 0;
}
.k-textbox.k-space-left {
  padding-left: 1.9em;
}
.k-textbox.k-space-right {
  padding-right: 1.9em;
}
.k-textbox .k-icon {
  top: 50%;
  margin: -8px 0 0;
  position: absolute;
}
.k-space-left .k-icon {
  left: 3px;
}
.k-space-right .k-icon {
  right: 3px;
}
/*prevent missing bottom border at some zoom levels*/
span.k-textbox:after {
  content: "\a0";
  display: block;
  height: .4px;
  overflow: hidden;
}
.k-autocomplete,
.k-dropdown-wrap.k-state-focused,
.k-dropdown-wrap.k-state-hover,
.k-picker-wrap.k-state-focused,
.k-picker-wrap.k-state-hover,
.k-numeric-wrap.k-state-focused,
.k-numeric-wrap.k-state-hover {
  transition: box-shadow .15s ease-out;
  transition: "box-shadow .15s ease-out";
}
.k-textbox > input,
.k-picker-wrap .k-input,
.k-numeric-wrap .k-input,
.k-combobox .k-input {
  width: 100%;
  vertical-align: top;
}
.k-picker-wrap .k-input,
.k-numeric-wrap .k-input,
.k-dropdown-wrap .k-input,
.k-selectbox .k-input {
  font-family: inherit;
  border-width: 0;
  outline: 0;
}
.k-dropdown .k-input,
.k-selectbox .k-input {
  background: transparent;
}
.k-picker-wrap .k-select,
.k-numeric-wrap .k-select,
.k-dropdown-wrap .k-select {
  position: absolute;
  /* icon positioning */
  top: 0;
  right: 0;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
}
.k-combobox .k-select,
.k-picker-wrap .k-select,
.k-numeric-wrap .k-select {
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: inherit;
  /* skin-related, inherit does not work in ie7- */
}
span.k-datetimepicker .k-select,
span.k-datetimepicker .k-select + .k-select {
  right: 0;
}
.k-textbox > input,
.k-autocomplete .k-input {
  display: block;
}
.k-combobox .k-icon {
  /*margin-top: 1px;*/
}
.k-dropdown .k-select,
.k-selectbox .k-select {
  overflow: hidden;
  border: 0;
  text-decoration: none;
  font: inherit;
  color: inherit;
}
.k-dropdown .k-input,
.k-selectbox .k-input {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.k-textbox > input,
.k-autocomplete .k-input,
.k-picker-wrap .k-input,
.k-numeric-wrap .k-input,
.k-dropdown-wrap .k-input,
.k-selectbox .k-input {
  height: 2.214em;
  line-height: 2.214em;
  padding: 0.177em 0;
  text-indent: 0.8em;
  border: 0;
  margin: 0;
}
.k-combobox .k-dropdown-wrap:before,
.k-picker-wrap:before,
.k-numeric-wrap:before {
  content: "\a0";
  display: inline-block;
  width: 0;
  height: 2.214em;
  padding-bottom: 0.3em;
}
/* fix missing bottom border on browser zoom in Chrome */
.k-webkit .k-combobox .k-dropdown-wrap:before,
.k-webkit .k-picker-wrap:before,
.k-webkit .k-numeric-wrap:before {
  padding-bottom: 0.38em;
}
/* above style breaks NumericTextBox layout due display:block style applied to the input */
.km.root .k-combobox .k-dropdown-wrap:before,
.km.root .k-picker-wrap:before,
.km.root .k-numeric-wrap:before {
  content: none;
}
.k-combobox .k-input,
.k-picker-wrap .k-input,
.k-numeric-wrap .k-input {
  display: inline;
}
.k-picker-wrap .k-select,
.k-numeric-wrap .k-select,
.k-dropdown-wrap .k-select {
  min-height: 2.214em;
  line-height: 2.564em;
  vertical-align: middle;
  -moz-box-sizing: border-box;
  text-align: center;
  width: 1.9em;
  height: 100%;
}
.k-numeric-wrap .k-select {
  padding: 0;
}
body .k-datetimepicker .k-select {
  border-radius: 0;
}
.k-combobox .k-select,
.k-dropdown,
.k-selectbox .k-icon {
  cursor: pointer;
}
.k-popup {
  border-style: solid;
  border-width: 1px;
}
.k-popup .k-item,
.k-list-optionlabel {
  cursor: default;
}
.k-popup .k-calendar {
  border: 0;
}
.k-list {
  height: auto;
}
.k-nodata {
  min-height: 138px;
  width: 100%;
  display: table;
  text-transform: uppercase;
  font-size: 0.85em;
  font-weight: lighter;
}
.k-nodata > div {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  padding: 11px;
}
.k-popup .k-list .k-item,
.k-fieldselector .k-list .k-item,
.k-list-optionlabel,
.k-popup > .k-group-header,
.k-popup > .k-virtual-wrap > .k-group-header,
.k-listbox .k-item,
.k-item.k-drag-clue {
  padding: 1px 5px 1px 5px;
  line-height: 1.8em;
  min-height: 1.8em;
}
.k-popup .k-list .k-item,
.k-listbox .k-item {
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  padding: 0 4px;
}
.k-popup .k-list .k-item > .k-group {
  top: -1px;
}
.k-group-header + div > .k-list > .k-item.k-first:before {
  content: " ";
  display: block;
  border-top-width: 1px;
  border-top-style: solid;
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
}
.k-popup > .k-group-header,
.k-popup > .k-virtual-wrap > .k-group-header {
  padding-right: 22px;
}
.k-overflow-container .k-item {
  padding: 1px;
}
.k-overflow-container > .k-state-disabled .k-button,
.k-overflow-container .k-button.k-state-disabled,
.k-overflow-container .k-button.k-state-disabled:hover {
  border: 0 ;
  background: none;
}
.k-popup .k-list .k-state-hover,
.k-popup .k-list .k-state-focused,
.k-popup .k-list .k-state-selected,
.k-overflow-container .k-state-hover,
.k-overflow-container .k-state-focused,
.k-overflow-container .k-state-selected,
.k-fieldselector .k-list .k-item,
.k-list-optionlabel.k-state-focused,
.k-list-optionlabel.k-state-selected,
.k-listbox .k-item {
  padding: 0 4px;
  border-width: 1px;
  border-style: solid;
}
.k-list-filter {
  position: relative;
  margin-bottom: 2px;
}
.k-list-filter > .k-textbox {
  padding-right: 20px;
  width: 100%;
}
.k-list-filter > .k-icon {
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
}
.km-root .k-list-filter > .k-textbox {
  padding-left: 0;
  padding-right: 0;
  border-left-width: 0;
  border-right-width: 0;
}
/* MultiSelect */
.k-multiselect-wrap {
  position: relative;
  border-width: 0px;
  border-style: solid;
  border-radius: 4px;
  border-color: #C5C5C5;
  background-color: #FFF;
  min-height: 2.04em;
  padding-right: 22px;
}
.k-multiselect-wrap .k-input {
  background-color: transparent;
  height: 1.31em;
  line-height: 1.31em;
  padding: 0.18em 0;
  text-indent: 0.8em;
  border: 0;
  margin: 1px 0 0;
  float: left;
}
.k-multiselect-wrap .k-input::-ms-clear {
  display: none;
}
.k-multiselect-wrap li {
  margin: 1px 0 1px 1px;
  padding: .1em 1.6em .1em .4em;
  line-height: 2.064em;
  float: left;
  position: relative;
}
.k-autocomplete .k-i-loading,
.k-multiselect .k-i-loading {
  position: absolute;
  right: 3px;
  bottom: 4px;
}
.k-multiselect-wrap .k-select {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: .03em .2em;
}
/* Inputs */
.k-autocomplete-clearable .k-input,
.k-multiselect-clearable .k-input,
.k-combobox-clearable .k-input {
  padding-right: 1.9em;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 2.568em;
}
/* Date/Time Pickers */
.k-datetimepicker .k-picker-wrap {
  padding-right: 3.8em;
}
.k-datetimepicker .k-select {
  width: 3.8em;
}
.k-datetimepicker .k-select .k-link-date {
  margin-left: -0.285em;
}
.k-datetimepicker .k-select .k-link-time {
  margin-right: -0.285em;
  margin-left: .428em;
}
.k-datetimepicker .k-picker-wrap .k-icon {
  margin: 0 2px;
}
.k-picker-wrap .k-icon {
  cursor: pointer;
}
.k-textbox,
.k-timepicker,
.k-datepicker,
.k-datetimepicker {
  display: inline-block;
  vertical-align: middle;
}
.k-picker-wrap .k-input {
  margin: 0;
}
.k-time-popup .k-item {
  padding: 1px 3px;
}
/* inputs */
.k-input {
  padding: 0.25em 0;
}
.k-input,
.k-textbox > input {
  outline: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-textbox {
  outline: 0;
}
input.k-textbox,
textarea.k-textbox {
  padding: 2px .3em;
}
input.k-textbox {
  height: 2.694em;
  text-indent: 0.8em;
  line-height: 1.6em;
}
.k-ie input.k-textbox {
  text-indent: 0.4em;
}
.k-ff input.k-textbox {
  height: 2.17em;
}
textarea.k-textbox {
  height: auto;
}
/* NumericTextBox */
span.k-numerictextbox {
  background-color: transparent;
}
.k-numerictextbox .k-input {
  margin: 0;
}
.k-numerictextbox .k-link {
  display: block;
  height: 1em;
  line-height: 1em;
  vertical-align: middle;
  border-width: 0;
  padding: 0;
  overflow: hidden;
}
.k-numerictextbox .k-icon {
  display: block;
  margin: auto;
  height: 100%;
}
.k-numerictextbox .k-i-arrow-60-up {
  top: 2px;
}
.k-numerictextbox .k-i-arrow-60-down {
  bottom: 2px;
}
.k-numeric-wrap .k-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
/* ColorPicker */
.k-colorpicker .k-picker-wrap {
  line-height: 2em;
}
.k-colorpicker .k-selected-color {
  vertical-align: top;
  display: inline-block;
  height: 2em;
  width: 2em;
}
.k-colorpicker .k-selected-color .k-i-line {
  font-size: 2em;
  color: #FF525E;
  display: inline-block;
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}
.k-colorpicker .k-selected-color .k-i-line:before {
  content: "\e510";
}
.k-colorpicker .k-tool-icon {
  position: relative;
  top: -2px;
  display: inline-block;
  padding: 3px 3px 2px;
  margin-right: 3px;
  margin-left: 2px;
  margin-bottom: 3px;
  background-repeat: no-repeat;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  -ms-high-contrast-adjust: none;
}
.k-colorpicker .k-tool-icon .k-selected-color {
  display: block;
  height: 3px;
  width: 16px;
  position: absolute;
  left: 3px;
  bottom: -3px;
  border-radius: 0 !important;
}
.k-colorpicker .k-select {
  cursor: pointer;
}
.k-colorpicker .k-picker-wrap .k-icon {
  overflow: visible;
}
.k-disabled-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.k-colorpalette {
  position: relative;
  line-height: 0;
  border-width: 0;
  display: inline-block;
}
.k-colorpalette .k-palette {
  border-collapse: collapse;
  position: relative;
  width: 100%;
  height: 100%;
}
.k-colorpalette .k-item {
  width: 14px;
  height: 14px;
  overflow: hidden;
  -ms-high-contrast-adjust: none;
}
.k-colorpalette .k-item.k-state-selected,
.k-colorpalette .k-item.k-state-selected:hover {
  z-index: 100;
  background: transparent;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.7), inset 0 0 0 1px rgba(255, 255, 255, 0.45);
  position: relative;
}
.k-colorpalette .k-item:hover {
  z-index: 101;
  position: relative;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.5), inset 0 0 0 1px rgba(255, 255, 255, 0.3);
}
.k-flatcolorpicker {
  position: relative;
  display: inline-block;
  width: 265px;
  padding-bottom: 5px;
  background-color: transparent;
  background-image: none;
}
.k-flatcolorpicker .k-selected-color {
  background-image: url("../textures/transtexture.png");
  background-position: 50% 50%;
  text-align: right;
}
.k-flatcolorpicker .k-selected-color input.k-color-value {
  font-family: Consolas, "Ubuntu Mono", "Lucida Console", "Courier New", monospace;
  padding: .75em .1em .65em .65em;
  border: 0;
  margin: 0;
  width: 82%;
}
.k-flatcolorpicker .k-selected-color .k-color-input {
  background-color: #fff;
  display: inline-block;
  width: 84%;
  text-align: left;
}
.k-flatcolorpicker .k-selected-color .k-clear-color {
  padding: .4em;
  line-height: 0;
}
.k-flatcolorpicker .k-selected-color .k-clear-color .k-i-reset-color:before {
  color: #000;
  opacity: 0.8;
}
.k-flatcolorpicker .k-selected-color .k-clear-color .k-i-reset-color:hover,
.k-flatcolorpicker .k-selected-color .k-clear-color .k-i-reset-color:active {
  opacity: 1;
}
.k-flatcolorpicker .k-clear-color-container .k-clear-color.k-state-focused:active {
  color: inherit;
}
.k-flatcolorpicker .k-clear-color-container .k-clear-color:active {
  color: #000;
  opacity: 1;
}
.k-flatcolorpicker .k-selected-color .k-clear-color .k-i-reset-color,
.k-flatcolorpicker .k-clear-color-container .k-clear-color .k-i-reset-color {
  background-image: none;
}
.k-flatcolorpicker .k-clear-color-container {
  text-align: left;
}
.k-flatcolorpicker .k-hsv-rectangle {
  position: relative;
  user-select: none;
  -ms-touch-action: pinch-zoom double-tap-zoom;
}
.k-flatcolorpicker .k-hsv-rectangle .k-draghandle {
  cursor: pointer;
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 50%;
  width: 8px;
  height: 8px;
  border: 1px solid #eee;
  margin-left: -5px;
  margin-top: -5px;
  border-radius: 6px;
  box-shadow: 0 1px 2px #444;
  background: transparent;
}
.k-flatcolorpicker .k-hsv-rectangle .k-draghandle:hover,
.k-flatcolorpicker .k-hsv-rectangle .k-draghandle:focus {
  background: transparent;
  border-color: #fff;
  box-shadow: 0 1px 5px #000;
}
.k-flatcolorpicker .k-hsv-rectangle.k-dragging,
.k-flatcolorpicker .k-hsv-rectangle.k-dragging * {
  cursor: none;
}
.k-flatcolorpicker .k-slider-horizontal {
  height: 20px;
  width: 90%;
  margin: 0 5%;
}
.k-flatcolorpicker .k-slider-horizontal .k-slider-track {
  box-shadow: 0 1px 0 #fff, 0 -1px 0 #999;
}
.k-flatcolorpicker .k-hue-slider,
.k-flatcolorpicker .k-transparency-slider {
  display: block;
}
.k-flatcolorpicker .k-hue-slider .k-slider-selection,
.k-flatcolorpicker .k-transparency-slider .k-slider-selection {
  background: transparent;
}
.k-flatcolorpicker .k-hue-slider .k-draghandle,
.k-flatcolorpicker .k-transparency-slider .k-draghandle {
  background: transparent;
  border: 3px solid #eee;
  margin-top: 1px;
  height: 8px;
  width: 8px;
  box-shadow: 0 1px 4px #444;
}
.k-flatcolorpicker .k-hue-slider .k-draghandle:hover,
.k-flatcolorpicker .k-transparency-slider .k-draghandle:hover,
.k-flatcolorpicker .k-hue-slider .k-draghandle:focus,
.k-flatcolorpicker .k-transparency-slider .k-draghandle:focus {
  background: transparent;
  border-color: #fff;
  box-shadow: 0 1px 5px #000;
  border-width: 2px;
  padding: 1px;
}
.k-flatcolorpicker .k-hue-slider .k-slider-track {
  background: linear-gradient(to right, #ff0000 0%, #ffff00 16%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 84%, #ff0004 100%);
}
.k-flatcolorpicker .k-transparency-slider .k-slider-track {
  background-image: url("../textures/transparency.png");
  background-size: 100% auto;
  background-position: 100% 50%;
  background-repeat: no-repeat;
}
.k-flatcolorpicker .k-controls {
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: center;
  font-size: 90%;
}
.k-flatcolorpicker .k-controls .k-button {
  width: 6em;
}
.k-flatcolorpicker .k-hsv-gradient {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000000 100%), linear-gradient(to right, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  height: 180px;
  margin-bottom: 5px;
}
.k-ie9 .k-flatcolorpicker .k-hue-slider .k-slider-track {
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmMDAwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjE2JSIgc3RvcC1jb2xvcj0iI2ZmZmYwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjMzJSIgc3RvcC1jb2xvcj0iIzAwZmYwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iIzAwZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjY3JSIgc3RvcC1jb2xvcj0iIzAwMDBmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9Ijg0JSIgc3RvcC1jb2xvcj0iI2ZmMDBmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZjAwMDQiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
}
.k-ie9 .k-flatcolorpicker .k-hsv-gradient {
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+), url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMCIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
}
.k-rtl .k-multiselect-wrap {
  padding-right: 0;
  padding-left: 22px;
}
.k-maskedtextbox {
  position: relative;
  display: inline-block;
  background-color: transparent;
  border-width: 0;
}
.k-maskedtextbox ::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
.k-maskedtextbox .k-i-warning {
  display: none;
  position: absolute;
  width: 1.9em;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.k-maskedtextbox.k-state-invalid .k-i-warning {
  display: inline-block;
}
.k-dateinput {
  position: relative;
  display: inline-block;
  border-width: 0;
}
.k-dateinput.k-widget {
  background-color: transparent;
}
.k-dateinput ::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
.k-dateinput .k-i-warning {
  display: none;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  overflow: visible;
}
.k-dateinput.k-state-invalid .k-i-warning {
  display: inline-block;
}
.k-datepicker .k-picker-wrap .k-i-warning,
.k-timepicker .k-picker-wrap .k-i-warning,
.k-datetimepicker .k-picker-wrap .k-i-warning {
  display: none;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  overflow: visible;
}
.k-datepicker .k-picker-wrap.k-state-invalid .k-i-warning,
.k-timepicker .k-picker-wrap.k-state-invalid .k-i-warning,
.k-datetimepicker .k-picker-wrap.k-state-invalid .k-i-warning {
  display: inline-block;
}
.k-listbox {
  box-sizing: border-box;
  height: 200px;
}
.k-listbox.k-widget {
  background-color: transparent;
}
.k-listbox .k-list-scroller {
  height: 100%;
  overflow: auto;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
}
.k-listbox .k-list-scroller li {
  cursor: default;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}
.k-listbox .k-listbox-toolbar {
  box-sizing: border-box;
}
.k-listbox.k-listbox-toolbar-top {
  padding: 15px 0 1.42857143em;
}
.k-listbox.k-listbox-toolbar-top .k-listbox-toolbar {
  margin-top: -15px;
  margin-bottom: 5px;
}
.k-listbox.k-listbox-toolbar-top .k-listbox-toolbar li {
  display: inline-block;
}
.k-listbox.k-listbox-toolbar-top .k-listbox-toolbar li + li {
  margin-left: 5px;
}
.k-listbox.k-listbox-toolbar-left .k-listbox-toolbar {
  float: left;
  margin-right: 5px;
}
.k-listbox.k-listbox-toolbar-left .k-listbox-toolbar li + li {
  margin-top: 5px;
}
.k-listbox.k-listbox-toolbar-right .k-listbox-toolbar {
  float: right;
  margin-left: 5px;
}
.k-listbox.k-listbox-toolbar-right .k-listbox-toolbar li:not(:last-child) {
  margin-bottom: 5px;
}
.k-listbox.k-listbox-toolbar-bottom {
  padding: 15px 0 1.45em;
}
.k-listbox.k-listbox-toolbar-bottom .k-list-scroller {
  margin-top: -15px;
}
.k-listbox.k-listbox-toolbar-bottom .k-listbox-toolbar {
  margin-top: 5px;
}
.k-listbox.k-listbox-toolbar-bottom .k-listbox-toolbar li {
  display: inline-block;
}
.k-listbox.k-listbox-toolbar-bottom .k-listbox-toolbar li + li {
  margin-left: 5px;
}
.k-listbox .k-ghost {
  opacity: .5;
}
.k-rtl .k-autocomplete-clearable .k-input,
.k-rtl .k-multiselect-clearable .k-input,
.k-rtl .k-combobox-clearable .k-input {
  padding-left: 1.9em;
  padding-right: 0.177em;
}
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Editor */
table.k-editor {
  width: 100%;
  height: 250px;
  table-layout: fixed;
  border-style: solid;
  border-width: 1px;
  border-collapse: separate;
  border-spacing: 4px;
  font-size: 100%;
  vertical-align: top;
  position: relative;
}
.k-editor-inline {
  border-width: 2px;
  padding: .3em .5em;
  word-wrap: break-word;
  overflow: auto;
  position: relative;
}
.k-editortoolbar-dragHandle {
  cursor: move;
  padding-left: 0;
  padding-right: 3px;
  box-shadow: none !important;
}
.k-editor-widget > .k-window-content {
  overflow: hidden;
}
.k-editor .k-editor-toolbar-wrap {
  border: 0;
  padding: 0;
}
.k-editor-toolbar {
  margin: 0;
  padding: .1em 0;
  list-style-type: none;
  line-height: 1.3em;
  cursor: default;
  word-wrap: break-word;
  /* allow tools to wrap properly in IE */
}
.k-editor-toolbar li {
  display: inline-block;
  vertical-align: middle;
}
.k-webkit .k-editor-toolbar,
.k-ff .k-editor-toolbar,
.k-ie9 .k-editor-toolbar,
.k-edge .k-editor-toolbar {
  padding: 0;
}
.k-webkit .k-editor-toolbar li,
.k-safari .k-editor-toolbar li,
.k-ff .k-editor-toolbar li,
.k-ie9 .k-editor-toolbar li,
.k-ie10 .k-editor-toolbar li,
.k-edge .k-editor-toolbar li {
  display: inline-block;
  padding: .1em 0;
}
.k-editor-toolbar .k-editor-widget,
.k-editor-toolbar > li {
  margin-right: 6px;
}
.k-group-start.k-group-end .k-editor-widget {
  margin-right: 0;
}
.k-editor-toolbar .k-editor-dropdown {
  position: relative;
}
.k-select-overlay {
  -webkit-appearance: none;
  opacity: 0;
  z-index: 11000;
  top: 0;
  left: 0;
  position: absolute;
  height: 26px;
  width: 100%;
  margin: -4px 0 0;
}
.k-editor-toolbar .k-separator {
  position: relative;
  top: 1px;
  border-style: solid;
  border-width: 0 1px 0 0;
  margin: 0 .3em 0 .1em;
  padding: 0 0 0 1px;
  font-size: 1.3em;
}
.k-editor-toolbar .k-break {
  display: block;
  height: 1px;
  font-size: 0;
  line-height: 0;
}
.k-editor-toolbar .k-dropdown,
.k-editor-toolbar .k-combobox,
.k-editor-toolbar .k-selectbox,
.k-editor-toolbar .k-colorpicker {
  vertical-align: middle;
}
.k-button-group {
  white-space: nowrap;
}
.k-button-group .k-tool {
  display: inline-block;
  vertical-align: middle;
  margin: 1px 0;
  width: 36px;
  height: 36px;
  line-height: 36px;
}
.k-button-group .k-tool-icon {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  -ms-high-contrast-adjust: none;
}
.k-editor-toolbar .k-tool-icon.k-icon {
  top: auto;
  font-size: 16px;
}
/* default tool widths */
.k-fontName {
  width: 110px;
}
.k-fontSize {
  width: 124px;
}
.k-formatBlock {
  width: 147px;
}
.k-editortoolbar-dragHandle {
  float: left;
  margin: 1px 0 0;
}
.k-editor-toolbar .k-button-group {
  padding: 1px;
}
.k-editor .k-editor-toolbar .k-row-break {
  display: block;
  height: 0;
  font-size: 0;
  line-height: 0;
}
.k-editor-toolbar .k-i-login {
  transform: scaleX(-1);
}
.k-button-group .k-tool {
  border-style: solid;
  border-width: 1px;
  margin-right: -1px;
}
.k-button-group .k-tool.k-state-hover,
.k-button-group .k-tool:focus {
  position: relative;
  z-index: 1;
}
.k-rtl .k-button-group .k-tool {
  border-style: solid;
  border-width: 1px;
}
.k-button-group .k-tool.k-group-end {
  border-right-width: 1px;
}
.k-rtl .k-button-group .k-tool.k-group-end {
  border-left-width: 1px;
}
.k-button-group .k-state-disabled {
  display: none;
}
.k-button-group .k-state-hover,
.k-button-group .k-state-active {
  vertical-align: middle;
}
.k-button-group .k-state-disabled {
  filter: alpha(opacity=30);
  opacity: .3;
}
.k-editor .k-editable-area {
  width: 100%;
  height: 100%;
  outline: 0;
}
.k-editor.k-resizable .k-editable-area {
  padding-bottom: 16px;
}
.k-editor .k-content {
  display: block;
  width: 100%;
  height: 100%;
  border-width: 0;
  margin: 0;
  padding: 0;
  background: #fff;
}
.k-editor .k-editable-area .k-content {
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
}
a.k-tool {
  color: inherit;
  outline: 0;
}
.k-editor iframe.k-content {
  display: inline;
  vertical-align: top;
  /*fixes missing top border caused by the inline display*/
}
.k-editor .k-raw-content {
  border: 0;
  margin: 0;
  padding: 0;
}
.k-editor .k-raw-content,
.k-editor-dialog .k-editor-textarea {
  font-size: inherit;
  font-family: consolas, "courier new", monospace;
}
.k-editor-dialog {
  padding: 1em;
  width: 400px;
}
.k-editor-dialog .k-edit-label {
  width: 25%;
}
.k-editor-dialog .k-edit-field {
  width: 66%;
}
.k-editor-dialog .k-edit-field .k-textbox {
  width: 96%;
}
.k-viewhtml-dialog {
  width: auto;
}
.k-filebrowser-dialog {
  width: auto;
  min-width: 350px;
}
.k-filebrowser-dialog .k-filebrowser {
  margin: 0 1em 0;
}
.k-filebrowser-dialog .k-edit-label {
  width: 18%;
}
.k-filebrowser-dialog .k-edit-field {
  width: 75%;
}
.k-filebrowser-dialog .k-edit-field .k-textbox {
  width: 70%;
}
#k-editor-image-width,
#k-editor-image-height {
  width: 5em;
}
.k-editor-dialog .k-button {
  display: inline-block;
}
.k-editor-dialog .k-editor-textarea {
  width: 600px;
  height: 350px;
  padding: .2em .2em .2em .4em;
  border-width: 1px;
  border-style: solid;
  overflow: auto;
}
.k-button-wrapper .k-link:hover {
  text-decoration: underline;
}
.k-ct-popup {
  width: 180.4px;
  padding: .65em .5em .5em;
}
.k-ct-popup .k-status {
  margin: .3em 0;
}
.k-ct-cell {
  border-width: 1px;
  border-style: solid;
  width: 18px;
  height: 18px;
  margin: 1px;
  vertical-align: top;
  display: inline-block;
  overflow: hidden;
  -ms-high-contrast-adjust: none;
}
.k-editor .k-resize-handle {
  position: absolute;
  padding: 2px 0;
  right: 0;
  bottom: 0;
  cursor: se-resize;
}
.k-editor .k-overlay {
  position: absolute;
  background-color: #fff;
  opacity: 0;
}
.k-toolbar-resizable {
  min-height: 2.4375em;
  position: relative;
}
.k-overflow-tools {
  position: absolute;
  right: 0;
  top: 0;
}
.k-editor-overflow-popup .k-tool {
  width: auto;
  height: auto;
  display: block;
  text-align: left;
  text-decoration: initial;
  border: 0;
  padding-right: 0.5em;
  margin: 0;
  padding: 0 6px;
}
.k-editor-overflow-popup .k-tool.k-state-disabled {
  display: none;
}
.k-editor-toolbar .k-tool-text {
  display: none;
}
.k-editor-inline .k-table-resize-handle-wrapper {
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: #fff;
  border: 1px solid #000;
  z-index: 100;
}
.k-editor-inline .k-table-resize-handle-wrapper .k-table-resize-handle {
  width: 100%;
  height: 100%;
}
.k-editor-inline .k-table-resize-handle-wrapper .k-table-resize-handle.k-resize-east {
  cursor: e-resize;
}
.k-editor-inline .k-table-resize-handle-wrapper .k-table-resize-handle.k-resize-north {
  cursor: n-resize;
}
.k-editor-inline .k-table-resize-handle-wrapper .k-table-resize-handle.k-resize-northeast {
  cursor: ne-resize;
}
.k-editor-inline .k-table-resize-handle-wrapper .k-table-resize-handle.k-resize-northwest {
  cursor: nw-resize;
}
.k-editor-inline .k-table-resize-handle-wrapper .k-table-resize-handle.k-resize-south {
  cursor: s-resize;
}
.k-editor-inline .k-table-resize-handle-wrapper .k-table-resize-handle.k-resize-southeast {
  cursor: se-resize;
}
.k-editor-inline .k-table-resize-handle-wrapper .k-table-resize-handle.k-resize-southwest {
  cursor: sw-resize;
}
.k-editor-inline .k-table-resize-handle-wrapper .k-table-resize-handle.k-resize-west {
  cursor: w-resize;
}
.k-editor-inline .k-column-resize-handle-wrapper {
  position: absolute;
  height: 10px;
  width: 10px;
  cursor: col-resize;
  z-index: 2;
}
.k-editor-inline .k-column-resize-handle-wrapper .k-column-resize-handle {
  width: 100%;
  height: 100%;
}
.k-editor-inline .k-column-resize-handle-wrapper .k-column-resize-handle .k-column-resize-marker {
  width: 2px;
  height: 100%;
  margin: 0 auto;
  background-color: #00b0ff;
  display: none;
  opacity: 0.8;
}
.k-table.k-table-resizing {
  opacity: 0.6;
}
.k-editor-inline .k-row-resize-handle-wrapper {
  position: absolute;
  z-index: 2;
  cursor: row-resize;
  width: 10px;
  height: 10px;
}
.k-editor-inline .k-row-resize-handle-wrapper .k-row-resize-handle {
  display: table;
  width: 100%;
  height: 100%;
}
.k-editor-inline .k-row-resize-handle-wrapper .k-row-resize-marker-wrapper {
  display: table-cell;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}
.k-editor-inline .k-row-resize-handle-wrapper .k-row-resize-marker {
  display: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 2px;
  background-color: #00b0ff;
  opacity: 0.8;
}
.k-editor-dialog.k-viewhtml-dialog .k-edit-buttons {
  clear: both;
  text-align: right;
  border-width: 1px 0 0;
  border-style: solid;
  position: relative;
  bottom: -1em;
  padding: .6em;
}
.k-editor-dialog.k-viewhtml-dialog .k-edit-buttons .k-button {
  margin-right: 0.5em;
}
.k-editor-dialog.k-editor-table-wizard-dialog.k-window-content {
  overflow: hidden;
  min-width: 325px;
  width: 24em;
  padding: 1em 0;
}
.k-editor-dialog .k-edit-form-container {
  width: auto;
}
.k-editor-dialog .k-tabstrip {
  margin: -1.333em 0 0;
}
.k-editor-dialog .k-root-tabs {
  margin: -1em -1em -1.5em;
  padding: 0.5em 1em 0;
  border-width: 0;
}
.k-editor-dialog ul.k-tabstrip-items {
  padding: 0.5em 1em 0;
  border-width: 0 0 1px;
  border-style: solid;
}
.k-editor-dialog ul.k-tabstrip-items .k-loading {
  border-top-width: 0;
}
.k-editor-dialog .k-tabstrip:focus {
  box-shadow: none;
}
.k-editor-dialog.k-editor-table-wizard-dialog .k-tabstrip-wrapper .k-content {
  overflow: hidden;
  min-height: 440px;
  height: 35em;
  margin: 0;
  padding: 1em 0.5em 0;
  border-width: 0;
}
.k-editor-dialog .k-edit-field .k-numerictextbox {
  width: 10.6em;
  margin: 0 .35714285em 0 0;
}
.k-editor-dialog.k-editor-table-wizard-dialog .k-edit-field .k-numerictextbox + .k-dropdown {
  width: 4em;
}
.k-editor-dialog.k-editor-table-wizard-dialog .k-edit-field .k-textbox,
.k-editor-dialog.k-editor-table-wizard-dialog .k-edit-field .k-dropdown {
  width: 15em;
}
.k-editor-table-wizard-dialog .k-numerictextbox + .k-dropdown {
  width: 5.35714285em;
}
.k-editor-dialog textarea.k-textbox {
  height: 14.28571428em;
}
.k-editor-dialog.k-editor-table-wizard-dialog .k-dropdown.k-align {
  width: 4em;
}
.k-popup.k-list-container.k-align {
  padding: 0;
  text-align: center;
}
.k-align-group {
  vertical-align: text-top;
}
.k-popup.k-align .k-list .k-item {
  display: inline-block;
  width: 2.57142857em;
  height: 2.57142857em;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0;
}
.k-popup.k-align .k-list .k-icon {
  vertical-align: bottom;
  vertical-align: -webkit-baseline-middle;
}
.k-popup.k-align .k-list .k-item:last-child {
  width: auto;
  display: block;
  border-width: 1px 0 0;
  border-style: solid;
}
.k-popup.k-align .k-list .k-item:before {
  width: 2.57142857em;
  height: 2.57142857em;
  line-height: 2.57142857em;
}
.k-animation-container .k-editor-toolbar {
  text-align: center;
  line-height: 1.3em;
}
.k-animation-container .k-editor-toolbar .k-tool {
  display: inline-block;
  height: 24px;
  padding: 0 0.5em;
  text-decoration: none;
  font-size: 0.85em;
  color: inherit;
}
.k-animation-container .k-editor-toolbar .k-i-table-wizard-insert {
  width: 24px;
  height: 24px;
}
.k-animation-container .k-editor-toolbar .k-tool-text {
  display: inline;
  vertical-align: sub;
  padding: 0 0 0 .5em;
}
.k-editor-dialog.k-editor-table-wizard-dialog .k-edit-buttons {
  padding: 1em 0.8em;
  margin: 0.5em 0 0;
}
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Notification */
.k-notification-wrap {
  padding: .6em .5em;
  cursor: default;
  position: relative;
  white-space: nowrap;
}
.k-notification-button .k-notification-wrap {
  padding-right: 20px;
}
.k-notification-wrap > .k-i-information,
.k-notification-wrap > .k-i-information,
.k-notification-wrap > .k-i-information,
.k-notification-wrap > .k-i-warning,
.k-notification-wrap > .k-i-information {
  vertical-align: text-bottom;
  margin-right: 4px;
}
.k-notification-wrap > .k-i-close {
  position: absolute;
  top: 7px;
  right: 4px;
  display: none;
}
.k-notification-button .k-notification-wrap > .k-i-close {
  display: block;
}
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Progressbar */
.k-progressbar {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}
.k-progressbar {
  border-radius: 4px;
}
.k-progressbar-horizontal {
  width: 27em;
  height: 1.9em;
}
.k-progressbar-vertical {
  width: 1.9em;
  height: 27em;
}
.k-progressbar > .k-state-selected {
  position: absolute;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
}
.k-progressbar-horizontal > .k-state-selected,
.k-rtl .k-progressbar-horizontal.k-progressbar-reverse > .k-state-selected {
  left: -1px;
  right: auto;
  top: -1px;
  height: 100%;
  border-radius: 4px 0 0 4px;
}
.k-progressbar-horizontal.k-progressbar-reverse > .k-state-selected,
.k-rtl .k-progressbar-horizontal > .k-state-selected {
  left: auto;
  right: -1px;
  border-radius: 0 4px 4px 0;
}
.k-progressbar-vertical > .k-state-selected {
  left: -1px;
  bottom: -1px;
  width: 100%;
  border-radius: 0 0 4px 4px;
}
.k-progressbar-vertical.k-progressbar-reverse > .k-state-selected {
  bottom: auto;
  top: -1px;
  border-radius: 4px 4px 0 0;
}
.k-progressbar > .k-state-selected.k-complete,
.k-rtl .k-progressbar > .k-state-selected.k-complete {
  border-radius: 4px;
}
.k-progressbar > .k-reset {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  left: -1px;
  top: -1px;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  white-space: nowrap;
}
.k-progressbar-horizontal .k-item {
  display: inline-block;
  height: 100%;
  border-style: solid;
  margin-left: -1px;
}
.k-progressbar-horizontal .k-item.k-first {
  margin-left: 0;
}
.k-progressbar-horizontal .k-item.k-last {
  border-right-width: 0;
}
.k-progressbar-horizontal .k-item,
.k-rtl .k-progressbar-horizontal.k-progressbar-reverse .k-item {
  border-width: 1px 0 1px 1px;
}
.k-progressbar-horizontal.k-progressbar-reverse .k-item,
.k-rtl .k-progressbar-horizontal .k-item {
  border-width: 1px 0 1px 1px;
}
.k-progressbar-horizontal .k-first,
.k-rtl .k-progressbar-horizontal .k-last,
.k-rtl .k-progressbar-horizontal.k-progressbar-reverse .k-last {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left-width: 1px;
}
.k-progressbar-horizontal .k-last,
.k-rtl .k-progressbar-horizontal .k-first {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.k-progressbar-horizontal.k-progressbar-reverse .k-last,
.k-rtl .k-progressbar-horizontal .k-first {
  border-right-width: 1px;
}
.k-progressbar-horizontal .k-last.k-state-selected {
  border-right-width: 1px;
}
.k-progressbar-vertical .k-item {
  width: 100%;
  border-style: solid;
  border-width: 1px 1px 0 1px;
  margin-top: -1px;
}
.k-progressbar-vertical .k-item.k-first {
  margin-top: 0;
}
.k-progressbar-vertical li.k-item.k-last {
  border-bottom-width: 0;
}
.k-progressbar-vertical .k-first {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.k-progressbar-vertical .k-last {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-width: 1px;
}
.k-progressbar-vertical.k-progressbar-reverse .k-item {
  border-width: 0 1px 1px 1px;
}
.k-progressbar-vertical.k-progressbar-reverse .k-first {
  border-top-width: 1px;
}
.k-progress-status-wrap {
  position: absolute;
  top: -1px;
  border: 1px solid transparent;
  line-height: 2em;
  width: 100%;
  height: 100%;
}
.k-progress-status-wrap,
.k-rtl .k-progressbar-horizontal.k-progressbar-reverse .k-progress-status-wrap {
  left: -1px;
  right: auto;
  text-align: right;
}
.k-progressbar-horizontal.k-progressbar-reverse .k-progress-status-wrap,
.k-rtl .k-progressbar-horizontal .k-progress-status-wrap {
  left: auto;
  right: -1px;
  text-align: left;
}
.k-progressbar-vertical .k-progress-status-wrap {
  top: auto;
  bottom: -1px;
}
.k-progressbar-vertical.k-progressbar-reverse .k-progress-status-wrap {
  bottom: auto;
  top: -1px;
}
.k-progress-status {
  display: inline-block;
  padding: 0 .5em;
  min-width: 10px;
  white-space: nowrap;
}
.k-progressbar-vertical.k-progressbar-reverse .k-progress-status {
  position: absolute;
  bottom: 0;
  left: 0;
}
.k-progressbar-vertical .k-progress-status {
  transform: rotate(-90deg) translateX(-100%);
  transform-origin: 0 0;
}
.k-progressbar-vertical.k-progressbar-reverse .k-progress-status {
  transform: rotate(90deg) translateX(-100%);
  transform-origin: 0 100%;
}
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Slider */
div.k-slider {
  position: relative;
  border-width: 0;
  background-color: transparent;
  user-select: none;
}
.k-slider-vertical {
  width: 26px;
  height: 200px;
  /* default height */
}
.k-slider-horizontal {
  display: inline-block;
  width: 200px;
  /* default width */
  height: 26px;
}
.k-slider-wrap {
  width: 100%;
  height: 100%;
}
.k-slider .k-button,
.k-grid .k-slider .k-button {
  position: absolute;
  top: 0;
  width: 24px;
  min-width: 0;
  height: 24px;
  margin: 0;
  padding: 0;
  outline: 0;
}
.k-state-disabled .k-slider-wrap {
  filter: alpha(opacity=60);
  opacity: .6;
}
.k-state-disabled .k-slider-wrap .k-slider-items {
  color: #333;
}
.k-slider .k-button-decrease {
  left: 0;
}
.k-slider-vertical .k-button-decrease,
.k-grid .k-slider-vertical .k-button-decrease {
  top: auto;
  bottom: 0;
}
.k-slider .k-button-increase {
  right: 0;
}
.k-slider .k-icon,
.k-slider-track,
.k-slider .k-tick {
  cursor: pointer;
}
.k-slider-track,
.k-slider-selection {
  position: absolute;
  margin: 0;
  padding: 0;
}
.k-slider-horizontal .k-slider-track,
.k-slider-horizontal .k-slider-selection {
  top: 50%;
  left: 0;
  height: 8px;
  margin-top: -4px;
  background-repeat: repeat-x;
}
.k-slider-horizontal .k-slider-buttons .k-slider-track {
  left: 34px;
}
.k-slider-vertical .k-slider-track,
.k-slider-vertical .k-slider-selection {
  left: 50%;
  bottom: 0;
  width: 8px;
  margin-left: -4px;
  background-repeat: repeat-y;
}
.k-slider-vertical .k-slider-buttons .k-slider-track {
  bottom: 34px;
}
.k-draghandle {
  position: absolute;
  background-repeat: no-repeat;
  background-color: transparent;
  text-indent: -3333px;
  overflow: hidden;
  text-decoration: none;
  text-align: center;
  outline: 0;
}
.k-slider-horizontal .k-draghandle {
  top: -4px;
  width: 13px;
  height: 14px;
}
.k-slider-vertical .k-draghandle {
  left: -4px;
  width: 14px;
  height: 13px;
}
.k-slider-buttons .k-slider-items {
  margin-left: 34px;
}
.k-slider-horizontal .k-slider-items {
  height: 100%;
}
.k-slider-vertical .k-slider-items {
  padding-top: 1px;
}
.k-slider-vertical .k-slider-buttons .k-slider-items {
  padding-top: 0;
}
.k-slider-vertical .k-slider-buttons .k-slider-items {
  margin: 0;
  padding-top: 35px;
}
.k-slider .k-tick {
  position: relative;
  margin: 0;
  padding: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
}
.k-slider-horizontal .k-tick {
  float: left;
  height: 100%;
  text-align: center;
}
.k-slider-horizontal .k-tick {
  background-position: center -92px;
}
.k-slider-horizontal .k-slider-topleft .k-tick {
  background-position: center -122px;
}
.k-slider-horizontal .k-slider-bottomright .k-tick {
  background-position: center -152px;
}
.k-slider-horizontal .k-tick-large {
  background-position: center -2px;
}
.k-slider-horizontal .k-slider-topleft .k-tick-large {
  background-position: center -32px;
}
.k-slider-horizontal .k-slider-bottomright .k-tick-large {
  background-position: center -62px;
}
.k-slider-vertical .k-tick {
  background-position: -92px center;
}
.k-slider-vertical .k-slider-topleft .k-tick {
  background-position: -122px center;
}
.k-slider-vertical .k-slider-bottomright .k-tick {
  background-position: -152px center;
}
.k-slider-vertical .k-tick-large {
  background-position: -2px center;
}
.k-slider-vertical .k-slider-topleft .k-tick-large {
  background-position: -32px center;
}
.k-slider-vertical .k-slider-bottomright .k-tick-large {
  background-position: -62px center;
}
.k-slider-horizontal .k-first {
  background-position: 0 -92px;
}
.k-slider-horizontal .k-tick-large.k-first {
  background-position: 0 -2px;
}
.k-slider-horizontal .k-slider-topleft .k-first {
  background-position: 0 -122px;
}
.k-slider-horizontal .k-slider-topleft .k-tick-large.k-first {
  background-position: 0 -32px;
}
.k-slider-horizontal .k-slider-bottomright .k-first {
  background-position: 0 -152px;
}
.k-slider-horizontal .k-slider-bottomright .k-tick-large.k-first {
  background-position: 0 -62px;
}
.k-slider-horizontal .k-last {
  background-position: 100% -92px;
}
.k-slider-horizontal .k-tick-large.k-last {
  background-position: 100% -2px;
}
.k-slider-horizontal .k-slider-topleft .k-last {
  background-position: 100% -122px;
}
.k-slider-horizontal .k-slider-topleft .k-tick-large.k-last {
  background-position: 100% -32px;
}
.k-slider-horizontal .k-slider-bottomright .k-last {
  background-position: 100% -152px;
}
.k-slider-horizontal .k-slider-bottomright .k-tick-large.k-last {
  background-position: 100% -62px;
}
.k-slider-vertical .k-first {
  background-position: -92px 100%;
}
.k-slider-vertical .k-tick-large.k-first {
  background-position: -2px 100%;
}
.k-slider-vertical .k-slider-topleft .k-first {
  background-position: -122px 100%;
}
.k-slider-vertical .k-slider-topleft .k-tick-large.k-first {
  background-position: -32px 100%;
}
.k-slider-vertical .k-slider-bottomright .k-first {
  background-position: -152px 100%;
}
.k-slider-vertical .k-slider-bottomright .k-tick-large.k-first {
  background-position: -62px 100%;
}
.k-slider-vertical .k-last {
  background-position: -92px 0;
}
.k-slider-vertical .k-tick-large.k-last {
  background-position: -2px 0;
}
.k-slider-vertical .k-slider-topleft .k-last {
  background-position: -122px 0;
}
.k-slider-vertical .k-slider-topleft .k-tick-large.k-last {
  background-position: -32px 0;
}
.k-slider-vertical .k-slider-bottomright .k-last {
  background-position: -152px 0;
}
.k-slider-vertical .k-slider-bottomright .k-tick-large.k-last {
  background-position: -62px 0;
}
.k-slider-vertical .k-tick {
  text-align: right;
}
.k-slider-vertical .k-slider-topleft .k-tick {
  text-align: left;
}
.k-slider .k-label {
  position: absolute;
  white-space: nowrap;
  font-size: .92em;
}
.k-slider-horizontal .k-label {
  left: 0;
  width: 100%;
  line-height: 1;
}
.k-slider-horizontal .k-first .k-label {
  left: -50%;
}
.k-slider-horizontal .k-last .k-label {
  left: auto;
  right: -50%;
}
.k-slider-horizontal .k-label {
  bottom: -1.2em;
}
.k-slider-horizontal .k-slider-topleft .k-label {
  top: -1.2em;
}
.k-slider-vertical .k-label {
  left: 120%;
  display: block;
  text-align: left;
}
.k-slider-vertical .k-last .k-label {
  top: -0.5em;
}
.k-slider-vertical .k-first .k-label {
  bottom: -0.5em;
}
.k-slider-vertical .k-slider-topleft .k-label {
  left: auto;
  right: 120%;
}
.k-slider-tooltip {
  top: -4444px;
  /*prevent window resize in IE8 when appending*/
}
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Scheduler */
.k-scheduler-toolbar,
.k-scheduler-footer {
  border-style: solid;
}
.k-scheduler-toolbar,
.k-scheduler-footer {
  line-height: 28px;
  padding: 6px;
}
.k-scheduler-toolbar {
  position: relative;
  border-width: 0 0 1px;
}
.k-edit-field.k-scheduler-toolbar {
  border-width: 0;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
.k-scheduler-header {
  text-align: center;
}
.k-scheduler-footer {
  border-width: 1px 0 0;
}
.k-scheduler-toolbar > ul {
  float: right;
}
.k-scheduler-toolbar > ul:first-child {
  float: left;
}
.k-scheduler-toolbar > .k-scheduler-tools {
  float: left;
  margin-bottom: .5em;
}
.k-scheduler-tools + .k-scheduler-navigation {
  float: left;
  clear: left;
}
.k-scheduler-toolbar > ul > li,
.k-scheduler-footer > ul > li {
  display: inline-block;
  border-style: solid;
  border-width: 1px 1px 1px 0;
}
.k-scheduler .k-scheduler-toolbar .k-nav-current,
.k-scheduler .k-scheduler-toolbar .k-scheduler-tools > li {
  border-width: 0;
}
.k-scheduler-toolbar > ul > li:first-child,
.k-scheduler-toolbar > ul.k-scheduler-views > li:first-child + li {
  border-left-width: 1px;
}
.k-scheduler div.k-scheduler-footer ul li {
  margin-right: .6em;
  border-width: 1px;
}
.k-scheduler-toolbar .k-link,
.k-scheduler-footer .k-link {
  display: inline-block;
  padding: 0 1.1em;
}
.k-scheduler-toolbar .k-nav-prev .k-link,
.k-scheduler-toolbar .k-nav-next .k-link {
  padding-left: .6em;
  padding-right: .6em;
}
.k-scheduler-toolbar .k-nav-current .k-link {
  padding: 0;
}
.k-scheduler-toolbar .k-nav-current {
  margin: 0 1.1em;
}
.k-scheduler div.k-scheduler-toolbar > ul > li.k-nav-current,
.k-scheduler .k-nav-current > .k-state-active {
  background: none;
}
.k-scheduler-phone .k-scheduler-toolbar + .k-scheduler-toolbar .k-scheduler-navigation {
  width: 100%;
  text-align: center;
}
.k-scheduler-phone .k-scheduler-toolbar + .k-scheduler-toolbar .k-scheduler-navigation > li {
  background: none;
  border: 0;
}
.k-scheduler-phone .k-toolbar .k-nav-next {
  float: right;
}
.k-scheduler-phone .k-toolbar .k-nav-prev {
  float: left;
}
.k-scheduler-toolbar .k-i-calendar,
.k-scheduler-footer .k-icon {
  margin: -2px 6px 0 0;
}
.k-scheduler-header,
.k-scheduler-header-wrap {
  overflow: hidden;
}
.k-scheduler-header-wrap {
  position: relative;
  border-style: solid;
  border-width: 0;
}
.k-scheduler .k-scrollbar-v .k-scheduler-header-wrap {
  border-right-width: 1px;
}
.k-scheduler-times,
.k-scheduler-content {
  position: relative;
}
.k-scheduler-times {
  overflow: hidden;
  border-style: solid;
  border-width: 0;
}
.k-scheduler-content {
  overflow: auto;
}
.k-scheduler-layout,
.k-scheduler-table {
  border-spacing: 0;
  width: 100%;
  margin: 0;
  border-collapse: separate;
}
.k-scheduler-layout > tbody > tr > td {
  padding: 0;
  vertical-align: top;
}
/* fix smashed second layout column in iPad */
.k-safari .k-scheduler-layout > tbody > tr > td + td {
  width: 100%;
}
.k-scheduler-table {
  table-layout: fixed;
  max-width: none;
}
.k-scheduler-times .k-scheduler-table {
  table-layout: auto;
}
.k-scheduler-monthview .k-scheduler-content .k-scheduler-table {
  height: 100%;
}
.k-scheduler-table td,
.k-scheduler-table th {
  height: 1.5em;
  padding: .334em .5em;
  font-size: 100%;
}
.k-scheduler .k-scheduler-table td,
.k-scheduler .k-scheduler-table th {
  box-sizing: content-box;
}
.k-scheduler-monthview .k-hidden,
.k-scheduler-monthview .k-hidden > div {
  width: 0 !important;
  overflow: hidden !important;
}
.k-scheduler-monthview .k-hidden {
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-right-width: 0 !important;
}
.k-scheduler-monthview > tbody > tr:first-child .k-scheduler-times {
  margin-right: 1px;
}
.k-scheduler-monthview > tbody > tr:first-child .k-scheduler-times .k-hidden {
  height: auto;
}
.k-scheduler-monthview .k-scheduler-table td,
.k-scheduler-monthview .k-hidden {
  height: 80px;
  text-align: right;
}
.k-scheduler-phone .k-scheduler-monthview .k-scheduler-table td,
.k-scheduler-phone .k-scheduler-monthview .k-hidden {
  height: 40px;
}
.k-scheduler-table td,
.k-slot-cell {
  vertical-align: top;
}
/* separate due to old IEs */
.k-scheduler-layout tr + tr .k-scheduler-times th:last-child {
  vertical-align: top;
}
.k-scheduler-phone .k-scheduler-monthview .k-scheduler-table td {
  text-align: center;
  vertical-align: middle;
}
.k-scheduler-phone .k-scheduler-monthview .k-scheduler-table td span {
  font-size: 1.5em;
}
.k-scheduler-header th {
  overflow: hidden;
  text-overflow: ellipsis;
}
.k-scheduler-table td,
.k-scheduler-header th {
  border-style: solid;
  border-width: 0 0 1px 1px;
}
.k-scheduler-table td:first-child,
.k-scheduler-header th:first-child {
  border-left-width: 0;
}
.k-scheduler-agendaview .k-scheduler-table td:first-child {
  border-left-width: 1px;
}
.k-scheduler-agendaview .k-scheduler-table td.k-first {
  border-left-width: 0;
}
.k-scheduler-layout tr + tr .k-scheduler-times tr:last-child > th,
.k-scheduler-layout tr + tr .k-scheduler-table > tbody > tr:last-child > td,
.k-scheduler-table > tbody > tr > .k-last {
  border-bottom-width: 0;
}
.k-scrollbar-h tr + tr .k-scheduler-times,
.k-scrollbar-h .k-scheduler-content .k-scheduler-table > tbody > tr:last-child > td,
.k-scheduler-agendaview.k-scrollbar-h .k-scheduler-table > tbody > tr > td.k-last {
  border-bottom-width: 1px;
}
.k-scheduler-times th {
  text-align: right;
  padding-right: .6em;
  border-style: solid;
  border-width: 0 1px 1px 0;
  border-color: transparent;
  white-space: nowrap;
}
.k-scheduler-layout tr + tr .k-scheduler-times th {
  border-bottom-color: transparent;
}
.k-scheduler-layout tr + tr .k-scheduler-times th.k-slot-cell,
.k-scheduler-layout tr + tr .k-scheduler-times th.k-scheduler-times-all-day {
  border-bottom-color: inherit;
}
.k-scheduler .k-middle-row td {
  border-bottom-style: dotted;
}
.k-scheduler-now-arrow,
.k-scheduler-now-line {
  position: absolute;
}
.k-scheduler-now-arrow {
  width: 0;
  height: 0;
  border: solid 5px transparent;
  left: 0;
}
.k-scheduler-now-line {
  left: 5px;
  right: 0;
  height: 1px;
}
.k-task {
  position: relative;
}
div.k-more-events {
  text-align: center;
  font-size: 18px;
  line-height: 1.2;
  padding: 0;
}
.k-more-events > span {
  display: block;
  margin-top: -0.6em;
}
.k-event,
.k-more-events {
  position: absolute;
  border-style: solid;
  border-width: 1px;
  text-align: left;
  overflow: hidden;
}
.k-event {
  cursor: default;
  min-height: 1.3em;
}
.k-event-drag-hint {
  filter: alpha(opacity=60);
  opacity: .6;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}
.k-scheduler-header .k-event {
  white-space: nowrap;
}
.k-event-template {
  padding: .3em 1.4em .3em .6em;
}
.k-event-time {
  display: none;
  padding-bottom: 0;
  font-size: .9em;
}
.k-event-drag-hint .k-event-time {
  display: block;
}
.k-event-actions,
.k-event > .k-link,
.k-task > .k-link {
  position: absolute;
  top: 3px;
  right: 4px;
  white-space: nowrap;
}
.k-event-actions {
  z-index: 1;
}
.k-scheduler-agendaview .k-task > .k-link {
  top: 0;
  right: 0;
}
.k-event-actions:first-child {
  position: static;
  float: left;
  margin: 4px 2px 0 4px;
}
.k-webkit .k-event-actions:first-child {
  margin-top: 3px;
}
.k-event-actions:first-child > .k-link {
  display: inline-block;
}
.k-event-delete {
  display: none;
}
.k-event:hover .k-event-delete,
tr:hover > td > .k-task .k-event-delete {
  display: inline-block;
}
.k-event .k-event-top-actions,
.k-event .k-event-bottom-actions {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
}
.k-event .k-event-bottom-actions {
  top: auto;
  bottom: 0;
}
.k-event .k-resize-handle,
.k-scheduler-mobile .k-event:hover .k-resize-handle {
  position: absolute;
  visibility: hidden;
  z-index: 2;
}
.k-event:hover .k-resize-handle,
.k-event-active .k-resize-handle,
.k-scheduler-mobile .k-event-active:hover .k-resize-handle {
  visibility: visible;
}
.k-event .k-resize-handle:after {
  content: "";
  position: absolute;
  filter: alpha(opacity=50);
  opacity: .5;
}
.k-scheduler-mobile .k-event .k-resize-handle:after {
  filter: none;
  opacity: 1;
}
.k-event > .k-resize-n {
  top: 0;
  left: 0;
  right: 0;
  height: .4em;
}
.k-event > .k-resize-s {
  bottom: 0;
  left: 0;
  right: 0;
  height: .4em;
}
.k-event > .k-resize-e {
  right: 0;
  top: 0;
  bottom: 0;
  width: .4em;
}
.k-event > .k-resize-w {
  left: 0;
  top: 0;
  bottom: 0;
  width: .4em;
}
.k-event > .k-resize-n:after,
.k-event > .k-resize-s:after {
  top: 1px;
  left: 50%;
  margin-left: -1em;
  width: 2em;
  height: 1px;
}
.k-event > .k-resize-s:after {
  top: auto;
  bottom: 1px;
}
.k-event > .k-resize-e:after,
.k-event > .k-resize-w:after {
  left: 1px;
  top: 50%;
  margin-top: -0.7em;
  height: 1.4em;
  width: 1px;
}
.k-event > .k-resize-e:after {
  left: auto;
  right: 1px;
}
.k-scheduler-mobile .k-event > .k-resize-n,
.k-scheduler-mobile .k-event > .k-resize-s {
  height: .6em;
}
.k-scheduler-mobile .k-event > .k-resize-e,
.k-scheduler-mobile .k-event > .k-resize-w {
  width: .6em;
}
.k-scheduler-mobile .k-event > .k-resize-n:after,
.k-scheduler-mobile .k-event > .k-resize-s:after {
  top: 0;
  margin-left: -3em;
  width: 4em;
  height: .6em;
}
.k-scheduler-mobile .k-event > .k-resize-s:after {
  bottom: 0;
}
.k-scheduler-mobile .k-event > .k-resize-e:after,
.k-scheduler-mobile .k-event > .k-resize-w:after {
  left: 0;
  margin-top: -0.7em;
  height: 1.4em;
  width: .6em;
}
.k-scheduler-mobile .k-event > .k-resize-e:after {
  right: 0;
}
.k-scheduler-mobile .k-event > .k-resize-n:after {
  border-radius: 0 0 4px 4px;
}
.k-scheduler-mobile .k-event > .k-resize-s:after {
  border-radius: 4px 4px 0 0;
}
.k-scheduler-mobile .k-event > .k-resize-w:after {
  border-radius: 0 4px 4px 0;
}
.k-scheduler-mobile .k-event > .k-resize-e:after {
  border-radius: 4px 0 0 4px;
}
.k-scheduler-phone .k-scheduler-monthview .k-events-container {
  position: absolute;
  text-align: center;
  height: 6px;
  line-height: 6px;
}
.k-scheduler-phone .k-scheduler-monthview .k-event {
  position: static;
  display: inline-block;
  width: 4px;
  height: 4px;
  min-height: 0;
  margin: 1px;
}
.k-scheduler-marquee {
  border-style: solid;
  border-width: 0;
}
.k-scheduler-marquee.k-first:before,
.k-scheduler-marquee.k-last:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 3px;
}
div.k-scheduler-marquee:before {
  top: 0;
  left: 0;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
div.k-scheduler-marquee:after {
  bottom: 0;
  right: 0;
  border-top-color: transparent;
  border-left-color: transparent;
}
.k-scheduler-marquee .k-label-top {
  position: absolute;
  top: .3em;
  left: .8em;
  font-size: .8em;
}
.k-scheduler-marquee .k-label-bottom {
  position: absolute;
  bottom: .3em;
  right: .81em;
  font-size: .8em;
}
.k-scheduler-quickedit .k-textbox {
  width: 200px;
}
.k-tooltip-bottom {
  text-align: left;
}
.k-tooltip-bottom .k-button {
  float: left;
  margin-right: .3em;
}
.k-tooltip-bottom .k-quickedit-details {
  float: right;
  margin-right: 0;
}
.k-scheduler-agendaview .k-scheduler-table th,
.k-scheduler-agendaview .k-scheduler-table td {
  text-align: left;
}
.k-scheduler-times .k-slot-cell,
.k-scheduler-groupcolumn {
  width: 6em;
}
.k-scheduler-datecolumn {
  width: 12em;
}
.k-scheduler-timecolumn {
  width: 11em;
}
.k-scheduler-timecolumn > div {
  position: relative;
  vertical-align: top;
}
.k-webkit .k-scheduler-timecolumn > div > .k-icon {
  vertical-align: top;
}
.k-scheduler-timecolumn > div > .k-i-arrow-60-right {
  position: absolute;
  right: -4px;
}
.k-scheduler-timecolumn .k-i-arrow-60-left {
  margin-left: -4px;
}
.k-scheduler-mark {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  margin-right: .5em;
}
.k-scheduler-agendaday {
  float: left;
  margin: 0 .2em 0 0;
  font-size: 3em;
  font-weight: normal;
}
.k-scheduler-agendaweek {
  display: block;
  margin: .4em 0 0;
  font-size: 1.1em;
  font-style: normal;
}
.k-scheduler-agendadate {
  font-size: .8em;
}
.k-scheduler-timecolumn {
  white-space: nowrap;
}
.k-scheduler-edit-form .k-edit-form-container,
.k-scheduler-timezones .k-edit-form-container {
  width: 520px;
}
.k-scheduler-edit-form .k-edit-label {
  width: 17%;
}
.k-scheduler-edit-form .k-edit-field {
  width: 77%;
}
.k-scheduler-edit-form .k-textbox[name="title"],
.k-scheduler-edit-form textarea.k-textbox {
  width: 100%;
}
.k-scheduler-edit-form textarea.k-textbox {
  min-height: 4em;
  resize: vertical;
}
.k-scheduler-edit-form > .k-edit-box:first-child .k-datetimepicker {
  margin-right: 1em;
}
.km-pane-wrapper .k-scheduler-edit-form .k-edit-buttons {
  clear: right;
  margin-right: 2%;
  margin-left: 2%;
  padding: 0 0 .6em;
}
.k-edit-box {
  float: left;
}
.k-edit-box + .k-edit-box {
  float: right;
}
.k-scheduler-edit-form label + input {
  margin-left: 1em;
}
.k-edit-field > ul.k-reset > li {
  margin: .2em 0 .4em;
  line-height: 2.4;
}
.k-edit-field > ul.k-reset.k-toolbar > li {
  margin: 0;
}
.k-edit-field > ul.k-reset .k-widget {
  margin-left: .8em;
}
.k-edit-field > ul.k-reset .k-numerictextbox,
.k-edit-field span.k-recur-interval {
  width: 5em;
}
.k-edit-field > ul.k-reset .k-dropdown,
.k-edit-field > ul.k-reset .k-datepicker,
div[name="recurrenceRule"] > .k-dropdown {
  width: 9em;
}
.k-scheduler-edit-form .k-edit-buttons .k-scheduler-delete {
  float: left;
}
.k-popup-message {
  margin: 0;
  padding: 1em 0 2em;
  text-align: center;
}
.k-scheduler-timezones .k-dropdown:first-child {
  width: 100%;
}
.k-scheduler-timezones .k-dropdown + .k-dropdown {
  margin: .5em 0 .7em;
}
.k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view {
  display: none;
}
.k-scheduler-toolbar .k-nav-current .k-lg-date-format,
.k-scheduler-toolbar .k-nav-current .k-sm-date-format {
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.k-scheduler-toolbar .k-nav-current .k-lg-date-format {
  display: inline-block;
}
.k-scheduler-toolbar .k-nav-current .k-sm-date-format {
  display: none;
}
.k-scheduler-refresh {
  float: right;
  padding: 0 .5em;
}
/* Responsive styles  */
@media only screen and (max-width: 1024px) {
  .k-scheduler-toolbar > ul.k-scheduler-views {
    position: absolute;
    right: 6px;
    top: 6px;
    z-index: 10000;
  }
  .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views {
    right: auto;
    left: 6px;
  }
  .k-scheduler-toolbar > ul.k-scheduler-views > li:not(.k-current-view) {
    display: none;
  }
  .k-scheduler-toolbar li.k-nav-current .k-lg-date-format {
    display: none;
  }
  .k-scheduler-toolbar li.k-nav-current .k-sm-date-format {
    display: inline-block;
  }
  .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view {
    display: block;
    border-width: 1px;
  }
  .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view {
    text-align: left;
    padding-left: 1em;
  }
  .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link {
    display: block;
    position: relative;
    padding-right: 2.5em;
    padding-left: 1em;
  }
  .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link {
    padding-left: 0;
  }
  .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link:after {
    content: "\E006";
    position: absolute;
    top: 50%;
    right: 0.6em;
    margin-top: -0.5em;
    line-height: 1em;
  }
  .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li,
  .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li:first-child + li {
    display: block;
    border: 0;
    border-radius: 0;
  }
  .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded {
    border: 1px solid #c5c5c5;
    background-color: #fff;
    background-image: none;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
  }
  .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded {
    text-align: left;
  }
}
@media only screen and (max-width: 480px) {
  .k-scheduler:not(.k-scheduler-mobile) .k-scheduler-toolbar li.k-nav-current .k-sm-date-format {
    display: none;
  }
  .k-scheduler-timecolumn {
    width: 5em;
  }
  .k-scheduler-datecolumn {
    width: 6em;
    overflow: hidden;
  }
  .k-scheduler-timecolumn > div,
  .k-scheduler-datecolumn > div {
    white-space: normal;
  }
}
/* Remove scrollbars during PDF export */
.k-scheduler-pdf-export {
  overflow: hidden;
}
.k-pdf-export-shadow .k-scheduler,
.k-scheduler-pdf-export .k-scheduler-content,
.k-scheduler-pdf-export .k-scheduler-times {
  height: auto !important;
  overflow: visible !important;
}
.k-scheduler-pdf-export .k-scheduler-header {
  padding: 0 !important;
}
.k-scheduler-pdf-export .k-scheduler-header-wrap {
  border-width: 0 !important;
}
.k-scheduler-pdf-export .k-scheduler-header .k-scheduler-table,
.k-scheduler-pdf-export .k-scheduler-content .k-scheduler-table {
  width: 100% !important;
}
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Tooltip */
.k-tooltip {
  position: absolute;
  z-index: 12000;
  border-style: solid;
  border-width: 0;
  padding: 5px 5px 5px 6px;
  background-repeat: repeat-x;
  min-width: 20px;
  /*slider tooltip only*/
  text-align: center;
  /*slider tooltip only*/
}
.k-tooltip-button {
  text-align: right;
  height: 0;
}
.k-tooltip-content {
  height: 100%;
}
.k-tooltip-closable .k-tooltip-content {
  padding-right: 20px;
}
span.k-tooltip {
  position: static;
  display: inline-block;
  border-width: 1px;
  padding: 2px 5px 1px 6px;
}
.k-invalid-msg {
  display: none;
}
.k-callout {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px;
  border-color: transparent;
  pointer-events: none;
}
.k-callout-n {
  top: -12px;
  left: 50%;
  pointer-events: none;
}
.k-callout-w {
  top: 50%;
  left: -12px;
  pointer-events: none;
}
.k-callout-s {
  left: 50%;
  bottom: -12px;
  pointer-events: none;
}
.k-callout-e {
  top: 50%;
  right: -12px;
  pointer-events: none;
}
.k-slider-tooltip .k-callout-n,
.k-slider-tooltip .k-callout-s {
  margin-left: -6px;
}
.k-slider-tooltip .k-callout-w,
.k-slider-tooltip .k-callout-e {
  margin-top: -6px;
}
.k-tooltip-validation .k-i-warning {
  vertical-align: text-top;
  margin-right: 3px;
}
.k-tooltip-validation {
  z-index: 9999;
}
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Toolbar */
.k-toolbar {
  position: relative;
  display: block;
  vertical-align: middle;
  line-height: 2.9em;
}
.k-toolbar .k-button .k-sprite,
.k-overflow-container .k-button .k-sprite {
  vertical-align: middle;
  margin-top: -7px;
  margin-bottom: -5px;
}
.k-toolbar .k-input {
  line-height: inherit;
  height: inherit;
  padding-top: 2px;
  padding-bottom: 2px;
}
.k-toolbar .k-input:before {
  content: "\a0";
  display: inline-block;
  width: 0;
}
.k-ie .k-toolbar .k-input {
  height: 1.65em;
}
.k-toolbar .k-combobox .k-dropdown-wrap:before,
.k-toolbar .k-picker-wrap:before,
.k-toolbar .k-numeric-wrap:before {
  display: none;
}
.k-overflow-container .k-sprite {
  margin-left: -4px;
}
.k-toolbar-resizable {
  overflow: hidden;
  white-space: nowrap;
}
.k-toolbar > .k-align-left {
  float: none;
}
.k-toolbar > .k-align-right {
  float: right;
}
.k-toolbar > * {
  display: inline-block;
}
.k-toolbar .k-separator {
  border-width: 0 0 0 1px;
  border-style: solid;
  width: 1px;
  line-height: inherit;
}
.k-toolbar .k-button-group {
  list-style-type: none;
}
.k-toolbar .k-button-group > li {
  display: inline-block;
}
.k-toolbar .k-button-group .k-button {
  margin: 0 0 0 -1px;
  border-radius: 0;
}
.k-toolbar .k-button,
.k-toolbar .k-split-button,
.k-toolbar .k-button-group,
.k-toolbar .k-widget,
.k-toolbar .k-textbox,
.k-toolbar label,
.k-toolbar .k-separator {
  margin: 0 .2em;
  line-height: 1.72em;
  vertical-align: middle;
}
.k-toolbar .k-split-button {
  padding-left: 0;
}
.k-toolbar .k-split-button .k-button,
.k-toolbar .k-button-group .k-group-start {
  margin: 0;
}
.k-toolbar .k-split-button .k-split-button-arrow {
  margin: 0 0 0 -1px;
}
.k-toolbar .k-overflow-anchor {
  border-width: 0 0 0 1px;
  border-style: solid;
  height: 3em;
  width: 3em;
  line-height: inherit;
  padding: 0 .5em;
  margin: 0;
  position: relative;
  float: right;
  border-radius: 0;
}
.k-overflow-container .k-item {
  float: none;
  border: 0;
}
.k-overflow-container .k-separator {
  border-width: 0 0 1px;
  border-style: solid;
  height: 1px;
  line-height: 0;
  font-size: 0;
  padding: 0;
}
.k-overflow-container .k-overflow-button,
.k-split-container .k-button {
  text-align: left;
  display: block;
  background: none;
  border-color: transparent;
  white-space: nowrap;
}
.k-split-container {
  margin-top: -1px;
}
.k-overflow-container .k-button-group {
  padding: 0;
}
.k-overflow-container .k-button-group > li {
  display: block;
}
.k-overflow-container .k-overflow-group {
  display: block;
  border-width: 1px 0;
  border-style: solid;
  border-radius: 0;
  padding: 2px 0;
  margin: 1px 0;
}
.k-overflow-container .k-overflow-hidden {
  display: none;
}
.k-overflow-container .k-toolbar-first-visible,
.k-overflow-container .k-overflow-group + .k-overflow-group,
.k-overflow-container .k-separator + .k-overflow-group {
  border-top: 0;
  margin-top: 0;
  padding-top: 1px;
}
.k-overflow-container .k-overflow-group + .k-separator {
  display: none;
}
.k-overflow-container .k-toolbar-last-visible {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 1px;
}
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Splitter */
.k-splitter {
  position: relative;
  height: 300px;
}
.k-pane > .k-splitter {
  border-width: 0;
  overflow: hidden;
}
.k-splitter .k-pane {
  overflow: hidden;
}
.k-splitter .k-scrollable {
  overflow: auto;
}
.k-splitter .k-pane-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
}
.k-ghost-splitbar,
.k-splitbar {
  position: absolute;
  border-style: solid;
  font-size: 0;
  outline: 0;
  user-select: none;
}
.k-splitter .k-ghost-splitbar-horizontal,
.k-splitter .k-splitbar-horizontal {
  top: 0;
  width: 5px;
  border-width: 0 1px;
  background-repeat: repeat-y;
}
.k-ghost-splitbar-vertical,
.k-splitbar-vertical {
  left: 0;
  height: 5px;
  border-width: 1px 0;
  background-repeat: repeat-x;
}
.k-splitbar-draggable-horizontal {
  cursor: w-resize;
}
.k-splitbar-draggable-vertical {
  cursor: n-resize;
}
.k-splitbar .k-resize-handle {
  display: none;
}
.k-restricted-size-horizontal,
.k-restricted-size-vertical {
  background-color: #f00;
}
.k-splitbar-horizontal .k-icon {
  position: absolute;
  top: 50%;
  margin-top: -10px;
}
.k-splitbar-horizontal .k-i-arrow-60-right {
  margin-left: -2px;
}
.k-splitbar-horizontal.k-splitbar-draggable-horizontal .k-i-arrow-60-right {
  margin-top: 13px;
}
.k-splitbar-horizontal .k-i-arrow-60-left {
  margin-left: -3px;
}
.k-splitbar-horizontal.k-splitbar-draggable-horizontal .k-i-arrow-60-left {
  margin-top: -28px;
}
.k-splitbar-vertical .k-i-arrow-60-up {
  margin-top: -2px;
}
.k-splitbar-vertical .k-i-arrow-60-down {
  margin-top: -2px;
}
.k-splitbar-vertical.k-splitbar-draggable-vertical .k-i-arrow-60-up {
  margin-left: -17px;
}
.k-splitbar-vertical.k-splitbar-draggable-vertical .k-i-arrow-60-down {
  margin-left: 23px;
}
.k-i-arrow-60-up,
.k-i-arrow-60-right,
.k-i-arrow-60-down,
.k-i-arrow-60-left {
  cursor: pointer;
}
.k-splitbar-horizontal .k-icon {
  position: absolute;
  top: 50%;
  width: 7px;
  height: 20px;
  margin-top: -10px;
}
.k-splitbar-static-horizontal {
  width: 1px;
}
.k-splitbar-static-vertical {
  height: 1px;
}
.k-splitbar-vertical .k-icon {
  position: absolute;
  left: 50%;
}
.k-splitbar-draggable-vertical .k-resize-handle,
.k-splitbar-draggable-horizontal .k-resize-handle {
  display: inline-block;
  border-radius: 1px;
}
.k-splitbar-draggable-horizontal .k-resize-handle {
  width: 3px;
  height: 16px;
  margin-left: 1px;
}
.k-splitbar-draggable-vertical .k-resize-handle {
  width: 16px;
  height: 3px;
  margin-top: 1px;
}
.k-splitbar .k-i-arrow-60-up,
.k-splitbar .k-i-arrow-60-right,
.k-splitbar .k-i-arrow-60-down,
.k-splitbar .k-i-arrow-60-left {
  font-size: 10px;
}
.k-splitter-resizing {
  overflow: hidden;
}
.k-rtl .k-splitbar-horizontal .k-resize-handle {
  margin-right: 1px;
}
.k-rtl .k-splitbar-horizontal .k-i-arrow-60-right {
  margin-top: 13px;
  margin-right: -3px;
}
.k-rtl .k-splitbar-horizontal .k-i-arrow-60-left {
  margin-top: -28px;
  margin-right: -2px;
}
.k-rtl .k-splitbar-vertical .k-i-arrow-60-up {
  margin-top: -2px;
  margin-left: -17px;
}
.k-rtl .k-splitbar-vertical .k-i-arrow-60-down {
  margin-top: -2px;
  margin-left: 23px;
}
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Upload */
html .k-upload {
  position: relative;
}
.k-dropzone em,
.k-upload-button {
  vertical-align: middle;
}
.k-dropzone,
.k-file {
  position: relative;
}
.k-dropzone {
  border-style: solid;
  border-width: 0;
  padding: .8em;
  background-color: transparent;
}
.k-dropzone em {
  display: none;
  margin-left: .6em;
}
.k-dropzone-active em {
  display: inline-block;
}
.k-dropzone-active .k-upload-status {
  display: none;
}
.k-upload-button {
  position: relative;
  overflow: hidden;
  direction: ltr;
}
.k-upload .k-upload-button {
  min-width: 7.167em;
  margin-right: 1.6em;
}
.k-upload-sync .k-upload-button {
  margin: .8em;
}
.k-upload-button input {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  font: 170px monospace !important;
  /* critical for correct operation; larger values lead to ignoring or text layout problems in IE */
  filter: alpha(opacity=0);
  opacity: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.k-upload-files {
  line-height: 2.66;
  border-style: solid;
  border-width: 1px 0 0;
  padding-bottom: 5px;
}
.k-upload-files .k-button {
  padding: 0;
}
.k-upload-status .k-i-warning {
  display: none;
}
.k-upload-status-total .k-icon {
  display: inline-block;
  margin-right: 4px;
  vertical-align: text-bottom;
}
.k-dropzone .k-upload-status-total {
  top: 50%;
  transform: translateY(-50%);
}
.k-ie .k-dropzone .k-upload-status-total {
  transform: none;
}
.k-upload .k-clear-selected,
.k-upload .k-upload-selected {
  display: inline-block;
  width: 50%;
  margin: 0;
  line-height: 1em;
  padding: 1.214em 0;
  border: 0;
}
.k-upload .k-upload-selected {
  border-left-width: 1px;
  border-left-style: solid;
  margin-left: -1px;
}
.k-upload .k-clear-selected {
  border-radius: 0 0 0 4px;
}
.k-upload .k-upload-selected {
  border-radius: 0 0 4px 0;
}
.k-upload-files ~ .k-clear-selected,
.k-upload-files ~ .k-upload-selected {
  margin-top: -5px;
}
.k-upload .k-fail {
  background-position: -161px -111px;
}
.k-file {
  border-style: solid;
  border-width: 0 0 1px;
  padding: .65em .17em .65em 1em;
}
.k-file .k-upload-action {
  background: 0 0;
  border-width: 0;
  box-shadow: none;
  margin-right: 3px;
}
.k-file .k-icon {
  display: inline-block;
  position: relative;
}
.k-file-extension-wrapper,
.k-file-name-size-wrapper,
.k-multiple-files-extension-wrapper,
.k-file-invalid-extension-wrapper,
.k-multiple-files-invalid-extension-wrapper {
  display: inline-block;
}
.k-file-extension-wrapper,
.k-multiple-files-extension-wrapper,
.k-file-invalid-extension-wrapper,
.k-multiple-files-invalid-extension-wrapper {
  position: relative;
  width: 24px;
  height: 34px;
  border-width: 2px;
  border-style: solid;
  vertical-align: top;
  font-size: .57em;
  text-transform: uppercase;
}
.k-file-invalid-extension-wrapper,
.k-multiple-files-invalid-extension-wrapper {
  font-size: 1.2em;
}
.k-multiple-files-extension-wrapper,
.k-multiple-files-invalid-extension-wrapper {
  margin-top: 4px;
}
.k-file-state {
  visibility: hidden;
}
.k-file-name-size-wrapper {
  vertical-align: middle;
  margin-left: 1em;
}
.k-file-extension-wrapper:before,
.k-multiple-files-extension-wrapper:before,
.k-multiple-files-extension-wrapper:after,
.k-file-invalid-extension-wrapper:before,
.k-multiple-files-invalid-extension-wrapper:before,
.k-multiple-files-invalid-extension-wrapper:after {
  position: absolute;
  content: "";
  display: inline-block;
  border-style: solid;
}
.k-file-extension-wrapper:before,
.k-multiple-files-extension-wrapper:before,
.k-file-invalid-extension-wrapper:before,
.k-multiple-files-invalid-extension-wrapper:before {
  top: -1px;
  right: -1px;
  width: 0;
  height: 0;
  border-width: 6px;
  margin-top: -1px;
  margin-right: -1px;
}
.k-multiple-files-extension-wrapper:after,
.k-multiple-files-invalid-extension-wrapper:after {
  top: -6px;
  left: -6px;
  width: 15px;
  height: 35px;
  border-width: 2px 0 0 2px;
}
.k-file-extension,
.k-file-invalid-icon {
  position: absolute;
  bottom: 0;
  line-height: normal;
}
.k-file-extension {
  margin-left: .4em;
  margin-bottom: .3em;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.k-file-invalid-icon {
  margin-left: 5px;
}
.k-file-name,
.k-file-size,
.k-file-validation-message,
.k-file-information {
  display: block;
}
.k-file-name {
  position: relative;
  min-width: 10em;
  max-width: 16.667em;
  vertical-align: middle;
  line-height: 1.2em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.k-file-size,
.k-file-information,
.k-file-validation-message {
  font-size: .78em;
  line-height: 1.5em;
}
.k-file-information,
.k-file-validation-message {
  line-height: 1.5em;
}
.k-file-size {
  line-height: 1.2em;
}
.k-upload-files .k-upload-status {
  line-height: 1;
  position: absolute;
  right: 10px;
  top: 1em;
  opacity: 0.65;
}
.k-upload-action,
.k-button.k-upload-action:hover,
.k-button.k-upload-action:active:hover,
.k-button.k-upload-action.k-state-active:hover {
  color: inherit;
  background: transparent;
  border: 0;
}
.k-upload-action {
  opacity: 0.6;
}
.k-upload-action:hover {
  opacity: 1;
}
.k-upload-action .k-icon {
  vertical-align: top;
}
.k-file .k-upload-status .k-button,
.k-file .k-upload-status > .k-icon {
  vertical-align: middle;
  border-radius: 50%;
}
.k-dropzone .k-upload-status {
  position: static;
  margin-left: 10px;
}
.k-upload-pct {
  margin-right: .75em;
}
.k-ie9 .k-upload-status-total {
  top: 1.5em;
}
.k-ie9 .k-upload-status-total > .k-icon {
  margin-top: -3px;
}
.k-upload-action {
  line-height: normal;
}
.k-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
}
.k-file-invalid > .k-progress {
  width: 100%;
}
.k-upload-selected {
  min-width: 7.167em;
  margin: 0.25em 0 0;
  box-sizing: content-box;
}
.k-upload-selected,
.k-upload-cancel {
  margin-bottom: .8em;
}
.k-upload-selected {
  margin-left: .8em;
  margin-right: .2em;
}
.k-rtl .k-file-extension-wrapper {
  margin-left: 14px;
}
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.k-mediaplayer {
  position: relative;
}
.k-mediaplayer > video,
.k-mediaplayer > iframe {
  vertical-align: top;
}
.k-mediaplayer .k-mediaplayer-overlay {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
}
.k-mediaplayer-titlebar {
  padding: 8px;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  color: white;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.05) 80%, rgba(0, 0, 0, 0));
}
.k-mediaplayer-toolbar-wrap {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  right: 0;
}
.k-mediaplayer-toolbar {
  padding: 2px;
  border-width: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
.k-mediaplayer-toolbar > * {
  display: inline-flex;
  align-items: center;
  align-content: center;
}
.k-mediaplayer-toolbar > * + * {
  margin-left: 5px;
}
.k-mediaplayer-toolbar .k-toolbar-spacer {
  margin-top: 0;
  margin-bottom: 0;
  border-width: 0;
  flex: 1;
  opacity: 0;
}
.k-mediaplayer-toolbar .k-align-right > * + * {
  margin-left: 5px;
}
.k-mediaplayer-toolbar .k-button.k-bare {
  padding-left: 9px;
  padding-right: 9px;
}
.k-mediaplayer-toolbar .k-mediaplayer-quality {
  width: auto;
  padding: 0;
  background: none transparent;
}
.k-mediaplayer-toolbar .k-mediaplayer-quality .k-dropdown-wrap {
  border: 0 none;
  box-shadow: none;
  padding: 0;
  background: none transparent;
  color: inherit;
}
.k-mediaplayer-toolbar .k-mediaplayer-quality .k-input {
  display: none;
}
.k-mediaplayer-toolbar .k-mediaplayer-quality .k-select {
  position: relative;
  width: auto;
  min-height: 0;
  line-height: inherit;
}
.k-mediaplayer-volume-wrap,
.k-mediaplayer-quality-wrap {
  display: inline-flex;
  align-items: center;
}
.k-quality-list {
  width: 80px !important;
  margin-left: -32px !important;
}
.k-slider.k-mediaplayer-seekbar {
  width: 100%;
  position: absolute;
  z-index: 3;
  top: -17px;
  left: 0;
}
.k-slider.k-mediaplayer-seekbar .k-slider-track {
  width: 100% !important;
  border-radius: 0;
}
.k-slider.k-mediaplayer-seekbar .k-slider-selection {
  border-radius: 0;
}
.k-slider.k-mediaplayer-seekbar .k-draghandle {
  opacity: 0;
  transition: 0.2s opacity;
}
.k-slider.k-mediaplayer-seekbar .k-draghandle.k-state-selected,
.k-slider.k-mediaplayer-seekbar:hover .k-draghandle {
  opacity: 1;
}
.k-mediaplayer-fullscreen {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}
.k-no-flexbox .k-mediaplayer-toolbar .k-align-right > * {
  display: inline-block;
  vertical-align: middle;
}
.k-no-flexbox .k-mediaplayer-toolbar .k-bare.k-button {
  height: auto;
}
.k-no-flexbox .k-mediaplayer-toolbar .k-toolbar-spacer {
  display: none;
}
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ImageBrowser */
.k-toolbar-wrap {
  float: left;
}
.k-toolbar-wrap .k-dropzone em,
.k-toolbar-wrap .k-upload-files {
  display: none;
}
.k-toolbar-wrap .k-dropzone {
  border: 0;
  padding: 0;
}
.k-toolbar-wrap .k-dropzone-active {
  text-align: center;
}
.k-toolbar-wrap .k-dropzone-active em {
  display: inline;
  margin: 0;
  font-size: 5em;
  font-style: normal;
}
.k-toolbar-wrap .k-dropzone-active .k-upload-button {
  display: none;
}
.k-filebrowser-dropzone {
  z-index: 10010;
  filter: alpha(opacity=40);
  opacity: .4;
  position: fixed;
}
.k-search-wrap {
  position: relative;
  float: right;
  width: 20%;
  padding: 0;
}
.k-search-wrap label {
  position: absolute;
  top: 0;
  left: 4px;
  line-height: 20px;
  font-style: italic;
}
.k-search-wrap input.k-input {
  padding-left: 0;
  padding-right: 0;
}
.k-search-wrap .k-search {
  position: absolute;
  top: 50%;
  right: 2px;
  margin: 0;
  margin-top: -8px;
}
.k-breadcrumbs {
  position: relative;
  float: left;
  width: 79%;
}
.k-breadcrumbs-wrap {
  position: absolute;
  top: 3px;
  left: 0;
  z-index: 1;
  padding-left: 5px;
  line-height: 18px;
}
.k-breadcrumbs > .k-input {
  width: 100%;
  font-size: inherit;
  font-family: inherit;
  border: 0;
}
.k-breadcrumbs .k-link,
.k-breadcrumbs-wrap .k-icon {
  margin-top: 0;
  text-decoration: none;
  vertical-align: middle;
  position: relative;
}
.k-breadcrumbs .k-link:hover {
  text-decoration: underline;
}
.k-filebrowser .k-tile .k-folder,
.k-filebrowser .k-tile .k-file,
.k-filebrowser .k-tile .k-i-folder,
.k-filebrowser .k-tile .k-i-file {
  font-size: 5em;
}
.k-filebrowser .k-breadcrumbs .k-i-arrow-end-left {
  text-decoration: none;
  cursor: default;
}
.k-filebrowser .k-filebrowser-toolbar {
  border-style: solid;
  border-width: 1px;
  margin: 8px 0 0;
  padding: .25em;
  line-height: 23px;
  white-space: nowrap;
  /*required by WebKit*/
}
.k-filebrowser .k-filebrowser-toolbar .k-button.k-state-disabled {
  display: none;
}
.k-filebrowser .k-tiles-arrange {
  float: right;
}
.k-filebrowser .k-tiles-arrange .k-dropdown {
  width: 5.3em;
}
.k-filebrowser .k-upload {
  float: left;
  z-index: 10010;
  border-width: 0;
  background-color: transparent;
}
.k-filebrowser .k-upload .k-upload-status {
  display: none;
}
.k-filebrowser .k-upload .k-upload-button {
  width: auto;
  margin-left: 0;
}
.k-tiles {
  clear: both;
  height: 390px;
  border-style: solid;
  border-width: 1px;
  border-top-width: 0;
  margin: 0 0 1.4em;
  padding: 9px;
  overflow: auto;
  line-height: 1.2;
}
.k-tile {
  float: left;
  width: 223px;
  height: 88px;
  overflow: hidden;
  border-style: solid;
  border-width: 1px;
  margin: 1px;
  padding: 0 0 4px;
  background-position: 0 100px;
  background-repeat: repeat-x;
  cursor: pointer;
}
.k-tiles li.k-state-hover,
.k-tiles li.k-state-selected {
  background-position: 0 center;
}
.k-filebrowser .k-thumb {
  float: left;
  display: inline;
  width: 80px;
  height: 80px;
  margin: 4px 10px 0 4px;
  user-select: none;
}
.k-filebrowser .k-file,
.k-filebrowser .k-i-file {
  width: 80px;
  height: 80px;
}
.k-filebrowser .k-image {
  margin: 2px 0 0 2px;
}
.k-filebrowser .k-folder,
.k-filebrowser .k-i-folder {
  width: 80px;
  height: 80px;
  background-position: 0 -200px;
  background-repeat: no-repeat;
}
.k-filebrowser .k-loading {
  margin: 35px 0 0 33px;
}
.k-tile strong,
.k-tile input {
  margin: 10px 0 4px;
  font-weight: normal;
}
.k-tile strong {
  float: left;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.k-tile input {
  width: 100px;
}
.k-tile strong,
.k-tile input {
  display: block;
}
.k-tile .k-filesize {
  display: inline-block;
}
.k-filebrowser .k-form-text-row {
  text-align: right;
}
.k-filebrowser .k-form-text-row label {
  width: 14%;
}
.k-filebrowser .k-form-text-row input {
  width: 80%;
}
.k-tile-empty {
  margin: 160px 0 0;
}
.k-tile-empty .k-dialog-upload {
  font-weight: bold;
  font-size: 120%;
}
.k-tile-empty strong {
  display: block;
  margin: 0 0 0.2em;
  font-size: 3em;
  font-weight: normal;
}
.k-tile-empty,
.k-tile-empty .k-button-wrapper {
  text-align: center;
}
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.k-chart,
.k-gauge,
.k-sparkline,
.k-stockchart {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-chart,
.k-stockchart {
  height: 400px;
}
.k-chart > canvas {
  vertical-align: top;
}
div.k-chart,
div.k-gauge,
span.k-sparkline,
.k-stockchart {
  background-color: transparent;
}
.k-gauge {
  text-align: left;
  position: relative;
}
.k-baseline-marker {
  zoom: 1;
  *display: inline;
}
.k-chart-tooltip {
  border-radius: 4px;
  padding: 6px;
  white-space: nowrap;
  z-index: 12000;
  line-height: normal;
  background-repeat: repeat-x;
  background-position: 0 0;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAADNJREFUeNpi/P//vwMDFQELEP8beQb+HTWQYgP/DHoD/466cAR4edRAyg38P6hLbIAAAwCnWhhVsxvdCAAAAABJRU5ErkJggg==);
  color: #fff;
}
.k-chart-tooltip-inverse {
  color: #000;
}
.k-chart-tooltip table {
  border-spacing: 0;
  border-collapse: collapse;
}
.k-chart-tooltip th {
  width: auto;
  text-align: center;
  padding: 1px;
}
.k-chart-tooltip td {
  width: auto;
  text-align: left;
  padding: .1em .2em;
}
.k-chart-shared-tooltip-marker {
  display: block;
  width: 15px;
  height: 3px;
}
/*Stock Charts*/
/* Selection */
.k-selector {
  position: absolute;
  -webkit-transform: translateZ(0);
}
.k-selection {
  position: absolute;
  border-width: 1px;
  border-style: solid;
  border-color: #d2d2d2;
  border-bottom: 0;
  height: 100%;
}
.k-selection-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.01);
  filter: alpha(opacity=1);
}
.k-handle {
  background: #d2d2d2;
  width: 7px;
  height: 26px;
  cursor: e-resize;
  z-index: 1;
  border-radius: 6px;
  position: absolute;
}
.k-handle div {
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.k-left-handle {
  left: -4px;
}
.k-right-handle {
  right: -4px;
}
.k-left-handle div {
  margin: -20px 0 0 -15px;
  padding: 40px 30px 0 0;
}
.k-left-handle.k-handle-active div {
  margin-left: -40px;
  padding-right: 55px;
}
.k-right-handle div {
  margin: -20px 0 0 -15px;
  padding: 40px 0 0 30px;
}
.k-right-handle.k-handle-active div {
  padding-left: 55px;
}
.k-mask {
  position: absolute;
  height: 100%;
  background-color: #fff;
  filter: alpha(opacity=80);
  opacity: 0.80;
}
.k-border {
  background: #d2d2d2;
  width: 1px;
  height: 100%;
  position: absolute;
}
/* Navigator hint */
.k-navigator-hint div {
  position: absolute;
}
.k-navigator-hint .k-scroll {
  position: absolute;
  height: 4px;
  border-radius: 4px;
  background: #d2d2d2;
}
.k-navigator-hint .k-tooltip {
  margin-top: 20px;
  min-width: 160px;
  opacity: 1;
  text-align: center;
  border: 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  background: #fff;
}
/* Sparklines */
.k-sparkline,
.k-sparkline span {
  display: inline-block;
  vertical-align: top;
}
.k-sparkline span {
  height: 100%;
  width: 100%;
}
/* Map */
.k-map,
.k-diagram {
  height: 600px;
}
.k-map .km-scroll-wrapper,
.k-diagram .km-scroll-wrapper {
  padding-bottom: 0;
  user-select: none;
}
.k-map .km-scroll-wrapper,
.k-diagram .km-scroll-wrapper,
.k-map .km-scroll-container,
.k-diagram .km-scroll-container {
  position: absolute;
  width: 100%;
  height: 100%;
}
.k-map .k-layer,
.k-diagram .k-layer {
  position: absolute;
  left: 0;
  top: 0;
}
.k-map .km-touch-scrollbar,
.k-diagram .km-touch-scrollbar {
  display: none;
}
.k-map .k-marker {
  position: absolute;
  width: 28px;
  height: 40px;
  margin: -40px 0 0 -14px;
  cursor: pointer;
}
.k-map .k-marker-pin {
  background-position: 0px 40px;
}
.k-map .k-marker-pin-target {
  background-position: 0px 0px;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
  .k-map .k-marker {
    width: 56px;
    height: 80px;
    margin: -80px 0 0 -28px;
  }
  .k-map .k-marker-pin {
    background-position: 0px 80px;
  }
}
/* Control positions */
.k-map .k-pos-top {
  top: 0;
}
.k-map .k-pos-bottom {
  bottom: 0;
}
.k-map .k-pos-left {
  left: 0;
}
.k-map .k-pos-right {
  right: 0;
}
.k-map-controls {
  position: absolute;
}
.k-map-controls.k-pos-left .k-widget:first-child {
  margin-right: 0;
}
.k-map-controls.k-pos-right .k-widget:first-child {
  margin-left: 0;
}
/* Map navigator */
.k-navigator {
  width: 50px;
  height: 50px;
  margin: 20px;
  border-radius: 80px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.k-pdf-export .k-navigator {
  display: none;
}
.k-navigator > button {
  border-color: transparent;
  background: none;
}
div.k-navigator > .k-button {
  margin: 0;
  padding: 0;
  line-height: 10px;
  border-radius: 16px;
  position: absolute;
  font-size: 1px;
  /*IE7*/
  line-height: 1px;
}
div.k-navigator .k-navigator-n,
div.k-navigator .k-navigator-up {
  top: 2px;
  left: 50%;
  margin-left: -9px;
}
div.k-navigator .k-navigator-e,
div.k-navigator .k-navigator-right {
  right: 2px;
  top: 50%;
  margin-top: -9px;
}
div.k-navigator .k-navigator-s,
div.k-navigator .k-navigator-down {
  bottom: 2px;
  left: 50%;
  margin-left: -9px;
}
div.k-navigator .k-navigator-w,
div.k-navigator .k-navigator-left {
  left: 2px;
  top: 50%;
  margin-top: -9px;
}
/* Attribution */
.k-map .k-attribution {
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 10px;
  padding: 2px 4px;
  z-index: 1000;
}
/* Zoom */
.k-zoom-control {
  margin: 14px;
  vertical-align: middle;
}
.k-pdf-export .k-zoom-control {
  display: none;
}
.k-button-wrap {
  border-radius: 4px;
  display: inline-block;
}
.k-button-wrap .k-button {
  position: relative;
  padding: 4px;
  line-height: 16px;
}
.k-button-wrap .k-button .k-icon {
  vertical-align: top;
}
.k-buttons-horizontal :first-child {
  border-radius: 4px 0 0 4px;
}
.k-buttons-horizontal :first-child + .k-zoom-in {
  border-radius: 0;
  margin-left: -1px;
}
.k-buttons-horizontal .k-zoom-out {
  border-radius: 0 4px 4px 0;
  margin-left: -1px;
}
.k-button-wrap .k-button:hover {
  z-index: 1;
}
.k-buttons-vertical .k-button {
  display: block;
}
.k-buttons-vertical :first-child {
  border-radius: 4px 4px 0 0;
}
.k-buttons-vertical .k-zoom-out {
  border-radius: 0 0 4px 4px;
  margin-top: -1px;
}
.k-zoom-text {
  margin: 0;
  width: 4.3em;
  vertical-align: top;
}
/* RTL */
.k-rtl .k-buttons-horizontal :first-child {
  border-radius: 0 4px 4px 0;
}
.k-rtl .k-buttons-horizontal :first-child + .k-zoom-in {
  border-radius: 0;
  margin-left: 0;
  margin-right: -1px;
}
.k-rtl .k-buttons-horizontal .k-zoom-out {
  border-radius: 4px 0 0 4px;
  margin-left: 0;
  margin-right: -1px;
}
/* Diagram */
.k-diagram {
  height: 600px;
}
.k-diagram .km-scroll-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}
.k-diagram .km-scroll-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}
.k-canvas-container {
  width: 100%;
  height: 100%;
}
/* IE8- */
.k-diagram img {
  box-sizing: content-box;
}
/* TreeMap start */
.k-treemap {
  overflow: hidden;
  height: 400px;
}
.k-treemap-tile {
  box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  position: absolute;
  margin: -1px 0 0 -1px;
  overflow: hidden;
}
.k-treemap-tile.k-leaf {
  padding: .6em;
}
.k-treemap-wrap.k-last > .k-treemap-tile {
  padding: .3em;
}
.k-treemap-tile.k-state-hover,
.k-treemap-tile.k-state-hover:hover {
  z-index: 2;
  background-image: none;
}
.k-treemap > .k-treemap-tile {
  position: relative;
  height: 100%;
}
.k-treemap-title {
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 2.42em;
  height: 2.42em;
  padding: 0 .6em;
  white-space: nowrap;
}
.k-treemap-wrap .k-treemap-title {
  border-width: 0 0 1px;
  border-style: solid;
}
.k-treemap-wrap {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.k-treemap-title + .k-treemap-wrap {
  top: 2.42em;
}
.k-treemap-title-vertical {
  box-sizing: border-box;
  text-overflow: ellipsis;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2.42em;
  line-height: 2.42em;
  overflow: hidden;
  padding: .6em 0;
  white-space: nowrap;
}
.k-treemap-title-vertical > div {
  position: absolute;
  top: 0;
  right: 1.23em;
  transform-origin: right;
  transform: rotate(-90deg);
}
.k-treemap-title-vertical + .k-treemap-wrap {
  left: 2.42em;
}
/* TreeMap end */
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* common mobile css */
.km-root,
.km-pane,
.km-pane-wrapper {
  width: 100%;
  height: 100%;
  -ms-touch-action: none;
  -ms-content-zooming: none;
  user-select: none;
  text-size-adjust: none;
}
.km-pane-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}
.km-pane,
.km-shim {
  font-family: sans-serif;
}
.km-pane {
  overflow-x: hidden;
}
.km-view {
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: stretch;
  align-content: stretch;
  vertical-align: top;
}
.k-ff .km-view,
.k-ff .km-pane {
  overflow: hidden;
}
.k-ff18 .km-view,
.k-ff18 .km-pane,
.k-ff19 .km-view,
.k-ff19 .km-pane,
.k-ff20 .km-view,
.k-ff20 .km-pane,
.k-ff21 .km-view,
.k-ff21 .km-pane {
  position: relative;
}
.k-ff .km-view {
  display: -moz-inline-box;
  display: flex;
}
.km-content {
  min-height: 1px;
  flex: 1;
  flex-align: stretch;
  display: block;
  width: auto;
  overflow: hidden;
  position: relative;
}
.km-actionsheet > li {
  list-style-type: none;
  padding: inherit 1em;
  line-height: 2em;
}
.km-actionsheet {
  padding: 0;
  margin: 0;
}
.km-shim {
  left: 0;
  bottom: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10001;
}
.km-shim .k-animation-container,
.km-actionsheet-wrapper {
  width: 100%;
  box-shadow: none;
  border: 0;
}
.km-shim .k-animation-container {
  width: auto;
}
/* /common mobile css */
.km-pane-wrapper .k-grid-edit-form > .km-header,
.km-pane-wrapper .k-grid-column-menu > .km-header,
.km-pane-wrapper .k-grid-filter-menu > .km-header,
.km-pane-wrapper .k-scheduler-edit-form > .km-header {
  border-style: solid;
  border-width: 1px;
  padding: .3em .6em;
  text-align: center;
  width: auto;
  line-height: 2em;
}
.k-ie .km-pane-wrapper .k-scheduler > .k-scheduler-toolbar,
.k-ie .km-pane-wrapper .k-scheduler > .k-scheduler-footer {
  line-height: 2em;
}
.km-pane-wrapper .k-grid-edit-form .k-multiselect,
.km-pane-wrapper .k-scheduler-edit-form .k-multiselect {
  width: 15em;
}
.km-pane-wrapper .k-grid-edit-form .k-dropdown-wrap,
.km-pane-wrapper .k-scheduler-edit-form .k-dropdown-wrap {
  display: block;
}
.km-pane-wrapper .k-grid-column-menu .k-done,
.km-pane-wrapper .k-grid-filter-menu .k-submit,
.km-pane-wrapper .k-grid-edit-form .k-grid-update,
.km-pane-wrapper .k-scheduler-edit-form .k-scheduler-update {
  float: right;
}
.km-pane-wrapper .k-grid-filter-menu .k-i-cancel,
.km-pane-wrapper .k-grid-edit-form .k-grid-cancel,
.km-pane-wrapper .k-scheduler-edit-form .k-scheduler-cancel {
  float: left;
}
.km-pane-wrapper .k-grid-edit-row .k-textbox {
  box-sizing: border-box;
}
/* Actiosheet Styles */
.km-pane-wrapper .k-scheduler-edit-form .k-scheduler-delete,
*:not(.km-pane) > .km-shim .km-actionsheet .k-button {
  display: block;
  text-align: center;
}
*:not(.km-pane) > .km-shim .km-actionsheet .k-button {
  font-size: 1.4em;
  margin: .3em 1em;
}
*:not(.km-pane) > .km-shim .km-actionsheet-title {
  text-align: center;
  line-height: 3em;
  margin-bottom: -0.3em;
}
*:not(.km-pane) > .km-shim > .k-animation-container {
  margin: 0 !important;
  padding: 0 !important;
  left: 0 !important;
}
/* Adaptive Grid */
.km-pane-wrapper > div.km-pane {
  box-shadow: none;
  font-weight: normal;
}
.km-pane-wrapper .k-popup-edit-form .km-content > .km-scroll-container,
.km-pane-wrapper .k-grid-edit-form .km-content > .km-scroll-container,
.km-pane-wrapper .k-grid-column-menu .km-content > .km-scroll-container,
.km-pane-wrapper .k-grid-filter-menu .km-content > .km-scroll-container {
  position: absolute;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
}
.km-pane-wrapper .k-mobile-list .k-edit-field {
  width: 74%;
}
.km-pane-wrapper .k-grid-edit-form .k-popup-edit-form,
.km-pane-wrapper .k-grid-edit-form .k-edit-form-container {
  width: auto;
}
.km-pane-wrapper .k-filter-menu .k-button {
  width: 100%;
  margin: 0;
}
.k-grid-mobile {
  border-width: 0;
}
.k-grid-mobile .k-resize-handle-inner {
  position: absolute;
  top: 50%;
  margin-top: -10px;
  left: -7px;
  width: 17px;
  height: 17px;
  border-style: solid;
  border-width: 2px;
  border-radius: 10px;
}
.k-grid-mobile .k-resize-handle-inner:before {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -3px;
  left: 1px;
  width: 6px;
  height: 6px;
  background-position: -5px -53px;
}
.k-grid-mobile .k-resize-handle-inner:after {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -3px;
  right: 1px;
  width: 6px;
  height: 6px;
  background-position: -5px -21px;
}
/* Adaptive Grid & Scheduler */
.km-pane-wrapper .km-pane * {
  background-clip: border-box;
}
.km-pane-wrapper .km-pane .k-mobile-list,
.km-pane-wrapper .k-mobile-list ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  border-radius: 0;
  background: none;
}
.km-pane-wrapper .km-switch {
  top: 50%;
  right: .8rem;
  position: absolute;
  margin-top: -1.1rem;
}
.km-pane-wrapper .k-mobile-list .k-state-disabled {
  opacity: 1;
}
.km-pane-wrapper .k-mobile-list .k-state-disabled > * {
  opacity: .7;
}
.km-pane-wrapper .k-mobile-list .k-item,
.km-pane-wrapper .k-mobile-list .k-item > .k-link,
.km-pane-wrapper .k-mobile-list .k-item > .k-label,
.km-pane-wrapper .k-mobile-list .k-edit-label {
  display: block;
  position: relative;
  list-style-type: none;
  vertical-align: middle;
  box-sizing: border-box;
  padding: .5em 0 .5em 1em;
  font-size: 1em;
}
.km-pane-wrapper .k-edit-form-container,
.km-pane-wrapper .k-scheduler-edit-form .km-scroll-container {
  padding-top: 1em;
  width: 100%;
}
.km-pane-wrapper .k-mobile-list .k-edit-label {
  position: absolute;
  margin: 0;
  float: none;
  clear: none;
  width: 100%;
}
.km-pane-wrapper .k-mobile-list .k-edit-field,
.km-pane-wrapper .k-mobile-list .k-edit-label label {
  display: block;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  padding: .1em 0;
  margin: 0;
}
.km-pane-wrapper .k-mobile-list .k-item,
.km-pane-wrapper .k-mobile-list .k-edit-field,
.km-pane-wrapper .k-mobile-list .k-edit-label {
  font-size: 1em;
  line-height: 1.6em;
  overflow: hidden;
}
.km-pane-wrapper .k-mobile-list .k-edit-field,
.km-pane-wrapper .k-mobile-list .k-edit-label {
  width: 100%;
  float: none;
  clear: none;
  min-height: 2.7em;
}
.km-pane-wrapper .km-header .k-icon,
.km-pane-wrapper .k-grid-toolbar .k-icon,
.km-pane-wrapper .k-grid-edit .k-icon,
.km-pane-wrapper .k-grid-delete .k-icon {
  display: none;
}
.km-pane-wrapper .k-mobile-list .k-edit-field {
  padding: .5em 0;
}
.km-pane-wrapper .k-mobile-list .k-scheduler-toolbar {
  padding: .3em 0;
}
.km-pane-wrapper .k-mobile-list .k-scheduler-toolbar ul li {
  line-height: 2em;
}
.km-pane-wrapper .k-mobile-list .k-item > * {
  line-height: normal;
}
.km-pane-wrapper .k-mobile-list .k-edit-buttons,
.km-pane-wrapper .k-mobile-list .k-button-container {
  box-sizing: border-box;
  padding: .5em 1em;
  margin: 0;
}
.km-pane-wrapper .k-mobile-list > ul > li > .k-link,
.km-pane-wrapper .k-mobile-list .k-filter-help-text > li > .k-link,
.km-pane-wrapper .k-mobile-list .k-recur-view > .k-edit-label:nth-child(3),
.km-pane-wrapper #recurrence .km-scroll-container > .k-edit-label:first-child {
  display: block;
  padding: .2em 1em;
  font-size: .95em;
  position: -webkit-sticky;
  margin: 0;
  font-weight: normal;
  line-height: 2em;
  background: transparent;
  border-top: 1em solid transparent;
}
.km-pane-wrapper .k-mobile-list .k-recur-view > .k-edit-label:nth-child(3),
.km-pane-wrapper #recurrence .km-scroll-container > .k-edit-label:first-child {
  position: relative;
}
.km-pane-wrapper .k-mobile-list .k-item:first-child {
  border-top: 0;
}
.km-pane-wrapper .k-mobile-list .k-item:last-child {
  border-bottom: 0;
}
.km-pane-wrapper .k-mobile-list .k-item > .k-link,
.km-pane-wrapper .k-mobile-list .k-item > .k-label {
  line-height: inherit;
  text-decoration: none;
  margin: -0.5em 0 -0.5em -1em;
}
/* Mobile list form elements */
.k-check[type=checkbox],
.k-check[type=radio],
.k-mobile-list .k-edit-field [type=checkbox],
.k-mobile-list .k-edit-field [type=radio] {
  appearance: none;
  background-color: transparent;
}
.km-pane-wrapper .k-mobile-list .k-link .k-check,
.km-pane-wrapper .k-mobile-list .k-label .k-check,
.k-mobile-list .k-edit-field [type=checkbox],
.k-mobile-list .k-edit-field [type=radio] {
  border: 0;
  font-size: inherit;
  width: 13px;
  height: 13px;
  margin: .26em 1em .26em 0;
}
.k-ie .km-pane-wrapper .k-icon,
.k-ie .km-pane-wrapper .k-mobile-list .k-link .k-check,
.k-ie .km-pane-wrapper .k-mobile-list .k-label .k-check,
.k-ie .k-mobile-list .k-edit-field [type=checkbox],
.k-ie .k-mobile-list .k-edit-field [type=radio] {
  font-size: inherit;
  text-indent: -9999px;
  width: 1.01em;
  height: 1em;
}
.km-pane-wrapper .k-column-menu .k-label .k-check {
  height: 16px;
  width: 16px;
}
.km-pane-wrapper .km-pane .k-mobile-list input:not([type="checkbox"]):not([type="radio"]),
.km-pane-wrapper .km-pane .k-mobile-list select:not([multiple]),
.km-pane-wrapper .km-pane .k-mobile-list textarea,
.km-pane-wrapper .k-mobile-list .k-widget,
.km-pane-wrapper .k-edit-field > *:not([type="checkbox"]):not([type="radio"]):not(.k-button) {
  text-indent: 0;
  font-size: 1em;
  line-height: 1.6em;
  vertical-align: middle;
  height: auto;
  padding: 0;
  border: 0;
  margin: 0;
  color: inherit;
  background: transparent;
  box-shadow: none;
  border-radius: 0;
}
.km-pane-wrapper .k-mobile-list .k-widget {
  border: 0;
  border-radius: 0;
}
.k-ie .km-pane-wrapper .k-mobile-list .k-widget {
  height: initial;
}
.km-pane-wrapper .k-mobile-list .k-widget .k-input,
.km-pane-wrapper .k-mobile-list .k-widget .k-state-default {
  border: 0;
  background: transparent;
}
.km-pane-wrapper *:not(.k-state-default):not(.k-pager-input) > input:not([type="checkbox"]):not([type="radio"]),
.km-pane-wrapper .k-mobile-list select:not([multiple]),
.km-pane-wrapper .k-mobile-list textarea,
.km-pane-wrapper .k-mobile-list .k-widget,
.km-pane-wrapper .k-edit-field > *:not([type="checkbox"]):not([type="radio"]):not(.k-button) {
  width: 80%;
  padding: .6em 0;
  margin: -0.5em 0;
}
.km-pane-wrapper .km-pane .k-mobile-list input,
.km-pane-wrapper .km-pane .k-mobile-list select:not([multiple]),
.km-pane-wrapper .km-pane .k-mobile-list textarea,
.km-pane-wrapper .k-mobile-list .k-widget,
.km-pane-wrapper .k-mobile-list .k-edit-field > * {
  appearance: none;
  float: right;
  z-index: 1;
  position: relative;
}
.km-pane-wrapper .km-pane .k-mobile-list.k-filter-menu .k-space-right {
  padding: 10px;
  border-radius: 0;
}
.km-pane-wrapper .km-pane .k-mobile-list.k-filter-menu .k-space-right > input {
  float: none;
  width: 100%;
  padding: 1px 0;
  margin: 0;
  border-radius: 3px;
  text-indent: 30px;
  border-width: 1px;
  border-style: solid;
}
.km-pane-wrapper .km-pane .k-mobile-list.k-filter-menu .k-icon {
  position: absolute;
  left: 20px;
  z-index: 1;
}
.km-pane-wrapper .k-scheduler-views {
  width: 18em;
}
.km-pane-wrapper .k-mobile-list .k-edit-field.k-scheduler-toolbar {
  background: transparent;
  border: 0;
  box-shadow: none;
  padding: .5em 1em;
}
.km-pane-wrapper #recurrence .k-scheduler-navigation {
  width: 100%;
}
.km-pane-wrapper .k-scheduler-views,
.km-pane-wrapper .k-mobile-list .k-scheduler-navigation {
  display: table;
  table-layout: fixed;
}
.km-pane-wrapper .k-scheduler-views li,
.km-pane-wrapper .k-mobile-list .k-scheduler-navigation li {
  display: table-cell;
  text-align: center;
}
.km-pane-wrapper .k-mobile-list .k-recur-view > .k-edit-field .k-check {
  margin: 0;
  padding-left: 1em;
  box-sizing: border-box;
}
.km-pane-wrapper .k-mobile-list .k-recur-view > .k-edit-field .k-check:first-child {
  margin-top: -0.5em;
}
.km-pane-wrapper .k-mobile-list .k-recur-view > .k-edit-field .k-check:last-child {
  margin-bottom: -0.5em;
}
.km-pane-wrapper .k-mobile-list .k-scheduler-timezones .k-edit-field label.k-check {
  text-indent: 1em;
}
.km-pane-wrapper .k-mobile-list .k-edit-field > .k-button {
  margin-left: 20%;
  float: left;
}
.km-pane-wrapper .k-mobile-list .k-picker-wrap,
.km-pane-wrapper .k-mobile-list .k-numeric-wrap,
.km-pane-wrapper .k-mobile-list .k-dropdown-wrap {
  position: static;
  box-shadow: none;
}
.km-pane-wrapper .k-mobile-list .k-datepicker .k-select,
.km-pane-wrapper .k-mobile-list .k-datetimepicker .k-select,
.km-pane-wrapper .k-mobile-list .k-numerictextbox .k-select {
  position: absolute;
  top: 0;
  right: 0;
  line-height: normal;
}
.km-pane-wrapper .k-mobile-list .k-datepicker .k-select:before,
.km-pane-wrapper .k-mobile-list .k-datetimepicker .k-select:before {
  content: "\a0";
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
}
.km-pane-wrapper .k-mobile-list .k-numerictextbox .k-link {
  height: 50%;
}
.km-pane-wrapper .k-grid .k-button,
.km-pane-wrapper .k-edit-form-container .k-button {
  margin: 0;
}
.km-pane-wrapper .k-grid .k-button + .k-button,
.km-pane-wrapper .k-edit-form-container .k-button + .k-button {
  margin: 0 0 0 .18em;
}
.km-pane-wrapper .k-pager-numbers .k-link,
.km-pane-wrapper .k-pager-numbers .k-state-selected,
.km-pane-wrapper .k-pager-wrap > .k-link {
  width: 2.4em;
  height: 2.4em;
  line-height: 2.1em;
  border-radius: 2em;
  box-sizing: border-box;
}
.km-pane-wrapper .k-pager-numbers .k-link,
.km-pane-wrapper .k-pager-numbers .k-state-selected {
  width: auto;
  line-height: 2.2em;
  padding: 0 .86em;
  min-width: .7em;
}
.km-pane-wrapper .k-pager-wrap {
  line-height: 2.4em;
}
@media all and (max-width: 1024px) {
  .km-pane-wrapper .k-pager-nav + .k-pager-nav ~ .k-pager-nav {
    position: absolute;
    right: .3em;
    top: .3em;
  }
  .km-pane-wrapper .k-pager-wrap .k-pager-numbers + .k-pager-nav,
  .km-pane-wrapper .k-pager-nav:first-child + .k-pager-nav + .k-pager-nav {
    right: 3em;
  }
  .km-pane-wrapper .k-pager-info,
  .km-pane-wrapper .k-pager-refresh {
    display: none;
  }
}
@media all and (max-width: 699px), (-ms-high-contrast: active) and (-ms-high-contrast: none) and (max-width: 800px) {
  .km-pane-wrapper *:not(.k-state-default):not(.k-pager-input) > input:not([type="checkbox"]):not([type="radio"]),
  .km-pane-wrapper .k-mobile-list select:not([multiple]),
  .km-pane-wrapper .k-mobile-list textarea,
  .km-pane-wrapper .k-mobile-list .k-widget,
  .km-pane-wrapper .k-edit-field > *:not([type="checkbox"]):not([type="radio"]):not(.k-button) {
    width: 50%;
  }
  .km-pane-wrapper .k-mobile-list .k-edit-field > .k-button {
    margin-left: 50%;
  }
  .km-pane-wrapper .k-mobile-list .k-edit-field > .k-timezone-button {
    margin-left: 1em;
  }
  .km-pane-wrapper .k-nav-today a {
    padding-left: .6em;
    padding-right: .6em;
  }
  .km-pane-wrapper li.k-nav-current {
    margin-left: 0;
    margin-right: 0;
  }
  .km-pane-wrapper .k-pager-wrap {
    position: relative;
  }
  .km-pane-wrapper .k-pager-numbers {
    width: auto;
    display: block;
    overflow: hidden;
    margin-right: 5.5em;
    float: none;
    text-overflow: ellipsis;
    height: 2.4em;
    text-align: center;
  }
  .km-pane-wrapper .k-pager-numbers li {
    float: none;
    display: inline-block;
  }
  .km-pane-wrapper .k-pager-nav {
    float: left;
  }
}
.km-pane-wrapper .k-mobile-list .k-recur-view > .k-edit-field .k-check,
.km-pane-wrapper .k-mobile-list .k-edit-field > * > select:not([multiple]),
.km-pane-wrapper .k-mobile-list .k-scheduler-timezones .k-edit-field label.k-check {
  width: 100%;
}
/* Mobile Scroller */
.km-scroll-container {
  user-select: none;
  -webkit-margin-collapse: separate;
  -webkit-transform: translatez(0);
}
.k-widget .km-scroll-wrapper {
  position: relative;
  padding-bottom: 0;
}
.km-touch-scrollbar {
  position: absolute;
  visibility: hidden;
  z-index: 200000;
  height: .3em;
  width: .3em;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: opacity 0.3s linear;
  transition: "opacity 0.3s linear";
}
.km-vertical-scrollbar {
  height: 100%;
  right: 2px;
  top: 2px;
}
.km-horizontal-scrollbar {
  width: 100%;
  left: 2px;
  bottom: 2px;
}
/* Responsive styles  */
@media only screen and (max-width: 1024px) {
  .k-scheduler-toolbar > ul.k-scheduler-views {
    position: absolute;
    right: 6px;
    top: 6px;
    z-index: 10000;
  }
  .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views {
    right: auto;
    left: 6px;
  }
  .k-scheduler-toolbar > ul.k-scheduler-views > li:not(.k-current-view) {
    display: none;
  }
  .k-scheduler-toolbar li.k-nav-current .k-lg-date-format {
    display: none;
  }
  .k-scheduler-toolbar li.k-nav-current .k-sm-date-format {
    display: inline;
  }
  .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view {
    display: block;
    border-width: 1px;
  }
  .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view {
    text-align: left;
    padding-left: 1em;
  }
  .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link {
    display: block;
    position: relative;
    padding-right: 2.5em;
    padding-left: 1em;
    box-sizing: border-box;
  }
  .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link {
    padding-left: 0;
  }
  .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link:after {
    content: "\E006";
    position: absolute;
    top: 50%;
    right: 0.6em;
    margin-top: -0.5em;
    line-height: 1em;
  }
  .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li,
  .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li:first-child + li {
    display: block;
    border: 0;
    border-radius: 0;
  }
  .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded {
    border: 1px solid #c5c5c5;
    background-color: #fff;
    background-image: none;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
  }
  .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded {
    text-align: left;
  }
}
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* animation classes */
.k-fx-end .k-fx-next,
.k-fx-end .k-fx-current {
  transition: all 350ms ease-out;
}
.k-fx {
  position: relative;
}
.k-fx .k-fx-current {
  z-index: 0;
}
.k-fx .k-fx-next {
  z-index: 1;
}
.k-fx-hidden,
.k-fx-hidden * {
  visibility: hidden !important;
}
.k-fx-reverse .k-fx-current {
  z-index: 1;
}
.k-fx-reverse .k-fx-next {
  z-index: 0;
}
/* Zoom */
.k-fx-zoom.k-fx-start .k-fx-next {
  transform: scale(0) !important;
}
.k-fx-zoom.k-fx-end .k-fx-next {
  transform: scale(1) !important;
}
.k-fx-zoom.k-fx-reverse.k-fx-start .k-fx-next,
.k-fx-zoom.k-fx-reverse.k-fx-end .k-fx-next {
  transform: scale(1) !important;
}
.k-fx-zoom.k-fx-reverse.k-fx-start .k-fx-current {
  transform: scale(1) !important;
}
.k-fx-zoom.k-fx-reverse.k-fx-end .k-fx-current {
  transform: scale(0) !important;
}
/* Fade */
.k-fx-fade.k-fx-start .k-fx-next {
  will-change: opacity;
  opacity: 0;
}
.k-fx-fade.k-fx-end .k-fx-next {
  opacity: 1;
}
.k-fx-fade.k-fx-reverse.k-fx-start .k-fx-current {
  will-change: opacity;
  opacity: 1;
}
.k-fx-fade.k-fx-reverse.k-fx-end .k-fx-current {
  opacity: 0;
}
/* Slide */
.k-fx-slide {
  /* left */
  /* left reverse */
  /* right */
}
.k-fx-slide.k-fx-end .k-fx-next .km-content,
.k-fx-slide.k-fx-end .k-fx-next .km-header,
.k-fx-slide.k-fx-end .k-fx-next .km-footer,
.k-fx-slide.k-fx-end .k-fx-current .km-content,
.k-fx-slide.k-fx-end .k-fx-current .km-header,
.k-fx-slide.k-fx-end .k-fx-current .km-footer {
  transition: all 350ms ease-out;
}
.k-fx-slide.k-fx-start .k-fx-next .km-content {
  will-change: transform;
  transform: translatex(100%);
}
.k-fx-slide.k-fx-start .k-fx-next .km-header,
.k-fx-slide.k-fx-start .k-fx-next .km-footer {
  will-change: opacity;
  opacity: 0;
}
.k-fx-slide.k-fx-end .k-fx-current .km-content {
  transform: translatex(-100%);
}
.k-fx-slide.k-fx-end .k-fx-next .km-header,
.k-fx-slide.k-fx-end .k-fx-next .km-footer {
  opacity: 1;
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-content {
  will-change: transform;
  transform: translatex(0);
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-content {
  transform: translatex(100%);
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-content {
  transform: translatex(-100%);
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .km-content {
  transform: translatex(0);
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-header,
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-footer {
  will-change: opacity;
  opacity: 1;
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-header,
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-footer {
  opacity: 1;
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-header,
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-footer {
  opacity: 0;
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .km-header,
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .km-footer {
  opacity: 1;
}
.k-fx-slide.k-fx-right {
  /* right reverse */
}
.k-fx-slide.k-fx-right.k-fx-start .k-fx-next .km-content {
  transform: translatex(-100%);
}
.k-fx-slide.k-fx-right.k-fx-end .k-fx-current .km-content {
  transform: translatex(100%);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current .km-content {
  transform: translatex(0);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current .km-content {
  transform: translatex(-100%);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next .km-content {
  transform: translatex(100%);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-next .km-content {
  transform: translatex(0%);
}
/* Tile */
.k-fx-tile {
  /* left */
  /* left reverse */
  /* right */
}
.k-fx-tile.k-fx-start .k-fx-next {
  will-change: transform;
  transform: translatex(100%);
}
.k-fx-tile.k-fx-end .k-fx-current {
  transform: translatex(-100%);
}
.k-fx-tile.k-fx-reverse.k-fx-start .k-fx-current {
  will-change: transform;
  transform: translatex(0);
}
.k-fx-tile.k-fx-reverse.k-fx-end .k-fx-current {
  transform: translatex(100%);
}
.k-fx-tile.k-fx-reverse.k-fx-start .k-fx-next {
  transform: translatex(-100%);
}
.k-fx-tile.k-fx-reverse.k-fx-end .k-fx-next {
  transform: translatex(0);
}
.k-fx-tile.k-fx-right {
  /* right reverse */
}
.k-fx-tile.k-fx-right.k-fx-start .k-fx-next {
  transform: translatex(-100%);
}
.k-fx-tile.k-fx-right.k-fx-end .k-fx-current {
  transform: translatex(100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current {
  transform: translatex(0);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current {
  transform: translatex(-100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next {
  transform: translatex(100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-end .k-fx-next {
  transform: translatex(0%);
}
/* Tile */
.k-fx-tile {
  /* left */
  /* left reverse */
  /* right */
}
.k-fx-tile.k-fx-start .k-fx-next {
  will-change: transform;
  transform: translatex(100%);
}
.k-fx-tile.k-fx-end .k-fx-current {
  transform: translatex(-100%);
}
.k-fx-tile.k-fx-reverse.k-fx-start .k-fx-current {
  will-change: transform;
  transform: translatex(0);
}
.k-fx-tile.k-fx-reverse.k-fx-end .k-fx-current {
  transform: translatex(100%);
}
.k-fx-tile.k-fx-reverse.k-fx-start .k-fx-next {
  transform: translatex(-100%);
}
.k-fx-tile.k-fx-reverse.k-fx-end .k-fx-next {
  transform: translatex(0);
}
.k-fx-tile.k-fx-right {
  /* right reverse */
}
.k-fx-tile.k-fx-right.k-fx-start .k-fx-next {
  transform: translatex(-100%);
}
.k-fx-tile.k-fx-right.k-fx-end .k-fx-current {
  transform: translatex(100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current {
  transform: translatex(0);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current {
  transform: translatex(-100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next {
  transform: translatex(100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-end .k-fx-next {
  transform: translatex(0%);
}
/* Overlay */
.k-fx.k-fx-overlay.k-fx-start .k-fx-next,
.k-fx.k-fx-overlay.k-fx-left.k-fx-start .k-fx-next {
  will-change: transform;
  transform: translatex(100%);
}
.k-fx.k-fx-overlay.k-fx-right.k-fx-start .k-fx-next {
  transform: translatex(-100%);
}
.k-fx.k-fx-overlay.k-fx-up.k-fx-start .k-fx-next {
  transform: translatey(100%);
}
.k-fx.k-fx-overlay.k-fx-down.k-fx-start .k-fx-next {
  transform: translatey(-100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-start .k-fx-next {
  transform: none;
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-start .k-fx-current {
  will-change: transform;
  transform: none;
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-end .k-fx-current,
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-left.k-fx-end .k-fx-current {
  transform: translatex(100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-right.k-fx-end .k-fx-current {
  transform: translatex(-100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-up.k-fx-end .k-fx-current {
  transform: translatey(100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-down.k-fx-end .k-fx-current {
  transform: translatey(-100%);
}
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Virtual List */
.k-virtual-wrap {
  position: relative;
}
.k-virtual-wrap .k-list.k-virtual-list {
  height: auto;
}
.k-virtual-content {
  overflow-y: scroll;
  /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch;
  position: relative;
}
.k-virtual-list > .k-virtual-content {
  position: absolute;
  top: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.k-virtual-option-label {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.k-virtual-wrap > .k-virtual-header {
  text-align: right;
}
.k-popup .k-item.k-first {
  position: relative;
}
.k-virtual-content > .k-virtual-list > .k-virtual-item {
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
}
.k-popup .k-list .k-item > .k-group,
.k-popup > .k-group-header,
.k-popup > .k-virtual-wrap > .k-group-header {
  text-transform: uppercase;
  font-size: .857em;
}
.k-popup .k-list .k-item > .k-group {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 .5em;
  line-height: 1.8;
}
.k-popup .k-state-hover > .k-group {
  right: -1px;
}
.k-virtual-item.k-first,
.k-group-header + .k-list > .k-item.k-first,
.k-static-header + .k-list > .k-item.k-first {
  border-top-style: solid;
  border-top-width: 1px;
  padding-top: 0;
}
.k-popup > .k-group-header,
.k-popup > .k-virtual-wrap > .k-group-header {
  text-align: right;
}
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@font-face {
  font-family: 'WebComponentsIcons';
  src: url('../fonts/glyphs/WebComponentsIcons.eot?gedxeo');
  src: url('../fonts/glyphs/WebComponentsIcons.eot?gedxeo#iefix') format('embedded-opentype'), url('../fonts/glyphs/WebComponentsIcons.ttf?gedxeo') format('truetype'), url('../fonts/glyphs/WebComponentsIcons.woff?gedxeo') format('woff'), url('../fonts/glyphs/WebComponentsIcons.svg?gedxeo#WebComponentsIcons') format('svg');
  font-weight: normal;
  font-style: normal;
}
.k-i-arrow-45-up-right:before {
  content: "\e000";
}
.k-i-collapse-ne:before {
  content: "\e000";
}
.k-i-resize-ne:before {
  content: "\e000";
}
.k-i-arrow-45-down-right:before {
  content: "\e001";
}
.k-i-collapse-se:before {
  content: "\e001";
}
.k-i-collapse:before {
  content: "\e001";
}
.k-i-resize-se:before {
  content: "\e001";
}
.k-i-arrow-45-down-left:before {
  content: "\e002";
}
.k-i-collapse-sw:before {
  content: "\e002";
}
.k-i-resize-sw:before {
  content: "\e002";
}
.k-i-arrow-45-up-left:before {
  content: "\e003";
}
.k-i-collapse-nw:before {
  content: "\e003";
}
.k-i-resize-new:before {
  content: "\e003";
}
.k-i-arrow-60-up:before {
  content: "\e004";
}
.k-i-arrow-n:before {
  content: "\e004";
}
.k-i-kpi-trend-increase:before {
  content: "\e004";
}
.k-i-arrow-60-right:before {
  content: "\e005";
}
.k-i-arrow-e:before {
  content: "\e005";
}
.k-i-expand:before {
  content: "\e005";
}
.k-i-arrow-60-down:before {
  content: "\e006";
}
.k-i-arrow-s:before {
  content: "\e006";
}
.k-i-kpi-trend-decrease:before {
  content: "\e006";
}
.k-i-arrow-60-left:before {
  content: "\e007";
}
.k-i-arrow-w:before {
  content: "\e007";
}
.k-i-arrow-end-up:before {
  content: "\e008";
}
.k-i-seek-n:before {
  content: "\e008";
}
.k-i-arrow-end-right:before {
  content: "\e009";
}
.k-i-seek-e:before {
  content: "\e009";
}
.k-i-arrow-end-down:before {
  content: "\e00a";
}
.k-i-seek-s:before {
  content: "\e00a";
}
.k-i-arrow-end-left:before {
  content: "\e00b";
}
.k-i-seek-w:before {
  content: "\e00b";
}
.k-i-arrow-double-60-up:before {
  content: "\e00c";
}
.k-i-arrow-seek-up:before {
  content: "\e00c";
}
.k-i-arrow-double-60-right:before {
  content: "\e00d";
}
.k-i-arrow-seek-right:before {
  content: "\e00d";
}
.k-i-forward-sm:before {
  content: "\e00d";
}
.k-i-arrow-double-60-down:before {
  content: "\e00e";
}
.k-i-arrow-seek-down:before {
  content: "\e00e";
}
.k-i-arrow-double-60-left:before {
  content: "\e00f";
}
.k-i-arrow-seek-left:before {
  content: "\e00f";
}
.k-i-rewind-sm:before {
  content: "\e00f";
}
.k-i-arrows-kpi:before {
  content: "\e010";
}
.k-i-kpi:before {
  content: "\e010";
}
.k-i-arrows-no-change:before {
  content: "\e011";
}
.k-i-arrow-overflow-down:before {
  content: "\e012";
}
.k-i-arrow-chevron-up:before {
  content: "\e013";
}
.k-i-arrow-chevron-right:before {
  content: "\e014";
}
.k-i-arrow-chevron-down:before {
  content: "\e015";
}
.k-i-arrow-chevron-left:before {
  content: "\e016";
}
.k-i-arrow-up:before {
  content: "\e017";
}
.k-i-arrow-right:before {
  content: "\e018";
}
.k-i-arrow-down:before {
  content: "\e019";
}
.k-i-arrow-left:before {
  content: "\e01a";
}
.k-i-arrow-drill:before {
  content: "\e01b";
}
.k-i-arrow-parent:before {
  content: "\e01c";
}
.k-i-arrow-root:before {
  content: "\e01d";
}
.k-i-arrows-resizing:before {
  content: "\e01e";
}
.k-i-arrows-dimensions:before {
  content: "\e01f";
}
.k-i-arrows-swap:before {
  content: "\e020";
}
.k-i-drag-and-drop:before {
  content: "\e021";
}
.k-i-categorize:before {
  content: "\e022";
}
.k-i-grid:before {
  content: "\e023";
}
.k-i-grid-layout:before {
  content: "\e024";
}
.k-i-group:before {
  content: "\e025";
}
.k-i-ungroup:before {
  content: "\e026";
}
.k-i-handler-drag:before {
  content: "\e027";
}
.k-i-layout:before {
  content: "\e028";
}
.k-i-layout-1-by-4:before {
  content: "\e029";
}
.k-i-layout-2-by-2:before {
  content: "\e02a";
}
.k-i-layout-side-by-side:before {
  content: "\e02b";
}
.k-i-layout-stacked:before {
  content: "\e02c";
}
.k-i-columns:before {
  content: "\e02d";
}
.k-i-rows:before {
  content: "\e02e";
}
.k-i-reorder:before {
  content: "\e02f";
}
.k-i-menu:before {
  content: "\e030";
}
.k-i-more-vertical:before {
  content: "\e031";
}
.k-i-more-horizontal:before {
  content: "\e032";
}
.k-i-undo:before {
  content: "\e100";
}
.k-i-redo:before {
  content: "\e101";
}
.k-i-reset:before {
  content: "\e102";
}
.k-i-reload:before {
  content: "\e103";
}
.k-i-refresh:before {
  content: "\e103";
}
.k-i-recurrence:before {
  content: "\e103";
}
.k-i-non-recurrence:before {
  content: "\e104";
}
.k-i-reset-sm:before {
  content: "\e105";
}
.k-i-reload-sm:before {
  content: "\e106";
}
.k-i-refresh-sm:before {
  content: "\e106";
}
.k-i-recurrence-sm:before {
  content: "\e106";
}
.k-i-clock:before {
  content: "\e107";
}
.k-i-calendar:before {
  content: "\e108";
}
.k-i-save:before {
  content: "\e109";
}
.k-i-floppy:before {
  content: "\e109";
}
.k-i-print:before {
  content: "\e10a";
}
.k-i-printer:before {
  content: "\e10a";
}
.k-i-edit:before {
  content: "\e10b";
}
.k-i-pencil:before {
  content: "\e10b";
}
.k-i-delete:before {
  content: "\e10c";
}
.k-i-trash:before {
  content: "\e10c";
}
.k-i-attachment:before {
  content: "\e10d";
}
.k-i-clip:before {
  content: "\e10d";
}
.k-i-attachment-45:before {
  content: "\e10e";
}
.k-i-clip-45:before {
  content: "\e10e";
}
.k-i-link-horizontal:before {
  content: "\e10f";
}
.k-i-hyperlink:before {
  content: "\e10f";
}
.k-i-unlink-horizontal:before {
  content: "\e110";
}
.k-i-hyperlink-remove:before {
  content: "\e110";
}
.k-i-link-vertical:before {
  content: "\e111";
}
.k-i-unlink-vertical:before {
  content: "\e112";
}
.k-i-lock:before {
  content: "\e113";
}
.k-i-unlock:before {
  content: "\e114";
}
.k-i-cancel:before {
  content: "\e115";
}
.k-i-cancel-outline:before {
  content: "\e116";
}
.k-i-cancel-circle:before {
  content: "\e117";
}
.k-i-check:before {
  content: "\e118";
}
.k-i-checkmark:before {
  content: "\e118";
}
.k-i-check-outline:before {
  content: "\e119";
}
.k-i-checkmark-outline:before {
  content: "\e119";
}
.k-i-success:before {
  content: "\e119";
}
.k-i-check-circle:before {
  content: "\e11a";
}
.k-i-checkmark-circle:before {
  content: "\e11a";
}
.k-i-close:before {
  content: "\e11b";
}
.k-i-x:before {
  content: "\e11b";
}
.k-i-close-outline:before {
  content: "\e11c";
}
.k-i-x-outline:before {
  content: "\e11c";
}
.k-i-error:before {
  content: "\e11c";
}
.k-i-close-circle:before {
  content: "\e11d";
}
.k-i-x-circle:before {
  content: "\e11d";
}
.k-i-plus:before {
  content: "\e11e";
}
.k-i-add:before {
  content: "\e11e";
}
.k-i-plus-outline:before {
  content: "\e11f";
}
.k-i-plus-circle:before {
  content: "\e120";
}
.k-i-minus:before {
  content: "\e121";
}
.k-i-kpi-trend-equal:before {
  content: "\e121";
}
.k-i-minus-outline:before {
  content: "\e122";
}
.k-i-minus-circle:before {
  content: "\e123";
}
.k-i-sort-asc:before {
  content: "\e124";
}
.k-i-sort-desc:before {
  content: "\e125";
}
.k-i-unsort:before {
  content: "\e126";
}
.k-i-sort-clear:before {
  content: "\e126";
}
.k-i-sort-asc-sm:before {
  content: "\e127";
}
.k-i-sort-desc-sm:before {
  content: "\e128";
}
.k-i-filter:before {
  content: "\e129";
}
.k-i-filter-clear:before {
  content: "\e12a";
}
.k-i-filter-sm:before {
  content: "\e12b";
}
.k-i-filter-sort-asc-sm:before {
  content: "\e12c";
}
.k-i-filter-sort-desc-sm:before {
  content: "\e12d";
}
.k-i-filter-add-expression:before {
  content: "\e12e";
}
.k-i-filter-add-group:before {
  content: "\e12f";
}
.k-i-login:before {
  content: "\e130";
}
.k-i-logout:before {
  content: "\e131";
}
.k-i-download:before {
  content: "\e132";
}
.k-i-upload:before {
  content: "\e133";
}
.k-i-hyperlink-open:before {
  content: "\e134";
}
.k-i-hyperlink-open-sm:before {
  content: "\e135";
}
.k-i-launch:before {
  content: "\e136";
}
.k-i-window:before {
  content: "\e137";
}
.k-i-window-maximize:before {
  content: "\e137";
}
.k-i-windows:before {
  content: "\e138";
}
.k-i-window-restore:before {
  content: "\e138";
}
.k-i-tiles:before {
  content: "\e138";
}
.k-i-window-minimize:before {
  content: "\e139";
}
.k-i-gear:before {
  content: "\e13a";
}
.k-i-cog:before {
  content: "\e13a";
}
.k-i-custom:before {
  content: "\e13a";
}
.k-i-gears:before {
  content: "\e13b";
}
.k-i-cogs:before {
  content: "\e13b";
}
.k-i-wrench:before {
  content: "\e13c";
}
.k-i-settings:before {
  content: "\e13c";
}
.k-i-preview:before {
  content: "\e13d";
}
.k-i-eye:before {
  content: "\e13d";
}
.k-i-zoom:before {
  content: "\e13e";
}
.k-i-search:before {
  content: "\e13e";
}
.k-i-zoom-in:before {
  content: "\e13f";
}
.k-i-zoom-out:before {
  content: "\e140";
}
.k-i-pan:before {
  content: "\e141";
}
.k-i-move:before {
  content: "\e141";
}
.k-i-calculator:before {
  content: "\e142";
}
.k-i-cart:before {
  content: "\e143";
}
.k-i-shopping-cart:before {
  content: "\e143";
}
.k-i-connector:before {
  content: "\e144";
}
.k-i-plus-sm:before {
  content: "\e145";
}
.k-i-minus-sm:before {
  content: "\e146";
}
.k-i-kpi-status-deny:before {
  content: "\e147";
}
.k-i-kpi-status-hold:before {
  content: "\e148";
}
.k-i-kpi-status-open:before {
  content: "\e149";
}
.k-i-play:before {
  content: "\e200";
}
.k-i-pause:before {
  content: "\e201";
}
.k-i-stop:before {
  content: "\e202";
}
.k-i-rewind:before {
  content: "\e203";
}
.k-i-forward:before {
  content: "\e204";
}
.k-i-volume-down:before {
  content: "\e205";
}
.k-i-volume-up:before {
  content: "\e206";
}
.k-i-volume-off:before {
  content: "\e207";
}
.k-i-hd:before {
  content: "\e208";
}
.k-i-subtitles:before {
  content: "\e209";
}
.k-i-playlist:before {
  content: "\e20a";
}
.k-i-audio:before {
  content: "\e20b";
}
.k-i-play-sm:before {
  content: "\e20c";
}
.k-i-pause-sm:before {
  content: "\e20d";
}
.k-i-stop-sm:before {
  content: "\e20e";
}
.k-i-heart-outline:before {
  content: "\e300";
}
.k-i-fav-outline:before {
  content: "\e300";
}
.k-i-favorite-outline:before {
  content: "\e300";
}
.k-i-heart:before {
  content: "\e301";
}
.k-i-fav:before {
  content: "\e301";
}
.k-i-favorite:before {
  content: "\e301";
}
.k-i-star-outline:before {
  content: "\e302";
}
.k-i-bookmark-outline:before {
  content: "\e302";
}
.k-i-star:before {
  content: "\e303";
}
.k-i-bookmark:before {
  content: "\e303";
}
.k-i-checkbox:before {
  content: "\e304";
}
.k-i-shape-rect:before {
  content: "\e304";
}
.k-i-checkbox-checked:before {
  content: "\e305";
}
.k-i-tri-state-indeterminate:before {
  content: "\e306";
}
.k-i-tri-state-null:before {
  content: "\e307";
}
.k-i-circle:before {
  content: "\e308";
}
.k-i-radiobutton:before {
  content: "\e309";
}
.k-i-shape-circle:before {
  content: "\e309";
}
.k-i-radiobutton-checked:before {
  content: "\e30a";
}
.k-i-notification:before {
  content: "\e400";
}
.k-i-bell:before {
  content: "\e400";
}
.k-i-information:before {
  content: "\e401";
}
.k-i-info:before {
  content: "\e401";
}
.k-i-question:before {
  content: "\e402";
}
.k-i-help:before {
  content: "\e402";
}
.k-i-warning:before {
  content: "\e403";
}
.k-i-exception:before {
  content: "\e403";
}
.k-i-photo-camera:before {
  content: "\e500";
}
.k-i-image:before {
  content: "\e501";
}
.k-i-photo:before {
  content: "\e501";
}
.k-i-image-export:before {
  content: "\e502";
}
.k-i-photo-export:before {
  content: "\e502";
}
.k-i-zoom-actual-size:before {
  content: "\e503";
}
.k-i-zoom-best-fit:before {
  content: "\e504";
}
.k-i-image-resize:before {
  content: "\e505";
}
.k-i-crop:before {
  content: "\e506";
}
.k-i-mirror:before {
  content: "\e507";
}
.k-i-flip-horizontal:before {
  content: "\e508";
}
.k-i-flip-vertical:before {
  content: "\e509";
}
.k-i-rotate:before {
  content: "\e50a";
}
.k-i-rotate-right:before {
  content: "\e50b";
}
.k-i-rotate-left:before {
  content: "\e50c";
}
.k-i-brush:before {
  content: "\e50d";
}
.k-i-palette:before {
  content: "\e50e";
}
.k-i-paint:before {
  content: "\e50f";
}
.k-i-droplet:before {
  content: "\e50f";
}
.k-i-background:before {
  content: "\e50f";
}
.k-i-line:before {
  content: "\e510";
}
.k-i-shape-line:before {
  content: "\e510";
}
.k-i-brightness-contrast:before {
  content: "\e511";
}
.k-i-saturation:before {
  content: "\e512";
}
.k-i-invert-colors:before {
  content: "\e513";
}
.k-i-transperancy:before {
  content: "\e514";
}
.k-i-opacity:before {
  content: "\e514";
}
.k-i-greyscale:before {
  content: "\e515";
}
.k-i-blur:before {
  content: "\e516";
}
.k-i-sharpen:before {
  content: "\e517";
}
.k-i-shape:before {
  content: "\e518";
}
.k-i-round-corners:before {
  content: "\e519";
}
.k-i-front-element:before {
  content: "\e51a";
}
.k-i-back-element:before {
  content: "\e51b";
}
.k-i-forward-element:before {
  content: "\e51c";
}
.k-i-backward-element:before {
  content: "\e51d";
}
.k-i-align-left-element:before {
  content: "\e51e";
}
.k-i-align-center-element:before {
  content: "\e51f";
}
.k-i-align-right-element:before {
  content: "\e520";
}
.k-i-align-top-element:before {
  content: "\e521";
}
.k-i-align-middle-element:before {
  content: "\e522";
}
.k-i-align-bottom-element:before {
  content: "\e523";
}
.k-i-thumbnails-up:before {
  content: "\e524";
}
.k-i-thumbnails-right:before {
  content: "\e525";
}
.k-i-thumbnails-down:before {
  content: "\e526";
}
.k-i-thumbnails-left:before {
  content: "\e527";
}
.k-i-full-screen:before {
  content: "\e528";
}
.k-i-fullscreen:before {
  content: "\e528";
}
.k-i-full-screen-exit:before {
  content: "\e529";
}
.k-i-fullscreen-exit:before {
  content: "\e529";
}
.k-i-reset-color:before {
  content: "\e52a";
}
.k-i-paint-remove:before {
  content: "\e52a";
}
.k-i-background-remove:before {
  content: "\e52a";
}
.k-i-page-properties:before {
  content: "\e600";
}
.k-i-bold:before {
  content: "\e601";
}
.k-i-italic:before {
  content: "\e602";
}
.k-i-underline:before {
  content: "\e603";
}
.k-i-font-family:before {
  content: "\e604";
}
.k-i-foreground-color:before {
  content: "\e605";
}
.k-i-convert-lowercase:before {
  content: "\e606";
}
.k-i-convert-uppercase:before {
  content: "\e607";
}
.k-i-strikethrough:before {
  content: "\e608";
}
.k-i-sub-script:before {
  content: "\e609";
}
.k-i-sup-script:before {
  content: "\e60a";
}
.k-i-div:before {
  content: "\e60b";
}
.k-i-all:before {
  content: "\e60c";
}
.k-i-h1:before {
  content: "\e60d";
}
.k-i-h2:before {
  content: "\e60e";
}
.k-i-h3:before {
  content: "\e60f";
}
.k-i-h4:before {
  content: "\e610";
}
.k-i-h5:before {
  content: "\e611";
}
.k-i-h6:before {
  content: "\e612";
}
.k-i-list-ordered:before {
  content: "\e613";
}
.k-i-list-numbered:before {
  content: "\e613";
}
.k-i-list-unordered:before {
  content: "\e614";
}
.k-i-list-bulleted:before {
  content: "\e614";
}
.k-i-indent-increase:before {
  content: "\e615";
}
.k-i-indent:before {
  content: "\e615";
}
.k-i-indent-decrease:before {
  content: "\e616";
}
.k-i-outdent:before {
  content: "\e616";
}
.k-i-insert-up:before {
  content: "\e617";
}
.k-i-insert-top:before {
  content: "\e617";
}
.k-i-insert-middle:before {
  content: "\e618";
}
.k-i-insert-down:before {
  content: "\e619";
}
.k-i-insert-bottom:before {
  content: "\e619";
}
.k-i-align-top:before {
  content: "\e61a";
}
.k-i-align-middle:before {
  content: "\e61b";
}
.k-i-align-bottom:before {
  content: "\e61c";
}
.k-i-align-left:before {
  content: "\e61d";
}
.k-i-align-center:before {
  content: "\e61e";
}
.k-i-align-right:before {
  content: "\e61f";
}
.k-i-align-justify:before {
  content: "\e620";
}
.k-i-align-remove:before {
  content: "\e621";
}
.k-i-text-wrap:before {
  content: "\e622";
}
.k-i-rule-horizontal:before {
  content: "\e623";
}
.k-i-table-align-top-left:before {
  content: "\e624";
}
.k-i-table-align-top-center:before {
  content: "\e625";
}
.k-i-table-align-top-right:before {
  content: "\e626";
}
.k-i-table-align-middle-left:before {
  content: "\e627";
}
.k-i-table-align-middle-center:before {
  content: "\e628";
}
.k-i-table-align-middle-right:before {
  content: "\e629";
}
.k-i-table-align-bottom-left:before {
  content: "\e62a";
}
.k-i-table-align-bottom-center:before {
  content: "\e62b";
}
.k-i-table-align-bottom-right:before {
  content: "\e62c";
}
.k-i-table-align-remove:before {
  content: "\e62d";
}
.k-i-borders-all:before {
  content: "\e62e";
}
.k-i-all-borders:before {
  content: "\e62e";
}
.k-i-borders-outside:before {
  content: "\e62f";
}
.k-i-outside-borders:before {
  content: "\e62f";
}
.k-i-borders-inside:before {
  content: "\e630";
}
.k-i-inside-borders:before {
  content: "\e630";
}
.k-i-borders-inside-horizontal:before {
  content: "\e631";
}
.k-i-inside-horizontal-borders:before {
  content: "\e631";
}
.k-i-borders-inside-vertical:before {
  content: "\e632";
}
.k-i-inside-vertical-borders:before {
  content: "\e632";
}
.k-i-border-top:before {
  content: "\e633";
}
.k-i-top-border:before {
  content: "\e633";
}
.k-i-border-bottom:before {
  content: "\e634";
}
.k-i-bottom-border:before {
  content: "\e634";
}
.k-i-border-left:before {
  content: "\e635";
}
.k-i-left-border:before {
  content: "\e635";
}
.k-i-border-right:before {
  content: "\e636";
}
.k-i-right-border:before {
  content: "\e636";
}
.k-i-border-no:before {
  content: "\e637";
}
.k-i-no-border:before {
  content: "\e637";
}
.k-i-borders-show-hide:before {
  content: "\e638";
}
.k-i-form:before {
  content: "\e639";
}
.k-i-border:before {
  content: "\e639";
}
.k-i-form-element:before {
  content: "\e63a";
}
.k-i-code-snippet:before {
  content: "\e63b";
}
.k-i-select-all:before {
  content: "\e63c";
}
.k-i-button:before {
  content: "\e63d";
}
.k-i-select-box:before {
  content: "\e63e";
}
.k-i-calendar-date:before {
  content: "\e63f";
}
.k-i-group-box:before {
  content: "\e640";
}
.k-i-textarea:before {
  content: "\e641";
}
.k-i-textbox:before {
  content: "\e642";
}
.k-i-textbox-hidden:before {
  content: "\e643";
}
.k-i-password:before {
  content: "\e644";
}
.k-i-paragraph-add:before {
  content: "\e645";
}
.k-i-edit-tools:before {
  content: "\e646";
}
.k-i-template-manager:before {
  content: "\e647";
}
.k-i-change-manually:before {
  content: "\e648";
}
.k-i-track-changes:before {
  content: "\e649";
}
.k-i-track-changes-enable:before {
  content: "\e64a";
}
.k-i-track-changes-accept:before {
  content: "\e64b";
}
.k-i-track-changes-accept-all:before {
  content: "\e64c";
}
.k-i-track-changes-reject:before {
  content: "\e64d";
}
.k-i-track-changes-reject-all:before {
  content: "\e64e";
}
.k-i-document-manager:before {
  content: "\e64f";
}
.k-i-custom-icon:before {
  content: "\e650";
}
.k-i-dictionary-add:before {
  content: "\e651";
}
.k-i-image-light-dialog:before {
  content: "\e652";
}
.k-i-image-insert:before {
  content: "\e652";
}
.k-i-image-edit:before {
  content: "\e653";
}
.k-i-image-map-editor:before {
  content: "\e654";
}
.k-i-comment:before {
  content: "\e655";
}
.k-i-comment-remove:before {
  content: "\e656";
}
.k-i-comments-remove-all:before {
  content: "\e657";
}
.k-i-silverlight:before {
  content: "\e658";
}
.k-i-media-manager:before {
  content: "\e659";
}
.k-i-video-external:before {
  content: "\e65a";
}
.k-i-flash-manager:before {
  content: "\e65b";
}
.k-i-find-and-replace:before {
  content: "\e65c";
}
.k-i-find:before {
  content: "\e65c";
}
.k-i-copy:before {
  content: "\e65d";
}
.k-i-files:before {
  content: "\e65d";
}
.k-i-cut:before {
  content: "\e65e";
}
.k-i-paste:before {
  content: "\e65f";
}
.k-i-paste-as-html:before {
  content: "\e660";
}
.k-i-paste-from-word:before {
  content: "\e661";
}
.k-i-paste-from-word-strip-file:before {
  content: "\e662";
}
.k-i-paste-html:before {
  content: "\e663";
}
.k-i-paste-markdown:before {
  content: "\e664";
}
.k-i-paste-plain-text:before {
  content: "\e665";
}
.k-i-apply-format:before {
  content: "\e666";
}
.k-i-clear-css:before {
  content: "\e667";
}
.k-i-copy-format:before {
  content: "\e668";
}
.k-i-strip-all-formating:before {
  content: "\e669";
}
.k-i-strip-css-format:before {
  content: "\e66a";
}
.k-i-strip-font-elements:before {
  content: "\e66b";
}
.k-i-strip-span-elements:before {
  content: "\e66c";
}
.k-i-strip-word-formatting:before {
  content: "\e66d";
}
.k-i-format-code-block:before {
  content: "\e66e";
}
.k-i-style-builder:before {
  content: "\e66f";
}
.k-i-module-manager:before {
  content: "\e670";
}
.k-i-hyperlink-light-dialog:before {
  content: "\e671";
}
.k-i-hyperlink-insert:before {
  content: "\e671";
}
.k-i-hyperlink-globe:before {
  content: "\e672";
}
.k-i-hyperlink-globe-remove:before {
  content: "\e673";
}
.k-i-hyperlink-email:before {
  content: "\e674";
}
.k-i-anchor:before {
  content: "\e675";
}
.k-i-table-light-dialog:before {
  content: "\e676";
}
.k-i-table-insert:before {
  content: "\e676";
}
.k-i-table:before {
  content: "\e677";
}
.k-i-table-properties:before {
  content: "\e678";
}
.k-i-table-wizard:before {
  content: "\e678";
}
.k-i-table-cell:before {
  content: "\e679";
}
.k-i-table-cell-properties:before {
  content: "\e67a";
}
.k-i-table-column-insert-left:before {
  content: "\e67b";
}
.k-i-table-column-insert-right:before {
  content: "\e67c";
}
.k-i-table-row-insert-above:before {
  content: "\e67d";
}
.k-i-table-row-insert-below:before {
  content: "\e67e";
}
.k-i-table-column-delete:before {
  content: "\e67f";
}
.k-i-table-row-delete:before {
  content: "\e680";
}
.k-i-table-cell-delete:before {
  content: "\e681";
}
.k-i-table-delete:before {
  content: "\e682";
}
.k-i-cells-merge:before {
  content: "\e683";
}
.k-i-cells-merge-horizontally:before {
  content: "\e684";
}
.k-i-cells-merge-vertically:before {
  content: "\e685";
}
.k-i-cell-split-horizontally:before {
  content: "\e686";
}
.k-i-cell-split-vertically:before {
  content: "\e687";
}
.k-i-table-unmerge:before {
  content: "\e688";
}
.k-i-pane-freeze:before {
  content: "\e689";
}
.k-i-row-freeze:before {
  content: "\e68a";
}
.k-i-column-freeze:before {
  content: "\e68b";
}
.k-i-toolbar-float:before {
  content: "\e68c";
}
.k-i-spell-checker:before {
  content: "\e68d";
}
.k-i-validation-xhtml:before {
  content: "\e68e";
}
.k-i-validation-data:before {
  content: "\e68f";
}
.k-i-toggle-full-screen-mode:before {
  content: "\e690";
}
.k-i-formula-fx:before {
  content: "\e691";
}
.k-i-sum:before {
  content: "\e692";
}
.k-i-symbol:before {
  content: "\e693";
}
.k-i-dollar:before {
  content: "\e694";
}
.k-i-currency:before {
  content: "\e694";
}
.k-i-percent:before {
  content: "\e695";
}
.k-i-custom-format:before {
  content: "\e696";
}
.k-i-decimal-increase:before {
  content: "\e697";
}
.k-i-decimal-decrease:before {
  content: "\e698";
}
.k-i-font-size:before {
  content: "\e699";
}
.k-i-image-absolute-position:before {
  content: "\e69a";
}
.k-i-globe-outline:before {
  content: "\e700";
}
.k-i-globe:before {
  content: "\e701";
}
.k-i-marker-pin:before {
  content: "\e702";
}
.k-i-marker-pin-target:before {
  content: "\e703";
}
.k-i-pin:before {
  content: "\e704";
}
.k-i-unpin:before {
  content: "\e705";
}
.k-i-share:before {
  content: "\e800";
}
.k-i-user:before {
  content: "\e801";
}
.k-i-inbox:before {
  content: "\e802";
}
.k-i-blogger:before {
  content: "\e803";
}
.k-i-blogger-box:before {
  content: "\e804";
}
.k-i-delicious:before {
  content: "\e805";
}
.k-i-delicious-box:before {
  content: "\e806";
}
.k-i-digg:before {
  content: "\e807";
}
.k-i-digg-box:before {
  content: "\e808";
}
.k-i-email:before {
  content: "\e809";
}
.k-i-envelop:before {
  content: "\e809";
}
.k-i-letter:before {
  content: "\e809";
}
.k-i-email-box:before {
  content: "\e80a";
}
.k-i-envelop-box:before {
  content: "\e80a";
}
.k-i-letter-box:before {
  content: "\e80a";
}
.k-i-facebook:before {
  content: "\e80b";
}
.k-i-facebook-box:before {
  content: "\e80c";
}
.k-i-google:before {
  content: "\e80d";
}
.k-i-google-box:before {
  content: "\e80e";
}
.k-i-google-plus:before {
  content: "\e80f";
}
.k-i-google-plus-box:before {
  content: "\e810";
}
.k-i-linkedin:before {
  content: "\e811";
}
.k-i-linkedin-box:before {
  content: "\e812";
}
.k-i-myspace:before {
  content: "\e813";
}
.k-i-myspace-box:before {
  content: "\e814";
}
.k-i-pinterest:before {
  content: "\e815";
}
.k-i-pinterest-box:before {
  content: "\e816";
}
.k-i-reddit:before {
  content: "\e817";
}
.k-i-reddit-box:before {
  content: "\e818";
}
.k-i-stumble-upon:before {
  content: "\e819";
}
.k-i-stumble-upon-box:before {
  content: "\e81a";
}
.k-i-tell-a-friend:before {
  content: "\e81b";
}
.k-i-tell-a-friend-box:before {
  content: "\e81c";
}
.k-i-tumblr:before {
  content: "\e81d";
}
.k-i-tumblr-box:before {
  content: "\e81e";
}
.k-i-twitter:before {
  content: "\e81f";
}
.k-i-twitter-box:before {
  content: "\e820";
}
.k-i-yammer:before {
  content: "\e821";
}
.k-i-yammer-box:before {
  content: "\e822";
}
.k-i-behance:before {
  content: "\e823";
}
.k-i-behance-box:before {
  content: "\e824";
}
.k-i-dribbble:before {
  content: "\e825";
}
.k-i-dribbble-box:before {
  content: "\e826";
}
.k-i-rss:before {
  content: "\e827";
}
.k-i-rss-box:before {
  content: "\e828";
}
.k-i-vimeo:before {
  content: "\e829";
}
.k-i-vimeo-box:before {
  content: "\e82a";
}
.k-i-youtube:before {
  content: "\e82b";
}
.k-i-youtube-box:before {
  content: "\e82c";
}
.k-i-folder:before {
  content: "\e900";
}
.k-i-folder-open:before {
  content: "\e901";
}
.k-i-folder-add:before {
  content: "\e902";
}
.k-i-folder-up:before {
  content: "\e903";
}
.k-i-folder-more:before {
  content: "\e904";
}
.k-i-fields-more:before {
  content: "\e904";
}
.k-i-aggregate-fields:before {
  content: "\e905";
}
.k-i-file:before {
  content: "\e906";
}
.k-i-file-vertical:before {
  content: "\e906";
}
.k-i-file-add:before {
  content: "\e907";
}
.k-i-file-txt:before {
  content: "\e908";
}
.k-i-txt:before {
  content: "\e908";
}
.k-i-file-csv:before {
  content: "\e909";
}
.k-i-csv:before {
  content: "\e909";
}
.k-i-file-excel:before {
  content: "\e90a";
}
.k-i-file-xls:before {
  content: "\e90a";
}
.k-i-excel:before {
  content: "\e90a";
}
.k-i-xls:before {
  content: "\e90a";
}
.k-i-file-word:before {
  content: "\e90b";
}
.k-i-file-doc:before {
  content: "\e90b";
}
.k-i-word:before {
  content: "\e90b";
}
.k-i-doc:before {
  content: "\e90b";
}
.k-i-file-mdb:before {
  content: "\e90c";
}
.k-i-mdb:before {
  content: "\e90c";
}
.k-i-file-ppt:before {
  content: "\e90d";
}
.k-i-ppt:before {
  content: "\e90d";
}
.k-i-file-pdf:before {
  content: "\e90e";
}
.k-i-pdf:before {
  content: "\e90e";
}
.k-i-file-psd:before {
  content: "\e90f";
}
.k-i-psd:before {
  content: "\e90f";
}
.k-i-file-flash:before {
  content: "\e910";
}
.k-i-flash:before {
  content: "\e910";
}
.k-i-file-config:before {
  content: "\e911";
}
.k-i-config:before {
  content: "\e911";
}
.k-i-file-ascx:before {
  content: "\e912";
}
.k-i-ascx:before {
  content: "\e912";
}
.k-i-file-bac:before {
  content: "\e913";
}
.k-i-bac:before {
  content: "\e913";
}
.k-i-file-zip:before {
  content: "\e914";
}
.k-i-zip:before {
  content: "\e914";
}
.k-i-film:before {
  content: "\e915";
}
.k-i-css3:before {
  content: "\e916";
}
.k-i-html5:before {
  content: "\e917";
}
.k-i-html:before {
  content: "\e918";
}
.k-i-source-code:before {
  content: "\e918";
}
.k-i-view-source:before {
  content: "\e918";
}
.k-i-css:before {
  content: "\e919";
}
.k-i-js:before {
  content: "\e91a";
}
.k-i-exe:before {
  content: "\e91b";
}
.k-i-csproj:before {
  content: "\e91c";
}
.k-i-vbproj:before {
  content: "\e91d";
}
.k-i-cs:before {
  content: "\e91e";
}
.k-i-vb:before {
  content: "\e91f";
}
.k-i-sln:before {
  content: "\e920";
}
.k-i-cloud:before {
  content: "\e921";
}
.k-i-file-horizontal:before {
  content: "\e922";
}
.k-i-page-portrait::before {
  content: "\e906";
}
.k-i-page-landscape::before {
  content: "\e922";
}
.k-rtl .k-i-collapse:before {
  content: "\e002";
}
.k-rtl .k-i-expand:before {
  content: "\e007";
}
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.k-spreadsheet {
  width: 800px;
  height: 600px;
  position: relative;
  border-width: 1px;
  border-style: solid;
  cursor: default;
}
.k-spreadsheet .k-spreadsheet-pane {
  padding-right: 1px;
  position: absolute;
  border-width: 1px;
  border-style: solid;
  overflow: hidden;
  box-sizing: border-box;
}
.k-spreadsheet .k-spreadsheet-pane.k-top {
  border-top-width: 0;
}
.k-spreadsheet .k-spreadsheet-pane.k-left {
  border-left-width: 0;
}
.k-spreadsheet .k-spreadsheet-pane .k-spreadsheet-cell {
  position: absolute;
  white-space: pre;
  box-sizing: border-box;
  overflow: hidden;
  padding: 1px 3px;
  background-clip: padding-box;
}
.k-spreadsheet .k-spreadsheet-vaxis,
.k-spreadsheet .k-spreadsheet-haxis {
  position: absolute;
  border-style: solid;
}
.k-spreadsheet .k-spreadsheet-vaxis {
  top: 0;
  border-width: 0 0 0 1px;
}
.k-spreadsheet .k-spreadsheet-haxis {
  left: 0;
  border-width: 1px 0 0;
}
.k-spreadsheet .k-filter-range,
.k-spreadsheet .k-spreadsheet-selection {
  border-width: 1px;
  border-style: solid;
  position: absolute;
  box-sizing: border-box;
}
.k-spreadsheet .k-spreadsheet-active-cell {
  position: absolute;
  box-sizing: border-box;
}
.k-spreadsheet .k-spreadsheet-action-bar {
  border-width: 0 0 1px;
  border-style: solid;
  position: relative;
  font-size: 12px;
  font-family: Arial, Verdana, sans-serif;
  display: table;
  width: 100%;
}
.k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-name-editor,
.k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-formula-bar {
  display: table-cell;
}
.k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-name-editor {
  position: relative;
  vertical-align: top;
  border-width: 0 1px 0 0;
  border-style: solid;
}
.k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-name-editor .k-combobox {
  width: 9.5em;
  border-radius: 0;
  border-color: transparent;
}
.k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-name-editor .k-combobox .k-dropdown-wrap {
  border-radius: 0;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
  border-width: 0;
}
.k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-name-editor .k-combobox .k-dropdown-wrap .k-input {
  border-radius: 0;
}
.k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-name-editor .k-combobox .k-dropdown-wrap .k-select {
  border-radius: 0;
  margin: -1px -1px -1px 0;
  padding: 1px 1px 1px 0;
}
.k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-formula-bar {
  width: 100%;
  padding-left: 31px;
}
.k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-formula-bar::before {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 1em;
  height: 1em;
  text-align: center;
  vertical-align: middle;
  background-image: none;
  font: 16px/1 'WebComponentsIcons';
  speak: none;
  font-variant: normal;
  text-transform: none;
  text-indent: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e691";
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-width: 0 1px 0 0;
  border-style: solid;
  width: 24px;
  margin-left: -24px;
}
.k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-formula-input {
  overflow: hidden;
  height: 2.568em;
  max-height: 2.568em;
  line-height: 2.214em;
  text-indent: 0.8em;
  border: 0;
  margin: 0;
}
.k-spreadsheet .k-spreadsheet-action-bar .k-state-border-down {
  padding-bottom: 0;
}
.k-spreadsheet .k-spreadsheet-formula-input {
  outline: none;
  border-width: 0;
  height: 26px;
  line-height: 20px;
  width: 100%;
  box-sizing: border-box;
  white-space: pre;
  padding: 3px 0.5em;
}
.k-spreadsheet .k-spreadsheet-cell-editor {
  position: absolute;
  display: none;
  padding: 0 3px;
  line-height: 20px;
  z-index: 2000;
  overflow: hidden;
}
.k-spreadsheet > .k-spreadsheet-view {
  position: relative;
  font-size: 12px;
  font-family: Arial, Verdana, sans-serif;
}
.k-spreadsheet .k-tabstrip-wrapper {
  position: relative;
  line-height: 1.7em;
}
.k-spreadsheet .k-tabstrip-wrapper .k-tabstrip-items {
  padding: 0 0 0;
}
.k-spreadsheet .k-spreadsheet-quick-access-toolbar {
  display: inline-block;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  padding: 0 0 0;
}
.k-spreadsheet .k-spreadsheet-quick-access-toolbar .k-button {
  padding: .3em;
  line-height: 1.4em;
  height: auto;
}
.k-spreadsheet .k-merged-cells-wrapper,
.k-spreadsheet .k-selection-wrapper,
.k-spreadsheet .k-filter-wrapper,
.k-spreadsheet .k-spreadsheet-row-header {
  position: relative;
}
.k-spreadsheet .k-spreadsheet-column-header {
  position: absolute;
}
.k-spreadsheet .k-filter-wrapper {
  pointer-events: none;
}
.k-spreadsheet .k-spreadsheet-sheets-bar {
  border-width: 1px 0 0;
}
.k-spreadsheet .k-vertical-align-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.k-spreadsheet .k-vertical-align-bottom {
  position: relative;
  top: 100%;
  transform: translateY(-100%);
}
.k-spreadsheet .k-dirty {
  position: absolute;
  top: 0;
  right: 0;
  margin: -2px 0 0 -7px;
  border-width: 4px;
  border-color: #f00 #f00 transparent transparent;
}
.k-spreadsheet .k-single-selection::after {
  content: " ";
  height: 6px;
  width: 6px;
  position: absolute;
  display: block;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  margin-bottom: -5px;
  margin-right: -5px;
  border-width: 1px;
  border-style: solid;
  z-index: 100;
  cursor: crosshair;
}
.k-spreadsheet .k-spreadsheet-edit-container .k-single-selection::after {
  display: none;
}
.k-spreadsheet .k-auto-fill,
.k-spreadsheet .k-auto-fill-punch,
.k-spreadsheet .k-auto-fill-br-hint {
  position: absolute;
  box-sizing: border-box;
}
.k-spreadsheet .k-auto-fill {
  border-width: 1px;
  border-style: solid;
  cursor: crosshair;
}
.k-spreadsheet .k-auto-fill-wrapper {
  position: relative;
}
.k-spreadsheet .k-spreadsheet-vborder {
  position: absolute;
  border-left-style: solid;
  border-left-width: 1px;
}
.k-spreadsheet .k-spreadsheet-hborder {
  position: absolute;
  border-top-style: solid;
  border-top-width: 1px;
}
.k-spreadsheet-names-popup .k-item {
  position: relative;
}
.k-spreadsheet-names-popup .k-icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.7;
  cursor: pointer;
}
.k-spreadsheet-names-popup .k-icon:hover {
  opacity: 1;
}
.k-horizontal-resize {
  cursor: col-resize;
}
.k-vertical-resize {
  cursor: row-resize;
}
.k-spreadsheet-data,
.k-merged-cells-wrapper,
.k-selection-wrapper,
.k-spreadsheet-active-cell {
  cursor: cell;
}
.k-horizontal-resize .k-spreadsheet-data,
.k-horizontal-resize .k-merged-cells-wrapper,
.k-horizontal-resize .k-selection-wrapper,
.k-horizontal-resize .k-spreadsheet-active-cell {
  cursor: col-resize;
}
.k-vertical-resize .k-spreadsheet-data,
.k-vertical-resize .k-merged-cells-wrapper,
.k-vertical-resize .k-selection-wrapper,
.k-vertical-resize .k-spreadsheet-active-cell {
  cursor: row-resize;
}
.k-spreadsheet .k-colorpicker .k-tool-icon {
  overflow: visible;
}
.k-button.k-reset-color,
.k-button.k-custom-color {
  display: block;
  width: 100%;
  border-radius: 0;
  border: none;
  text-align: left;
  line-height: 2em;
}
.k-spreadsheet-colorpicker .k-colorpalette {
  border: 1px solid #dbdbdb;
  border-left: none;
  border-right: none;
  padding: 0.4em;
}
.k-spreadsheet-filter {
  position: absolute;
  cursor: pointer;
  pointer-events: all;
}
.k-spreadsheet-sample {
  float: right;
}
.k-spreadsheet-clipboard-paste,
.k-spreadsheet-clipboard {
  position: fixed;
  opacity: 0;
  top: 0;
  left: 0;
  overflow: hidden;
  padding: 0;
  margin: 0;
  border: 0;
  width: 1px;
  height: 1px;
  box-sizing: border-box;
}
textarea.k-spreadsheet-clipboard {
  position: fixed;
}
.k-spreadsheet-top-corner {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  border-width: 0 1px 1px 0;
  border-style: solid;
}
.k-spreadsheet-top-corner:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  right: 0;
  border-width: 6px;
  border-style: solid;
}
.k-spreadsheet-scroller {
  width: 100%;
  height: 100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  z-index: 1;
}
.k-spreadsheet-fixed-container {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  z-index: 2;
  user-select: none;
}
.k-spreadsheet-view-size {
  position: relative;
}
.k-spreadsheet-column-header,
.k-spreadsheet-row-header {
  text-align: center;
}
.k-spreadsheet-column-header .k-spreadsheet-cell,
.k-spreadsheet-row-header .k-spreadsheet-cell {
  border-style: solid;
  border-width: 0 1px 1px 0;
}
div.k-spreadsheet-window {
  padding: 1.167em .75em .75em;
}
.k-spreadsheet-window .k-edit-form-container {
  width: 100%;
}
.k-spreadsheet-window .k-root-tabs {
  border-width: 0;
  margin: -1em -1em 0;
  padding: .5em 1em 0;
}
.k-spreadsheet-window .k-root-tabs .k-loading {
  display: none;
}
.k-spreadsheet-window .k-list-wrapper {
  padding: .15em 0;
  border-width: 1px;
  border-style: solid;
  height: 190px;
}
.k-spreadsheet-window .k-list .k-item {
  border-radius: 0;
  padding: .5em .6em .4em .6em;
  cursor: pointer;
  line-height: 1.1em;
}
.k-spreadsheet-window .k-format-filter {
  width: 100%;
}
.k-spreadsheet-window .k-format-filter + .k-group-header + .k-list-wrapper {
  margin-top: 1em;
}
.k-spreadsheet-window .k-action-buttons {
  padding: 1em;
}
.k-spreadsheet-window .k-edit-label {
  width: 25%;
  margin: 0 0 0 .75em;
}
.k-spreadsheet-window .k-edit-field {
  width: 67%;
  margin: 0 .917em 0 0;
}
.k-spreadsheet-window .k-edit-field > .k-radio-label {
  margin-right: 1.25em;
  vertical-align: baseline;
}
.k-spreadsheet-window .k-edit-field > .k-radio-label:before,
.k-spreadsheet-window .k-edit-field > .k-radio-label:after {
  margin-top: -1px;
}
.k-spreadsheet-window .k-edit-label,
.k-spreadsheet-window .k-edit-field {
  padding: 0;
  line-height: 3em;
}
.k-spreadsheet-window .k-edit-field .k-textbox,
.k-spreadsheet-window .k-edit-field .k-dropdown {
  width: 100%;
}
.k-spreadsheet-window .k-export-config .k-hr {
  margin-left: 15px;
  margin-right: 15px;
}
.k-spreadsheet-window .k-export-config .k-edit-field {
  width: 40%;
  float: left;
  margin-left: 30px;
}
.k-spreadsheet-window .k-edit-field > input[type="radio"] {
  display: inline;
  opacity: 0;
  width: 0;
  margin: 0;
}
.k-spreadsheet-window .k-page-orientation {
  position: absolute;
  right: 2em;
  top: 25%;
}
.k-spreadsheet-window .k-page-orientation .k-icon {
  font-size: 6em;
}
.k-spreadsheet-window .k-export-config:after,
.k-spreadsheet-window > div:after {
  content: "";
  display: block;
  clear: both;
  height: 0;
}
.k-spreadsheet-format-cells .k-spreadsheet-preview {
  font-weight: bold;
  text-align: center;
  font-size: 1.16em;
  line-height: 3.64em;
  margin: 0 -0.863em 0.72em;
  border-style: solid;
  border-width: 1px 0;
}
.k-spreadsheet-border-palette {
  width: 153px;
}
.k-spreadsheet-border-palette .k-spreadsheet-border-type-palette .k-button {
  box-sizing: border-box;
  width: 20%;
}
.k-spreadsheet-border-palette .k-colorpalette {
  vertical-align: bottom;
}
.k-spreadsheet-popup .k-separator {
  width: 1px;
  height: 1.8em;
  vertical-align: middle;
  display: inline-block;
}
.k-spreadsheet-popup .k-colorpalette {
  vertical-align: top;
}
.k-spreadsheet-popup .k-button.k-button-icon {
  padding: 0.6em;
  border-width: 0;
  border-radius: 0;
}
.k-spreadsheet-popup .k-button.k-button-icontext {
  display: block;
  text-align: left;
  text-transform: initial;
  padding: 0.6em 1.2em 0.6em 1em;
  border-width: 0;
  border-radius: 0;
}
.k-spreadsheet-filter-menu {
  width: 280px;
}
.k-spreadsheet-filter-menu > .k-menu {
  border-width: 0;
}
.k-spreadsheet-filter-menu > .k-menu .k-link {
  padding-left: 26px;
}
.k-spreadsheet-filter-menu > .k-menu .k-icon {
  margin-left: -26px;
  width: 26px;
}
.k-spreadsheet-filter-menu .k-spreadsheet-value-treeview-wrapper {
  height: 200px;
  overflow-y: scroll;
  overflow-x: auto;
  border-width: 1px;
  border-style: solid;
}
.k-spreadsheet-filter-menu .k-spreadsheet-value-treeview-wrapper .k-treeview {
  overflow: visible;
  padding: 6px 7px;
}
.k-spreadsheet-filter-menu .k-details {
  border-top-width: 1px;
  border-top-style: solid;
  padding: 4px 0;
}
.k-spreadsheet-filter-menu .k-details-summary {
  cursor: pointer;
  line-height: 26px;
}
.k-spreadsheet-filter-menu .k-details-summary .k-icon {
  margin: 0 5px;
}
.k-spreadsheet-filter-menu .k-details-content {
  padding: 0 8px 0 26px;
}
.k-spreadsheet-filter-menu .k-details-content > .k-textbox,
.k-spreadsheet-filter-menu .k-details-content > .k-widget {
  width: 100%;
  margin-bottom: 3px;
}
.k-spreadsheet-filter-menu .k-details-content .k-space-right {
  background-image: none;
}
.k-spreadsheet-filter-menu .k-details-content .k-filter-and {
  width: 75px;
  margin: 8px 0;
}
.k-spreadsheet-filter-menu .k-action-buttons {
  border-top-width: 0;
  margin: 8px;
  padding: 0;
  position: static;
}
.k-resize-handle,
.k-resize-hint {
  position: absolute;
}
.k-resize-hint-handle {
  width: 100%;
  height: 20px;
}
.k-resize-hint-marker {
  width: 2px;
  height: 100%;
  margin: 0 auto;
}
.k-resize-hint-vertical .k-resize-hint-handle {
  height: 100%;
  width: 20px;
  float: left;
}
.k-resize-hint-vertical .k-resize-hint-marker {
  height: 2px;
  width: 100%;
}
.k-spreadsheet-quick-access-toolbar .k-button,
.k-button.k-spreadsheet-sheets-bar-add {
  border-color: transparent;
  background-color: transparent;
  background-image: none;
}
/* top TabStrip */
.k-spreadsheet-tabstrip {
  border-left-width: 0;
  border-right-width: 0;
  border-top-width: 0;
}
.k-spreadsheet-tabstrip > .k-content {
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
  margin: 0;
  padding: 0;
}
.k-spreadsheet-tabstrip .k-loading {
  display: none;
}
.k-spreadsheet-toolbar.k-toolbar {
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
}
.k-spreadsheet-toolbar.k-toolbar .k-overflow-anchor + * {
  margin-left: 0;
}
.k-spreadsheet-toolbar.k-toolbar > .k-button-group {
  border-width: 0;
}
.k-spreadsheet-toolbar > .k-widget,
.k-spreadsheet-toolbar .k-button,
.k-spreadsheet-toolbar > .k-button-group,
.k-spreadsheet-toolbar > .k-widget .k-state-default:not(.k-state-hover):not(.k-state-active) {
  margin: 0;
  border-color: transparent;
  background-color: transparent;
  background-image: none;
}
.k-spreadsheet-toolbar > .k-widget[data-property='fontSize'] {
  width: 75px;
}
.k-spreadsheet-toolbar > .k-widget[data-property='format'] {
  width: 100px;
}
.k-spreadsheet-toolbar > .k-widget[data-property='fontFamily'] {
  width: 130px;
}
.k-spreadsheet-toolbar > .k-combobox .k-state-default:not(.k-state-hover):not(.k-state-active) .k-input {
  background-color: transparent;
  box-shadow: none;
}
.k-spreadsheet-toolbar > .k-widget .k-state-default:not(.k-state-hover):not(.k-state-active) .k-select {
  border-color: transparent;
}
.k-spreadsheet-toolbar [data-tool=alignment],
.k-spreadsheet-toolbar [data-tool=backgroundColor],
.k-spreadsheet-toolbar [data-tool=borders],
.k-spreadsheet-toolbar [data-tool=freeze],
.k-spreadsheet-toolbar [data-tool=merge],
.k-spreadsheet-toolbar [data-tool=textColor] {
  width: auto;
}
.k-spreadsheet-toolbar .k-separator {
  margin: 0 3px;
  width: 0;
  overflow: hidden;
  height: 1.8em;
  vertical-align: middle;
  display: inline-block;
}
.k-spreadsheet-formula-input > .k-syntax-func.k-syntax-at-point,
.k-spreadsheet-formula-input > .k-syntax-bool.k-syntax-at-point,
.k-spreadsheet-formula-input > .k-syntax-ref.k-syntax-at-point,
.k-spreadsheet-formula-input > .k-syntax-str.k-syntax-at-point,
.k-spreadsheet-formula-input > .k-syntax-num.k-syntax-at-point {
  text-decoration: underline;
}
.k-spreadsheet-formula-input > .k-series-a,
.k-spreadsheet-formula-input > .k-series-b,
.k-spreadsheet-formula-input > .k-series-c,
.k-spreadsheet-formula-input > .k-series-d {
  background-color: transparent;
}
.k-spreadsheet-selection-highlight {
  border-width: 1px;
  border-style: solid;
  position: absolute;
  box-sizing: border-box;
}
.k-spreadsheet-formula-list {
  min-width: 100px;
}
.k-spreadsheet-formula-list .k-item {
  padding: 0 .3em;
  box-shadow: none;
}
/* bottom TabStrip */
.k-spreadsheet-sheets-bar {
  position: relative;
}
.k-button.k-spreadsheet-sheets-bar-add {
  position: absolute;
  bottom: .2em;
  left: .4em;
  z-index: 1;
}
.k-spreadsheet-sheets-bar .k-spreadsheet-sheets-remove {
  padding: 0;
  margin: 0.5em 0.5em 0.5em -0.5em;
}
.k-spreadsheet-sheets-bar .k-spreadsheet-sheets-remove .k-button-icon {
  padding: 2px;
  line-height: normal;
}
.k-spreadsheet-sheets-bar .k-spreadsheet-sheets-remove .k-icon {
  margin: 0;
}
.k-spreadsheet-sheets-items-hint {
  margin-top: .1em;
  background: none !important;
  border-width: 0 !important;
}
.k-spreadsheet-editor-button {
  position: absolute;
  width: 20px;
  z-index: 10;
  margin-left: 2px;
  padding: 0 !important;
}
.k-spreadsheet-editor-button.k-spreadsheet-last-column {
  margin-left: -21px;
}
.k-spreadsheet-editor-button span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.hint-wrapper {
  margin-top: 1em;
}
.k-edit-form-container > div:after {
  display: block;
  content: "";
  clear: both;
}
.k-spreadsheet-filter-menu .k-i-arrow-45-down-right {
  cursor: pointer;
}
.k-spreadsheet-import-errors .k--header-message {
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: 1px solid #888;
  margin-bottom: 5px;
}
.k-spreadsheet-import-errors .k--errors {
  max-height: 350px;
  overflow: auto;
}
.k-spreadsheet-import-errors .k--errors table {
  border-collapse: collapse;
  font-size: 90%;
}
.k-spreadsheet-import-errors .k--errors table td,
.k-spreadsheet-import-errors .k--errors table th {
  padding: 2px 4px;
  border-bottom: 1px solid #bbb;
}
.k-spreadsheet-import-errors .k--errors table th {
  text-align: left;
  border-bottom: 1px solid #000;
}
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Dialog */
.k-dialog {
  min-width: 90px;
  min-height: 3em;
  max-width: 100%;
  max-height: 100%;
}
.k-window.k-dialog {
  position: fixed;
  padding-top: 0;
}
.k-dialog .k-dialog-titlebar {
  position: static;
  margin: 0;
  padding: 0.6em 0.8em 1em 0.8em;
  width: auto;
}
.k-dialog .k-dialog-titlebar .k-dialog-title {
  margin: 0;
  padding: 0;
  width: auto;
  max-width: 98%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.2em;
  line-height: 1.2em;
}
.k-dialog .k-content {
  margin: 0;
  padding: 1.8em 1em 1.8em 1em;
}
.k-dialog.k-dialog-titleless .k-content {
  padding: 2.4em;
}
.k-dialog .k-dialog-buttongroup {
  margin: 0;
  padding: 0;
}
.k-dialog .k-dialog-titlebar .k-dialog-actions {
  top: .6em;
  right: .8em;
  padding: 0;
}
.k-dialog a.k-dialog-action.k-dialog-close {
  cursor: pointer;
  z-index: 10000;
}
.k-dialog.k-dialog-titleless a.k-dialog-action.k-dialog-close {
  right: .5em;
  top: 1em;
}
.k-dialog.k-alert .k-dialog-titlebar,
.k-dialog.k-confirm .k-dialog-titlebar,
.k-dialog.k-prompt .k-dialog-titlebar {
  background: none;
  border-bottom: none;
}
.k-dialog.k-alert .k-content,
.k-dialog.k-confirm .k-content {
  padding: 2.4em;
}
.k-dialog.k-prompt .k-content {
  padding: 1.2em 1.2em 0.6em 1.2em;
}
.k-dialog .k-prompt-container {
  width: auto;
  padding: 0.6em 1.2em 1.8em 1.2em;
}
.k-dialog .k-prompt-container .k-textbox {
  width: 100%;
}
.k-dialog .k-button {
  box-sizing: border-box;
}
/* Default fonts for PDF export */
/*!
 * Copyright 2017 Telerik AD
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* sans-serif */
@font-face {
  font-family: "DejaVu Sans";
  src: url("DejaVuSans.ttf?v=1.1") format("truetype");
}
@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  src: url("DejaVuSans-Bold.ttf?v=1.1") format("truetype");
}
@font-face {
  font-family: "DejaVu Sans";
  font-style: italic;
  src: url("DejaVuSans-Oblique.ttf?v=1.1") format("truetype");
}
@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  font-style: italic;
  src: url("DejaVuSans-BoldOblique.ttf?v=1.1") format("truetype");
}
/* serif */
@font-face {
  font-family: "DejaVu Serif";
  src: url("DejaVuSerif.ttf?v=1.1") format("truetype");
}
@font-face {
  font-family: "DejaVu Serif";
  font-weight: bold;
  src: url("DejaVuSerif-Bold.ttf?v=1.1") format("truetype");
}
@font-face {
  font-family: "DejaVu Serif";
  font-style: italic;
  src: url("DejaVuSerif-Italic.ttf?v=1.1") format("truetype");
}
@font-face {
  font-family: "DejaVu Serif";
  font-weight: bold;
  font-style: italic;
  src: url("DejaVuSerif-BoldItalic.ttf?v=1.1") format("truetype");
}
/* monospace */
@font-face {
  font-family: "DejaVu Mono";
  src: url("DejaVuSansMono.ttf?v=1.1") format("truetype");
}
@font-face {
  font-family: "DejaVu Mono";
  font-weight: bold;
  src: url("DejaVuSansMono-Bold.ttf?v=1.1") format("truetype");
}
@font-face {
  font-family: "DejaVu Mono";
  font-style: italic;
  src: url("DejaVuSansMono-Oblique.ttf?v=1.1") format("truetype");
}
@font-face {
  font-family: "DejaVu Mono";
  font-weight: bold;
  font-style: italic;
  src: url("DejaVuSansMono-BoldOblique.ttf?v=1.1") format("truetype");
}
.kendo-pdf-hide-pseudo-elements:before,
.kendo-pdf-hide-pseudo-elements:after {
  display: none !important;
}
.k-pdf-export sub,
.k-pdf-export sup {
  display: inline-block;
}
.k-button {
  line-height: 1.143em;
  padding: 10px 14px;
}
.k-button-icon,
.k-split-button-arrow {
  padding: 10px calc(7px);
  height: calc(23em);
  width: calc(23em);
}
.k-widget.k-tabstrip {
  background-image: none;
  border-style: none;
  box-shadow: none;
}
.k-tabstrip .k-tabstrip-items {
  padding: 0;
}
.k-tabstrip > .k-button.k-bare {
  top: .3em;
}
.k-tabstrip > div.k-content,
.k-panelbar .k-tabstrip > div.k-content {
  margin: 0;
}
.k-panelbar > .k-item > .k-link,
.k-panel > .k-item > .k-link {
  line-height: 3.5em;
}
.k-tabstrip > .k-tabstrip-items > .k-item {
  text-transform: uppercase;
  border-width: 0;
  border-style: solid;
  padding: 0;
}
.k-tabstrip-left > .k-tabstrip-items .k-loading,
.k-tabstrip-right > .k-tabstrip-items .k-loading {
  display: none;
}
.k-tabstrip-top > .k-tabstrip-items .k-tab-on-top,
.k-tabstrip-top > .k-tabstrip-items .k-state-active {
  margin-bottom: -1px;
}
.k-tabstrip-top > .k-tabstrip-items > .k-item {
  border-bottom-width: 2px;
}
.k-tabstrip-left > .k-tabstrip-items .k-tab-on-top,
.k-tabstrip-left > .k-tabstrip-items .k-state-active {
  margin-right: -2px;
}
.k-tabstrip-left > .k-tabstrip-items > .k-item {
  border-right-width: 2px;
}
.k-tabstrip-right > .k-tabstrip-items > .k-item {
  border-left-width: 2px;
}
.k-tabstrip-bottom > .k-tabstrip-items > .k-item {
  border-top-width: 2px;
}
.k-tabstrip .k-tabstrip-items .k-link {
  padding: 1.071em;
}
.k-slider-track {
  border-width: 1px;
  border-style: solid;
}
.k-grouping-dropclue {
  margin-top: 10px;
}
.k-grid-header th.k-header,
.k-filter-row th {
  padding: .786em .6em;
}
.k-grid-header th.k-header {
  padding-left: 1.286em;
}
.k-rtl .k-grid-header th.k-header {
  padding-right: 1.286em;
}
.k-filtercell > span {
  padding-right: 5.714em;
}
.k-filtercell > span > .k-button {
  padding-left: .714em;
  padding-right: .714em;
  line-height: 1.286em;
  margin: 0;
}
.k-filter-row .k-dropdown-operator {
  width: 2.714em;
  right: 2.857em;
}
.k-filter-menu {
  padding: .5em .5em 0;
}
.k-filter-menu .k-button {
  margin: 0;
  width: 50%;
  border-radius: 0;
}
.k-filter-menu .k-primary {
  float: right;
}
.k-filter-menu > div > div:last-child {
  margin: 0.5em -0.5em 0;
  border-top-width: 1px;
  border-top-style: solid;
}
.k-filter-menu .k-primary {
  border-left-width: 1px;
  border-left-style: solid;
}
.k-grouping-header .k-group-indicator {
  padding: .5em .15em .429em .4em;
}
.k-grid .k-grouping-row td {
  padding: .6em .6em 0.643em;
}
.k-grouping-header a,
.k-grouping-header .k-button {
  vertical-align: baseline;
}
.k-grid td {
  padding: 0.929em 1.286em;
}
.k-grid-header th.k-header > .k-link {
  padding: 0;
  margin: 0;
  min-height: 16px;
  line-height: inherit;
}
.k-grouping-header {
  line-height: 2.6;
}
.k-grid-content tr:last-child > td {
  border-bottom-width: 0;
}
.k-grid tr td,
.k-pivot-layout .k-grid tr td {
  border-bottom-width: 1px;
  border-style: solid;
}
.k-grouping-row + tr td {
  border-top-width: 0;
}
.k-grid-content table tr:first-child td,
.k-grid-content-locked table tr:first-child td {
  border-top: 0;
}
.k-grid-content tr td {
  border-left-width: 0;
}
.k-pager-numbers .k-state-selected {
  line-height: 2.429em;
  cursor: pointer;
}
.k-pager-numbers .k-link,
.k-pager-numbers .k-state-selected {
  border-width: 2px 0 0;
  padding-top: .7em;
}
.k-pager-wrap {
  line-height: 1.286em;
  padding: .429em 0 .429em .25em;
}
.k-pager-wrap > .k-link,
.k-pager-numbers .k-link {
  height: 2.429em;
  line-height: 2.429em;
}
.k-pager-wrap .k-link,
.k-pager-sizes {
  padding: 0;
  min-width: 2.429em;
}
.k-pager-wrap .k-pager-numbers .k-state-selected {
  margin-top: -0.45em;
  padding-top: .45em;
  min-width: 2.429em;
}
.k-pager-wrap input.k-textbox {
  height: 2.571em;
}
.k-pager-info {
  padding: .714em 1.333em .643em 1.333em;
}
.k-pager-wrap .k-pager-refresh {
  margin-right: 1.214em;
}
.k-pager-wrap .k-dropdown {
  width: 5.2em;
}
.k-combobox > .k-dropdown-wrap > .k-i-close {
  right: 3.3em;
}
.k-combobox-clearable .k-input {
  padding-right: 2.5em;
}
.k-multiselect > .k-multiselect-wrap > .k-i-close {
  top: 7px;
}
.k-autocomplete .k-i-loading,
.k-multiselect .k-i-loading {
  bottom: 10px;
}
.k-multiselect > .k-multiselect-wrap > .k-i-close {
  top: 10px;
}
.k-autocomplete > .k-i-close,
.k-multiselect > .k-multiselect-wrap > .k-i-close {
  margin-right: 10px;
}
.k-dropdown-wrap,
.k-picker-wrap,
.k-numeric-wrap {
  padding: 0 2.571em 0 0;
}
.k-picker-wrap .k-select,
.k-numeric-wrap .k-select,
.k-dropdown-wrap .k-select {
  width: 2.571em;
}
.k-datetimepicker {
  width: 19.5em;
}
.k-datetimepicker .k-select {
  width: 5em;
}
.k-datetimepicker .k-picker-wrap {
  padding-right: 5em;
}
.k-datetimepicker .k-picker-wrap .k-icon {
  margin: 0 6px;
}
.k-calendar .k-header {
  margin: 0 -5px;
}
.k-calendar td {
  padding: 0;
}
.k-calendar .k-content .k-link {
  min-height: 2.571em;
  line-height: 2.571em;
  padding: 0;
  text-align: center;
}
.k-calendar .k-century .k-link {
  text-align: left;
  padding: 0 .7em;
  line-height: 1.3em;
}
.k-calendar th {
  border-bottom-width: 0;
  padding: .714em .45em .714em 0;
}
.k-calendar .k-footer {
  border-top-width: 1px;
  border-top-style: solid;
  margin: 0 -5px;
}
.k-popup.k-list-container {
  padding: 0.286em 0;
}
.k-popup.k-calendar-containe {
  padding: 0;
}
.k-popup .k-list .k-item,
.k-popup > .k-group-header,
.k-fieldselector .k-list .k-item {
  min-height: 2.143em;
  line-height: 2.143em;
}
.k-fieldselector .k-pivot-configurator-settings li.k-item {
  padding-right: 3.8em;
}
.k-popup .k-list .k-item {
  padding: 1px 11px;
}
.k-button,
.k-calendar .k-header .k-link,
.k-calendar .k-footer {
  text-transform: uppercase;
}
.k-widget.k-calendar .k-nav-fast {
  width: 65%;
  height: 2.571em;
  line-height: 2.571em;
  margin: 0.429em -0.08333em 0.429em 0;
}
.k-calendar .k-header .k-icon {
  vertical-align: middle;
}
.k-widget.k-calendar .k-nav-prev,
.k-widget.k-calendar .k-nav-next {
  position: absolute;
  top: 0.429em;
  line-height: 2.571em;
  height: 2.571em;
}
.k-calendar .k-header .k-link.k-nav-prev,
.k-calendar .k-header .k-link.k-nav-next {
  height: 2.571em;
  width: 2.571em;
}
.k-widget.k-calendar .k-nav-prev {
  left: 0.429em;
}
.k-widget.k-calendar .k-nav-next {
  right: 0.429em;
}
.k-calendar .k-footer .k-nav-today,
.k-calendar .k-footer > .k-state-disabled {
  padding: 1.143em 0 1.071em;
}
.k-popup.k-calendar-container {
  padding: 0;
  border: 0;
}
.k-multiselect-wrap .k-input {
  height: 2.214em;
}
.k-multiselect-wrap li {
  border-radius: 1.071em;
  margin: 3px 0 3px 3px;
  padding: 0 1.6em 0 .857em;
  line-height: 1.86em;
}
.k-multiselect-wrap li span {
  margin-right: .4em;
}
.k-multiselect-wrap li span.k-icon {
  margin: 0;
}
.k-multiselect-wrap .k-select {
  padding-top: 0;
}
.k-numeric-wrap .k-select {
  vertical-align: baseline;
}
.k-numerictextbox .k-link {
  display: block;
  height: 1.284em;
}
.k-numerictextbox .k-link .k-i-arrow-60-up {
  vertical-align: bottom;
}
.k-numerictextbox .k-link .k-i-arrow-60-down {
  vertical-align: top;
}
.k-menu.k-header,
.k-menu .k-item,
.k-widget.k-menu-horizontal > .k-item {
  border-width: 0;
}
.k-popup.k-context-menu {
  border-width: 1px;
  border-style: solid;
}
.k-context-menu.k-menu-vertical > .k-item > .k-link,
.k-menu .k-menu-group .k-item > .k-link {
  padding: 0.5em 4.929em 0.5em 1.714em;
}
.k-menu .k-item > .k-link > .k-i-arrow-60-right {
  right: 2.143rem;
}
.k-menu .k-animation-container .k-menu-group {
  padding: 1.143em 0;
}
.k-column-menu .k-menu .k-animation-container .k-menu-group {
  padding: 0;
}
.k-column-menu .k-menu-vertical .k-separator {
  height: 0;
}
table.k-editor {
  border-spacing: 0;
}
.k-editor.k-resizable .k-editable-area {
  padding-bottom: 20px;
}
.k-editor .k-editable-area .k-content {
  border-width: 1px 0 0;
}
.k-editor .k-editor-toolbar-wrap {
  padding-left: 10px;
}
.k-toolbar {
  line-height: 3.42em;
}
.k-toolbar > div > label {
  line-height: 3.42em;
}
.k-toolbar .k-overflow-anchor > .k-icon {
  margin-top: -6px;
  margin-bottom: -6px;
}
.k-toolbar .k-dropdown {
  margin-top: -1px;
}
.k-toolbar .k-split-button .k-button {
  padding-top: 10px;
  padding-bottom: 10px;
}
.k-toolbar .k-button-group {
  line-height: 3.286em;
}
.k-toolbar .k-button-icon,
.k-toolbar .k-split-button-arrow {
  line-height: 1.85714286;
  height: calc(23.85714286em);
  width: calc(23.85714286em);
}
.k-toolbar .k-button-group .k-button {
  line-height: inherit;
  padding: 0 .857em;
  margin: 0;
}
.k-toolbar .k-input {
  height: 2.214em;
  line-height: 2.214em;
  padding: 0.177em 0;
  text-indent: 0.8em;
  border: 0;
  margin: 0;
}
.k-toolbar .k-overflow-anchor {
  border-width: 0;
  line-height: 3.286em;
  width: 3.42em;
  height: 3.42em;
  padding: 0;
}
.k-overflow-container .k-overflow-button,
.k-split-container .k-button {
  font-size: 1.2em;
}
.k-button-group .k-tool {
  margin: 6px 0;
}
.k-editor-toolbar li.k-tool-group,
.k-editor-toolbar li.k-overflow-tools {
  padding: 0;
}
.k-editor-toolbar .k-button-group .k-tool {
  width: 48px;
  height: 48px;
  line-height: 48px;
  margin: 0;
}
.k-editor-toolbar .k-tool,
.k-editor-toolbar .k-button-group .k-tool.k-group-end {
  border-width: 0;
}
.k-editor-toolbar .k-state-selected {
  font-weight: bold;
}
.k-filebrowser .k-filebrowser-toolbar {
  display: flex;
}
.k-filebrowser .k-toolbar-wrap {
  display: inline-flex;
  align-items: center;
}
.k-filebrowser .k-upload .k-upload-button {
  vertical-align: bottom;
}
.k-filebrowser .k-search-wrap .k-search {
  right: 4px;
}
.k-filebrowser .k-search-wrap {
  padding: 2px .3em;
}
.k-filebrowser .k-tiles-arrange {
  margin-left: auto;
}
.k-filebrowser .k-tiles-arrange .k-dropdown {
  width: 80px;
}
.k-draghandle {
  border-width: 2px;
}
span.k-tooltip {
  padding: 9px 17px;
}
.k-block > .k-header,
.k-window-titlebar {
  padding: 0.5em 0 0.571em;
}
.k-window-titlebar .k-window-actions {
  right: 0.6em;
}
div.k-window {
  border-width: 0;
}
.k-window-title {
  right: 1.143em;
  left: 1.143em;
}
div.k-window-content {
  padding: 1.333em;
}
div.editorToolbarWindow.k-window-content {
  padding: 0;
}
.editorToolbarWindow .k-editortoolbar-dragHandle {
  line-height: 30px;
}
.k-file {
  padding: .643em .17em .643em 1em;
}
.k-scheduler-table td,
.k-scheduler-table th {
  height: 1.643em;
}
.k-gantt-treelist .k-grid-header tr {
  height: 5.714em;
}
.k-gantt-timeline .k-grid-header tr {
  height: 2.857em;
}
.k-gantt .k-task-summary:before,
.k-gantt .k-task-summary-complete:before,
.k-gantt .k-task-summary:after,
.k-gantt .k-task-summary-complete:after {
  border-width: 0;
}
.k-gantt .k-task {
  border-width: 0;
}
.k-gantt .k-task-actions {
  line-height: 2.429em;
}
.k-gantt .k-task-single {
  margin-top: -0.429em;
}
.k-gantt .k-task-template {
  padding: .643em 1.4em .643em .6em;
}
.k-gantt .k-task-milestone {
  width: 17px;
  height: 17px;
}
.k-gantt .k-task-draghandle {
  margin: 0 23px;
  border-width: 8px;
  border-radius: 0 50% 50% 50%;
  border-style: solid;
  bottom: -11px;
}
.k-gantt .k-gantt-timeline th {
  text-align: left;
}
.k-gantt .k-grid-content tr,
.k-gantt-rows tr,
.k-gantt-tasks tr {
  height: 3.5em;
}
.k-gantt-edit-form > .k-edit-form-container .k-textbox,
.k-gantt-edit-form > .k-edit-form-container .k-numerictextbox {
  width: 19.5em;
}
.k-notification-wrap {
  padding: 1.786em;
}
.k-notification-wrap > .k-i-close {
  top: 21px;
}
.k-slider-track {
  border-width: 0;
}
.k-slider-horizontal .k-slider-track,
.k-slider-horizontal .k-slider-selection {
  height: 2px;
  margin-top: -1px;
}
.k-slider-vertical .k-slider-track,
.k-slider-vertical .k-slider-selection {
  width: 2px;
  margin-left: -1px;
}
.k-slider-horizontal .k-draghandle,
.k-slider-vertical .k-draghandle,
.k-flatcolorpicker .k-hue-slider .k-draghandle,
.k-flatcolorpicker .k-transparency-slider .k-draghandle {
  width: 6px;
  height: 6px;
}
.k-flatcolorpicker .k-hue-slider .k-draghandle {
  margin-top: -1px;
}
.k-colorpicker .k-picker-wrap {
  line-height: 2.214em;
}
.k-colorpicker .k-selected-color {
  height: 2.57142857em;
  width: 2.57142857em;
}
.k-colorpicker .k-selected-color .k-i-line {
  line-height: 2.57142857em;
  font-size: 2.57142857em;
}
.k-draghandle.k-state-selected,
.k-draghandle.k-state-selected:link,
.k-flatcolorpicker .k-hue-slider .k-draghandle.k-state-selected,
.k-flatcolorpicker .k-transparency-slider .k-draghandle.k-state-selected {
  width: 10px;
  height: 10px;
}
.k-draghandle.k-state-focused.k-state-selected {
  margin-left: -2px;
}
.k-slider-horizontal .k-draghandle.k-state-selected,
.k-flatcolorpicker .k-hue-slider .k-draghandle.k-state-selected {
  top: -6px;
}
.k-slider-vertical .k-draghandle.k-state-selected {
  left: -4px;
}
/* PanelBar */
.k-panelbar-expand,
.k-panelbar-collapse {
  right: 16px;
}
/* Grid */
.k-header.k-grid-toolbar {
  border-color: #3343a4;
}
.k-grouping-header,
.k-grid-toolbar {
  padding: 0.429em;
}
.k-grouping-header {
  padding-left: 1.286em;
}
.k-grid .k-icon {
  opacity: 0.45;
}
.k-grid-filter .k-icon {
  opacity: .7;
}
.k-grid th.k-group-cell,
.k-grid td.k-group-cell {
  text-overflow: clip;
}
form.k-filter-menu .k-textbox {
  margin-bottom: 7px;
}
/* Scheduler */
.k-gantt-toolbar,
.k-scheduler-toolbar .k-nav-today,
.k-scheduler-toolbar .k-scheduler-views,
.k-scheduler-footer .k-scheduler-fullday {
  text-transform: uppercase;
}
.k-scheduler-footer .k-icon.k-i-clock {
  display: none;
}
.k-gantt-toolbar > ul > li,
.k-scheduler-toolbar > ul > li {
  border-width: 0 0 2px 0;
}
.k-gantt-toolbar li .k-link,
.k-scheduler-toolbar li .k-link,
.k-gantt-toggle {
  vertical-align: middle;
  line-height: 46px;
}
.k-gantt-toggle {
  padding: 0;
}
.k-gantt-actions {
  vertical-align: middle;
  line-height: 48px;
}
.k-gantt-actions > .k-button {
  vertical-align: middle;
}
.k-gantt-toolbar,
.k-scheduler-toolbar,
.k-scheduler-footer {
  line-height: 44px;
  vertical-align: middle;
}
.k-scheduler-footer {
  padding: 0;
}
.k-popup-edit-form .k-primary {
  float: right;
}
.k-popup-edit-form:after {
  content: " ";
  display: block;
  clear: both;
}
.k-gantt-toolbar,
.k-scheduler-toolbar {
  padding: 0 1.286em;
}
.k-scheduler-navigation {
  margin-left: -0.786em;
}
.k-drag-clue {
  font-size: 1em;
  padding: .65em 1em;
}
.k-splitbar-horizontal-hover .k-resize-handle {
  background-position: -165px -6px;
}
.k-splitbar-vertical-hover .k-resize-handle {
  background-position: -38px -309px;
}
.k-splitbar-horizontal.k-state-focused .k-resize-handle {
  background-position: -181px -6px;
}
.k-splitbar-vertical.k-state-focused .k-resize-handle {
  background-position: -70px -309px;
}
.k-grid-header .k-grid-filter,
.k-grid-header .k-header-column-menu {
  padding: .714em;
  margin: -0.786em -0.6em -0.786em 0;
}
.k-checkbox-label,
.k-radio-label {
  line-height: 18px;
}
.k-checkbox:indeterminate + .k-checkbox-label:before {
  border-width: 2px;
}
.k-checkbox + .k-checkbox-label:before {
  z-index: 1;
}
.k-checkbox:indeterminate + .k-checkbox-label:after {
  width: 10px;
  height: 10px;
  border-width: 0;
  margin-left: 0;
  top: 5px;
  left: 5px;
  transform: none;
  z-index: 2;
}
.k-radio:checked + .k-radio-label:after {
  top: 4px;
  left: 4px;
}
.k-rtl .k-widget .k-dropdown-wrap,
.k-rtl .k-widget .k-picker-wrap,
.k-rtl .k-widget .k-numeric-wrap {
  padding-left: 2.5em;
}
.k-rtl .k-widget.k-autocomplete > .k-i-close,
.k-rtl .k-widget.k-multiselect > .k-multiselect-wrap > .k-i-close {
  left: 0;
  right: initial;
  margin-left: 10px;
  margin-right: 0;
}
.k-rtl .k-widget.k-combobox > .k-dropdown-wrap > .k-i-close {
  left: 3.3em;
  right: initial;
}
.k-rtl .k-widget.k-datetimepicker .k-picker-wrap {
  padding-left: 5em;
}
.k-rtl .k-widget.k-datetimepicker .k-picker-wrap .k-icon {
  margin: 0 6px;
}
.km-pane-wrapper .k-pager-numbers .k-link,
.km-pane-wrapper .k-pager-numbers .k-state-selected,
.km-pane-wrapper .k-pager-wrap > .k-link {
  border-radius: 0;
}
.km-pane-wrapper .k-pager-numbers .k-link,
.km-pane-wrapper .k-pager-wrap > .k-link,
.km-pane-wrapper .k-pager-wrap > .k-pager-info {
  padding: .571em .86em;
}
.km-pane-wrapper .k-pager-numbers .k-state-selected {
  padding: .971em .86em .571em;
}
.km-pane-wrapper .k-pager-numbers .k-link {
  min-width: 2.429em;
}
.km-pane-wrapper .k-mobile-list .k-edit-field.k-scheduler-toolbar {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding-bottom: 0;
}
.km-pane-wrapper .k-mobile-list .k-edit-field.k-scheduler-toolbar .k-scheduler-navigation {
  margin-bottom: -0.6em;
}
.km-pane-wrapper .k-scheduler-views {
  width: auto;
}
.k-spreadsheet .k-spreadsheet-action-bar {
  font-size: 14px;
}
.k-spreadsheet-window .k-edit-label,
.k-spreadsheet-window .k-edit-field {
  padding: 0;
  line-height: 4em;
}
.k-spreadsheet-window .k-edit-label > label {
  vertical-align: middle;
}
.k-spreadsheet-window .k-edit-field .k-radio-label {
  padding-left: 1.75em;
}
.k-spreadsheet-window .k-edit-field > .k-radio-label:before,
.k-spreadsheet-window .k-edit-field > .k-radio-label:after {
  margin-top: -2px;
}
div.k-spreadsheet-window {
  padding: 1em;
}
.k-editor-dialog.k-editor-table-wizard-dialog.k-window-content {
  min-width: 325px;
  width: 25.8em;
}
.k-editor-dialog.k-editor-table-wizard-dialog .k-tabstrip-wrapper .k-content {
  min-height: 510px;
  height: 42em;
}
.k-edit-label,
.k-edit-form-container .editor-label {
  padding: 0.8em 0 1em;
}
.k-edit-form-container {
  width: 410px;
}
.k-editor-dialog .k-root-tabs {
  padding: 0 1em;
}
.k-editor-dialog ul.k-tabstrip-items {
  padding: 0;
  border: 0;
}
.k-editor-dialog.k-editor-table-wizard-dialog .k-dropdown.k-align {
  min-width: 59px;
}
.k-editor-dialog.k-editor-table-wizard-dialog .k-edit-field .k-textbox,
.k-editor-dialog.k-editor-table-wizard-dialog .k-edit-field .k-dropdown {
  width: 15.74em;
}
.k-editor-dialog.k-editor-table-wizard-dialog .k-edit-field .k-numerictextbox + .k-dropdown,
.k-editor-dialog.k-editor-table-wizard-dialog .k-dropdown.k-align {
  width: 4.7em;
}
.k-animation-container .k-popup.k-align .k-list .k-icon {
  line-height: 1.4em;
}
.k-animation-container .k-popup.k-align .k-list .k-i-align-remove {
  line-height: 1.6em;
}
.k-editor-dialog.k-editor-table-wizard-dialog .k-edit-buttons {
  padding: 0.6em 1.3em;
}
.k-grid .k-edit-cell input.k-checkbox + label.k-checkbox-label {
  margin-left: 1em;
}
.k-rtl .k-combobox-clearable .k-input {
  padding-left: 2.5em;
}
.k-rtl .k-gantt .k-task-template {
  padding: .643em .6em .643em 1.4em;
}
.k-rtl .k-gantt .k-task-draghandle {
  border-radius: 50% 0 50% 50%;
}
